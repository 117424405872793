import { Status, type ResolutionLevel, type UserPermissions } from '../../../../../types';
import type { Task } from '../../../state/tasks';
import type { ProjectTaskFormInitialValues } from './types';

interface Props {
  data: Task;
  field: keyof ProjectTaskFormInitialValues;
  permissions: UserPermissions;
  currentUserId: number;
  availableResolutionLevels: ResolutionLevel[];
}

export function fieldCanBeEdited(props: Props) {
  const { data, field, permissions, currentUserId, availableResolutionLevels } = props;

  if (!permissions?.canUpdateProjectTask) {
    return false;
  }

  if (field === 'userFunction' && data.responsible) {
    return false;
  }

  if (field === 'comments' && data.status === Status.RESOLVED && permissions?.canCreateComment) {
    return true;
  }

  if (data.status === Status.RESOLVED && !permissions?.canUpdateResolvedProjectTask) {
    return false;
  }

  if (field === 'resolutionLevel' && permissions?.canUpdateAllProjectTask) {
    const resolutionLevelByPermissions = availableResolutionLevels.filter(
      (item) => permissions[`canCreateIncidentResolutionLevel${item}`],
    );
    return resolutionLevelByPermissions.includes(data.resolutionLevel);
  }

  if (!permissions?.canUpdateAllProjectTask) {
    const userRegistered = data.registeredBy?.id === currentUserId;
    const responsibleChangeStatus = data.responsible?.id === currentUserId && field === 'status';
    const addingComments = field === 'comments';

    if (!userRegistered && !responsibleChangeStatus && !addingComments) {
      return false;
    }
  }

  return true;
}
