import type { ReactNode } from 'react';

import { Stack } from '@mui/material';

export const EntitiesPageWrapper = ({ children }: { children: ReactNode }) => (
  <Stack
    sx={{
      height: '100%',
    }}
  >
    {children}
  </Stack>
);
