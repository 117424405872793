import type { RecoilTaskInterface } from 'recoil-toolkit';

import { incidentService } from '../../api';
import { incidentLogsByIdState } from './atoms';

export const getIncidentLogsTask = (rti: RecoilTaskInterface) => async (incident: number) => {
  const { set } = rti;

  const logs = await incidentService.getIncidentLogs({ incident });

  set(incidentLogsByIdState(incident), logs.data);
};
