/* eslint-disable max-len */
import type { RecoilTaskInterface } from 'recoil-toolkit';

import { incidentsFilterState } from '.';
import { eventSettingService } from '../../api';
import type { EventSettings, UserDesktopFilters } from '../../types';
import { userState } from '../user';

export const addFilterByFlaggedTask =
  ({ set }: RecoilTaskInterface) =>
  () => {
    set(incidentsFilterState, (state) => ({ ...state, flagged: true }));
  };

export const clearFilterByFlaggedTask =
  ({ set }: RecoilTaskInterface) =>
  () => {
    set(incidentsFilterState, (state) => ({ ...state, flagged: undefined }));
  };

export const addFilterByUnreadTask =
  ({ set }: RecoilTaskInterface) =>
  () => {
    set(incidentsFilterState, (state) => ({ ...state, viewedUser: true }));
  };

export const clearFilterByUnreadTask =
  ({ set }: RecoilTaskInterface) =>
  () => {
    set(incidentsFilterState, (state) => ({ ...state, viewedUser: undefined }));
  };

export const changeFilterByTimeDateTask =
  ({ set }: RecoilTaskInterface) =>
  (value: boolean) => {
    set(incidentsFilterState, (state) => ({ ...state, hideDateTime: value }));
  };

export const setDefaultFiltersTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async (filters: UserDesktopFilters) => {
    const { currentEvent, eventSettings } = snapshot.getLoadable(userState).getValue();
    const currentEventSetting = eventSettings.find(
      (eventSetting) => eventSetting.event.id === currentEvent.id,
    ) as EventSettings;

    const { data } = await eventSettingService.update({ id: currentEventSetting.id, defaultDesktopFilters: filters });
    const newEventSettings = eventSettings.map((item) => {
      if (item.id === data.data.id) {
        return {
          ...item,
          ...data.data,
        };
      }

      return item;
    });

    set(userState, (state) => ({ ...state, eventSettings: newEventSettings }));
  };
