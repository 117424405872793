import { useTranslation } from 'react-i18next';

import { MenuProjectPlan } from '../Menu/MenuProjectPlan';
import { Layout } from './components/Layout';
import { useLayoutContext } from './model/LayoutProvider';

export const LayoutProjectPlan = () => {
  const { t } = useTranslation();
  const { hideNavigation } = useLayoutContext();

  return <Layout menu={<MenuProjectPlan hideNavigation={!hideNavigation} />} headerName={t('pmp')} />;
};
