// https://yandex.ru/dev/jsapi30/doc/ru/dg/concepts/localization
const AVAILABLE_LANGUAGES = ['ru_RU', 'ru_UA', 'uk_UA', 'tr_TR', 'en_RU', 'en_US', 'he_IL', 'en_IL'];

export function getLocale() {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  const transformLocale = userLocale.split('-').join('_');
  const isAvailableLocale = AVAILABLE_LANGUAGES.includes(transformLocale);

  return isAvailableLocale ? transformLocale : 'ru_RU';
}
