import type { AxiosError } from 'axios';
import axios from 'axios';
import type { TFunction } from 'i18next';

type ErrorAtom = {
  id: number;
  key?: string;
  error?: unknown;
  taskId?: number;
}

type StrapiError = {
  data: unknown,
  error: {
    message: string,
    name: 'ValidationError',
    status: 400
  }
};

function getErrorBadRequest(error: AxiosError<StrapiError>) {
  if (error?.response?.data?.error) {
    return error.response.data.error.message;
  }
  return undefined;
}

export function getErrorMessage(error: ErrorAtom | undefined) {
  if (error && axios.isAxiosError(error.error) && error.error?.code === 'ERR_BAD_REQUEST') {
    return getErrorBadRequest(error.error as AxiosError<StrapiError>);
  }
  return undefined;
}

export function translateServerMessage(message: string | undefined, t: TFunction<'translation'>) {
  if (message === 'Invalid identifier or password') {
    return t('invalid-identifier-or-password');
  }
  if (message === 'email must be a valid email') {
    return t('email-must-be-valid');
  }
  if (message === 'Email or Username are already taken') {
    return t('email-or-username-already-taken');
  }
  return message;
}
