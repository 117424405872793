import type { RefObject } from 'react';

import type { AgGridReact } from 'ag-grid-react';

import { useInlineAdding } from '../../../modules/EntitiesGrid';
import { useIncidentInlineAddingParams } from '../state';

export function useIncidentInlineAdding(gridRef: RefObject<AgGridReact<unknown>>) {
  const { editNewEntity } = useInlineAdding(gridRef, useIncidentInlineAddingParams);

  return {
    editPreparedIncident: editNewEntity,
  };
}
