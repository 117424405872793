import type {
  FC,
} from 'react';

import {
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { userState } from '../../state/user';

export const EventLimitFields: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const user = useRecoilValue(userState);

  return user?.isOwner ? (
    <>
      <Grid xs={6} item>
        <Controller
          name='limitUsers'
          control={control}
          render={({ field }) => (
            <TextField placeholder={t('limit-users')} label={t('limit-users')} {...field} fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
          )}
        />
      </Grid>
      <Grid xs={6} item>
        <Controller
          name='limitLocations'
          control={control}
          render={({ field }) => (
            <TextField placeholder={t('limit-locations')} label={t('limit-locations')} {...field} fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
          )}
        />
      </Grid>

    </>
  ) : null;
};
