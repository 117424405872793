import DateEditor from '../../../components/IncidentTable/components/editor/DateEditor';
import { DateRenderer } from '../../../components/IncidentTable/components/renderer';
import { dateType } from '../cellTypes';

type Props = {
  editable?: boolean,
  sortable?: boolean,
  cellEditorParams?: Record<string, unknown>
}

export const dateTimeField = ({ editable, sortable, cellEditorParams }: Props) => ({
  sortable,
  ...(editable || cellEditorParams
    ? {
      cellEditor: DateEditor,
      cellEditorPopup: true,
      cellEditorParams,
    } : {
      editable,
    }),
  ...(sortable ? {
    comparator: dateType.comparator,
    equals: dateType.equals,
    getQuickFilterText: () => '',
  } : {}),
  cellRenderer: DateRenderer,
  resizable: false,
  width: 100,
});
