import type { ReactNode } from 'react';

import { Stack } from '@mui/material';

import { WrapperBox } from '../../../../components/ControlPanel/components/WrapperBox';

type Props = {
  leftSlot: ReactNode;
  rightSlot: ReactNode;
};

export const ControlPanelWrapper = ({ leftSlot, rightSlot }: Props) => (
  <WrapperBox>
    <Stack direction='row' spacing='8px' sx={{ flexWrap: 'wrap' }} alignItems='center'>
      {leftSlot}
    </Stack>
    <Stack direction={{ md: 'column', lg: 'row' }} spacing='8px' sx={{ flexWrap: 'wrap' }} alignItems='center'>
      {rightSlot}
    </Stack>
  </WrapperBox>
);
