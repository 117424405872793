import type { FC } from 'react';

import {
  Button,
} from '@mui/material';
import { Trans } from 'react-i18next';

import {
  Icon,
} from '../../../base';

type FilterButtonProps = {
  onClick: () => void,
  color?: 'grey' | 'violet'
}

export const FilterButton: FC<FilterButtonProps> = ({ onClick, color = 'grey' }) => (
  <Button
    variant='outlined'
    onClick={onClick}
    {...(color === 'grey' ? { color: 'inherit', sx: { borderColor: '#C7D1D4', height: '40px', fontWeight: 400 } } : {})}
  >
    <Trans i18nKey='filter' />
    <Icon sx={{ marginLeft: '10px', width: '20px', height: '20px' }} htmlColor='#C7D1D4' icon='filter' />
  </Button>
);
