import { useEffect, useState } from 'react';

import type { AutocompleteGetTagProps } from '@mui/material';
import { Typography, Chip, FormControl, Grid, Stack, TextField, Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pick } from 'remeda';

import { defaultUserFunctions as baseUserFunctions } from '../../constants/defaultUserFanctions';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import useConfirmLeavePage from '../../hooks/useConfirmLeavePage';
import useDefaultUserFunctions from '../../hooks/useDefaultUserFunctions';
import { LocationMap } from '../../modules/Map/feature/LocationMap';
import type { AddUserFunction, RemoveUserFunction } from '../../state/event';
import { eventState, useUpdateEvent } from '../../state/event';
import { locationsState } from '../../state/locations';
import { locationsControlState, TypeLocation, type LocationControlItem } from '../../state/locationsForm';
import { NotificationType, useShowNotification } from '../../state/notification';
import { userState } from '../../state/user';
import { userFunctionsState } from '../../state/userFunctions';
import type { NewEvent } from '../../types';
import { chunks } from '../../utils/chunks';
import getFullUrl from '../../utils/getFullUrl';
import { Button } from '../base';
import { EventLimitFields } from '../EventLimitFields';
import { CheckboxField } from '../fields/CheckboxField';
import { ImageField } from '../fields/ImageField';
import { getUniqElementsFromArrayByName } from './lib/getUniqElementsFromArray';
import { formHasChanges } from './utils';

export const EventEditForm = () => {
  const { t } = useTranslation();
  // It is used to detect form submission and stop updating reset points in useEffect.
  const [updateEfterSubmitForm, setUpdateEfterSubmitForm] = useState(false);
  const { execute: updateEvent, loading } = useUpdateEvent();
  const { execute: showNotification } = useShowNotification();
  const existingLocations = useRecoilValue(locationsState);
  const [locations, setLocations] = useRecoilState(locationsControlState);
  const currentEvent = useRecoilValue(eventState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const user = useRecoilValue(userState);
  const { defaultUserFunctions, loading: loadingDefaultUserFunctions } = useDefaultUserFunctions();
  const { showConfirm, confirmModal: confirmModalDialog } = useConfirmDialog({
    content: t('modal-remove-plan-site-content'),
  });

  const userFunctionsWithDefault = getUniqElementsFromArrayByName([...defaultUserFunctions, ...baseUserFunctions]);

  const existingUserFunctions = userFunctions.map(({ name }) => name);
  const renderTags = (tags: readonly string[], getTagProps: AutocompleteGetTagProps) =>
    tags.map((tag: string, index: number) => {
      const item = userFunctionsWithDefault.find((i) => i.name === tag);
      return (
        <Chip
          variant='outlined'
          label={tag}
          {...getTagProps({ index })}
          {...(item && item?.canRemove === false ? { disabled: true } : {})}
        />
      );
    });

  const form = useForm<NewEvent>({
    defaultValues: {
      name: currentEvent.name,
      description: currentEvent.description,
      userFunctions: existingUserFunctions,
      limitLocations: currentEvent.limitLocations,
      limitUsers: currentEvent.limitUsers,
      sitePlan: currentEvent.sitePlan?.url ? [getFullUrl(currentEvent.sitePlan.url)] : [],
      incidentPlan: currentEvent.incidentPlan,
      projectPlan: currentEvent.projectPlan ?? false,
      dailyPlan: currentEvent.dailyPlan ?? false,
    },
  });
  const { handleSubmit, control, watch } = form;

  const sitePlanValue = watch('sitePlan');
  const dailyPlanValue = watch('dailyPlan');
  const incidentPlanValue = watch('incidentPlan');

  const [imageMap, setImageMap] = useState<{
    prev: string | undefined;
    curr: string | undefined;
  }>({
    prev: currentEvent.sitePlan?.url ? getFullUrl(currentEvent.sitePlan.url) : undefined,
    curr: currentEvent.sitePlan?.url ? getFullUrl(currentEvent.sitePlan.url) : undefined,
  });

  useEffect(() => {
    if (!sitePlanValue || sitePlanValue.length === 0) {
      setImageMap((prev) => ({
        prev: prev.curr,
        curr: undefined,
      }));
    } else {
      const url = typeof sitePlanValue[0] === 'string' ? sitePlanValue[0] : URL.createObjectURL(sitePlanValue[0]);
      setImageMap((prev) => ({
        prev: prev.curr,
        curr: url,
      }));
    }
    setUpdateEfterSubmitForm(false);
  }, [currentEvent, sitePlanValue]);

  useEffect(() => {
    if (imageMap.curr !== imageMap.prev && !updateEfterSubmitForm) {
      const locationChunks = chunks(locations, 5);
      const resetLocationGeo = locationChunks.map<LocationControlItem[]>((item, i) =>
        item.map((location, j) => ({
          ...location,
          geo: {
            id: 'id' in location.geo ? location.geo.id : 0,
            latitude: i,
            longitude: j,
          },
        })),
      );
      setLocations(resetLocationGeo.flat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageMap]);

  const formChanged = formHasChanges({
    name: {
      old: currentEvent.name,
      new: watch('name'),
    },
    description: {
      old: currentEvent.description,
      new: watch('description'),
    },
    userFunctions: {
      old: existingUserFunctions,
      new: watch('userFunctions'),
    },
    locations: {
      old: existingLocations,
      new: locations,
    },
  });
  const { confirmModal } = useConfirmLeavePage(formChanged, t('you-have-unsaved-changes-confirm-leave'));

  useEffect(() => {
    setLocations(
      existingLocations.map(({ id, title, description, geo }) => ({
        id,
        title,
        description,
        geo: {
          ...geo,
        },
        type: TypeLocation.Edit,
      })),
    );

    return () => {
      setLocations([]);
    };
  }, [existingLocations, setLocations]);

  useEffect(() => {
    form.setValue(
      'userFunctions',
      userFunctions.map(({ name }) => name),
    );
  }, [userFunctions, form]);

  const onSubmit = (values: NewEvent) => {
    const removedUserFunctions = userFunctions.reduce<RemoveUserFunction>(
      (acc, userFunction) => {
        if (!values.userFunctions.includes(userFunction.name)) {
          acc.data.push(userFunction);
        }
        return acc;
      },
      { type: 'remove', data: [] },
    );

    const addedUserFunctions = values.userFunctions.reduce<AddUserFunction>(
      (acc, name) => {
        if (!existingUserFunctions.includes(name)) {
          acc.data.push(name);
        }

        return acc;
      },
      { type: 'add', data: [] },
    );

    updateEvent({
      event: {
        ...pick(values, ['name', 'description', 'sitePlan', 'incidentPlan', 'dailyPlan', 'projectPlan']),
        ...(user.isOwner ? pick(values, ['limitUsers', 'limitLocations']) : {}),
        userFunctions: {
          added: addedUserFunctions,
          removed: removedUserFunctions,
        },
        locations,
        id: currentEvent.id,
      },
      successCallback: () => {
        showNotification({ type: NotificationType.SUCCESS, text: t('update-project-success') });
        setUpdateEfterSubmitForm(true);
      },
    });
  };

  const handleRemoveFile = async () => {
    const isOk = await showConfirm();

    return isOk;
  };

  return (
    <FormProvider {...form}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item container rowSpacing={2} columnSpacing={2} xs={12}>
              <Grid item container rowSpacing={2} xs={7} direction='column' wrap='nowrap'>
                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <Controller
                    name='name'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField placeholder={t('name')} label={t('name')} fullWidth required {...field} />
                    )}
                  />
                </Grid>

                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <Controller
                    name='description'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={t('description')}
                        label={t('description')}
                        fullWidth
                        required
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <FormControl fullWidth>
                    <Controller
                      name='userFunctions'
                      control={control}
                      render={({ field: { value, ref, onBlur, onChange } }) => (
                        <Autocomplete
                          disableClearable
                          value={value}
                          onChange={(_, data) => onChange(data)}
                          multiple
                          options={userFunctionsWithDefault.map((option) => option.name)}
                          freeSolo
                          disableCloseOnSelect
                          filterSelectedOptions
                          renderTags={renderTags}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('functions')}
                              placeholder={t('functions')}
                              inputProps={{ ...params.inputProps, maxLength: 30 }}
                            />
                          )}
                          onBlur={onBlur}
                          ref={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid xs={5} item>
                <FormControl fullWidth>
                  <Typography variant='fieldLabel'>
                    {sitePlanValue?.length === 0 ? t('site-plan-download') : t('site-plan')}
                  </Typography>
                  <ImageField
                    control={control}
                    name='sitePlan'
                    allowImagePreview={false}
                    beforeRemoveFile={handleRemoveFile}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <CheckboxField
                    control={control}
                    label={t('incidents')}
                    name='incidentPlan'
                    disabled={!dailyPlanValue}
                  />
                  <CheckboxField control={control} label={t('project-plan')} name='projectPlan' />
                  <CheckboxField
                    control={control}
                    label={t('daily-plan')}
                    name='dailyPlan'
                    disabled={!incidentPlanValue}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <Box
                sx={{
                  height: '500px',
                }}
                pt={2}
              >
                <LocationMap imageMap={imageMap.curr} />
              </Box>
            </Grid>

            <EventLimitFields />
          </Grid>
        </Stack>

        <Box pt={2}>
          <Button
            fullWidth
            type='submit'
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
            loading={loading || loadingDefaultUserFunctions}
          >
            <Trans i18nKey='update-project' />
          </Button>
        </Box>
      </Box>
      {confirmModal}
      {confirmModalDialog}
    </FormProvider>
  );
};
