import type { ElementType, FC } from 'react';

import type { SvgIconTypeMap } from '@mui/material';
import { SvgIcon } from '@mui/material';

import { ReactComponent as CheckboxEmptyIcon } from './icons/checkbox-empty.svg';
import { ReactComponent as CheckboxIcon } from './icons/checkbox.svg';
import { ReactComponent as CircleIcon } from './icons/circle.svg';
import { ReactComponent as DateIcon } from './icons/date.svg';
import { ReactComponent as EditIcon } from './icons/edit.svg';
import { ReactComponent as EmptyCircleIcon } from './icons/emptyCircle.svg';
import { ReactComponent as EmptyFlagIcon } from './icons/emptyFlag.svg';
import { ReactComponent as ExclamationIcon } from './icons/exclamation.svg';
import { ReactComponent as ExclamationOutlineIcon } from './icons/exclamationOutline.svg';
import { ReactComponent as FileTypeAvi } from './icons/file-type-avi.svg';
import { ReactComponent as FileTypeKey } from './icons/file-type-key.svg';
import { ReactComponent as FileTypeMov } from './icons/file-type-mov.svg';
import { ReactComponent as FileTypeMp4 } from './icons/file-type-mp4.svg';
import { ReactComponent as FileTypeMpeg } from './icons/file-type-mpeg.svg';
import { ReactComponent as FileTypePdf } from './icons/file-type-pdf.svg';
import { ReactComponent as FileTypePowerpoint } from './icons/file-type-powerpoint.svg';
import { ReactComponent as FileTypeXls } from './icons/file-type-xls.svg';
import { ReactComponent as FileTypeDoc } from './icons/file-type.doc.svg';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as FlagIcon } from './icons/flag.svg';
import { ReactComponent as LocationIcon } from './icons/location.svg';
import { ReactComponent as PdfIcon } from './icons/pdf.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as ShowColumnIcon } from './icons/showColumn.svg';
import { ReactComponent as SortIcon } from './icons/sort.svg';
import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as ExcelIcon } from './icons/xlsx.svg';

export type IconKind =
  | 'circle'
  | 'date'
  | 'emptyCircle'
  | 'exclamation'
  | 'exclamationOutline'
  | 'filter'
  | 'location'
  | 'plus'
  | 'search'
  | 'checkbox'
  | 'checkboxEmpty'
  | 'showColumn'
  | 'pdf'
  | 'excel'
  | 'flag'
  | 'emptyFlag'
  | 'sort'
  | 'edit'
  | 'trash'
  | 'fileTypePdf'
  | 'fileTypeXls'
  | 'fileTypeDoc'
  | 'fileTypePowerpoint'
  | 'fileTypeKey'
  | 'fileTypeAvi'
  | 'fileTypeMov'
  | 'fileTypeMp4'
  | 'fileTypeMpeg';

const ICONS: Record<IconKind, ElementType> = {
  circle: CircleIcon,
  date: DateIcon,
  emptyCircle: EmptyCircleIcon,
  exclamation: ExclamationIcon,
  exclamationOutline: ExclamationOutlineIcon,
  filter: FilterIcon,
  location: LocationIcon,
  plus: PlusIcon,
  search: SearchIcon,
  sort: SortIcon,
  checkbox: CheckboxIcon,
  checkboxEmpty: CheckboxEmptyIcon,
  showColumn: ShowColumnIcon,
  pdf: PdfIcon,
  excel: ExcelIcon,
  flag: FlagIcon,
  emptyFlag: EmptyFlagIcon,
  edit: EditIcon,
  trash: TrashIcon,
  fileTypePdf: FileTypePdf,
  fileTypeXls: FileTypeXls,
  fileTypeDoc: FileTypeDoc,
  fileTypePowerpoint: FileTypePowerpoint,
  fileTypeKey: FileTypeKey,
  fileTypeAvi: FileTypeAvi,
  fileTypeMov: FileTypeMov,
  fileTypeMp4: FileTypeMp4,
  fileTypeMpeg: FileTypeMpeg,
};

type Props = SvgIconTypeMap['props'] & {
  icon: IconKind;
}

const Icon: FC<Props> = ({ icon, ...props }) => <SvgIcon inheritViewBox component={ICONS[icon]} {...props} sx={{ ...props.sx, fill: 'none' }} />;

export default Icon;
