import { useEffect, useState } from 'react';

interface UseCreateSquareImageParams {
  url: string;
}

export const useCreateSquareImage = (params: UseCreateSquareImageParams) => {
  const { url } = params;
  const [image, setImage] = useState<HTMLCanvasElement>();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const max = Math.max(img.naturalHeight, img.naturalWidth);
      const canvas = document.createElement('canvas');
      canvas.width = max;
      canvas.height = max;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(img, max / 2 - img.width / 2, max / 2 - img.height / 2);
        setImage(canvas);
      }

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            setImageUrl(blobUrl);
          }
        },
        'image/png',
        0.95,
      );
    };
  }, [url]);

  return { image, imageUrl };
};
