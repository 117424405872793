import { PlaylistAddCheck, PlaylistRemove } from '@mui/icons-material';
import { Box } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';

import { Button } from '../../../../../components/base';
import { getAllParentsNode } from '../../../../EntitiesGrid/lib/getAllParentsNode';
import { newEntityReady, useAddProjectTask } from '../../../state/projectTasks';
import { TaskType } from '../../../state/tasks';
import { newChildTaskState } from '../model/store/newChildTask.state';

export const ChildNewRowRenderer = (props: ICellRendererParams) => {
  const { api, data, node } = props;
  const { execute: addTask } = useAddProjectTask();
  const setChildData = useSetRecoilState(newChildTaskState);

  const onCreateTask = async () => {
    if (data) {
      const { title, description, endDatetime, startDatetime, responsible, userFunction, status, parent } = data;
      await addTask({
        data: {
          title,
          description,
          startDatetime,
          endDatetime,
          responsible,
          userFunction,
          status,
          type: TaskType.ProjectTask,
          parent: parent ?? undefined,
        },
      });
      setChildData(null);
    }
  };

  const onRemoveTask = () => {
    api.applyTransaction({
      remove: [data],
    });

    // Update parents nodes, for update styles in grid
    api.refreshCells({
      rowNodes: getAllParentsNode(node),
      force: true,
    });
    setChildData(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ paddingInline: '8px' }}>{data.count}</Box>
      <Box>
        <Button type='button' onClick={onCreateTask} sx={{ minWidth: 40 }} disabled={!newEntityReady(data ?? {})}>
          <PlaylistAddCheck />
        </Button>
        <Button type='button' onClick={onRemoveTask} sx={{ minWidth: 40 }}>
          <PlaylistRemove />
        </Button>
      </Box>
    </Box>
  );
};
