import type { ChangeEvent, FC } from 'react';

import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';

export type TrimTextFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string;
  onChange: (value: string) => void;
};

const TrimTextField: FC<TrimTextFieldProps> = ({ value = '', onChange, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value.trim());
  };

  return <TextField {...rest} onChange={handleChange} value={value} />;
};

export default TrimTextField;
