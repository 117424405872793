import type { FirstDataRenderedEvent } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';

import { getResolutionLevelForChildrenNodes } from '../../../lib/resolutionLevelForChildrenNodes';
import type { ProjectTaskFormProps } from '../../ProjectTaskForm/ProjectTaskForm';

export const useScrollToRowByParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToRowByParams = (callback: (params: ProjectTaskFormProps) => void) => (event: FirstDataRenderedEvent) => {
    if (searchParams.has('taskId')) {
      const currentTaskId = searchParams.get('taskId');
      searchParams.delete('taskId');
      setSearchParams(searchParams);
      const node = event.api.getRowNode(currentTaskId ?? '');

      if (node) {
        const availableResolutionLevels = getResolutionLevelForChildrenNodes(node);
        event.api.ensureNodeVisible(node, 'bottom');
        callback({
          id: Number(currentTaskId),
          count: node?.data.count,
          availableResolutionLevels,
        });
      }
    }
  };

  return { scrollToRowByParams };
};
