import type { FC, MouseEvent } from 'react';
import { useState, useCallback } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { useLogout } from '../../state/auth';
import { userState, useSendNotification } from '../../state/user';
import getFullName from '../../utils/getFullName';
import { UserAvatar } from '../base';
import { UserMenu } from '../UserMenu';

type Props = {
  sx?: SxProps<Theme>;
};

export const UserControl: FC<Props> = ({ sx }) => {
  const user = useRecoilValue(userState);
  const { execute: logout } = useLogout();
  const { notificationSettings } = user;
  const { execute: changeSendNotfication } = useSendNotification();
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const onSwitchSendNotificationEmail = useCallback(() => {
    changeSendNotfication({
      email: !notificationSettings?.email,
    });
  }, [changeSendNotfication, notificationSettings]);
  const onSwitchSendNotificationPush = useCallback(() => {
    changeSendNotfication({
      push: !notificationSettings?.push,
    });
  }, [changeSendNotfication, notificationSettings]);

  return (
    <>
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          width: 'fit-content',
          border: '1px solid #C7D1D4',
          borderRadius: '8px',
          ...sx,
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          <Stack
            direction='row'
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
            }}
            spacing={2}
          >
            <UserAvatar user={user} size='s' />
            <Typography variant='body2'>{getFullName(user)}</Typography>
          </Stack>
        </IconButton>
      </Box>
      <UserMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        onLogoutClick={logout}
        sendNotificationEmail={notificationSettings?.email}
        onSwitchSendNotificationEmail={onSwitchSendNotificationEmail}
        sendNotificationPush={notificationSettings?.push}
        onSwitchSendNotificationPush={onSwitchSendNotificationPush}
      />
    </>
  );
};
