import type { ICellRendererParams } from 'ag-grid-enterprise';

import { checkIsPrepareChildField } from '../lib/checkIsPrepareChildField';
import { ChildNewRowRenderer } from './ChildNewRowRenderer';
import { ParentNewRowRenderer } from './ParentNewRowRenderer';

type FactoryNewRowRendererProps = ICellRendererParams;

export const FactoryNewRowRenderer = (props: FactoryNewRowRendererProps) => {
  const { data } = props;
  const isPreparedRow = checkIsPrepareChildField(data.id);

  if (isPreparedRow) {
    return <ChildNewRowRenderer {...props} />;
  }

  return <ParentNewRowRenderer {...props} />;
};
