import { type FC, useRef, type ChangeEvent } from 'react';

import { Box } from '@mui/material';
import { Trans } from 'react-i18next';

import { FILE_TYPE_MIME } from '../../../../utils/mime';

interface ImportExcelButtonProps {
  onClick: (file: File) => void;
  onClose?: () => void;
}

export const ImportExcelButton: FC<ImportExcelButtonProps> = ({ onClick, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChooseFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onChangeInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target?.files[0] : null;

    if (!file) return;

    await onClick(file);
    onClose?.();

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Box onClick={onChooseFile}>
        <Trans i18nKey='import-from-excel' />
      </Box>
      <Box
        component='input'
        ref={inputRef}
        type='file'
        onChange={onChangeInput}
        accept={FILE_TYPE_MIME.excel.join(',')}
        sx={{ visibility: 'hidden', position: 'absolute', pointerEvents: 'none' }}
      />
    </>
  );
};
