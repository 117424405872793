import type { PostSortRowsParams } from 'ag-grid-community';

import { checkIsPrepareChildField } from './checkIsPrepareChildField';

export function excludeSortPrepareField(params: PostSortRowsParams) {
  const { nodes } = params;

  let nextInsertPos = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < nodes.length; i++) {
    if (checkIsPrepareChildField(nodes[i].data.id)) {
      nodes.splice(nextInsertPos, 0, nodes.splice(i, 1)[0]);
      // eslint-disable-next-line no-plusplus
      nextInsertPos++;
    }
  }
}
