import type { IncidentMarkerProps, LocationMarkerProps } from '../../../shared/components/GoogleMap';
import type { IncidentMapDataProviderApi } from '../model/IncidentMapProvider';
import type { MetaData } from './types';

type CustomMarkerProps = {
  key: string;
  infoWindowContent: string;
  options: {
    metadata: MetaData;
  };
};

export function normalizeGoogleMapData(data: IncidentMapDataProviderApi) {
  const { locations, incidents, center, onMarkerClick, onClusterClick, onDraggableMarker, ...rest } = data;

  const googleCenter: google.maps.LatLngLiteral = {
    lng: center[0],
    lat: center[1],
  };

  const incidentPoints: (CustomMarkerProps & IncidentMarkerProps)[] = incidents.map((incident) => ({
    key: incident.key,
    position: {
      lng: incident.position[0],
      lat: incident.position[1],
    },
    options: {
      metadata: incident.metadata,
    },
    color: incident.color,
    onClick: onMarkerClick(incident.metadata),
    draggable: true,
    onDragEnd: (e) => {
      if (e.latLng) {
        onDraggableMarker(incident.metadata.id, {
          longitude: e.latLng.lng(),
          latitude: e.latLng.lat(),
        });
      }
    },
    infoWindowContent: incident.hint,
  }));

  const locationPoints: (CustomMarkerProps & LocationMarkerProps)[] = locations.map((location) => ({
    key: location.key,
    position: {
      lng: location.position[0],
      lat: location.position[1],
    },
    options: {
      metadata: location.metadata,
    },
    label: location?.label
      ? {
          text: location.label,
          fontWeight: 'bold',
        }
      : undefined,
    onClick: onMarkerClick(location.metadata),
    infoWindowContent: location.hint,
  }));

  const handleClickCluster = (cluster: { getMarkers: () => google.maps.Marker[] }) => {
    const markersInCluster = cluster
      .getMarkers()
      .map((marker) => marker.get('metadata')) as CustomMarkerProps['options']['metadata'][];
    onClusterClick(markersInCluster)();
  };

  return { locationPoints, incidentPoints, center: googleCenter, onClusterClick: handleClickCluster, ...rest };
}
