import type { LngLat } from '@yandex/ymaps3-types';
import type { Feature } from '@yandex/ymaps3-types/packages/clusterer';

import type { IncidentMapDataProviderApi } from '../model/IncidentMapProvider';
import type { IncidentMarker, LocationMarker } from './types';

export type CustomFeatureLocation = Feature & {
  properties: LocationMarker;
};

export type CustomFeatureIncident = Feature & {
  properties: IncidentMarker;
};

export function normalizeYandexMapData(data: IncidentMapDataProviderApi) {
  const { locations, incidents, onDraggableMarker, ...rest } = data;

  const locationPoints: CustomFeatureLocation[] = locations.map((location) => ({
    type: 'Feature',
    id: location.key,
    geometry: {
      type: 'Point',
      coordinates: location.position,
    },
    properties: location,
  }));

  const incidentPoints: CustomFeatureIncident[] = incidents.map((incident) => ({
    type: 'Feature',
    id: incident.key,
    geometry: {
      type: 'Point',
      coordinates: incident.position,
    },
    properties: incident,
  }));

  const handleDraggableMarker = (incident: IncidentMarker) => (coordinates: LngLat) => {
    onDraggableMarker(incident.metadata.id, {
      longitude: coordinates[0],
      latitude: coordinates[1],
    });
  };

  return { locationPoints, incidentPoints, onDraggableMarker: handleDraggableMarker, ...rest };
}
