import { compareAsc, isEqual } from 'date-fns';
import { equals as baseEquals, identity } from 'remeda';

import { DATE_START_OF_TIME } from '../../../constants/base';
import type { User, UserFunction } from '../../../types';
import { Status } from '../../../types';
import formatDate from '../../../utils/formatDate';
import getFullName from '../../../utils/getFullName';

export type CellTypeFns<T> = {
  toString: (value: T) => string;
  comparator: (valueA: T, valueB: T) => number;
  equals: (valueA: T, valueB: T) => boolean;
};

const numberComparator = (valueA: number, valueB: number) => valueA - valueB;

export const numberType = {
  toString: (value: number) => (value ? value.toString() : ''),
  comparator: (valueA: number, valueB: number) => valueA - valueB,
  equals: baseEquals,
};

type NullableDateType = Date | number | undefined | null;

export const dateType = {
  toString: (value: Date | undefined, hideDateTime = false) => (value ? formatDate(value, hideDateTime) : ''),
  comparator: (valueA: NullableDateType, valueB: NullableDateType) => {
    const valueAWithDefault = valueA || DATE_START_OF_TIME;
    const valueBWithDefault = valueB || DATE_START_OF_TIME;
    return compareAsc(valueAWithDefault, valueBWithDefault);
  },
  equals: isEqual,
};

const stringComparator = (valueA: string, valueB: string) => {
  if (valueA < valueB) return -1;
  if (valueA > valueB) return 1;
  return 0;
};
export const stringType = {
  toString: identity,
  comparator: stringComparator,
  equals: baseEquals,
};

const userToString = (value: Pick<User, 'firstName' | 'lastName'> | undefined) => (value ? getFullName(value) : '');
const equalsEntity = (entity1?: { id: number }, entity2?: { id: number }) => entity1?.id === entity2?.id;

export const userType = {
  toString: userToString,
  comparator: (valueA: User, valueB: User) => stringComparator(userToString(valueA), userToString(valueB)),
  equals: equalsEntity,
};
const functionToString = (userFunction: UserFunction | undefined) => (userFunction ? userFunction.name : '');

export const userFunctionType = {
  toString: functionToString,
  comparator: (valueA: UserFunction, valueB: UserFunction) =>
    stringComparator(functionToString(valueA), functionToString(valueB)),
  equals: equalsEntity,
};

const STATUS_ORDER = {
  [Status.DRAFT]: 0,
  [Status.IN_PROGRESS]: 2,
  [Status.REGISTERED]: 1,
  [Status.RESOLVED]: 3,
};

export const statusType = {
  toString: identity,
  comparator: (valueA: Status, valueB: Status) => numberComparator(STATUS_ORDER[valueA], STATUS_ORDER[valueB]),
  equals: baseEquals,
};

const multiUsersStringify = (users: User[] | undefined) => (users || []).map(userToString).join(', ');

const toIds = <T extends { id: number }>(entities: T[] | undefined) => (entities || []).map((entity) => entity.id);

export const multiUsersType = {
  toString: multiUsersStringify,
  comparator: (valueA: User[] | undefined, valueB: User[] | undefined) =>
    stringComparator(multiUsersStringify(valueA), multiUsersStringify(valueB)),
  equals: (valueA: User[] | undefined, valueB: User[] | undefined) => baseEquals(toIds(valueA), toIds(valueB)),
};
