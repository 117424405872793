import { useEffect, useState } from 'react';

import type { Incident, PreparedNewIncident } from '../../../../../types';
import getBase64 from '../../../../../utils/getBase64';
import type { ImageItemType } from './types';

const useSourceImages = (incident: Incident | PreparedNewIncident) => {
  const [sourceImages, setSourceImages] = useState<ImageItemType[]>([]);
  const isCreatedIncident = 'id' in incident;

  useEffect(() => {
    if (!isCreatedIncident) {
      Promise.all<ImageItemType>(
        incident.photoSources.map(({ source }) => getBase64(source).then(
          (base64) => new Promise((resolve) => {
            const image = new Image();
            image.src = base64;

            image.onload = () => {
              const { width, height } = image;

              resolve({ width, height, url: base64 });
            };
          }),
        )),
      ).then((data) => setSourceImages(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedIncident, setSourceImages]);

  return {
    sourceImages,
  };
};

export default useSourceImages;
