import { useState } from 'react';

import type { FilePondProps } from 'react-filepond';
import { FilePond as FilePondBase } from 'react-filepond';

import { useFullScreen } from '../FullScreen';

export const FilePond = (props: FilePondProps) => {
  const { onaddfile, ...rest } = props;
  const { fullScreen, onToggleFullScreen } = useFullScreen();
  const [isOpenedFullScreen] = useState(fullScreen);

  return (
    <FilePondBase
      {...rest}
      onaddfile={(err, file) => {
        if (isOpenedFullScreen) {
          onToggleFullScreen();
        }
        onaddfile?.(err, file);
      }}
    />
  );
};
