import { useMemo } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { useTheme } from '@mui/material';
import type { ColDef, SuppressKeyboardEventParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import type { TFunction } from 'i18next';
import { renderToString } from 'react-dom/server';
import { useRecoilValue } from 'recoil';

import { dateTimeField, multiUsersGridField } from '../../../modules/EntitiesGrid';
import { comparatorUserFunction } from '../../../modules/Tasks/lib/comparators';
import { currentEventSettings, permissionState } from '../../../state/event';
import { ResolutionLevel, type Incident } from '../../../types';
import { Icon } from '../../base';
import {
  CommentsEditor,
  LocationEditor,
  ResolutionLevelEditor,
  ResponsibleEditor,
  StatusEditor,
  TextEditor,
  UserFunctionEditor,
} from '../components/editor';
import {
  BooleanRenderer,
  CommentsRenderer,
  FlaggedRenderer,
  IdRenderer,
  LocationRenderer,
  PhotosRenderer,
  ResolutionLevelRenderer,
  StatusRenderer,
  TextRenderer,
  UrgentRenderer,
  UserFunctionRenderer,
  UserRenderer,
} from '../components/renderer';
import { RelationRenderer } from '../components/renderer/RelationRenderer';
import { ColumnKey, newRowIncidentState } from '../state';
import { cellСanBeEdited } from '../utils';
import type { CellPermissions } from '../utils/cellCanBeEdited';
import getHeaderTemplate from '../utils/getHeaderTemplate';

type Setter = (params: ValueSetterParams) => boolean;

const suppressKeyboardEventFn = (params: SuppressKeyboardEventParams) => params.event.key === 'Enter';

const equalsEntity = (entity1?: { id: number }, entity2?: { id: number }) => entity1?.id === entity2?.id;

const isExistedIncident = (params: { data: { id: number } }) => Boolean(params.data.id);

export const countField = () => ({
  field: 'count',
  headerName: '#',
  width: 74,
  editable: false,
  resizable: false,
  cellRenderer: IdRenderer,
  sortingOrder: ['desc', 'asc'] as ('desc' | 'asc')[],
  cellStyle: {
    cursor: 'pointer',
  },
});

export const flaggedField = (flaggedSetter: Setter, flaggedHeaderTemplate: string) => ({
  headerComponentParams: {
    template: flaggedHeaderTemplate,
  },
  field: 'isFlagged',
  width: 60,
  resizable: false,
  editable: false,
  singleClickEdit: true,
  cellRenderer: FlaggedRenderer,
  valueSetter: flaggedSetter,
  sortable: false,
  suppressMovable: true,
  lockVisible: true,
});

export const urgentField = (urgentHeaderTemplate: string) => ({
  headerComponentParams: {
    template: urgentHeaderTemplate,
  },
  field: 'isUrgent',
  headerClass: 'urgent-header-cell',
  width: 70,
  resizable: false,
  editable: false,
  singleClickEdit: true,
  cellRenderer: UrgentRenderer,
});

export const relationField = (): ColDef<Incident> => ({
  headerComponentParams: {
    template: getHeaderTemplate({
      contentTemplate: renderToString(<LinkIcon htmlColor='rgba(0, 0, 0, 0.54)' viewBox='0 0 26 26' />),
    }),
  },
  field: 'relations',
  width: 70,
  resizable: false,
  editable: false,
  cellRenderer: RelationRenderer,
  valueGetter: (params) => (params.data?.relations ? params.data?.relations?.length > 0 : false),
});

export const titleField = (t: TFunction<'translation'>, showRequired = false): ColDef => ({
  headerName: showRequired ? t('title-required') : t('title'),
  field: 'title',
  cellClass: 'title-cell',
  cellRenderer: TextRenderer,
  cellEditorPopup: true,
  cellEditor: TextEditor,
  cellEditorParams: {
    inputProps: {
      maxLength: 255,
    },
  },
  minWidth: 130,
  flex: 1,
  suppressKeyboardEvent: suppressKeyboardEventFn,
});

export const descriptionField = (t: TFunction<'translation'>) => ({
  headerName: t('description'),
  field: 'description',
  cellClass: 'description-cell',
  cellEditorPopup: true,
  cellEditor: TextEditor,
  cellRenderer: TextRenderer,
  minWidth: 170,
  flex: 1,
  suppressKeyboardEvent: suppressKeyboardEventFn,
});

export const registeredByField = (t: TFunction<'translation'>) => ({
  headerName: t('by'),
  field: 'registeredBy',
  cellRenderer: UserRenderer,
  editable: false,
  resizable: false,
  width: 60,
});

export const statusByField = (
  t: TFunction<'translation'>,
  statusSetter: Setter,
  permissions: CellPermissions['permissions'],
) => ({
  headerName: t('status'),
  field: 'status',
  cellEditor: StatusEditor,
  cellEditorParams: {
    permissions,
  },
  cellEditorPopup: true,
  width: 112,
  resizable: false,
  cellRenderer: StatusRenderer,
  valueSetter: statusSetter,
  singleClickEdit: true,
  editable: cellСanBeEdited,
});

export const resolutionLevelField = (t: TFunction<'translation'>, levels: ResolutionLevel[]) => ({
  headerName: t('level'),
  field: 'resolutionLevel',
  cellEditor: ResolutionLevelEditor,
  cellEditorPopup: true,
  width: 84,
  resizable: false,
  cellRenderer: ResolutionLevelRenderer,
  cellEditorParams: {
    levels,
  },
  singleClickEdit: true,
});
export const userFunctionField = (t: TFunction<'translation'>): ColDef => ({
  headerName: t('function'),
  field: 'userFunction',
  cellEditorPopup: true,
  cellRenderer: UserFunctionRenderer,
  cellEditor: UserFunctionEditor,
  valueGetter: (params: ValueGetterParams) => params.data.userFunction?.name,
  width: 120,
  singleClickEdit: true,
  comparator: comparatorUserFunction,
});

export const locationField = (t: TFunction<'translation'>) => ({
  headerName: t('location'),
  field: 'location',
  cellRenderer: LocationRenderer,
  cellEditor: LocationEditor,
  cellEditorPopup: true,
  singleClickEdit: true,
  width: 125,
});

export const locationDescriptionField = (t: TFunction<'translation'>) => ({
  headerName: t('loc.-details'),
  cellClass: 'location-details-cell',
  field: 'locationDescription',
  cellEditorPopup: true,
  cellEditor: 'agLargeTextCellEditor',
  cellRenderer: TextRenderer,
  width: 125,
  sortable: false,
});

export const responsibleField = (t: TFunction<'translation'>) => ({
  headerName: t('resp.'),
  field: 'responsible',
  cellRenderer: UserRenderer,
  cellEditor: ResponsibleEditor,
  equals: equalsEntity,
  cellEditorPopup: true,
  resizable: false,
  singleClickEdit: true,
  width: 60,
  minWidth: 60,
});

export const isResponsibleInformedField = (t: TFunction<'translation'>) => ({
  headerName: t('not.'),
  field: 'isResponsibleInformed',
  width: 60,
  minWidth: 60,
  resizable: false,
  editable: false,
  singleClickEdit: true,
  cellRenderer: BooleanRenderer,
});

export const photosField = (t: TFunction<'translation'>) => ({
  headerName: t('photos'),
  field: 'photos',
  cellRenderer: PhotosRenderer,
  width: 110,
  resizable: false,
  editable: false,
  cellClass: 'photos-cell',
  sortable: false,
});

export const commentsField = (
  t: TFunction<'translation'>,
  commentsSetter: Setter,
  permissions: CellPermissions['permissions'],
): ColDef => ({
  headerName: t('comments'),
  field: 'comments',
  cellRenderer: CommentsRenderer,
  cellEditor: CommentsEditor,
  cellEditorPopup: true,
  cellEditorParams: {
    permissions,
  },
  singleClickEdit: true,
  editable: isExistedIncident,
  valueSetter: commentsSetter,
  sortable: false,
  suppressKeyboardEvent: suppressKeyboardEventFn,
  minWidth: 250,
});

export const registeredDateField = (t: TFunction<'translation'>) => ({
  ...dateTimeField({
    editable: false,
  }),
  headerName: t('when'),
  field: 'registeredDate',
});

export const resolvedDateField = (t: TFunction<'translation'>) => ({
  ...dateTimeField({
    editable: false,
  }),
  headerName: t('resolved'),
  field: 'resolvedDate',
});

export const endDateTimeField = (t: TFunction<'translation'>) => ({
  ...dateTimeField({
    editable: true,
  }),
  headerName: t('end-datetime'),
  field: 'endDatetime',
});

type Props = {
  t: TFunction<'translation'>;
  commentsSetter: Setter;
  flaggedSetter: Setter;
  statusSetter: Setter;
};

function useColumnDefs(props: Props) {
  const { t, commentsSetter, flaggedSetter, statusSetter } = props;

  const theme = useTheme();
  const permissions = useRecoilValue(permissionState);
  const newEntity = useRecoilValue(newRowIncidentState);
  const availableResolutionLevels = Object.values(ResolutionLevel).filter(
    (item) => permissions[`canCreateIncidentResolutionLevel${item}`],
  );
  const eventSettings = useRecoilValue(currentEventSettings);

  const urgentHeaderTemplate = useMemo(
    () =>
      getHeaderTemplate({
        contentTemplate: renderToString(<Icon htmlColor={theme.palette.error.main} icon='exclamation' />),
      }),
    [theme.palette.error],
  );
  const flaggedHeaderTemplate = useMemo(
    () =>
      getHeaderTemplate({
        contentTemplate: renderToString(<Icon htmlColor={theme.palette.primary.main} icon='flag' />),
        isSort: false,
      }),
    [theme.palette.primary],
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      countField(),
      flaggedField(flaggedSetter, flaggedHeaderTemplate),
      urgentField(urgentHeaderTemplate),
      ...(eventSettings.availableDailyPlan || eventSettings.availableProjectPlan ? [relationField()] : []),
      titleField(t, Boolean(newEntity)),
      descriptionField(t),
      registeredByField(t),
      statusByField(t, statusSetter, permissions),
      resolutionLevelField(t, availableResolutionLevels),
      userFunctionField(t),
      locationField(t),
      locationDescriptionField(t),
      responsibleField(t),
      isResponsibleInformedField(t),
      photosField(t),
      commentsField(t, commentsSetter, permissions),
      registeredDateField(t),
      resolvedDateField(t),
      endDateTimeField(t),
      {
        ...multiUsersGridField({ editable: true, sortable: false }),
        headerName: t('participants'),
        field: ColumnKey.Participants,
        minWidth: 250,
      },
    ],
    [
      flaggedSetter,
      flaggedHeaderTemplate,
      urgentHeaderTemplate,
      eventSettings.availableDailyPlan,
      eventSettings.availableProjectPlan,
      t,
      newEntity,
      statusSetter,
      permissions,
      availableResolutionLevels,
      commentsSetter,
    ],
  );

  return columnDefs;
}

export default useColumnDefs;
