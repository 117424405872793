import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';

import { ResolutionLevelBlock } from '../../../base';

const ResolutionLevelRenderer = ({ value }: ICellRendererParams) => (
  <ResolutionLevelBlock level={value} />
);

export default memo(ResolutionLevelRenderer);
