import type { UserDesktopFilters } from '../types';
import { ResolutionLevel, Status } from '../types';

export const DEFAULT_FILTER_CONFIG: UserDesktopFilters = {
  text: '',
  isAssignedToMe: false,
  isCreatedByMe: false,
  resolutionLevel: Object.values(ResolutionLevel),
  status: [Status.IN_PROGRESS, Status.REGISTERED],
  registeredBy: [],
  responsible: [],
  locations: [],
  responsibleIsInformed: false,
  incidentIsUrgent: false,
  typeDate: 'registeredDate',
  startDate: null,
  endDate: null,
  userFunctions: [],
};

export default DEFAULT_FILTER_CONFIG;
