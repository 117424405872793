import { endOfDay, startOfDay } from 'date-fns';

import type { DailyTaskDefaultFilters } from '../../../../../types';

export const transformFiltersForBackend = (filters: DailyTaskDefaultFilters) => {
  const {
    startDate,
    endDate,
    responsible,
    userFunctions,
    status,
    participants,
    typeDate,
    resolutionLevel,
    text,
    locations,
  } = filters;

  return {
    ...(typeDate === 'registeredDate'
      ? {
          startDatetime: {
            $gte: startDate ? startDate.toISOString() : undefined,
            $lte: endDate ? endDate.toISOString() : undefined,
          },
        }
      : {
          resolvedDate: {
            $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
            $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
          },
        }),
    userFunction: userFunctions,
    responsible: {
      id: responsible,
    },
    resolutionLevel,
    status: {
      $in: status,
    },
    participants: {
      id: participants,
    },
    location: {
      id: locations,
    },
    $or: [
      {
        title: {
          $containsi: text,
        },
      },
      {
        description: {
          $containsi: text,
        },
      },
    ],
  };
};
