import type { FC } from 'react';

import { Logout, Notifications } from '@mui/icons-material';
import {
  ListItemIcon, Menu, MenuItem, Switch,
} from '@mui/material';
import { Trans } from 'react-i18next';

const PAPER_PROPS = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

type UserMenuProps = {
  open: boolean;
  onClose?: () => void;
  anchorEl?: HTMLElement;
  onLogoutClick?: () => void;
  sendNotificationEmail?: boolean;
  onSwitchSendNotificationEmail?: () => void;
  sendNotificationPush?: boolean;
  onSwitchSendNotificationPush?: () => void;
};

export const UserMenu: FC<UserMenuProps> = ({
  open,
  onClose,
  anchorEl,
  onLogoutClick,
  sendNotificationEmail,
  onSwitchSendNotificationEmail,
  sendNotificationPush,
  onSwitchSendNotificationPush,
}) => (
  <Menu
    anchorEl={anchorEl}
    id='account-menu'
    open={open}
    onClose={onClose}
    PaperProps={PAPER_PROPS}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    <MenuItem onClick={onSwitchSendNotificationEmail}>
      <ListItemIcon>
        <Notifications fontSize='small' />
      </ListItemIcon>
      <Trans i18nKey='send-notification-email' />
      <Switch edge='end' checked={sendNotificationEmail} />
    </MenuItem>
    <MenuItem onClick={onSwitchSendNotificationPush}>
      <ListItemIcon>
        <Notifications fontSize='small' />
      </ListItemIcon>
      <Trans i18nKey='send-notification-push' />
      <Switch edge='end' checked={sendNotificationPush} />
    </MenuItem>
    <MenuItem onClick={onLogoutClick}>
      <ListItemIcon>
        <Logout fontSize='small' />
      </ListItemIcon>
      <Trans i18nKey='logout' />
    </MenuItem>
  </Menu>
);
