export const defaultUserFunctions = [
  { name: 'Operations management', canRemove: false },
  { name: 'Event Operations Centre', canRemove: false },
  { name: 'Security', canRemove: false },
  { name: 'Safety', canRemove: false },
  { name: 'Crowd Management ', canRemove: false },
  { name: 'Medical', canRemove: false },
  { name: 'Transport', canRemove: false },
  { name: 'Logitics', canRemove: false },
  { name: 'Food and Beverage', canRemove: false },
  { name: 'Cleaning and Waste Management', canRemove: false },
  { name: 'Overlay', canRemove: false },
  { name: 'Maintenance', canRemove: false },
  { name: 'Internet and Technology', canRemove: false },
  { name: 'Accreditation', canRemove: false },
  { name: 'Ticketing', canRemove: false },
  { name: 'Production', canRemove: false },
  { name: 'Show', canRemove: false },
  { name: 'Sport', canRemove: false },
  { name: 'Visitor services', canRemove: false },
  { name: 'Protocol', canRemove: false },
  { name: 'Media Operations', canRemove: false },
  { name: 'Workforce', canRemove: false },
  { name: 'Marketing', canRemove: false },
];
