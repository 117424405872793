import type { RecoilState } from 'recoil';
import type { RecoilTaskInterface } from 'recoil-toolkit';
import { equals, isObject, mapValues, pick } from 'remeda';

import { userService } from '../../../../api';
import { userState } from '../../../../state/user';
import type { User } from '../../../../types';
import type { ColumnsSettings, ColumnValue } from './types';

export const setColumnsToUserPropertyTask =
  <T>(userProperty: keyof User, columnAtom: RecoilState<T>) =>
  ({ set, snapshot }: RecoilTaskInterface) =>
  async (columns: T) => {
    const { id } = snapshot.getLoadable(userState).getValue();

    const columnSettings = { columns };

    set(columnAtom, columns);
    await userService.update({ id, data: { [userProperty]: columnSettings } });
    set(userState, (state) => ({ ...state, [userProperty]: columnSettings }));
  };

const pickColumn = pick<ColumnValue, keyof ColumnValue>(['isShow', 'width', 'index']);
const pickColumns = mapValues((column) => pickColumn(column));

const isEqualColumns = <T extends string>(columnsA: ColumnsSettings<T>, columnsB: ColumnsSettings<T>) =>
  equals(pickColumns(columnsA), pickColumns(columnsB));

export const updateColumnsFromUserPropertyTask =
  <T extends string>(userProperty: keyof User, columnAtom: RecoilState<ColumnsSettings<T>>) =>
  ({ set, snapshot }: RecoilTaskInterface) =>
  () => {
    const user = snapshot.getLoadable(userState).getValue();
    const prevColumns = snapshot.getLoadable(columnAtom).getValue();
    const updatedPrevColumns = {
      ...prevColumns,
      ...(userProperty === 'projectTasksSettings'
        ? {
            count: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              ...(prevColumns?.count || {}),
              isShow: true,
            },
          }
        : {}),
    };
    const userGridSettings = user[userProperty];
    if (isObject(userGridSettings) && 'columns' in userGridSettings) {
      const userColumns = {
        ...userGridSettings.columns,
        ...(userProperty === 'projectTasksSettings'
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            { count: { ...(userGridSettings.columns?.count ?? {}), isShow: true } }
          : {}),
      } as ColumnsSettings<T>;
      if (!isEqualColumns(userColumns, updatedPrevColumns)) {
        set(
          columnAtom,
          mapValues((column, columnKey) => ({
            ...column,
            ...(userColumns[columnKey] ? pickColumn(userColumns[columnKey]) : {}),
          })),
        );
      }
    }
  };
