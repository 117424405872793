import type { FirstDataRenderedEvent } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';

export const useScrollToRowByParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToRowByParams = (callback: (page: number, taskId: number) => void) => (event: FirstDataRenderedEvent) => {
    if (searchParams.has('taskId')) {
      const currentTaskId = searchParams.get('taskId');
      searchParams.delete('taskId');
      setSearchParams(searchParams);
      const node = event.api.getRowNode(currentTaskId ?? '');
      if (node) {
        const goPage = node.rowIndex ? Math.floor(node.rowIndex / event.api.paginationGetPageSize()) : 0;
        event.api.paginationGoToPage(goPage);
        event.api.ensureNodeVisible(node, 'bottom');
        callback(goPage + 1, Number(currentTaskId));
      }
    }
  };

  return { scrollToRowByParams };
};
