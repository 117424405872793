import { useState } from 'react';

import type { FilePondFile, FilePondInitialFile } from 'filepond';

import type { SavedImageData } from './lib/types';

type PhotosState = (FilePondInitialFile | FilePondFile)[];

interface UseImageEditorProps {
  photos: PhotosState;
  onConfirm: (output: PhotosState) => void;
}
export function useImageEditor(props: UseImageEditorProps) {
  const { photos, onConfirm } = props;
  const [imageToEdit, setImageToEdit] = useState<File>();

  const imageEditor = {
    // Called by FilePond to edit the image
    // - should open your image editor
    // - receive          s file object and image edit instructions
    open: (file: File) => {
      setImageToEdit(file);
      // onOpen();
    },

    // Callback set by FilePond
    // - should be called by the editor when user confirms editing
    // - should receive output object, resulting edit information
    onconfirm: (output: SavedImageData) => {
      fetch(output.imageBase64 ?? '')
        .then((res) => res.blob())
        .then((blob) => {
          const fileObject = new File([blob], output.fullName ?? '', {
            type: output.mimeType,
          });
          const newPhotos = photos.map((item) => {
            if (
              (typeof item.source !== 'string' && item.source.name === output.fullName) ||
              (typeof item.source === 'string' && item.source.includes(output.fullName ?? ''))
            ) {
              return {
                source: fileObject,
                options: {
                  type: 'local',
                },
              } as unknown as FilePondInitialFile;
            }

            return item;
          });
          onConfirm(newPhotos);
          setImageToEdit(undefined);
        });
    },

    // Callback set by FilePond
    // - should be called by the editor when user cancels editing
    oncancel: () => {
      // onClose();
    },

    // Callback set by FilePond
    // - should be called by the editor when user closes the editor
    onclose: () => {
      setImageToEdit(undefined);
    },
  };

  return {
    imageToEdit,
    imageEditor,
  };
}
