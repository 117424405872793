import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, type MarkerProps } from 'react-leaflet';

import { PointerMarker, type PointerMarkerProps } from '../../Markers';

interface LocationMarkerProps extends PointerMarkerProps, MarkerProps {}

export const LocationMarker = (props: LocationMarkerProps) => {
  const { label, view, ...rest } = props;
  return (
    <Marker
      {...rest}
      icon={divIcon({
        html: renderToStaticMarkup(<PointerMarker label={label} view={view} />),
        iconSize: [0, 0],
      })}
    />
  );
};
