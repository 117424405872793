import type { FC } from 'react';

import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';

import getFullName from '../../utils/getFullName';
import type { UserAvatarProps } from './UserAvatar';
import UserAvatar from './UserAvatar';

type Props = ChipProps & {
  user: UserAvatarProps['user'];
};

const UserChip: FC<Props> = ({ user, sx, ...props }) => {
  const fullName = getFullName(user);

  return (
    <Chip
      sx={{
        background: 'white',
        verticalAlign: 'baseline',
        minWidth: 0,
        ...sx,
        '& .MuiChip-avatar': {
          color: 'white',
        },
      }}
      avatar={<UserAvatar size='s' user={user} />}
      label={fullName}
      variant='outlined'
      {...props}
    />
  );
};

export default UserChip;
