import type { RefObject } from 'react';
import { useCallback } from 'react';

import type { GridApi, RowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useRecoilTask } from 'recoil-toolkit';

import type { EditNewEntityFn } from '../../../EntitiesGrid';
import {
  cellTypes,
  useDefaultGridSettings,
  useDefaultGridSort,
  useGridCommentsSetter,
  useGridDefaultSetter,
  useGridStatusSetter,
} from '../../../EntitiesGrid';
import { tasksState } from './atoms';
import { DEFAULT_SORT } from './constants';
import { addCommentTask, getTasks } from './tasks';
import { isPreparedNewTask } from './types';

export const useTasks = () =>
  useRecoilTask(getTasks, [], {
    dataSelector: tasksState,
    errorStack: true,
  });

type UseGridSetters = {
  editTaskPart: (id: number, changes: Record<string, unknown>) => void;
  editPreparedTask: EditNewEntityFn;
  addCommentHook?: () => void;
  callbackIdString?: (params: { id: string; colField: string; value: unknown; api: GridApi }) => void;
};

export const useAddComment = () =>
  useRecoilTask(addCommentTask, [], {
    loaderStack: 'addTaskCommentTask',
    errorStack: true,
  });

export const useGridSetters = ({ editTaskPart, editPreparedTask, callbackIdString }: UseGridSetters) => {
  const { t } = useTranslation();
  const { execute: addComment } = useAddComment();
  const { execute: loadTasks } = useTasks();
  const { defaultValueSetter } = useGridDefaultSetter({
    isPreparedEntity: isPreparedNewTask,
    editPreparedEntity: editPreparedTask,
    editEntityPart: editTaskPart,
    callbackIdString,
  });
  const { statusSetter, modals } = useGridStatusSetter({
    isPreparedEntity: isPreparedNewTask,
    defaultValueSetter,
    confirmCloseText: t('please-confirm-to-close-this-task'),
    confirmReopenText: t('please-confirm-to-reopen-this-task'),
  });
  const { commentsSetter } = useGridCommentsSetter(addComment, loadTasks);

  return {
    defaultValueSetter,
    statusSetter,
    commentsSetter,
    modals,
  };
};

export const useScrollToNow = (gridRef: RefObject<AgGridReact<unknown>>) => {
  const scrollToNow = useCallback(() => {
    if (gridRef?.current?.api) {
      let firstNodeAfterNow: RowNode | null = null;

      gridRef.current?.api.forEachNodeAfterFilterAndSort((node: RowNode) => {
        const end = node && node.data.endDatetime;
        // console.log(end, firstNodeAfterNow, end && cellTypes.dateType.comparator(Date.now(), end));
        if (end && !firstNodeAfterNow && cellTypes.dateType.comparator(Date.now(), end) === -1) {
          firstNodeAfterNow = node;
        }
      });
      // console.log(firstNodeAfterNow);
      if (firstNodeAfterNow) {
        gridRef.current.api.ensureNodeVisible(firstNodeAfterNow, 'middle');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    scrollToNow,
  };
};

const CONTAINER_STYLE = { height: '100%' };
export const useDefaultTasksGridSettings = () => {
  const { onGridReady, ...gridDefaultSettings } = useDefaultGridSettings();

  const sortOptions = useDefaultGridSort({
    onGridReady,
    defaultSort: DEFAULT_SORT,
  });

  return {
    ...gridDefaultSettings,
    ...sortOptions,
    containerStyle: CONTAINER_STYLE,
  };
};
