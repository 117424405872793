import type { FC, ReactNode } from 'react';

import {
  Box,
} from '@mui/material';

export const WrapperBox: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '40px',
      paddingRight: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Box>
);
