import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const TruncatedTypography = styled(Typography)(() => ({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export default TruncatedTypography;
