import { useMemo } from 'react';

import { MarkerClusterer } from '@react-google-maps/api';

import {
  GoogleMap,
  IncidentMarker,
  LocationMarker,
  useGoogleMapInfoWindow,
  useGoogleMapOnLoad,
} from '../../../shared/components/GoogleMap';
import { useGoogleMapLoader } from '../../../shared/components/GoogleMap/model/useGoogleMapLoader';
import { MapLoader } from '../../../shared/components/MapLoader';
import { normalizeGoogleMapData } from '../lib/normalizeGoogleMapData';
import { useIncidentMap } from '../model/IncidentMapProvider';

export const GoogleMapIncident = () => {
  const { isLoaded } = useGoogleMapLoader();
  const data = useIncidentMap();
  const { center, zoom, locationPoints, incidentPoints, onClusterClick } = useMemo(
    () => normalizeGoogleMapData(data),
    [data],
  );
  const { map, onLoadMap } = useGoogleMapOnLoad();
  const { createOnLoadMarker, onMouseOut, createOnMouseOver, infoWindowInstance } = useGoogleMapInfoWindow({ map });

  if (isLoaded) {
    return (
      <GoogleMap
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={center}
        zoom={zoom}
        onLoad={onLoadMap}
      >
        <MarkerClusterer zoomOnClick={false} onClick={onClusterClick}>
          {(cluster) => (
            <>
              {incidentPoints.map(({ key, infoWindowContent, ...marker }) => (
                <IncidentMarker
                  key={key}
                  onLoad={createOnLoadMarker(key)}
                  onMouseOut={onMouseOut}
                  clusterer={cluster}
                  onMouseOver={createOnMouseOver(key, infoWindowContent)}
                  {...marker}
                />
              ))}
            </>
          )}
        </MarkerClusterer>

        {locationPoints.map(({ key, infoWindowContent, ...marker }) => (
          <LocationMarker
            view='primary'
            key={key}
            onLoad={createOnLoadMarker(key)}
            onMouseOut={onMouseOut}
            onMouseOver={createOnMouseOver(key, infoWindowContent)}
            {...marker}
          />
        ))}

        {infoWindowInstance}
      </GoogleMap>
    );
  }

  return <MapLoader />;
};
