import type { RecoilTaskInterface } from 'recoil-toolkit';

import { eventService, locationService, userFunctionService, userService } from '../../api';
import type { NewEvent, UserFunction } from '../../types';
import type { LocationControlItem } from '../locationsForm';
import { TypeLocation } from '../locationsForm';
import { getCurrentUserTask, userState } from '../user';

const isString = (value: unknown | string): value is string => typeof value === 'string';

const getFile = (sitePlan: (string | File)[] | undefined) => {
  if (!sitePlan || sitePlan.length === 0) {
    return 'removed';
  }

  const plan = sitePlan[0];

  if (isString(plan)) {
    return 'no-changed';
  }

  return plan;
};

export const addEventTask =
  (recoilTaskEvent: RecoilTaskInterface) =>
  async (params: { event: NewEvent & { locations: LocationControlItem[] }; successCallback: () => void }) => {
    const {
      event: {
        name,
        description,
        userFunctions,
        locations,
        limitLocations,
        limitUsers,
        sitePlan,
        incidentPlan,
        projectPlan,
        dailyPlan,
      },
      successCallback,
    } = params;
    const user = recoilTaskEvent.snapshot.getLoadable(userState).getValue();
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        name,
        description,
        users: [user.id],
        incidentPlan,
        projectPlan,
        dailyPlan,
        ...(limitLocations ? { limitLocations } : {}),
        ...(limitUsers ? { limitUsers } : {}),
      }),
    );
    const sitePlanFile = getFile(sitePlan);
    if (sitePlanFile instanceof File) {
      formData.append('files.sitePlan', sitePlanFile, sitePlanFile.name);
    }

    const {
      data: { id: eventId },
    } = await eventService.addEvent(formData);

    const userFunctionsPromises = userFunctions.map((userFunction) =>
      userFunctionService.addUserFunction({
        userFunction: {
          name: userFunction,
          events: [eventId],
        },
      }),
    );

    const locationsPromises = locations.map((location) =>
      locationService.addLocation({
        location: {
          description: location.description,
          title: location.title,
          geo: location.geo,
          event: eventId,
        },
      }),
    );

    await Promise.all([...userFunctionsPromises, ...locationsPromises]);

    await userService.changeCurrentEvent({ id: user.id, currentEvent: eventId });
    await getCurrentUserTask(recoilTaskEvent)();
    successCallback();
  };

export type AddUserFunction = {
  type: 'add';
  data: string[];
};

export type RemoveUserFunction = {
  type: 'remove';
  data: UserFunction[];
};

type UpdatedEvent = {
  id: number;
  locations: LocationControlItem[];
  userFunctions: {
    added: AddUserFunction;
    removed: RemoveUserFunction;
  };
};

export const updateEventTask =
  (recoilTaskEvent: RecoilTaskInterface) =>
  async (params: { event: Omit<NewEvent, 'userFunctions'> & UpdatedEvent; successCallback: () => void }) => {
    const { event, successCallback } = params;
    const {
      id,
      name,
      description,
      userFunctions,
      limitLocations,
      limitUsers,
      locations,
      sitePlan,
      projectPlan,
      incidentPlan,
      dailyPlan,
    } = event;

    const locationsPromises = locations.map((location) => {
      if (location.type === TypeLocation.New) {
        return locationService.addLocation({
          location: {
            description: location.description,
            title: location.title,
            geo: location.geo,
            event: id,
          },
        });
      }

      if (location.type === TypeLocation.Edit) {
        return locationService.updateLocation({
          location: {
            id: location.id,
            description: location.description,
            title: location.title,
            geo: location.geo,
          },
        });
      }

      return Promise.resolve();
    });

    const addUserFunctionsPromises = userFunctions.added.data.map((userFunction) =>
      userFunctionService.addUserFunction({
        userFunction: {
          name: userFunction,
          events: [id],
        },
      }),
    );

    const unsubscribeUserFunctionsPromise =
      userFunctions.removed.data.length > 0
        ? [
            userFunctionService.unsubscribe({
              event: id,
              userFunctionsIds: userFunctions.removed.data.map((userFunction) => userFunction.id),
            }),
          ]
        : [];

    await Promise.all([...addUserFunctionsPromises, ...locationsPromises, ...unsubscribeUserFunctionsPromise]);

    const formData = new FormData();

    const sitePlanFile = getFile(sitePlan);
    if (sitePlanFile instanceof File) {
      formData.append('files.sitePlan', sitePlanFile, sitePlanFile.name);
    }
    formData.append(
      'data',
      JSON.stringify({
        name,
        description,
        sitePlan: sitePlanFile === 'no-changed' ? undefined : null,
        projectPlan,
        incidentPlan,
        dailyPlan,
        ...(limitLocations ? { limitLocations } : {}),
        ...(limitUsers ? { limitUsers } : {}),
      }),
    );

    await eventService.updateEvent({
      id,
      data: formData,
    });
    await getCurrentUserTask(recoilTaskEvent)();
    successCallback?.();
  };

export const archiveEventTask =
  (recoilTaskEvent: RecoilTaskInterface) => async (params: { id: number; successCallback: () => void }) => {
    const { id } = params;

    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        isArchived: true,
        users: [],
      }),
    );

    await eventService.updateEvent({
      id,
      data: formData,
    });

    await getCurrentUserTask(recoilTaskEvent)();
  };
