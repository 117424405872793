import type { GetQuickFilterTextParams } from 'ag-grid-community';

import { multiUsersType } from '../../cellTypes';
import { MultiUsersGridEditor } from './MultiUsersGridEditor';
import { MultiUsersGridRenderer } from './MultiUsersGridRenderer';

type Props = {
  editable?: boolean,
  sortable?: boolean,
  cellEditorParams?: Record<string, unknown>
}

export const multiUsersGridField = ({ editable, sortable, cellEditorParams }: Props) => ({
  sortable,
  ...(editable || cellEditorParams
    ? {
      cellEditor: MultiUsersGridEditor,
      cellEditorPopup: true,
      cellEditorParams,
    } : {
      editable,
    }),
  ...(sortable
    ? {
      comparator: multiUsersType.comparator,
      equals: multiUsersType.equals,
      getQuickFilterText: (params: GetQuickFilterTextParams) => multiUsersType.toString(params.value),
    } : {}),
  cellRenderer: MultiUsersGridRenderer,
  resizable: true,
  width: 100,
});
