import type { FC, MouseEvent } from 'react';
import { useState } from 'react';

import type { SxProps, Theme} from '@mui/material';
import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';

import { useSelectLanguage } from '../../state/user';
import type { User } from '../../types';
import { LanguageMenu } from '../LanguageMenu';

type Props = {
  sx?: SxProps<Theme>;
}

export const LanguageControl: FC<Props> = ({ sx }) => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>();
  const open = Boolean(anchorEl);
  const { execute: selectLanguage } = useSelectLanguage();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleChangeProject = (id: User['language']) => {
    selectLanguage(id);
    handleClose();
  };

  return (
    <>
      <Box sx={{
        height: '40px',
        display: 'flex',
        width: 'fit-content',
        border: '1px solid #C7D1D4',
        borderRadius: '8px',
        ...sx,
      }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          <Stack
            direction='row'
            sx={{
              display: 'flex', alignItems: 'center', padding: '8px',
            }}
            spacing={2}
          >

            <Typography variant='body2'>
              <Trans i18nKey='language' />
            </Typography>
          </Stack>
        </IconButton>
      </Box>

      <LanguageMenu
        open={open}
        onClose={handleClose}
        onClick={handleChangeProject}
        anchorEl={anchorEl}
      />
    </>
  );
};
