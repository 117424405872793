/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImageList } from '@mui/material';
import { Gallery } from 'react-photoswipe-gallery';

import type { Incident } from '../../../../types';
import getPhotoFormats from '../../../../utils/getPhotoFormats';
import { useFullScreen } from '../../../FullScreen';
import ImageItem from '../../../IncidentTable/components/renderer/PhotosRenderer/components/ImageItem';
import { IMAGE_SIZE } from '../../../IncidentTable/components/renderer/PhotosRenderer/constants';

const PhotosRenderer = (photos: Incident['photos']) => {
  const images = (photos ?? []).map((photo) => getPhotoFormats(photo));
  const { ref } = useFullScreen();
  const openPromise = ref ? Promise.resolve() : Promise.reject();
  return (
    <Gallery
      options={{
        appendToEl: ref.current ?? undefined,
      }}
    >
      <ImageList sx={{ maxHeight: 300 }} cols={2} rowHeight={IMAGE_SIZE}>
        {images.map(({ thumbnailSizeImage, originalSizeImage }, index) => (
          <ImageItem
            key={thumbnailSizeImage.url}
            thumbnailImage={thumbnailSizeImage}
            originalImage={originalSizeImage}
            index={index}
            maxImageIndex={null}
          />
        ))}
      </ImageList>
    </Gallery>
  );
};

export default PhotosRenderer;
