import { memo } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import type { ICellRendererParams } from 'ag-grid-community';

import { useTooltip, Tooltip } from '../../../../modules/EntitiesGrid';
import type { Comment } from '../../../../types';
import { commentsComparator } from '../../../../utils/comparators';
import getFullName from '../../../../utils/getFullName';
import getPhotoFormats from '../../../../utils/getPhotoFormats';
import { ClampedTypography, FallbackImage, TruncatedTypography, UserAvatar } from '../../../base';

const CommentsRenderer = ({ value = [] }: ICellRendererParams) => {
  const comments: Comment[] = [...value];
  const availableComments = comments.filter((comment) => !comment?.hidden);
  const { ref, tooltipProps } = useTooltip();

  if (availableComments.length === 0) {
    return null;
  }

  const lastComment = [...availableComments].sort(commentsComparator)[availableComments.length - 1];
  const { author, body, image } = lastComment;
  const thumbnailImage = image ? getPhotoFormats(image).thumbnailSizeImage : undefined;

  return (
    <Tooltip title={body} {...tooltipProps}>
      <Stack gap='8px' overflow='hidden' maxHeight='100%'>
        <Grid container wrap='nowrap' sx={{ alignItems: 'center' }}>
          <Grid item>
            <UserAvatar sx={{ marginRight: '5px' }} size='s' user={author} />
          </Grid>

          <TruncatedTypography variant='body2' sx={{ color: '#808080' }}>
            {getFullName(author)}
          </TruncatedTypography>
        </Grid>

        {thumbnailImage && (
          <FallbackImage
            sx={{
              marginBottom: '8px',
              height: '55px',
              maxHeight: '55px',
              borderRadius: '8px',
              objectFit: 'contain',
              width: 'fit-content',
            }}
            src={thumbnailImage.url}
            alt={thumbnailImage.name}
            mime={thumbnailImage.mime}
            name={thumbnailImage.name}
            aria-hidden='true'
          />
        )}

        <ClampedTypography ref={ref} lineclamp={3} variant='body2'>
          {body}
        </ClampedTypography>
      </Stack>
    </Tooltip>
  );
};

export default memo(CommentsRenderer);
