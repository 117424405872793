import type { PickersLocaleText } from '@mui/x-date-pickers';

export type DateView = 'year' | 'month' | 'day';
export const getPickersLocalization = (pickersTranslations: Partial<PickersLocaleText<unknown>>) => ({
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: { ...pickersTranslations },
      },
    },
  },
});

const ruRUPickers: PickersLocaleText<unknown> = {
  // Calendar navigation
  previousMonth: 'Предыдущий месяц',
  nextMonth: 'Следующий месяц',

  // View navigation
  openPreviousView: 'открыть предыдущее отображение',
  openNextView: 'открыть следующее отображение',
  calendarViewSwitchingButtonAriaLabel: (view: DateView) =>
    (view === 'year'
      ? 'открыто отображение года, переключится в отображение календаря'
      : 'открыто отображение календаря, переключится в отображение года'),
  inputModeToggleButtonAriaLabel: (isKeyboardInputOpen, viewType) =>
    (isKeyboardInputOpen
      ? `текстовое поле открыто, перейти до ${viewType} отображения`
      : `${viewType} отображение открыто, перейти до текстового поля`),

  // DateRange placeholders
  start: 'Начало (с)',
  end: 'Окончание (по)',

  // Action bar
  cancelButtonLabel: 'Отмена',
  clearButtonLabel: 'Очистить',
  okButtonLabel: 'Да',
  todayButtonLabel: 'Сегодня',

  // Toolbar titles
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  datePickerToolbarTitle: 'Выберите дату',
  dateTimePickerToolbarTitle: 'Выберите дату и время',
  timePickerToolbarTitle: 'Выберите дату',
  dateRangePickerToolbarTitle: 'Выберите период',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Выберите ${view}. ${
      time === null ? 'Время не выбрано' : `Выбраное время: ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} часов`,
  minutesClockNumberText: (minutes) => `${minutes} минут`,
  secondsClockNumberText: (seconds) => `${seconds} секунд`,

  // Open picker labels
  openDatePickerDialogue: (value, utils) =>
    (value !== null && utils.isValid(value)
      ? `Выберите дату, выбранная дата: ${utils.format(value, 'fullDate')}`
      : 'Выберите дату'),
  openTimePickerDialogue: (value, utils) =>
    (value !== null && utils.isValid(value)
      ? `Выберите время, выбраное время: ${utils.format(value, 'fullTime')}`
      : 'Выберите время'),

  // Table labels
  timeTableLabel: 'выберите время',
  dateTableLabel: 'выберите дату',

  // Field section placeholders
  fieldYearPlaceholder: (params: { digitAmount: number }) => 'Y'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params: { contentType: string }) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
  fieldDayPlaceholder: () => 'DD',
  fieldHoursPlaceholder: () => 'hh',
  fieldMinutesPlaceholder: () => 'mm',
  fieldSecondsPlaceholder: () => 'ss',
  fieldMeridiemPlaceholder: () => 'aa',
};

export const ruRU = getPickersLocalization(ruRUPickers);
