import type { FC } from 'react';

import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { IconButton } from '@mui/material';
import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { eventState } from '../../state/event';
import type { Geo, Map } from '../../types';
import { CopyToClipboard } from '../base';

type CopyGMapLinkProps = {
  geo: Geo;
  size: 'small' | 'large' | 'medium';
};

const YMapLink = (geo: Geo) => `https://yandex.ru/maps/?pt=${geo.longitude},${geo.latitude}&z=17&l=map`;
const GoogleMapLink = (geo: Geo) =>
  `https://www.google.com/maps/search/?api=1&query=${geo.latitude.toFixed(6)},${geo.longitude.toFixed(6)}`;

const makeGeoLink = (geo: Geo, map: Map) => {
  switch (map) {
    case 'yandex':
      return YMapLink(geo);

    case 'google':
    default:
      return GoogleMapLink(geo);
  }
};

const CopyGMapLink: FC<CopyGMapLinkProps> = ({ geo, size }) => {
  const currentEvent = useRecoilValue(eventState);
  return (
    <CopyToClipboard
      text={makeGeoLink(geo, currentEvent.map)}
      notificationText={<Trans i18nKey='copied-to-clipboard' />}
    >
      {({ onCopyClick }) => (
        <IconButton onClick={onCopyClick} size={size}>
          <ShareLocationIcon fontSize={size} color='primary' />
        </IconButton>
      )}
    </CopyToClipboard>
  );
};

export default CopyGMapLink;
