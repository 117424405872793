import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useCurrentUserEvent from '../../../../hooks/useCurrentUserEvent';
import { useCurrentUser } from '../../../../modules/EntitiesGrid';
import { Drawer, useShowDrawer } from '../../../../state/drawer';
import { locationsState } from '../../../../state/locations';
import { NotificationType, useShowNotification } from '../../../../state/notification';
import { userRolesState } from '../../../../state/roles';
import { useDeleteUserEvent, useEditUserEvent } from '../../../../state/user';
import { userFunctionsState } from '../../../../state/userFunctions';
import { usersState } from '../../../../state/users';
import { type Role, type User, type UserFunction } from '../../../../types';
import { MenuButton } from '../../../base';
import { ObjectSelectController } from '../../../form';
import { capitalizeFirstLetter } from '../lib/capitalizeFirstLetter';
import { prepareUserFormPermissionsData } from '../lib/prepareUserFormPermissionsData';

type UserFormValues = {
  role: Role;
  function: UserFunction;
};

type UserEventFormRowProps = {
  user: User;
  onChange: (id: number | undefined, hasChanges: boolean) => void;
};

const UserEventFormRow: FC<UserEventFormRowProps> = ({ user, onChange }) => {
  const userEvent = useCurrentUserEvent(user);
  const { execute: deleteUserEvent } = useDeleteUserEvent();
  const { execute: editUserEvent } = useEditUserEvent();
  const { execute: showDrawer } = useShowDrawer();
  const { execute: showNotification } = useShowNotification();
  const { t } = useTranslation();
  const roles = useRecoilValue(userRolesState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const locations = useRecoilValue(locationsState);
  const users = useRecoilValue(usersState);
  const { currentUserId, currentUser } = useCurrentUser();
  const currentUserEvent = useCurrentUserEvent(currentUser);
  const eventSettings = useMemo(
    () => user.eventSettings.find((item) => item.event.id === userEvent?.event.id),
    [user, userEvent],
  );

  const handleEditUser = () => {
    showDrawer({ type: Drawer.USER_EDIT, props: { user } });
  };
  const form = useForm<UserFormValues>({
    defaultValues: {
      role: userEvent?.role,
      function: userEvent?.function,
    },
  });
  const { handleSubmit, watch } = form;
  const values = watch();
  const hasChanges = userEvent?.role.name !== values.role.name || userEvent?.function?.name !== values.function?.name;
  const userId = userEvent?.id;
  useEffect(() => {
    onChange(userId, hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges, userId]);

  if (!userEvent || !eventSettings || !currentUserEvent) {
    return null;
  }
  const onSubmit = async (params: UserFormValues) => {
    await editUserEvent({
      id: userEvent.id,
      function: params.function.id,
      role: params.role.type,
    });
    showNotification({ type: NotificationType.SUCCESS, text: t('user-event-save-success') });
  };
  const handleDeleteUserEvent = async () => {
    await deleteUserEvent(userEvent.id);
    showNotification({ type: NotificationType.SUCCESS, text: t('user-event-delete-success') });
  };
  const handleEditUserEvent = handleSubmit(onSubmit);

  const handleClickSettings = async () => {
    const data = prepareUserFormPermissionsData(userEvent, locations, userFunctions, eventSettings, users, user);
    await showDrawer({ type: Drawer.USES_FORM_SETTINGS, props: { ...data } });
  };

  const permissionUpdateRole = `canUpdate${capitalizeFirstLetter(userEvent.role.type)}Role` as const;
  const isCurrentUser = currentUserId === user.id;
  const isUpdatePermissionsRole =
    currentUserEvent?.permissions.canUpdateUserEvent && currentUserEvent?.permissions[permissionUpdateRole];

  return (
    <TableRow
      key={user.id}
      sx={{
        opacity: user.blocked ? 0.5 : 1,
      }}
    >
      <FormProvider {...form}>
        <TableCell>
          <Box
            component='button'
            sx={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              background: 'transparent',
              border: 'none',
              display: 'flex',
              color: 'black',
            }}
            onClick={handleEditUser}
          >
            {user.id}
          </Box>
        </TableCell>
        <TableCell component='th' scope='row'>
          {user.firstName}
          &nbsp;
          {user.lastName}
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          <ObjectSelectController
            name='role'
            data={roles}
            itemRenderer={(item) => item.name}
            required
            disabled={!isUpdatePermissionsRole}
          />
        </TableCell>
        <TableCell>
          <ObjectSelectController
            name='function'
            data={userFunctions}
            itemRenderer={(item) => item.name}
            required
            disabled={!isUpdatePermissionsRole}
          />
        </TableCell>
        <TableCell>
          {!isCurrentUser && isUpdatePermissionsRole && (
            <MenuButton
              sx={{ border: 0 }}
              buttonContent={<MoreHorizIcon />}
              commands={[{ content: <Trans i18nKey='settings' />, onClick: handleClickSettings }]}
            />
          )}
        </TableCell>
        <TableCell>
          {!isCurrentUser && isUpdatePermissionsRole && (
            <>
              <IconButton type='submit' aria-label='save user' color='primary' onClick={handleEditUserEvent}>
                <CheckCircleIcon />
              </IconButton>
              {!userEvent.hasIncidents && currentUser.id !== user.id && (
                <IconButton type='button' aria-label='delete user' color='error' onClick={handleDeleteUserEvent}>
                  <CancelIcon />
                </IconButton>
              )}
            </>
          )}
        </TableCell>
      </FormProvider>
    </TableRow>
  );
};

export { UserEventFormRow };
