import { useTranslation } from 'react-i18next';

import { useDailyTasksData } from '../../modules/Tasks/state/dailyTasks';
import { MenuDailyPlan } from '../Menu/MenuDailyPlan';
import { Layout } from './components/Layout';
import { useLayoutContext } from './model/LayoutProvider';

export const LayoutDailyPlan = () => {
  const { t } = useTranslation();
  const { hideNavigation } = useLayoutContext();
  useDailyTasksData();
  return <Layout menu={<MenuDailyPlan hideNavigation={!hideNavigation} />} headerName={t('drs')} />;
};
