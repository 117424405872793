import { atom, selector } from 'recoil';

import type { Incident } from '../../types';

export enum IncidentUpdateType {
  Updated = 'updated',
  Created = 'created'
}

type IncidentUpdate = {
  type: IncidentUpdateType.Created | IncidentUpdateType.Updated,
  id: number
}

type UpdatedIncident = {
  type: IncidentUpdate['type'],
  incident: Incident
}

export const incidentsUpdatesIdsState = atom<Record<number, IncidentUpdate>>({
  key: 'incidentsUpdatesIds',
  default: {},
});

export const loadedIncidentsUpdatesState = atom<Record<number, Incident>>({
  key: 'incidentsUpdates',
  default: {},
});

export const incidentsUpdatesState = selector({
  key: 'incidentsUpdatesWithType',
  get: ({ get }) => {
    const ids = Object.values(get(incidentsUpdatesIdsState));
    const incidents = get(loadedIncidentsUpdatesState);
    const result = ids.reduce<UpdatedIncident[]>(
      (acc, { id, type }) => {
        if (incidents[id]) {
          acc.push({ type, incident: incidents[id] });
        }
        return acc;
      },
      [],
    );

    return result;
  },
});
