import { useEffect } from 'react';

import { useDebounce } from 'react-use';
import { useRecoilState } from 'recoil';
import { noop } from 'remeda';
import io from 'socket.io-client';

import { sessionService } from '../api/sessionService';
import { API_URL } from '../constants/env';
import { useDashboards } from '../entities/incident/dashboards';
import { incidentsUpdatesIdsState } from '../state/incidentsUpdates/atoms';

const WSS_ENDPOINT = API_URL.startsWith('https') ? API_URL.replace('https', 'wss') : API_URL.replace('http', 'ws');
const RECONNECTION_DELAY = 10000;
const DASHBOARDS_DEBOUNCE = 500;

function useIncidentUpdates(authToken: string | undefined) {
  const [incidentsUpdates, setIncidentsUpdates] = useRecoilState(incidentsUpdatesIdsState);
  const {
    execute: loadDashboards,
    data: { dashboards },
  } = useDashboards();

  useEffect(() => {
    if (!authToken) {
      return noop;
    }
    const socket = io(WSS_ENDPOINT, {
      reconnectionDelayMax: RECONNECTION_DELAY,
      auth: {
        token: authToken,
      },
    });

    socket.on('update', (message) => {
      const notShowUpdatesSocket = sessionService.getItem('notShowUpdatesSocket');
      if (!notShowUpdatesSocket) {
        setIncidentsUpdates((prevIncidentsUpdates) => ({
          ...prevIncidentsUpdates,
          [message.id]: message,
        }));
      }

      sessionService.removeItem('notShowUpdatesSocket');
    });

    return () => {
      socket.offAny();
    };
  }, [setIncidentsUpdates, authToken]);

  useDebounce(
    () => {
      if (Object.keys(incidentsUpdates).length && dashboards.length) {
        loadDashboards();
      }
    },
    DASHBOARDS_DEBOUNCE,
    [incidentsUpdates, dashboards],
  );
}

export default useIncidentUpdates;
