import type { YMapMarkerProps } from '@yandex/ymaps3-types';

import type { PointerMarkerProps } from '../../Markers';
import { PointerMarker } from '../../Markers';
import type { YandexMapApiLoader } from '../model/YandexMapProvider';

type IncidentMarkerProps = {
  ymapsInstance: YandexMapApiLoader;
} & YMapMarkerProps &
  PointerMarkerProps;
export const LocationMarker = ({ ymapsInstance, label, view, ...props }: IncidentMarkerProps) => {
  const { YMapMarker } = ymapsInstance.ymaps;
  return (
    <YMapMarker {...props}>
      <PointerMarker label={label} view={view} />
    </YMapMarker>
  );
};
