import { useRecoilValue } from 'recoil';
import { useRecoilTask } from 'recoil-toolkit';

import { useNewEntityRow, useSyncColumnStateFromUserByUpdateHook } from '../../../modules/EntitiesGrid';
import { userState } from '../../../state/user';
import { useIncidentInlineAddingParams } from './params';
import { setColumnsTask, updateColumnsFromUserTask } from './tasks';

export const useNewIncidentRow = () => {
  const {
    loading,
    newEntity,
    prepareNewEntity,
    destroyNewEntity,
    addNewEntity,
    setNewEntity,
  } = useNewEntityRow(useIncidentInlineAddingParams);
  const user = useRecoilValue(userState)

  return {
    loading,
    newIncident: newEntity,
    prepareNewIncident: () => prepareNewEntity({
      registeredBy: user
    }),
    destroyNewIncident: destroyNewEntity,
    addNewIncident: addNewEntity,
    setNewIncident: setNewEntity,
  };
};

export const useSetColumns = () => useRecoilTask(setColumnsTask, [], {
  loaderStack: 'setColumnsTask',
  errorStack: true,
});

const useUpdateColumnState = () => useRecoilTask(updateColumnsFromUserTask, [], {
  loaderStack: 'updateColumnsFromUserTask',
  errorStack: true,
});

export const useSyncColumnStateFromUser = () => useSyncColumnStateFromUserByUpdateHook(useUpdateColumnState);
