import { useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';

import { SplitButton, Icon, Button } from '../../../../components/base';

type AddNewButton = {
  text: string;
  action: () => void;
};

type AddTaskControlProps<T> = {
  loading: boolean;
  newEntity: T | undefined;
  destroyNewEntity: () => void;
  addNewEntity: () => void;
  addNewButtons: AddNewButton[];
  newEntityReady: (entity: T) => boolean;
  disabled?: boolean;
};

export const AddEntityControl = <T extends Record<string, unknown>>({
  loading,
  newEntity,
  destroyNewEntity,
  addNewEntity,
  addNewButtons,
  newEntityReady,
  disabled,
}: AddTaskControlProps<T>) => {
  const options = addNewButtons.map((button) => ({
    content: (
      <>
        <Icon icon='plus' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
        {button.text}
      </>
    ),
    action: () => button.action(),
  }));
  const addButtonOptions = {
    disabled: Boolean(newEntity),
    sx: { color: 'white' },
  };

  useEffect(
    () => () => {
      destroyNewEntity();
    },
    [destroyNewEntity],
  );

  return (
    <Stack direction='row' spacing={2} sx={{ flexShrink: 0, alignItems: 'center' }}>
      {options.length > 1 ? (
        <SplitButton {...addButtonOptions} options={options} disabled={disabled} />
      ) : (
        <Button {...addButtonOptions} variant='contained' onClick={options[0].action} disabled={disabled}>
          {options[0].content}
        </Button>
      )}

      {newEntity && (
        <>
          <Button
            variant='contained'
            size='small'
            onClick={addNewEntity}
            disabled={!newEntityReady(newEntity) || loading}
          >
            <CheckCircleIcon htmlColor='white' />
          </Button>

          <Button variant='contained' size='small' onClick={destroyNewEntity} disabled={loading}>
            <CancelIcon htmlColor='white' />
          </Button>
        </>
      )}
    </Stack>
  );
};
