import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { dailyTasksFiltersState } from '../../../modules/Tasks/state/dailyTasks';
import { userState } from '../../../state/user';
import { transformFilterForBackend } from '../lib/transformFilterForBackend';
import type { DailyTasksFormFilerValue } from '../lib/types';

const getDailyTaskByFilters = (rti: RecoilTaskInterface) => async (filters: DailyTasksFormFilerValue) => {
  const { set, snapshot } = rti;
  const currentUser = snapshot.getLoadable(userState).getValue();
  const newFilters = transformFilterForBackend({
    filters,
    currentUser,
  });
  set(dailyTasksFiltersState, (prevValue) => ({
    ...prevValue,
    ...newFilters,
  }));
};

export const useGetDailyTaskByFilters = () =>
  useRecoilTask(getDailyTaskByFilters, [], {
    loaderStack: 'getDailyTaskByFilters',
    errorStack: true,
  });
