/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { taskService } from '../../../api';
import type { ProjectTaskState } from '../../../state/projectTasks/lib/addHierarchyField';
import { getProjectTasks } from '../../../state/projectTasks/tasks';

const updateRows = (rti: RecoilTaskInterface) => async (rows: ProjectTaskState[]) => {
  if (rows.length > 0) {
    const updatedMainTask = rows[0];
    await taskService.editTask(updatedMainTask.id, { parent: updatedMainTask.parent });
    await getProjectTasks(rti)();
  }
};

export const useUpdateRows = () =>
  useRecoilTask(updateRows, [], {
    loaderStack: 'updateRows',
    errorStack: true,
  });
