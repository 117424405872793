import { columnsSettingsState, newRowEntityState } from '../../../modules/EntitiesGrid';
import { DEFAULT_COLUMNS } from './constants';
import type { PreparedNewIncident } from './types';

export const newRowIncidentState = newRowEntityState<PreparedNewIncident>({ key: 'incident' });

export const columnsState = columnsSettingsState({
  default: DEFAULT_COLUMNS,
  key: 'incidentColumnSettings',
});

export { sortState } from '../../../state/incidentTable/sort';
