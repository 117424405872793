import type { FC } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import type { CheckboxProps } from '@mui/material';
import { Checkbox } from '@mui/material';

import Icon from './Icon';

export enum CheckboxType {
  CIRCLE = 'circle',
  EXCLAMATION = 'exclamation',
  FLAG = 'flag'
}

const CHECKBOX_ICONS = {
  [CheckboxType.CIRCLE]: {
    checkedIcon: <CheckCircleIcon color='primary' />,
    icon: <Icon icon='emptyCircle' htmlColor='#D0D0D0' />,
  },
  [CheckboxType.EXCLAMATION]: {
    checkedIcon: <Icon icon='exclamation' color='error' />,
    icon: <Icon icon='emptyCircle' htmlColor='#D0D0D0' />,
  },
  [CheckboxType.FLAG]: {
    checkedIcon: <Icon icon='flag' color='primary' />,
    icon: <Icon icon='emptyFlag' htmlColor='#D0D0D0' />,
  },
};

type Props = Omit<CheckboxProps, 'icon' | 'checkedIcon' | 'color'> & {
  type?: CheckboxType
}

const CustomCheckbox: FC<Props> = ({ type = CheckboxType.CIRCLE, ...props }) => {
  const { checkedIcon, icon } = CHECKBOX_ICONS[type];

  return (
    <Checkbox
      icon={icon}
      checkedIcon={checkedIcon}
      {...props}
    />
  );
};

export default CustomCheckbox;
