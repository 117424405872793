import type { ReactNode, FC } from 'react';
import { useState, useCallback } from 'react';

import { GoogleMap as ReactGoogleMap, type GoogleMapProps as ReactGoogleMapProps } from '@react-google-maps/api';

import { createMapSettings } from './config/baseSettings';

import './index.css';

interface GoogleMapProps extends ReactGoogleMapProps {
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  onLoad?: (map: google.maps.Map) => void | Promise<void>;
  children: ReactNode;
  containerStyle: Record<string, string>;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onResize?: () => void;
  zoom?: number;
}

export const GoogleMap: FC<GoogleMapProps> = ({
  center,
  children,
  onLoad,
  containerStyle,
  onClick,
  zoom,
  onResize,
  ...props
}) => {
  const [currentCenter, setCurrentCenter] = useState(center);
  const [instanceMap, setInstanceMap] = useState<google.maps.Map | null>(null);

  // After updating state, the map resets center to the initial position.
  // So after drag we keep the current center position.
  const handleOnDragEnd = useCallback(() => {
    if (instanceMap) {
      setCurrentCenter(instanceMap.getCenter());
    }
  }, [instanceMap]);

  const handleOnLoad = useCallback(
    (map: google.maps.Map) => {
      setInstanceMap(map);
      if (onLoad) {
        onLoad(map);
      }
    },
    [onLoad],
  );

  return (
    <ReactGoogleMap
      center={currentCenter}
      onLoad={handleOnLoad}
      onClick={onClick}
      onResize={onResize}
      onDragEnd={handleOnDragEnd}
      {...createMapSettings(containerStyle, zoom)}
      {...props}
    >
      {children}
    </ReactGoogleMap>
  );
};
