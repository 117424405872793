import { FormControlLabel } from '@mui/material';
import { useController, type FieldValues, type UseControllerProps, type FieldPath } from 'react-hook-form';

import { Checkbox } from '../base';

export type CheckboxFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  label: string;
} & UseControllerProps<TFieldValues, TName>;

export const CheckboxField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: CheckboxFieldProps<TFieldValues, TName>,
) => {
  const { onChange, disabled, name, label } = props;
  const { field } = useController(props);

  return (
    <FormControlLabel
      {...field}
      onChange={(_, value) => {
        onChange?.(value);
        field.onChange(value);
      }}
      control={<Checkbox checked={field.value} name={name} />}
      label={label}
      disabled={disabled}
    />
  );
};
