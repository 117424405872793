import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import ReactDOM from 'react-dom';

import { REACT_APP_YANDEX_MAPS_KEY } from '../../../config/env';
import { getLocale } from '../lib/getLocale';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type YMapsV3 = typeof import('@yandex/ymaps3-types/index');

const YMAP_URL = `https://api-maps.yandex.ru/v3/?apikey=${REACT_APP_YANDEX_MAPS_KEY}&lang=${getLocale()}`;
const YMAP_ID = 'yandex-map-script';

async function loadYmapsReactify(): Promise<YMapsV3> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ymaps: YMapsV3 = (window as any).ymaps3;
  await ymaps.ready;

  return ymaps;
}

function fetchScript(): Promise<YMapsV3> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const existingScript = document.getElementById(YMAP_ID) as HTMLScriptElement | null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ymaps3) {
      const data = await loadYmapsReactify();

      resolve(data);
    } else if (existingScript) {
      existingScript.onload = async () => {
        const data = await loadYmapsReactify();

        resolve(data);
      };
    } else {
      const script = document.createElement('script');
      document.head.appendChild(script);
      script.type = 'text/javascript';
      script.src = YMAP_URL;
      script.id = YMAP_ID;
      script.onload = async () => {
        const data = await loadYmapsReactify();

        resolve(data);
      };

      script.onerror = reject;
    }
  });
}

async function createApiLoader(ymaps: YMapsV3) {
  const [ymaps3Reactify, ymaps3Controls, ymaps3Clusterer, ymaps3Hint, ymaps3Projection] = await Promise.all([
    ymaps.import('@yandex/ymaps3-reactify'),
    ymaps.import('@yandex/ymaps3-controls@0.0.1'),
    ymaps.import('@yandex/ymaps3-clusterer@0.0.1'),
    ymaps.import('@yandex/ymaps3-hint@0.0.1'),
    ymaps.import('@yandex/ymaps3-cartesian-projection@0.0.1'),
  ]);

  const reactify = ymaps3Reactify.reactify.bindTo(React, ReactDOM);
  const ymapsReact = reactify.module(ymaps);
  const controls = reactify.module(ymaps3Controls);
  const clusterer = reactify.module(ymaps3Clusterer);
  const hint = reactify.module(ymaps3Hint);
  const projection = reactify.module(ymaps3Projection);

  return { ymaps: ymapsReact, controls, clusterer, hint, projection };
}

export type YandexMapApiLoader = Awaited<ReturnType<typeof createApiLoader>>;

type Context = {
  ymapsInstance: YandexMapApiLoader | null;
};

const YandexMapContext = createContext<Context>({
  ymapsInstance: null,
});

export const YandexMapProvider = ({ children }: PropsWithChildren) => {
  const [ymapsInstance, setYmapsInstance] = useState<YandexMapApiLoader | null>(null);

  useEffect(() => {
    fetchScript().then((data) => {
      createApiLoader(data).then(setYmapsInstance);
    });
  }, []);

  const value = useMemo<Context>(
    () => ({
      ymapsInstance,
    }),
    [ymapsInstance],
  );

  return <YandexMapContext.Provider value={value}>{children}</YandexMapContext.Provider>;
};

export const useYandexMap = () => {
  const context = useContext(YandexMapContext);

  if (context === undefined) {
    throw new Error('useYandexMap must be used within a YandexMapProvider');
  }

  return context;
};
