import type { FC } from 'react';
import { useEffect } from 'react';

import CachedIcon from '@mui/icons-material/Cached';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { groupBy, map, prop } from 'remeda';

import type { DrawerNavigationProps } from '../../state/drawer';
import { useIncidentsList } from '../../state/incidents';
import { incidentsUpdatesState, IncidentUpdateType } from '../../state/incidentsUpdates/atoms';
import { useIncidentsUpdatesList } from '../../state/incidentsUpdates/hooks';
import type { Incident } from '../../types';
import { Button } from '../base';
import { EditableIncidentsList } from '../EditableIncidentsList';
import { EditIncidentUpdatesForm } from '../IncidentForm/EditIncidentForms';

type IncidentUpdatesFormProps = DrawerNavigationProps;

const mapToIncidents = map(prop<{ incident: Incident }, 'incident'>('incident'));

export const IncidentUpdatesForm: FC<IncidentUpdatesFormProps> = ({ onEndAction, goBack, goForward }) => {
  const navigationProps = { goBack, goForward };
  const incidents = useRecoilValue(incidentsUpdatesState);
  const { loading, execute } = useIncidentsUpdatesList();
  const { execute: loadIncidents } = useIncidentsList();
  const { t } = useTranslation();
  const groupedIncidents = groupBy(
    incidents,
    ({ type }) => type,
  );

  const onClickReload = () => {
    loadIncidents()
      .then(() => onEndAction());
  };

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    incidents && incidents.length && !loading
      ? (
        <Stack spacing={2}>
          <Button
            variant='contained'
            endIcon={<CachedIcon />}
            onClick={onClickReload}
            sx={{ color: '#FFFFFF' }}
          >
            {t('refresh-list')}
          </Button>
          {groupedIncidents[IncidentUpdateType.Created]
            ? (
              <EditableIncidentsList
                EditIncidentForm={EditIncidentUpdatesForm}
                header={<Typography variant='h4'>{t('new-incidents')}</Typography>}
                incidents={mapToIncidents(groupedIncidents[IncidentUpdateType.Created])}
                {...navigationProps}
              />
            ) : null }
          {groupedIncidents[IncidentUpdateType.Updated]
            ? (
              <EditableIncidentsList
                EditIncidentForm={EditIncidentUpdatesForm}
                header={<Typography variant='h4'>{t('updated-incidents')}</Typography>}
                incidents={mapToIncidents(groupedIncidents[IncidentUpdateType.Updated])}
                {...navigationProps}
              />
            ) : null}
        </Stack>
      ) : (
        <Stack alignItems='center'>
          <CircularProgress color='inherit' />
        </Stack>
      )
  );
};
