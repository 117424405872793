import type { PropsWithChildren } from 'react';

import { Box, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { userEventState } from '../../state/event';
import { userEventBrand, userState } from '../../state/user';
import { EventControl } from '../EventControl';
import { LanguageControl } from '../LanguageControl';
import { UserControl } from '../UserControl';
import { TypeChangerControl } from './components/TypeChangerControl';

const BrandLogo = styled('img')({
  width: '52px',
  height: '52px',
  marginRight: '14px',
  objectFit: 'contain',
});

interface HeaderProps {
  name: string;
}

export const Header = (props: PropsWithChildren<HeaderProps>) => {
  const { children, name } = props;
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const userEvent = useRecoilValue(userEventState);
  const brand = useRecoilValue(userEventBrand);
  const event = userEvent?.event;

  const eventName = `${event?.name ?? ''}${event?.isArchived ? ` (${t('archived')})` : ''}`;

  return (
    <Box sx={{ paddingLeft: '40px', paddingRight: '40px', borderBottom: '1px solid #C7D1D4' }}>
      <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack direction='row' sx={{ alignItems: 'center', marginBottom: '42px' }}>
          {user &&
            (brand.enable ? (
              brand.logo && <BrandLogo src={brand.logo.url} alt={brand.logo.alternativeText} />
            ) : (
              <SvgIcon
                sx={{ fontSize: 52, marginRight: '14px', objectFit: 'contain' }}
                component={Logo}
                inheritViewBox
              />
            ))}
          <Typography variant='h1'>{name}</Typography>
        </Stack>

        <Typography variant='h1'>{eventName}</Typography>

        {user && (
          <Stack direction='row' spacing={1}>
            <TypeChangerControl />
            <LanguageControl />
            {user.userEvents?.length > 1 && <EventControl />}
            <UserControl />
          </Stack>
        )}
      </Stack>
      {children}
    </Box>
  );
};
