import type { FC, ChangeEvent, ReactNode } from 'react';

import { FormControlLabel } from '@mui/material';

import { Checkbox } from '../../../base';

type CheckboxControlProps = {
  name: string,
  label: ReactNode,
  checked: boolean,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxControl: FC<CheckboxControlProps> = ({
  name, label, checked, onChange,
}) => (
  <FormControlLabel
    sx={{ marginLeft: 0, marginRight: 0 }}
    control={<Checkbox name={name} checked={checked} onChange={onChange} />}
    label={label}
  />
);
