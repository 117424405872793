import { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

import { Autocomplete, AutocompletePlaceOption } from '../../Autocomplete';

export type GoogleMapAutocompleteValue = {
  lng: number;
  lat: number;
};

export type Option = {
  label: string;
  placeId: string;
  description: string;
  secondaryText: string;
};

type GoogleMapAutocompleteProps = {
  map: google.maps.Map | undefined;
  onChange?: (value: GoogleMapAutocompleteValue | null) => void;
};

export const GoogleMapAutocomplete = ({ map, onChange }: GoogleMapAutocompleteProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>([]);
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const placesService = useRef<google.maps.places.PlacesService>();

  const debouncePlacePredictions = useCallback(
    (inputValue: string) =>
      debounce(() => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions({ input: inputValue }, (result) => {
            const newOptions: Option[] = [];
            result?.forEach((item) => {
              newOptions.push({
                label: item.structured_formatting.main_text,
                placeId: item.place_id,
                secondaryText: item.structured_formatting.secondary_text,
                description: item.description,
              });
            });
            setOptions(newOptions);
          });
        }
      }, 400),
    [],
  );

  const handleChange = (option: Option | null) => {
    if (option && map && placesService.current) {
      placesService.current.getDetails({ placeId: option.placeId }, (details) => {
        const location = details?.geometry?.location;
        if (location) {
          onChange?.({
            lng: location.lng(),
            lat: location.lat(),
          });
        }
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (!google) return console.error('Google has not been found.');

    if (map) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
      placesService.current = new google.maps.places.PlacesService(map);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [map]);

  return (
    <Autocomplete
      value={value}
      options={options}
      noOptionsText={t('search-location')}
      includeInputInList
      getOptionLabel={(option) => option.description}
      isOptionEqualToValue={(o, v) => o.description === v.description}
      size='small'
      sx={{ width: 240 }}
      onChange={(_, option) => {
        handleChange(option);
        setValue(option);
      }}
      onInputChange={(_, newInputValue) => {
        debouncePlacePredictions(newInputValue)();
      }}
      renderOption={(props, option) => (
        <AutocompletePlaceOption key={option.description} props={props} option={option} />
      )}
    />
  );
};
