import { useProjectTasksDataTree } from '../../../../modules/Tasks/state/projectTasks';
import { transformDataForGantt } from '../lib/transformDataForGantt';

export const useGanttData = () => {
  const { projectTasks } = useProjectTasksDataTree();

  return {
    data: transformDataForGantt({ projectTasks }),
  };
};
