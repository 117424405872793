/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Trans } from 'react-i18next';

import type {
  UpdateActionDescription,
} from '../../../../types';
import { FieldValueCell } from '../FieldValueCell/FieldValueCell';
import { convertUpdateIncidentToFieldValue, fieldNameT } from '../utils';

type UpdateIncidentActionProps = {
  actionDescription: UpdateActionDescription
}
export const UpdateIncidentAction: FC<UpdateIncidentActionProps> = ({ actionDescription }) => {
  const attrsList = convertUpdateIncidentToFieldValue(actionDescription.attrs);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='20%' />
            <TableCell width='40%'><Trans i18nKey='before' /></TableCell>
            <TableCell width='40%'><Trans i18nKey='after' /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attrsList.map((props) => (
            <TableRow
              key={props.old.name}
            >
              <TableCell width='20%' component='th' scope='row'>
                <Trans i18nKey={fieldNameT(props.old.name)} />
              </TableCell>
              <TableCell width='40%'>
                <FieldValueCell {...props.old} />
              </TableCell>
              <TableCell width='40%'>
                <FieldValueCell {...props.new} />
              </TableCell>
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
