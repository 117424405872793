import { type FC } from 'react';

import { Box } from '@mui/material';
import { Trans } from 'react-i18next';

import { getUrlBackend } from '../../../../utils/url';

interface DownloadExcelTemplateProps {
  link: string;
}

export const DownloadExcelTemplate: FC<DownloadExcelTemplateProps> = ({ link }) => (
  <Box
    component='a'
    href={getUrlBackend(link)}
    download
    sx={{ display: 'flex', color: 'inherit', textDecoration: 'inherit' }}
  >
    <Trans i18nKey='download-for-excel' />
  </Box>
);
