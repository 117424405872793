import { atom, selector } from 'recoil';

import { userState } from '../user';

export const currentEventIdState = atom<number | null>({
  key: 'currentEventIdState',
  default: null,
});

export const userEventState = selector({
  key: 'userEvent',
  get: ({ get }) => {
    const id = get(currentEventIdState);
    const currentUserEvent = get(userState)?.userEvents?.find(({ event }) => event.id === id);

    return currentUserEvent;
  },
});

export const eventState = selector({
  key: 'event',
  get: ({ get }) => {
    const currentUserEvent = get(userEventState);

    if (currentUserEvent) {
      return currentUserEvent.event;
    }
    throw new Error('Not this event');
  },
});

export const permissionState = selector({
  key: 'permissions',
  get: ({ get }) => {
    const currentUserEvent = get(userEventState);

    return currentUserEvent
      ? currentUserEvent.permissions
      : {
          canCreateIncident: true,
          canCreateIncidentResolutionLevelGreen: true,
          canCreateIncidentResolutionLevelAmber: true,
          canCreateIncidentResolutionLevelOrange: false,
          canCreateIncidentResolutionLevelRed: false,
          canUpdateIncident: true,
          canUpdateResolvedIncident: false,
          canUpdateAllIncidents: false,
          canViewLogIncident: false,
          canViewAllIncidents: true,

          canCreateProjectTask: false,
          canRemoveProjectTask: false,
          canUpdateProjectTask: true,
          canUpdateResolvedProjectTask: false,
          canUpdateAllProjectTask: false,

          canCreateOperationalTask: false,
          canRemoveOperationalTask: false,
          canUpdateOperationalTask: true,
          canUpdateResolvedOperationalTask: false,
          canUpdateAllOperationalTask: false,

          canCreateComment: true,
          canUpdateComment: false,
          canUpdateResolvedIncidentComment: false,
          canViewComment: true,

          canCreateEvent: false,
          canUpdateEvent: false,
          canCloseEvent: false,
          canUpdateLocationEvent: false,
          canViewEvent: false,

          canViewUsers: false,

          canLocationFilters: true,
          canLevelFilters: true,
          canUserFunctionFilters: true,
          canResponsibleFilters: true,
          canParticipantsFilters: true,
          canRegisteredByFilters: true,

          canViewDashboard: true,

          canViewMaps: true,

          canViewArchivedProject: false,

          canRemoveUserEvent: false,
          canUpdateUserEvent: false,

          canDownloadExcel: true,

          canUpdateOwnerRole: false,
          canUpdateAdminRole: false,
          canUpdateObserverRole: false,
          canUpdateOperatorRole: false,
          canUpdateUserRole: false,
          canUpdateAuthenticatedRole: false,
        };
  },
});

export const currentEventSettings = selector({
  key: 'currentEventSettings',
  get: ({ get }) => {
    const { map, projectPlan, dailyPlan, incidentPlan } = get(eventState);
    return {
      map,
      availableProjectPlan: projectPlan ?? false,
      availableDailyPlan: dailyPlan ?? false,
      availableIncidentPlan: incidentPlan ?? false,
    };
  },
});
