import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import useDrawer from '../../../../hooks/useDrawer';
import { CountRenderer } from '../../../../modules/EntitiesGrid';
import { Drawer } from '../../../../state/drawer';
import { getIsViewedIncident } from '../../../../state/user';

const IdRenderer = ({ value, data }: ICellRendererParams) => {
  const count: number | undefined = value;
  const { id } = data;
  const { showDrawer } = useDrawer();
  const isViewedByUser = Boolean(useRecoilValue(getIsViewedIncident(id)));

  const onClickHandler = () => {
    showDrawer({ type: Drawer.INCIDENT_EDIT, props: { incidentId: id } });
  };

  return count ? (
    <CountRenderer
      count={count}
      onClick={onClickHandler}
      isViewedByUser={isViewedByUser}
    />
  ) : <>-</>;
};

export default memo(IdRenderer);
