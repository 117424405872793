import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';

import { useFullScreen } from '../FullScreen';

type Props = {
  isOpen: boolean;
  title?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onRequestClose: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  children: ReactNode;
};

const ConfirmDialog: FC<Props> = ({
  children,
  title,
  isOpen,
  confirmBtnText = <Trans i18nKey='ok' />,
  cancelBtnText = <Trans i18nKey='cancel' />,
  onConfirm,
  onCancel,
  onRequestClose,
}) => {
  const { ref } = useFullScreen();
  const onConfirmHandler = useCallback(() => {
    onConfirm?.();
    onRequestClose();
  }, [onConfirm, onRequestClose]);

  const onCancelHandler = useCallback(() => {
    onCancel?.();
    onRequestClose();
  }, [onRequestClose, onCancel]);

  return (
    <Dialog
      container={ref.current}
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onBackdropClick={onRequestClose}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelHandler}>{cancelBtnText}</Button>
        <Button onClick={onConfirmHandler} autoFocus>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
