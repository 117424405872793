import { makeInitialColumnSettings } from '../../../EntitiesGrid';
import { DEFAULT_PREPARED_TASK, TaskType } from '../tasks';
import type { PreparedNewProjectTask, ProjectTaskGridColumns } from './types';
import { ProjectTaskColumnKey } from './types';

export const DEFAULT_PROJECT_TASKS_COLUMNS: ProjectTaskGridColumns = makeInitialColumnSettings([
  ProjectTaskColumnKey.Count,
  ProjectTaskColumnKey.StartDatetime,
  ProjectTaskColumnKey.EndDatetime,
  ProjectTaskColumnKey.Title,
  ProjectTaskColumnKey.Relation,
  ProjectTaskColumnKey.Description,
  ProjectTaskColumnKey.Responsible,
  ProjectTaskColumnKey.UserFunction,
  ProjectTaskColumnKey.Status,
  ProjectTaskColumnKey.ResolutionLevel,
  ProjectTaskColumnKey.Comments,
  ProjectTaskColumnKey.ResolvedDate,
  ProjectTaskColumnKey.Participants,
]);

export const DEFAULT_PREPARED_PROJECT_TASK: PreparedNewProjectTask = {
  type: TaskType.ProjectTask,
  ...DEFAULT_PREPARED_TASK,
};
