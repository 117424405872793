import type { FC } from 'react';

import { Stack } from '@mui/material';

import type { CommentActionDescription } from '../../../../types';
import getPhotoFormats from '../../../../utils/getPhotoFormats';
import {
  ClampedTypography, FallbackImage,
} from '../../../base';

type AddCommentDataProps = {
  actionDescription: CommentActionDescription
}

export const AddCommentAction: FC<AddCommentDataProps> = ({ actionDescription }) => {
  const { image, body } = actionDescription.attrs.comment;
  const thumbnailImage = image ? getPhotoFormats(image).thumbnailSizeImage : undefined;

  return (
    <Stack gap='8px' spacing={2} direction='column' alignItems='left'>
      {thumbnailImage && (
        <FallbackImage
          sx={{
            marginBottom: '8px',
            height: '55px',
            maxHeight: '55px',
            borderRadius: '8px',
            objectFit: 'contain',
            width: 'fit-content',
          }}
          src={thumbnailImage.url}
          alt={thumbnailImage.name}
          mime={thumbnailImage.mime}
          url={thumbnailImage.url}
          name={thumbnailImage.name}
          aria-hidden='true'
        />
      )}

      <ClampedTypography lineclamp={3} variant='body2'>
        {body}
      </ClampedTypography>
    </Stack>
  );
};
