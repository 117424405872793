import type { FC } from 'react';

import { Card, FormControl } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { getTask } from '../../../modules/Tasks/state/tasks';
import { useAddComment, useRemoveComment, useUpdateComment } from '../../../state/comments';
import { permissionState } from '../../../state/event';
import { getIncident } from '../../../state/incidents';
import type { Comment, CommentBody } from '../../../types';
import { Status } from '../../../types';
import { IncidentComments } from '../../base';

type Props = {
  incidentId: number;
  data: Comment[];
  disabled?: boolean;
  type?: 'incident' | 'task';
};

const CommentsController: FC<Props> = ({ incidentId, data, disabled = false, type = 'incident' }) => {
  const { execute: addComment } = useAddComment();
  const incident = useRecoilValue(type === 'incident' ? getIncident(incidentId) : getTask(incidentId));
  const { canUpdateResolvedIncidentComment, canUpdateComment } = useRecoilValue(permissionState);
  const { execute: removeComment } = useRemoveComment();
  const { execute: updateComment } = useUpdateComment();

  const onSendHandler = ({ text, image }: CommentBody) => {
    addComment({
      incident: incidentId,
      body: text,
      image,
    });
  };

  const handleRemoveComment = (id: number) => {
    removeComment(id, incidentId);
  };

  const handleUpdateComment = (id: number, dataComment: CommentBody) => {
    updateComment(id, {
      body: dataComment.text,
      image: dataComment.image,
      incident: incidentId,
    });
  };

  return (
    <FormControl fullWidth>
      <Card variant='outlined'>
        <IncidentComments
          disabled={disabled}
          comments={data}
          onSend={onSendHandler}
          sx={{ maxHeight: '400px' }}
          canUpdateComment={incident?.status === Status.RESOLVED ? canUpdateResolvedIncidentComment : canUpdateComment}
          onRemove={handleRemoveComment}
          onUpdate={handleUpdateComment}
          stylePanelAspectRatio='0.15'
        />
      </Card>
    </FormControl>
  );
};

export default CommentsController;
