import { atom } from 'recoil';
import { pick } from 'remeda';

import DEFAULT_FILTER_CONFIG from '../../constants/defaultFilterConfig';
import type { UserDesktopFilters } from '../../types';

export const pickFastFilters = pick<UserDesktopFilters, keyof UserDesktopFilters>([
  'flagged',
  'viewedUser',
  'text',
  'hideDateTime',
]);

export const incidentsFilterState = atom({
  key: 'incidentsFilter',
  default: DEFAULT_FILTER_CONFIG,
});
