import { type ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Header } from '../../Header';
import { LayoutProvider, useLayoutContext } from '../model/LayoutProvider';

interface LayoutProps {
  menu: ReactNode;
  headerName: string;
}

const LayoutBase = (props: LayoutProps) => {
  const { menu, headerName } = props;
  const { hasNoEvent, canViewEvent } = useLayoutContext();

  return (
    <Box sx={{ width: '100%', height: '100%', paddingTop: '24px' }}>
      <Header name={headerName}>{menu}</Header>
      {hasNoEvent && !canViewEvent ? (
        <Stack p={3} sx={{ width: '100%', alignItems: 'center' }}>
          <Typography variant='h5'>
            <Trans i18nKey='you-dont-have-event' />
          </Typography>
        </Stack>
      ) : (
        <Box sx={{ height: 'calc(100% - 152px)' }}>
          <Outlet />
        </Box>
      )}
    </Box>
  );
};

export const Layout = (props: LayoutProps) => (
  <LayoutProvider>
    <LayoutBase {...props} />
  </LayoutProvider>
);
