import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { MultiUsersRenderer } from '../../../../modules/EntitiesGrid';
import { locationsState } from '../../../../state/locations';
import { userFunctionsState } from '../../../../state/userFunctions';
import indexById from '../../../../utils/indexedById';
import {
  BooleanRenderer,
  DateRenderer,
  FunctionRenderer,
  LocationRenderer,
  ResolutionLevelRenderer,
  StatusRenderer,
  TextRenderer,
  UserRenderer,
} from '../renderer';
import PhotosRenderer from '../renderer/PhotosRenderer';
import type { FieldValueCellProps } from '../utils';

export const FieldValueCell: FC<FieldValueCellProps> = (props) => {
  const locations = indexById(useRecoilValue(locationsState));
  const userFunctions = indexById(useRecoilValue(userFunctionsState));
  const dicts = {
    locations,
    userFunctions,
  };
  const { name, value } = props;
  if (value === undefined || value === null || value === '') {
    return <Typography>{' - '}</Typography>;
  }
  switch (name) {
    case 'status':
      return StatusRenderer(value);
    case 'responsible':
    case 'registeredBy':
      return UserRenderer(value);
    case 'resolutionLevel':
      return ResolutionLevelRenderer(value);
    case 'location':
      return LocationRenderer(value, dicts);
    case 'userFunction':
      return FunctionRenderer(value, dicts);
    case 'photos':
      return PhotosRenderer(value);
    case 'isUrgent':
    case 'isResponsibleInformed':
      return BooleanRenderer(value);
    case 'registeredDate':
    case 'resolvedDate':
    case 'endDatetime':
      return DateRenderer(value);
    case 'participants':
      return <MultiUsersRenderer users={value} />;
    default:
      return TextRenderer(value);
  }
};
