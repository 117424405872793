import type { ColumnsSettings } from '../../../modules/EntitiesGrid';
import type { Incident, NewPhotoSource } from '../../../types';

export enum ColumnKey {
  Count = 'count',
  Urgent = 'isUrgent',
  Title = 'title',
  Description = 'description',
  RegisteredBy = 'registeredBy',
  Status = 'status',
  ResolutionLevel = 'resolutionLevel',
  UserFunction = 'userFunction',
  Location = 'location',
  LocationDescription = 'locationDescription',
  Responsible = 'responsible',
  ResponsibleInformed = 'isResponsibleInformed',
  Photos = 'photos',
  Comments = 'comments',
  RegisteredDate = 'registeredDate',
  ResolvedDate = 'resolvedDate',
  EndDatetime = 'endDatetime',
  Participants = 'participants',
  Relations = 'relations',
}

export type IncidentTableColumns = ColumnsSettings<ColumnKey>;

export type PreparedNewIncident = Omit<
  Incident,
  'id' | 'registeredDate' | 'registeredBy' | 'registeredDate' | 'viewedBy' | 'count'
> & {
  photoSources: NewPhotoSource[];
};
