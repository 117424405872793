import { atom } from 'recoil';

const DEFAULT_PAGE_SIZE = 10;

export const pageState = atom<number>({
  key: 'incidentTable.pagination.page',
  default: 1,
});

export const pageSizeState = atom<number>({
  key: 'incidentTable.pagination.pageSize',
  default: DEFAULT_PAGE_SIZE,
});

export const countState = atom<number>({
  key: 'incidentTable.pagination.count',
  default: 0,
});

export const limitState = atom<number | undefined>({
  key: 'incidentTable.pagination.limit',
  default: undefined,
});
