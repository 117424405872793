import type { IconKind } from '../components/base/Icon/Icon';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const FILE_TYPE_MIME = {
  doc: ['.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['.xml', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  pdf: ['application/pdf'],
  powerPoint: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  key: ['application/x-iwork-keynote-sffkey'],
  image: ['image/png', 'image/jpeg'],
  avi: ['video/x-msvideo', 'video/avi'],
  mp4: ['video/mp4'],
  mpeg: ['video/mpeg'],
  mov: ['video/quicktime'],
};

export function getFormatFileByMime(mime: string) {
  const formats = Object.entries(FILE_TYPE_MIME) as Entries<typeof FILE_TYPE_MIME>;
  const format = formats.find(([, value]) => value.includes(mime));

  if (!format) {
    return null;
  }

  return format[0];
}

export function getIconByMime(mime: string): null | IconKind {
  const fileType = getFormatFileByMime(mime);

  switch (fileType) {
    case 'doc':
      return 'fileTypeDoc';

    case 'excel':
      return 'fileTypeXls';

    case 'pdf':
      return 'fileTypePdf';

    case 'powerPoint':
      return 'fileTypePowerpoint';

    case 'key':
      return 'fileTypeKey';

    case 'avi':
      return 'fileTypeAvi';

    case 'mp4':
      return 'fileTypeMp4';

    case 'mpeg':
      return 'fileTypeMpeg';

    case 'mov':
      return 'fileTypeMov';

    default:
      return null;
  }
}
