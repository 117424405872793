import type { FC } from 'react';

import type { SxProps, Theme} from '@mui/material';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Status } from '../../types';
import getStatusTitle from '../../utils/getStatusTitle';

type Props = {
  status: Status;
  sx?: SxProps<Theme>;
};

const STATUS_PROPS = {
  [Status.IN_PROGRESS]: { color: '#F2A861', backgroundColor: 'rgba(242, 168, 97, 0.1)' },
  [Status.RESOLVED]: { color: '#23A48B', backgroundColor: 'rgba(35, 164, 139, 0.1)' },
  [Status.DRAFT]: { color: '#C7D1D4', backgroundColor: 'rgba(199, 209, 212, 0.1)' },
  [Status.REGISTERED]: { color: '#AA96C8', backgroundColor: 'rgba(170, 150, 200, 0.1)' },
};

const StatusBadge: FC<Props> = ({ status, sx = {} }) => {
  const { t } = useTranslation();
  const title = getStatusTitle(status, t);

  return (
    <Box sx={{
      display: 'inline-block',
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingRight: '16px',
      paddingLeft: '16px',
      borderRadius: '20px',
      ...sx,
      ...STATUS_PROPS[status],
    }}
    >
      <Typography sx={{
        fontSize: '12px',
        lineHeight: '15px',
      }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default StatusBadge;
