import type { FC } from 'react';

import { Button } from '@mui/material';
import { Trans } from 'react-i18next';

import { Icon } from '../../../base';

export const AddNewIncidentButton: FC<{ onClick: () => void; disabled?: boolean }> = ({ onClick, disabled }) => (
  <Button variant='contained' sx={{ color: '#FFFFFF' }} onClick={onClick} disabled={disabled}>
    <Icon icon='plus' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
    <Trans i18nKey='add-new-incident' />
  </Button>
);
