import { useCallback } from 'react';

import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import type { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import { Controller, useFormContext } from 'react-hook-form';

import { DATETIME_FORMAT } from '../../../constants/base';
import { useFullScreen } from '../../FullScreen';

type Props = {
  name: 'endDatetime';
  label: string;
  disabled?: boolean;
  maxDateTime?: Date;
  minDateTime?: Date;
};

const EndDatetimeController = ({ name, label, disabled = false, maxDateTime, minDateTime = new Date() }: Props) => {
  const { ref } = useFullScreen();
  const { setValue, setError, control, clearErrors } = useFormContext();

  const onChangeHandler = useCallback(
    (newValue: unknown) => {
      setValue(name, newValue);
    },
    [name, setValue],
  );

  const onErrorHandler = useCallback(
    (reason: DateTimeValidationError) => {
      if (reason) {
        setError(name, { type: reason });
      } else {
        clearErrors(name);
      }
    },
    [clearErrors, name, setError],
  );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DateTimePicker
          {...field}
          value={field.value || null}
          label={label}
          disabled={disabled}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          ampm={false}
          inputFormat={DATETIME_FORMAT.full}
          onError={onErrorHandler}
          onChange={onChangeHandler}
          renderInput={(params) => <TextField fullWidth {...params} />}
          PopperProps={{
            container: ref.current,
          }}
        />
      )}
    />
  );
};

export default EndDatetimeController;
