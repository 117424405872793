import { useEffect } from 'react';

import { type Layout, type Layouts } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ControlPanelDashbords } from '../../../../components/ControlPanel/ControlPanelDashbords';
import { FullScreen } from '../../../../components/FullScreen';
import {
  dashboardsPrjectPlanState,
  useDashboards,
  useResetDashboards,
  useSaveDashboardSettings,
} from '../../../../entities/projectPlan/dashboards';
import { projectTasksFiltersState } from '../../../../modules/Tasks/state/projectTasks';
import { TaskType } from '../../../../modules/Tasks/state/tasks';
import { addNamesToLayout } from '../../../../shared/lib/addNamesToLayout';
import { ChartTypes } from '../../../../shared/lib/types/dashborad.types';
import { DashboardItem } from '../../../../shared/ui/DashboardItem';
import { DoughnutChart } from '../../../../shared/ui/DoughnutChart';
import { HorizontalBarChart } from '../../../../shared/ui/HorizontalBarChart';
import { LinearProgressChart } from '../../../../shared/ui/LinearProgressChart';
import { LineChart } from '../../../../shared/ui/LineChart';
import { Drawer } from '../../../../state/drawer';
import type { DashboardType } from '../../../../types';
import { datasetToDashboard } from '../lib/datasetToDashboard';
import { Loader } from '../ui/Loader';
import { ResponsiveGridLayout } from '../ui/ResponsiveGridLayout';

export const Dashboards = () => {
  const {
    execute: initDashboards,
    data: { dashboards: dashboardsData, layoutsSetting },
  } = useDashboards();
  const { execute: saveDashboardSettings, loading: saving } = useSaveDashboardSettings();
  const { execute: resetDashboards } = useResetDashboards();
  const filter = useRecoilValue(projectTasksFiltersState);
  const [, setState] = useRecoilState(dashboardsPrjectPlanState);
  const { t } = useTranslation();

  const onLayoutChange = (_: Layout[], allLayouts: Layouts) => {
    setState((state) => ({
      ...state,
      layoutsSetting: allLayouts,
    }));
  };

  useEffect(() => {
    initDashboards();

    return () => {
      resetDashboards();
    };
  }, [initDashboards, resetDashboards, filter]);

  const dashboards = datasetToDashboard(dashboardsData, t);
  const layoutsSettingsWithNames =
    dashboards.length > 0 ? addNamesToLayout(dashboards, layoutsSetting) : layoutsSetting;

  return dashboards.length > 0 ? (
    <FullScreen>
      <ControlPanelDashbords
        saveDashboardSettings={saveDashboardSettings}
        saving={saving}
        drawerType={{ type: Drawer.PROJECT_TASKS_FILTER }}
        taskType={TaskType.ProjectTask}
      />
      <ResponsiveGridLayout
        layouts={layoutsSettingsWithNames}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        }}
        onLayoutChange={onLayoutChange}
        rowHeight={30}
      >
        {dashboards.map((dashboard: DashboardType) => {
          let chart = null;
          if (dashboard.type === ChartTypes.DOUGHNUT_CHART) {
            chart = <DoughnutChart {...dashboard} keyName={dashboard.key} />;
          }

          if (dashboard.type === ChartTypes.HORIZONTAL_BAR_CHART) {
            chart = <HorizontalBarChart {...dashboard} keyName={dashboard.key} />;
          }

          if (dashboard.type === ChartTypes.LINE_CHART) {
            chart = (
              <LineChart
                {...dashboard}
                keyName={dashboard.key}
                minDate={filter.startDate ?? undefined}
                maxDate={filter.endDate ?? undefined}
              />
            );
          }

          if (dashboard.type === ChartTypes.LINEAR_PROGRESS_CHART) {
            chart = <LinearProgressChart {...dashboard} keyName={dashboard.key} />;
          }

          return (
            chart && (
              <div key={dashboard.name}>
                <DashboardItem title={dashboard.name} keyName={dashboard.key}>
                  {chart}
                </DashboardItem>
              </div>
            )
          );
        })}
      </ResponsiveGridLayout>
    </FullScreen>
  ) : (
    <Loader />
  );
};
