import { endOfDay, startOfDay } from 'date-fns';

import type { ProjectTaskDefaultFilters } from '../../../../../types';

export const transformFiltersForBackend = (filters: ProjectTaskDefaultFilters) => {
  const { startDate, endDate, responsible, userFunctions, status, participants, typeDate, resolutionLevel, text } =
    filters;

  return {
    ...(typeDate === 'registeredDate'
      ? {
          startDatetime: {
            $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
            $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
          },
        }
      : {
          resolvedDate: {
            $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
            $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
          },
        }),
    userFunction: userFunctions,
    responsible: {
      id: responsible,
    },
    resolutionLevel,
    status: {
      $in: status,
    },
    participants: {
      id: participants,
    },
    $or: [
      {
        title: {
          $containsi: text,
        },
      },
      {
        description: {
          $containsi: text,
        },
      },
      {
        count: {
          $containsi: text,
        },
      },
    ],
  };
};
