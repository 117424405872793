import { ControlPanelMap } from '../../components/ControlPanel/ControlPanelMap';
import { EntitiesPageWrapper } from '../../modules/EntitiesGrid';
import { IncidentMap } from '../../modules/Map/feature/IncidentMap';

const IncidentsMapPage = () => (
  <EntitiesPageWrapper>
    <ControlPanelMap />
    <IncidentMap />
  </EntitiesPageWrapper>
);

export default IncidentsMapPage;
