import { useMemo } from 'react';

import {
  GoogleMap,
  useGoogleMapInfoWindow,
  useGoogleMapOnLoad,
  GoogleMapAutocomplete,
  LocationMarker,
} from '../../../shared/components/GoogleMap';
import { useGoogleMapLoader } from '../../../shared/components/GoogleMap/model/useGoogleMapLoader';
import { MapLoader } from '../../../shared/components/MapLoader';
import { normalizeGoogleMapData } from '../lib/normalizeGoogleMapData';
import { useLocationMap } from '../model/LocationMapDataProvider';
import { AutocompleteBox } from './AutocompleteBox';

export const GoogleMapLocation = () => {
  const { isLoaded } = useGoogleMapLoader();
  const { map, onLoadMap } = useGoogleMapOnLoad();
  const data = useLocationMap();
  const {
    zoom,
    center,
    locations,
    onMarkerClick,
    onDraggableMarker,
    onMapClick,
    searchLocation,
    onChangeSearchLocation,
  } = useMemo(() => normalizeGoogleMapData(data, map), [data, map]);
  const { createOnLoadMarker, onMouseOut, createOnMouseOver, infoWindowInstance } = useGoogleMapInfoWindow({ map });

  if (isLoaded) {
    return (
      <GoogleMap
        zoom={zoom}
        center={center}
        onLoad={onLoadMap}
        onClick={onMapClick}
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
      >
        {searchLocation && (
          <LocationMarker
            position={{
              lng: searchLocation[0],
              lat: searchLocation[1],
            }}
          />
        )}
        {locations.map((marker) => (
          <LocationMarker
            view='primary'
            draggable
            key={marker.key}
            onDragEnd={onDraggableMarker(marker)}
            position={marker.position}
            onClick={onMarkerClick(marker)}
            onLoad={createOnLoadMarker(marker.key)}
            onMouseOver={createOnMouseOver(marker.key, marker.title)}
            onMouseOut={onMouseOut}
          />
        ))}
        {infoWindowInstance}
        <AutocompleteBox>
          <GoogleMapAutocomplete map={map} onChange={onChangeSearchLocation} />
        </AutocompleteBox>
      </GoogleMap>
    );
  }

  return <MapLoader />;
};
