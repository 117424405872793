import type { FC} from 'react';
import React, { useEffect } from 'react';

import { Popover, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';

type CopyToClipboardProps = {
  children: ({
    onCopyClick,
    handlePopoverClose,
  }: {
    onCopyClick: (event: React.MouseEvent<HTMLElement>) => boolean,
    handlePopoverClose: () => void
  }) => React.ReactNode,
  text: string,
  notificationText: React.ReactNode,
  copyOptions?: {
    debug?: boolean,
    message?: string,
    format?: string,
  }
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({
  children, text, copyOptions, notificationText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const onCopyClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    return copy(text, copyOptions);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handlePopoverClose();
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    }

    return () => null;
  }, [open]);

  return (
    <div>
      {children({ onCopyClick, handlePopoverClose })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{notificationText}</Typography>
      </Popover>
    </div>
  );
};

export default CopyToClipboard;
