import {
  forwardRef, useEffect, useMemo, useState,
} from 'react';

import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import type { BoxProps} from '@mui/material';
import { Box, Typography } from '@mui/material';

import { getIconByMime } from '../../utils/mime';
import Icon from './Icon';

type Props = Omit<BoxProps, 'component'> & {
  src: string;
  alt?: string;
  mime?: string;
  name?: string;
  url?: string;
};

const FallbackImage = forwardRef<HTMLImageElement, Props>((props, ref) => {
  const [hasError, setHasError] = useState(false);
  const fileType = getIconByMime(props.mime ?? '');
  const isBlob = useMemo(() => props.src.includes('blob:'), [props.src]);

  const errorHandler = () => {
    setHasError(true);
  };

  // Update error state, then edit comment.
  // This need because, the EditorComments in table is not updated, before closing the editor.
  useEffect(() => {
    if (isBlob) {
      setHasError(false);
    }
  }, [isBlob]);

  return (
    <>
      {hasError && (
        <Box
          sx={{
            ...props.sx,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #e2e2e2',
          }}
          onClick={props.onClick}
        >
          <BrokenImageIcon />
        </Box>
      )}

      {fileType === null ? (
        <Box
          {...props}
          component='img'
          onError={isBlob ? undefined : errorHandler}
          ref={ref}
          maxWidth='100%'
          sx={{ ...props.sx, display: hasError ? 'none' : 'block' }}
        />
      ) : (
        <Typography component='a' display='flex' alignItems='center' variant='caption' color='GrayText' download href={props?.url} mb={2}>
          <Icon icon={fileType} sx={{ width: 24, height: 29 }} />
          <Typography component='span' display='inline-block' variant='inherit' ml={1} noWrap>{props?.name}</Typography>
        </Typography>
      )}

    </>
  );
});

export default FallbackImage;
