import qs from 'qs';

import type { UserDesktopFilters } from '../../../../types';
import { getFilters } from './getFilters';

const POPULATIONS = [
  'comments',
  'responsible',
  'registeredBy',
  'photos',
  'location',
  'geo',
  'location.geo',
  'comments.author',
  'comments.image',
  'comments.hiddenUser',
  'comments.editedUser',
  'userFunction',
  'viewedBy',
  'participants',
  'relations',
];

interface QueryAllParams {
  filter: UserDesktopFilters;
  eventId: number;
}

export const getQueryAll = ({ filter, eventId }: QueryAllParams) =>
  qs.stringify(
    {
      filters: getFilters(filter, eventId),
      populate: POPULATIONS,
      pagination: {
        limit: -1,
      },
    },
    { encodeValuesOnly: true },
  );
