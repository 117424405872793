import { useCallback, useState } from 'react';

export function useGoogleMapOnLoad() {
  const [map, setMap] = useState<google.maps.Map | undefined>();

  const onLoadMap = useCallback(
    (mapInstance: google.maps.Map) => {
      setMap(mapInstance);
    },
    [setMap],
  );

  return {
    map,
    onLoadMap,
  };
}
