import { useCallback, useState } from 'react';

import { debounce } from '@mui/material/utils';
import type { LngLat } from '@yandex/ymaps3-types';
import { useTranslation } from 'react-i18next';

import { Autocomplete, AutocompletePlaceOption } from '../../Autocomplete';
import type { YandexMapApiLoader } from '../model/YandexMapProvider';

export type YandexMapAutocompleteOption = {
  label: string;
  coordinates: LngLat | undefined;
  secondaryText: string;
};

type YandexMapAutocompleteProps = {
  ymapsInstance: YandexMapApiLoader;
  onChange?: (value: YandexMapAutocompleteOption | null) => void;
};

export const YandexMapAutocomplete = ({ ymapsInstance, onChange }: YandexMapAutocompleteProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<YandexMapAutocompleteOption | null>(null);
  const [options, setOptions] = useState<YandexMapAutocompleteOption[]>([]);
  const { ymaps } = ymapsInstance;

  const debouncePlacePredictions = useCallback(
    (inputValue: string) =>
      debounce(async () => {
        const response = await ymaps.search({ text: inputValue });
        const newOptions: YandexMapAutocompleteOption[] = response.map((item) => ({
          label: item.properties.name,
          secondaryText: item.properties.description,
          coordinates: item.geometry?.coordinates,
        }));

        setOptions(newOptions);
      }, 400),
    [ymaps],
  );

  return (
    <Autocomplete
      value={value}
      options={options}
      includeInputInList
      noOptionsText={t('search-location')}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(o, v) => o.label === v.label}
      size='small'
      sx={{ width: 240 }}
      onChange={(_, option) => {
        setValue(option);
        onChange?.(option);
      }}
      onInputChange={(_, newInputValue) => {
        debouncePlacePredictions(newInputValue)();
      }}
      renderOption={(props, option) => (
        <AutocompletePlaceOption
          key={`${option.coordinates?.[1]}.${option.coordinates?.[0]}`}
          props={props}
          option={option}
        />
      )}
    />
  );
};
