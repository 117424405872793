import type { ReactNode } from 'react';

import { Box } from '@mui/material';

import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-material.min.css';

import './index.css';

const boxStyle = { width: '100%', height: '100%' };

export const EntitiesGridBox = ({ children }: { children: ReactNode }) => (
  <Box className='ag-theme-material' style={boxStyle}>
    {children}
  </Box>
);
