import { useEffect } from 'react';

import { useDebounce } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import { currentEventIdState } from '../state/event';
import { useIncidentsList, useResetIncidents } from '../state/incidents';
import { incidentsFilterState } from '../state/incidentsFilter';
import { limitState } from '../state/incidentTable/pagination';

const DEBOUNCE_TIME = 500;

const useIncidentsMap = () => {
  const filter = useRecoilValue(incidentsFilterState);
  const eventId = useRecoilValue(currentEventIdState);
  const [limit, setLimit] = useRecoilState(limitState);
  const { execute, data, loading } = useIncidentsList();
  const { execute: resetIncidents } = useResetIncidents();

  useEffect(
    () => {
      setLimit(-1);
      return () => {
        resetIncidents();
        setLimit(undefined);
      };
    },
    [setLimit, resetIncidents],
  );

  useDebounce(
    () => {
      if (eventId && limit === -1) {
        execute();
      }
    },
    DEBOUNCE_TIME,
    [filter, eventId, limit],
  );

  return { data, loading };
};

export default useIncidentsMap;
