/* eslint-disable @typescript-eslint/no-explicit-any */
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import MarkerClusterGroupBase from 'react-leaflet-cluster';

import { ClusterMarker } from '../../Markers';

interface ClusterProps {
  [key: string]: any;
}

export const MarkerClusterGroup = (props: ClusterProps) => (
  <MarkerClusterGroupBase
    {...props}
    iconCreateFunction={(cluster: any) =>
      divIcon({
        html: renderToStaticMarkup(<ClusterMarker count={cluster.getChildCount()} />),
        iconSize: [0, 0],
      })
    }
  />
);
