import type { ColumnsSettings } from '../../../modules/EntitiesGrid';
import { TaskType } from '../../../modules/Tasks/state/tasks';
import { ResolutionLevel, Status } from '../../../types';
import { ColumnKey } from './types';

export const DEFAULT_PREPARED_INCIDENT = {
  title: '',
  description: '',
  comments: [],
  photos: [],
  photoSources: [],
  status: Status.REGISTERED,
  resolutionLevel: ResolutionLevel.GREEN,
  isHaveSeenByOperator: false,
  isResponsibleInformed: false,
  isUrgent: false,
  type: TaskType.Incident,
};

export const DEFAULT_COLUMNS: ColumnsSettings<ColumnKey> = {
  count: {
    isShow: true,
    index: 1,
  },
  isUrgent: {
    isShow: true,
    index: 3,
  },
  [ColumnKey.Relations]: {
    isShow: true,
    index: 4,
  },
  title: {
    isShow: true,
    index: 4,
  },
  description: {
    isShow: true,
    index: 5,
  },
  resolutionLevel: {
    isShow: true,
    index: 6,
  },
  status: {
    isShow: true,
    index: 7,
  },
  responsible: {
    isShow: true,
    index: 8,
  },
  registeredBy: {
    isShow: true,
    index: 9,
  },
  userFunction: {
    isShow: true,
    index: 10,
  },
  location: {
    isShow: true,
    index: 11,
  },
  locationDescription: {
    isShow: true,
    index: 12,
  },
  photos: {
    key: 'pictures',
    isShow: true,
    index: 13,
  },
  comments: {
    isShow: true,
    index: 14,
  },
  isResponsibleInformed: {
    isShow: true,
    index: 16,
  },
  registeredDate: {
    isShow: true,
    index: 17,
  },
  resolvedDate: {
    isShow: true,
    index: 18,
  },
  endDatetime: {
    isShow: true,
    index: 19,
  },
  [ColumnKey.Participants]: {
    isShow: true,
    index: 20,
  },
};

export const DEFAULT_SORT_STATE = { field: 'count', order: 'desc' as const };
export const SORT_MAP_FIELD: Partial<Record<ColumnKey, string>> = {
  responsible: 'responsible.firstName',
  registeredBy: 'registeredBy.firstName',
};

const replaceFields: Partial<Record<ColumnKey, string>> = {
  location: 'location',
  userFunction: 'userFunction',
};

export const mapColumnKeyToField = (field: ColumnKey): string => {
  if (field in replaceFields) {
    return replaceFields[field] || '';
  }
  return field;
};
