import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import type { UserFunction } from '../../types';
import { CustomPopper } from './components/CustomPopper';

export type UserFunctionSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options extends UserFunction,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<UserFunction, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'isOptionEqualToValue' | 'getOptionLabel' | 'renderOption'
> &
  UseControllerProps<TFieldValues, TName> & {
    label: string;
    onChange?: (values: Options[]) => void;
    getOptionDisabled?: (value: TFieldValues[]) => boolean;
  };

export const UserFunctionSelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options extends UserFunction,
>(
  props: UserFunctionSelectFieldProps<TFieldValues, TName, Options>,
) => {
  const { label, placeholder, ...rest } = props;
  const { field } = useController(props);

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...rest}
        {...field}
        onChange={(_, value) => field.onChange(value)}
        renderInput={(params: TextFieldProps) => <TextField {...params} label={label} placeholder={placeholder} />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(userFunction) => userFunction.name}
        renderOption={(liProps, userFunction) => <li {...liProps}>{userFunction.name}</li>}
        PopperComponent={CustomPopper}
      />
    </FormControl>
  );
};
