import { useRecoilValueLoadable } from 'recoil';

import { Users } from '../../components/Users';
import { userState } from '../../state/user';

const UsersPage = () => {
  const currentUser = useRecoilValueLoadable(userState).valueMaybe();

  return currentUser ? <Users /> : null;
};

export default UsersPage;
