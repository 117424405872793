import { useCallback } from 'react';

import type { ValueSetterParams } from 'ag-grid-community';

type Params<T, E> = {
  defaultValueSetter: ({ colDef, data, newValue }: ValueSetterParams) => boolean,
  getShowConfirmFn: (oldValue: T, newValue: T, data: E) => (() => Promise<boolean>) | false,
}

export const useGridSetterWithConfirm = <V, E>({
  defaultValueSetter,
  getShowConfirmFn,
}: Params<V, E>) => {
  const setterWithConfirm = useCallback(
    (params: ValueSetterParams) => {
      const {
        newValue, oldValue, data,
      } = params;
      const showConfirm = getShowConfirmFn(oldValue, newValue, data);

      if (showConfirm) {
        showConfirm().then((isOk) => {
          if (isOk) {
            defaultValueSetter(params);
          }
        });
        return false;
      }
      return defaultValueSetter(params);
    },
    [defaultValueSetter, getShowConfirmFn],
  );

  return { setterWithConfirm };
};
