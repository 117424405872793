import type { YMapMarkerProps } from '@yandex/ymaps3-types';

import type { CircleMarkerProps } from '../../Markers';
import { CircleMarker } from '../../Markers';
import type { YandexMapApiLoader } from '../model/YandexMapProvider';

type IncidentMarkerProps = {
  ymapsInstance: YandexMapApiLoader;
} & YMapMarkerProps &
  CircleMarkerProps;
export const IncidentMarker = ({ ymapsInstance, color, ...props }: IncidentMarkerProps) => {
  const { YMapMarker } = ymapsInstance.ymaps;
  return (
    <YMapMarker {...props}>
      <CircleMarker color={color} />
    </YMapMarker>
  );
};
