import { Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { noop } from 'remeda';

import { Button, DrawerContainer } from '../../../../components/base';
import { DateField } from '../../../../components/fields/DateField';
import { RelationsField } from '../../../../components/fields/RelationField';
import { ResolutionLevelField } from '../../../../components/fields/ResolutionLevelField';
import { StatusField } from '../../../../components/fields/StatusField';
import { TextFieldField } from '../../../../components/fields/TextFieldField';
import { UserFunctionSelectField } from '../../../../components/fields/UserFunctionSelectField';
import { UserSelectField } from '../../../../components/fields/UserSelectField';
import { UserSelectMultipleField } from '../../../../components/fields/UserSelectMultipleField';
import { CommentsController } from '../../../../components/IncidentForm/components';
import { currentEventIdState, currentEventSettings, permissionState } from '../../../../state/event';
import { getIncidentsWithoutRelations } from '../../../../state/incidents';
import { NotificationType, useShowNotification } from '../../../../state/notification';
import { userFunctionsState } from '../../../../state/userFunctions';
import { usersState } from '../../../../state/users';
import type { ResolutionLevel } from '../../../../types';
import { type User } from '../../../../types';
import findCurrentUserEvent from '../../../../utils/findCurrentUserEvent';
import getAvailableStatuses from '../../../../utils/getAvailableStatuses';
import { getTask } from '../../state/tasks';
import type { ProjectTaskFormInitialValues } from './lib/types';
import { useCanEditFields } from './model/useCanEditFields';
import { useUpdateProjectTaskForm } from './model/useUpdateProjectTaskForm';

export interface ProjectTaskFormProps {
  id: number;
  count: number | string;
  availableResolutionLevels: ResolutionLevel[];
  onEndAction?: () => void;
}

export const ProjectTaskForm = (props: ProjectTaskFormProps) => {
  const { availableResolutionLevels, count, id, onEndAction } = props;
  const data = useRecoilValue(getTask(id));
  const {
    title,
    description,
    status,
    resolutionLevel,
    responsible,
    userFunction,
    comments,
    relation,
    participants,
    startDatetime,
    endDatetime,
  } = data;
  const { t } = useTranslation();
  const currentEventId = useRecoilValue(currentEventIdState);
  const users = useRecoilValue(usersState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const incidents = useRecoilValue(getIncidentsWithoutRelations(null));
  const { execute: onUpdateProjectTask } = useUpdateProjectTaskForm();
  const { execute: showNotification } = useShowNotification();
  const permissions = useRecoilValue(permissionState);
  const eventSettings = useRecoilValue(currentEventSettings);
  const { canUpdateAllIncidents } = permissions;
  const availableStatuses = getAvailableStatuses(canUpdateAllIncidents, status);
  const resolutionLevelByPermissions = availableResolutionLevels.filter(
    (item) => permissions[`canCreateIncidentResolutionLevel${item}`],
  );

  const initialValues: ProjectTaskFormInitialValues = {
    startDatetime: startDatetime ?? null,
    endDatetime: endDatetime ?? null,
    title,
    description,
    status,
    resolutionLevel,
    participants: participants || [],
    responsible: responsible ?? null,
    userFunction: userFunction ?? null,
    relation: relation ? [relation] : [],
    comments: null,
  };

  const form = useForm<ProjectTaskFormInitialValues>({
    defaultValues: initialValues,
  });
  const { handleSubmit, control, formState, watch, setValue } = form;

  const watchResponsible = watch('responsible');
  const { fields, formCanBeEdited } = useCanEditFields({
    formFields: Object.keys(initialValues) as Array<keyof ProjectTaskFormInitialValues>,
    data,
    availableResolutionLevels: resolutionLevelByPermissions,
  });

  const onSubmit = async (values: ProjectTaskFormInitialValues) => {
    await onUpdateProjectTask({
      id,
      values,
      successCallback: async () => {
        showNotification({ type: NotificationType.SUCCESS, text: t('task-update-success') });
        await onEndAction?.();
      },
    });
  };

  const handleChangeResponsible = (value: User | null) => {
    if (value) {
      const watchedUser = users.find((user) => value.id === user.id);

      if (watchedUser && currentEventId) {
        const userEvent = findCurrentUserEvent(currentEventId)(watchedUser.userEvents);

        if (userEvent) setValue('userFunction', userEvent?.function);
      }
    }
  };

  return (
    <DrawerContainer component='form' onSubmit={formCanBeEdited ? handleSubmit(onSubmit) : noop}>
      <Stack>
        <Grid container columnSpacing={1} rowSpacing={3} alignItems='center'>
          <Grid xs={12} item>
            <Typography variant='h4' sx={{ marginBottom: '16px' }}>
              {t('edit-project-plan')}
              &nbsp;#
              {count}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <DateField
              control={control}
              name='startDatetime'
              label={t('start-datetime')}
              disabled={!fields.startDatetime}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <DateField control={control} name='endDatetime' label={t('end-datetime')} disabled={!fields.endDatetime} />
          </Grid>
          <Grid xs={12} item>
            <TextFieldField
              control={control}
              name='title'
              rules={{ required: true, validate: (value) => Boolean((value || '').trim()) }}
              placeholder={t('title')}
              label={t('title')}
              required
              fullWidth
              inputProps={{
                maxLength: 250,
              }}
              disabled={!fields.title}
            />
          </Grid>

          <Grid xs={12} item>
            <TextFieldField
              control={control}
              name='description'
              placeholder={t('description')}
              label={t('description')}
              fullWidth
              maxRows={5}
              multiline
              disabled={!fields.description}
            />
          </Grid>

          <Grid xs={12} sm={3} item>
            <StatusField
              control={control}
              name='status'
              label={t('status')}
              required
              data={availableStatuses}
              disabled={!fields.status}
            />
          </Grid>
          <Grid xs={12} sm={3} item>
            <ResolutionLevelField
              control={control}
              name='resolutionLevel'
              label={t('level')}
              required
              data={fields.resolutionLevel ? resolutionLevelByPermissions : availableResolutionLevels}
              disabled={!fields.resolutionLevel}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <UserSelectField
              control={control}
              name='responsible'
              label={t('responsible')}
              placeholder={t('responsible')}
              options={users}
              fullWidth
              onChange={handleChangeResponsible}
              disabled={!fields.responsible}
            />
          </Grid>

          <Grid xs={12} sm={12} item>
            <UserSelectMultipleField
              control={control}
              name='participants'
              label={t('participants')}
              placeholder={t('participants')}
              options={users}
              fullWidth
              disabled={!fields.participants}
            />
          </Grid>

          <Grid xs={12} item>
            <UserFunctionSelectField
              control={control}
              name='userFunction'
              label={t('user-function')}
              placeholder={t('user-function')}
              options={userFunctions}
              disabled={Boolean(watchResponsible) || !fields.userFunction}
            />
          </Grid>

          {eventSettings.availableIncidentPlan && (
            <Grid xs={12} sm={12} item>
              <RelationsField
                control={control}
                name='relation'
                label={t('relations')}
                placeholder={t('relations')}
                options={incidents}
                disabled={!fields.relation}
              />
            </Grid>
          )}

          <Grid xs={12} sm={12} item>
            <Typography variant='fieldLabel'>{t('comments')}:</Typography>
            <CommentsController data={comments} incidentId={id} disabled={!fields.comments} type='task' />
          </Grid>
        </Grid>
      </Stack>
      {formCanBeEdited && (
        <Stack spacing={2}>
          <Button
            fullWidth
            loading={formState.isSubmitting}
            type='submit'
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
          >
            <Trans i18nKey='update-task' />
          </Button>
        </Stack>
      )}
    </DrawerContainer>
  );
};
