import { updateObj, type RecoilTaskInterface } from 'recoil-toolkit';
import { createPipe, pick } from 'remeda';

import { commentsService } from '../../../../api';
import { currentEventIdState } from '../../../../state/event';
import { getIncident, incidentsState } from '../../../../state/incidents';
import { userState } from '../../../../state/user';
import type { Incident } from '../../../../types';
import indexById from '../../../../utils/indexedById';
import updateIfExists from '../../../../utils/updateIfExists';
import { taskService } from '../../api';
import { tasksState } from './atoms';
import type { Task } from './types';
import { TaskType } from './types';

const editableFields = [
  'title',
  'description',
  'responsible',
  'status',
  'resolutionLevel',
  'resolvedDate',
  'userFunction',
  'endDatetime',
  'startDatetime',
  'participants',
  'location',
] as const;
type PartialTask = Partial<Task>;

export const convertRelationsToId = createPipe(
  updateIfExists<PartialTask, 'responsible', number>('responsible', (responsible) => responsible.id),
  updateIfExists('userFunction', (userFunction) => userFunction.id),
);

export const prepareEditTask = createPipe(pick<PartialTask, keyof Task>(editableFields), convertRelationsToId);

export const getTasks =
  ({ snapshot, set }: RecoilTaskInterface) =>
  async (filters = {}) => {
    const eventId = snapshot.getLoadable(currentEventIdState).getValue();

    if (eventId) {
      const dataOfTasks = await taskService.getTasks({ event: eventId, filters });
      set(tasksState, indexById(dataOfTasks.data));
    }
  };

export const addCommentTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async (entity: { id: number; type: TaskType }, body: string, image?: File) => {
    const user = snapshot.getLoadable(userState).getValue();
    const { data: comment } = await commentsService.addComment({ body, incident: entity.id, image }, user.id);

    if (entity.type === TaskType.Incident) {
      const incident = snapshot.getLoadable(getIncident(entity.id)).getValue();
      const oldComments = incident?.comments ?? [];

      set(incidentsState, updateObj<Incident>({ comments: [...oldComments, comment] }, { id: entity.id }));
    } else {
      set(tasksState, (tasks) => ({
        ...tasks,
        [entity.id]: {
          ...tasks[entity.id],
          comments: [...tasks[entity.id].comments, comment],
        },
      }));
    }
  };
