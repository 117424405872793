import { useEffect } from 'react';

import { useDebounce } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import { sortState } from '../components/IncidentTable/state';
import { useTasks } from '../modules/Tasks/state/tasks';
import { currentEventIdState } from '../state/event';
import { useIncidentsList, useResetIncidents } from '../state/incidents';
import { incidentsFilterState } from '../state/incidentsFilter';
import { limitState, pageSizeState, pageState } from '../state/incidentTable/pagination';

const DEBOUNCE_TIME = 500;

const useIncidents = () => {
  const filter = useRecoilValue(incidentsFilterState);
  const pageSize = useRecoilValue(pageSizeState);
  const [, setPage] = useRecoilState(pageState);
  const [limit, setLimit] = useRecoilState(limitState);
  const sort = useRecoilValue(sortState);
  const eventId = useRecoilValue(currentEventIdState);
  const { execute, data, loading } = useIncidentsList();
  const { execute: resetIncidents } = useResetIncidents();
  const { execute: loadTasks } = useTasks();

  useEffect(() => {
    setLimit(undefined);
    return () => {
      resetIncidents();
    };
  }, [setLimit, resetIncidents]);

  useEffect(() => {
    setPage(1);
  }, [filter, setPage]);

  useDebounce(
    () => {
      if (eventId && !limit) {
        execute();
        loadTasks();
      }
    },
    DEBOUNCE_TIME,
    [filter, pageSize, sort, eventId, limit],
  );

  return { data, loading };
};

export default useIncidents;
