const getFullUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }

  const originUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : document.location.origin;

  return originUrl + url;
};

export default getFullUrl;
