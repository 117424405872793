import type {
  FC,
} from 'react';

import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { userRolesState } from '../../state/roles';
import { userFunctionsState } from '../../state/userFunctions';
import { ObjectSelectController } from '../form';

export const UserEventFields: FC = () => {
  const { t } = useTranslation();

  const roles = useRecoilValue(userRolesState);
  const userFunctions = useRecoilValue(userFunctionsState);

  return (
    <>
      <Grid xs={12} item>
        <ObjectSelectController
          name='role'
          label={t('role')}
          data={roles}
          itemRenderer={(item) => item.name}
          required
        />
      </Grid>

      <Grid xs={12} item>
        <ObjectSelectController
          name='userFunction'
          label={t('user-function')}
          data={userFunctions}
          itemRenderer={(item) => item.name}
          required
        />
      </Grid>
    </>
  );
};
