import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import type { LinearProgressChartType } from '../../types';
import type { KeyNameType } from '../lib/types/dashborad.types';

const getPercentage = (value: number, total: number) => (value / total) * 100;

export const LinearProgressChart = ({
  dataset,
  total,
  legendTitle,
  keyName,
}: LinearProgressChartType & KeyNameType) => {
  const [leftName, leftValue] = Object.entries(dataset[0])[0];
  const [rightName, rightValue] = Object.entries(dataset[1])[0];

  return (
    <div id={keyName}>
      <Stack p={1}>
        <Stack direction='row' sx={{ justifyContent: 'space-between' }} pb={1}>
          <Box sx={{ textAlign: 'left' }}>{leftName}</Box>
          <Box sx={{ textAlign: 'right' }}>{rightName}</Box>
        </Stack>
        <Stack direction='row'>
          {Boolean(leftValue) && (
            <Box
              sx={{
                backgroundColor: 'rgb(170, 150, 200)',
                color: 'white',
                width: `${getPercentage(leftValue, total)}%`,
                minWidth: '36px',
              }}
              p={1}
            >
              <Typography component='div' fontSize='h5'>
                {leftValue}
              </Typography>
            </Box>
          )}
          {Boolean(rightValue) && (
            <Box
              sx={{
                backgroundColor: 'rgb(199, 209, 212)',
                color: 'black',
                width: `${getPercentage(rightValue, total)}%`,
                minWidth: '36px',
                textAlign: 'right',
              }}
              p={1}
            >
              <Typography component='div' fontSize='h5'>
                {rightValue}
              </Typography>
            </Box>
          )}
        </Stack>
        <Box pt={1} sx={{ textAlign: 'center' }}>
          <Typography component='div' variant='h5'>
            {legendTitle}:{total}
          </Typography>
        </Box>
      </Stack>
    </div>
  );
};
