import { useCallback, type FC } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { DatePicker } from '@mui/x-date-pickers';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { TextField, MenuButton } from '../../../../components/base';
import { DownloadExcelTemplate } from '../../../../components/ControlPanel/components/DownloadExcelTemplate';
import { ImportExcelButton } from '../../../../components/ControlPanel/components/ImportExcelButton';
import { FullScreenButton } from '../../../../components/FullScreen';
import useDrawer from '../../../../hooks/useDrawer';
import { Drawer } from '../../../../state/drawer';
import { permissionState } from '../../../../state/event';
import { useExportToPdfTableDailyTasks } from '../../../../state/export';
import { AddEntityControl, ButtonGridColumns, ControlPanelWrapper } from '../../../EntitiesGrid';
import { dailyTasksFiltersState, newEntityReady } from '../../state/dailyTasks';
import { useDailyTaskNewRow, useTabs } from '../../state/dailyTasks/hooks';
import { TaskFilters } from '../TaskFilters';

type Props = {
  onExportToExcel: () => void;
  onImportFromExcel: (file: File) => void;
  loadingImportFromExcel?: boolean;
};

export const DailyTasksControlPanel: FC<Props> = ({ onExportToExcel, onImportFromExcel, loadingImportFromExcel }) => {
  const { t } = useTranslation();
  const { currentTab, dayOptions, hasTasks, handleChangeTab, disabled } = useTabs();
  const { showDrawer } = useDrawer();
  const {
    loading: loadingNewRow,
    newEntity,
    prepareNewEntity: prepareNewTask,
    destroyNewEntity,
    addNewEntity,
  } = useDailyTaskNewRow();
  const { canCreateOperationalTask } = useRecoilValue(permissionState);
  const { loading: loadingExportPdfTable, execute: exportToPdfTable } = useExportToPdfTableDailyTasks();

  const exportToPdfTableCallback = useCallback(() => exportToPdfTable(t), [exportToPdfTable, t]);

  const loading = loadingImportFromExcel || loadingExportPdfTable;

  return (
    <ControlPanelWrapper
      leftSlot={
        <>
          {!hasTasks && currentTab && (
            <DatePicker
              label={t('day-daily-task')}
              inputFormat='dd/MM/yyyy'
              disabled={disabled}
              value={'day' in currentTab && currentTab.day ? currentTab.day : Date.now()}
              onChange={handleChangeTab}
              renderInput={(params) => <TextField required {...params} />}
            />
          )}
          {dayOptions && (
            <AddEntityControl
              disabled={!canCreateOperationalTask}
              loading={loadingNewRow}
              newEntity={newEntity}
              destroyNewEntity={destroyNewEntity}
              addNewEntity={addNewEntity}
              newEntityReady={newEntityReady}
              addNewButtons={[
                {
                  text: t('add-new-daily-task-inline'),
                  action: () => prepareNewTask(dayOptions),
                },
              ]}
            />
          )}
        </>
      }
      rightSlot={
        <>
          <TaskFilters
            filtersState={dailyTasksFiltersState}
            onClickFilterButton={() => showDrawer({ type: Drawer.DAILY_TASKS_FILTER })}
          />
          <ButtonGridColumns
            openGridColumnsSettings={() => showDrawer({ type: Drawer.DAILY_TASKS_COLUMNS })}
            columnsText={t('columns')}
          />
          <FullScreenButton />
          <MenuButton
            buttonContent={<MenuIcon />}
            isLoading={loading}
            commands={[
              ...(canCreateOperationalTask
                ? [
                    {
                      content: <ImportExcelButton onClick={onImportFromExcel} />,
                      disableAutoClose: true,
                    },
                  ]
                : []),
              ...(canCreateOperationalTask
                ? [
                    {
                      content: <DownloadExcelTemplate link='/excelTemplates/dailyPlanTemplate.xlsx' />,
                    },
                  ]
                : []),
              ...(onExportToExcel
                ? [
                    {
                      content: <Trans i18nKey='export-to-excel' />,
                      onClick: onExportToExcel,
                    },
                  ]
                : []),
              {
                content: <Trans i18nKey='export-table' />,
                onClick: exportToPdfTableCallback,
              },
            ]}
          />
        </>
      }
    />
  );
};
