import { TextField } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { AutocompleteMultiple, type AutocompleteMultipleProps } from '../AutocompleteMultiple';
import { CustomPopper } from './components/CustomPopper';

export type AutocompleteMultipleFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options,
> = Omit<
  AutocompleteMultipleProps<Options>,
  'filterSelectedOptions' | 'onChange' | 'renderInput' | 'getOptionDisabled'
> &
  UseControllerProps<TFieldValues, TName> & {
    label: string;
    onChange?: (values: Options[]) => void;
    getOptionDisabled?: (value: TFieldValues[]) => boolean;
  };

export const AutocompleteMultipleField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options extends { id: string | number },
>(
  props: AutocompleteMultipleFieldProps<TFieldValues, TName, Options>,
) => {
  const {
    label,
    options,
    disabled,
    onChange,
    limitTags = 4,
    getOptionDisabled,
    disableCloseOnSelect = true,
    ...rest
  } = props;
  const { field } = useController(props);
  return (
    <AutocompleteMultiple
      {...rest}
      {...field}
      options={options}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      limitTags={limitTags}
      filterSelectedOptions
      PopperComponent={CustomPopper}
      onChange={(_, value) => {
        switch (value) {
          case 'all':
            field.onChange(options);
            // eslint-disable-next-line no-case-declarations
            const opt = [...options];
            onChange?.(opt);
            break;

          default:
            field.onChange(value);
            onChange?.(value);
            break;
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getOptionDisabled={() => (getOptionDisabled ? getOptionDisabled(field.value) : false)}
    />
  );
};
