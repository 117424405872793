/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';

import type { GridApi, ValueSetterParams } from 'ag-grid-community';

import type { EditNewEntityFn, IsNewRowEntityFn } from '../../newEntity';

type Params = {
  isPreparedEntity: IsNewRowEntityFn;
  editPreparedEntity: EditNewEntityFn;
  editEntityPart: (id: number, changes: Record<string, unknown>) => void;
  callbackIdString?: (params: { id: string; colField: string; value: unknown; api: GridApi }) => void;
};

export const useGridDefaultSetter = <T extends { id?: number | string }, K extends string>({
  isPreparedEntity,
  editPreparedEntity,
  editEntityPart,
  callbackIdString,
}: Params) => {
  const defaultValueSetter = useCallback(
    ({ colDef, data, newValue, api }: ValueSetterParams) => {
      if (!colDef.field) {
        return false;
      }

      const entity: T = data;
      const colField = colDef.field as K;

      if (typeof entity.id === 'string') {
        callbackIdString?.({
          id: entity.id,
          colField,
          value: newValue,
          api,
        });
      } else if (isPreparedEntity(entity)) {
        editPreparedEntity(colField, newValue);
      } else if ('id' in entity && entity.id) {
        editEntityPart(entity.id, { [colField]: newValue });
      }

      return true;
    },
    [callbackIdString, editEntityPart, editPreparedEntity, isPreparedEntity],
  );

  return {
    defaultValueSetter,
  };
};
