import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'remeda';

import { GridColumnsSettingsDrawer } from '../../../EntitiesGrid';
import { projectTasksColumnsState, useSetColumns } from '../../state/projectTasks';

const getColumnsNamesTexts = (t: TFunction<'translation'>) => {
  const textsKeys = {
    count: 'count',
    startDatetime: 'start-datetime',
    endDatetime: 'end-datetime',
    title: 'title',
    description: 'description',
    responsible: 'responsible',
    userFunction: 'function',
    status: 'status',
    resolutionLevel: 'resolution-level',
    comments: 'comments',
    resolvedDate: 'resolved-date',
    participants: 'participants',
    relation: 'relations',
  };

  return mapValues(textsKeys, (key) => t(key));
};

export const ProjectTasksColumnsSettingsDrawer = () => {
  const { execute: setColumns } = useSetColumns();
  const { t } = useTranslation();
  return (
    <GridColumnsSettingsDrawer
      setColumns={setColumns}
      columnsState={projectTasksColumnsState}
      columnsNameTexts={getColumnsNamesTexts(t)}
      disabledColumns={['count']}
    />
  );
};
