import type { RolesEnum, UserPermissions } from '../types';
import requestService from './requestService';

type AddUserEvent = {
  role?: RolesEnum;
  function: number;
  user: number;
  event: number;
  permissions?: Partial<UserPermissions>;
};

const userEventService = {
  add: (userEvent: AddUserEvent) =>
    requestService.post('/user-events', {
      data: {
        role: userEvent.role,
        user: userEvent.user,
        event: userEvent.event,
        function: userEvent.function,
      },
    }),
  delete: (id: number) => requestService.delete(`/user-events/${id}`),
  edit: ({ id, ...data }: { id: number } & Pick<AddUserEvent, 'role' | 'function' | 'permissions'>) =>
    requestService.put(`/user-events/${id}`, { data }),
};

export default userEventService;
