import { Grid } from '@mui/material';

import { UserAvatar } from '../../../../../components/base';
import type { User } from '../../../../../types';

type Props = {
  users: User[],
};

export const MultiUsersRenderer = ({ users }: Props) => (
  <Grid container spacing={2}>
    {
      users.map((user) => (
        <Grid key={user.id} item xs>
          <UserAvatar size='s' user={user} />
        </Grid>
      ))
    }
  </Grid>
);
