import type { Incident } from '../../../types';
import { Status } from '../../../types';

export interface CellPermissions {
  permissions?: {
    canUpdateResolvedIncident?: boolean;
    canUpdateIncident?: boolean;
    canUpdateAllIncidents?: boolean;
    canUpdateResolvedIncidentComment?: boolean;
    canCreateComment?: boolean;
  };
}

type CellParams = {
  data: Incident;
  colDef?: {
    cellEditorParams?: {
      permissions?: CellPermissions['permissions'];
      currentUserId?: number;
    };
    field?: string;
  };
};

export const cellСanBeEdited = ({ data, colDef }: CellParams) => {
  const { permissions, currentUserId } = colDef?.cellEditorParams || {};

  if (!permissions?.canUpdateIncident) {
    return false;
  }

  if (colDef?.field === 'userFunction' && data.responsible) {
    return false;
  }

  if (colDef?.field === 'comments' && data.status === Status.RESOLVED && permissions?.canCreateComment) {
    return true;
  }

  if (data.status === Status.RESOLVED && !permissions?.canUpdateResolvedIncident) {
    return false;
  }

  if (!permissions?.canUpdateAllIncidents) {
    const userRegistered = data.registeredBy?.id === currentUserId;
    const responsibleChangeStatus = data.responsible?.id === currentUserId && colDef?.field === 'status';
    const addingComments = colDef?.field === 'comments';

    if (!userRegistered && !responsibleChangeStatus && !addingComments) {
      return false;
    }
  }

  return true;
};
