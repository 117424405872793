export type CircleMarkerProps = {
  color: string;
};

export const circleMarkerConfigSvg = {
  path: 'M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z',
  fillOpacity: 1,
};

export const CircleMarker = ({ color }: CircleMarkerProps) => (
  <div
    style={{
      width: '1px',
      height: '1px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    }}
  >
    <svg
      style={{ position: 'absolute' }}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d={circleMarkerConfigSvg.path} fill={color} />
    </svg>
  </div>
);
