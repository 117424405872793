import { useRecoilValue } from 'recoil';
import { omit } from 'remeda';

import { permissionState } from '../../../../../state/event';
import { userState } from '../../../../../state/user';
import type { ResolutionLevel } from '../../../../../types';
import type { Task } from '../../../state/tasks';
import { fieldCanBeEdited } from '../lib/fieldCanBeEdited';
import type { ProjectTaskFormInitialValues } from '../lib/types';

interface UseCanEditFieldsProps {
  data: Task;
  formFields: Array<keyof ProjectTaskFormInitialValues>;
  availableResolutionLevels: ResolutionLevel[];
}

export function useCanEditFields(props: UseCanEditFieldsProps) {
  const { data, formFields, availableResolutionLevels } = props;
  const permissions = useRecoilValue(permissionState);
  const { id: currentUserId } = useRecoilValue(userState);

  type Fields = {
    [K in keyof ProjectTaskFormInitialValues]: boolean;
  };

  const fields = formFields.reduce<Fields>(
    (acc, field) => ({
      ...acc,
      [field]: fieldCanBeEdited({
        data,
        field,
        permissions,
        currentUserId,
        availableResolutionLevels,
      }),
    }),
    {} as Fields,
  );

  return {
    fields,
    formCanBeEdited: Object.values(omit(fields, ['comments'])).some(Boolean),
  };
}
