import { atom } from 'recoil';

export const newRowEntityState = <T>({ key }: { key: string }) => atom<T | undefined>({
  key,
  default: undefined,
});

export function isNewRowEntity<T extends object>(entity: T) {
  return !('id' in entity);
}
