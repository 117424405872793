import type { FC } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authState } from '../../state/auth';
import { AppRoute } from '../../types';

type PropType = {
  forbidden?: boolean,
  component: FC;
  redirect?: AppRoute;
}

export const PrivateRoute: FC<PropType> = ({ forbidden, redirect = AppRoute.INCIDENTS, component: Component }) => {
  const auth = useRecoilValue(authState);
  const location = useLocation();

  if (auth) {
    if (forbidden) {
      return <Navigate to={redirect} state={{ from: location }} />;
    }

    return <Component />;
  }

  return <Navigate to={AppRoute.LOGIN} state={{ from: location }} />;
};
