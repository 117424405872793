import type { FC } from 'react';

import { FormControl, FormGroup, FormLabel, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import DEFAULT_FILTER_CONFIG from '../../constants/defaultFilterConfig';
import useDrawer from '../../hooks/useDrawer';
import { permissionState } from '../../state/event';
import { incidentsFilterState, pickFastFilters, useSetDefaultFilters } from '../../state/incidentsFilter';
import { pageState } from '../../state/incidentTable/pagination';
import { locationsState } from '../../state/locations';
import { userState } from '../../state/user';
import { userFunctionsState } from '../../state/userFunctions';
import { usersState } from '../../state/users';
import { Button, DrawerContainer } from '../base';
import { AssignedToMeField } from './components/AssignedToMeField';
import { CreatedByMeField } from './components/CreatedByMeField';
import { EndDateField } from './components/EndDateField';
import { IncidentUrgentField } from './components/IncidentUrgentField';
import { LocationField } from './components/LocationField';
import { ParticipantsField } from './components/ParticipantsField';
import { RegisteredByField } from './components/RegisteredByField';
import { ResolutionLevelField } from './components/ResolutionLevelField';
import { ResponsibleField } from './components/ResponsibleField';
import { ResponsibleInformedField } from './components/ResponsibleInformedField';
import { StartDateField } from './components/StartDateField';
import { StatusField } from './components/StatusField';
import { TypeDateField } from './components/TypeDateField';
import { UserFunctionField } from './components/UserFunctionField';
import { useFormatFilters } from './hooks';
import type { FormIncidentsFilter } from './lib/types';

export const FilterForm: FC = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useRecoilState(incidentsFilterState);
  const users = useRecoilValue(usersState);
  const [currentUser] = useRecoilState(userState);
  const locations = useRecoilValue(locationsState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const { getFormIncidentFilters, getUserIncidentFilters } = useFormatFilters();
  const { execute: setDefaultFilters } = useSetDefaultFilters();
  const {
    canLevelFilters,
    canLocationFilters,
    canUserFunctionFilters,
    canResponsibleFilters,
    canParticipantsFilters,
    canViewAllIncidents,
    canRegisteredByFilters,
  } = useRecoilValue(permissionState);
  const setPage = useSetRecoilState(pageState);
  const { closeDrawer } = useDrawer();

  const form = useForm<FormIncidentsFilter>({
    defaultValues: getFormIncidentFilters(filter),
  });

  const onSubmit = async (formValues: FormIncidentsFilter) => {
    setPage(1);
    setFilter((prevFilters) => ({
      ...pickFastFilters(prevFilters),
      ...getUserIncidentFilters(formValues),
    }));
    closeDrawer();
  };

  const loadDefaultFilter = () => {
    form.reset(
      getFormIncidentFilters(
        currentUser.eventSettings?.find((item) => item.event.id === currentUser.currentEvent.id)
          ?.defaultDesktopFilters || DEFAULT_FILTER_CONFIG,
      ),
    );
  };

  const updateDefaultFilter = () => {
    setDefaultFilters(getUserIncidentFilters(form.getValues()));
  };

  const clearFilters = () => {
    form.reset(
      getFormIncidentFilters({
        ...DEFAULT_FILTER_CONFIG,
        ...(!canLevelFilters ? { resolutionLevel: filter.resolutionLevel } : {}),
        ...(!canLocationFilters ? { locations: filter.locations } : {}),
        ...(!canUserFunctionFilters ? { userFunctions: filter.userFunctions } : {}),
        ...(!canResponsibleFilters ? { responsible: filter.responsible } : {}),
        ...(!canParticipantsFilters ? { participants: filter.participants } : {}),
        ...(!canViewAllIncidents
          ? {
              registeredBy: filter.registeredBy,
              responsible: filter.responsible,
              isAssignedToMe: filter.isAssignedToMe,
              isCreatedByMe: filter.isCreatedByMe,
            }
          : {}),
      }),
    );
  };

  const handleChangeAssignedToMe = (checked: boolean) => {
    form.resetField('responsible', { defaultValue: currentUser && checked ? [currentUser] : [] });
  };

  const handleChangeCreatedByMe = (checked: boolean) => {
    form.resetField('registeredBy', { defaultValue: currentUser && checked ? [currentUser] : [] });
  };

  const handleChangeResponsible = () => {
    if (form.getValues('isAssignedToMe')) {
      form.resetField('isAssignedToMe', { defaultValue: false });
    }
  };

  const handleChangeRegisteredBy = () => {
    if (form.getValues('isCreatedByMe')) {
      form.resetField('isCreatedByMe', { defaultValue: false });
    }
  };

  return (
    <FormProvider {...form}>
      <DrawerContainer component='form' onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Typography variant='h4'>{t('filters')}</Typography>
            <Button variant='text' onClick={clearFilters}>
              {t('clear-filters')}
            </Button>
          </Stack>

          <FormControl>
            <FormLabel sx={{ marginBottom: '8px' }}>{t('date-range')}</FormLabel>
            <Stack spacing={2}>
              <Stack>
                <TypeDateField control={form.control} />
              </Stack>
              <Stack direction='row' spacing={2}>
                <StartDateField control={form.control} />
                <EndDateField control={form.control} />
              </Stack>
            </Stack>
          </FormControl>

          <FormControl>
            <FormGroup>
              <AssignedToMeField
                control={form.control}
                disabled={!canResponsibleFilters || !canViewAllIncidents}
                onChange={handleChangeAssignedToMe}
              />
              <CreatedByMeField
                control={form.control}
                onChange={handleChangeCreatedByMe}
                disabled={!canViewAllIncidents}
              />
            </FormGroup>
          </FormControl>

          <FormControl>
            <FormLabel>{t('resolution-level')}</FormLabel>
            <ResolutionLevelField control={form.control} disabled={!canLevelFilters} />
          </FormControl>

          <FormControl>
            <FormLabel>{t('status')}</FormLabel>
            <StatusField control={form.control} />
          </FormControl>

          <ResponsibleField
            control={form.control}
            options={users}
            disabled={!canResponsibleFilters}
            onChange={handleChangeResponsible}
          />
          <RegisteredByField
            control={form.control}
            options={users}
            onChange={handleChangeRegisteredBy}
            disabled={!canRegisteredByFilters}
          />

          <ParticipantsField control={form.control} options={users} disabled={!canParticipantsFilters} />

          <UserFunctionField control={form.control} options={userFunctions} disabled={!canUserFunctionFilters} />

          <FormControl>
            <FormGroup>
              <ResponsibleInformedField control={form.control} />
              <IncidentUrgentField control={form.control} />
            </FormGroup>
          </FormControl>

          <LocationField control={form.control} options={locations} disabled={!canLocationFilters} />
        </Stack>

        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Button fullWidth variant='outlined' onClick={loadDefaultFilter}>
              {t('load-default-filters')}
            </Button>
            <Button fullWidth variant='outlined' onClick={updateDefaultFilter}>
              {t('set-selection-as-default')}
            </Button>
          </Stack>

          <Button type='submit' sx={{ marginTop: 'auto', color: 'white' }} variant='contained'>
            {t('show-incidents')}
          </Button>
        </Stack>
      </DrawerContainer>
    </FormProvider>
  );
};
