import { memo } from 'react';

import { IconButton, ImageList } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { Gallery } from 'react-photoswipe-gallery';

import useDrawer from '../../../../../hooks/useDrawer';
import { Drawer } from '../../../../../state/drawer';
import type { Incident, PreparedNewIncident } from '../../../../../types';
import getPhotoFormats from '../../../../../utils/getPhotoFormats';
import { Icon } from '../../../../base';
import { useFullScreen } from '../../../../FullScreen';
import { cellСanBeEdited } from '../../../utils';
import ImageItem from './components/ImageItem';
import { IMAGE_SIZE } from './constants';
import useSourceImages from './useSourceImages';

const PhotosRenderer = (props: ICellRendererParams) => {
  const { data } = props;
  const { showDrawer } = useDrawer();
  const incident = data as Incident | PreparedNewIncident;
  const isCreatedIncident = 'id' in incident;
  const { photos } = incident;
  const { ref } = useFullScreen();

  const { sourceImages } = useSourceImages(incident);

  const setPicturesStateHandler = () => {
    if (isCreatedIncident) {
      showDrawer({
        type: Drawer.INCIDENT_PHOTO_EDIT,
        props: { id: incident.id, count: Number(incident.count) },
      });
    } else {
      showDrawer({
        type: Drawer.INCIDENT_PHOTO_ADD,
        props: {},
      });
    }
  };

  const images = isCreatedIncident
    ? (photos ?? []).map((photo) => getPhotoFormats(photo))
    : sourceImages.map((item) => ({ originalSizeImage: item, thumbnailSizeImage: item }));

  return (
    <Gallery
      options={{
        appendToEl: ref.current ?? undefined,
      }}
    >
      <ImageList sx={{ overflow: 'hidden' }} cols={2} rowHeight={IMAGE_SIZE}>
        {images.map(({ thumbnailSizeImage, originalSizeImage }, index) => (
          <ImageItem
            key={thumbnailSizeImage.url}
            thumbnailImage={thumbnailSizeImage}
            originalImage={originalSizeImage}
            index={index}
          />
        ))}
        {cellСanBeEdited(props) ? (
          <IconButton
            sx={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: '4px',
              border: '1px solid #e2e2e2',
            }}
            color='primary'
            onClick={setPicturesStateHandler}
          >
            <Icon icon='plus' htmlColor='#C7D1D4' />
          </IconButton>
        ) : null}
      </ImageList>
    </Gallery>
  );
};

export default memo(PhotosRenderer);
