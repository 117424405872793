import { selector } from 'recoil';

import { userEventState } from '../event';

export const locationsState = selector({
  key: 'locations',
  get: ({ get }) => {
    const currentUserEvent = get(userEventState);

    return currentUserEvent ? currentUserEvent.event.locations : [];
  },
});
