import type { ColumnsSettings } from '../../../EntitiesGrid';
import type { PreparedNewTask, Task } from '../tasks';
import { TaskType } from '../tasks';

export enum ProjectTaskColumnKey {
  Count = 'count',
  Title = 'title',
  Description = 'description',
  Status = 'status',
  Responsible = 'responsible',
  UserFunction = 'userFunction',
  Comments = 'comments',
  ResolvedDate = 'resolvedDate',
  StartDatetime = 'startDatetime',
  EndDatetime = 'endDatetime',
  Participants = 'participants',
  ResolutionLevel = 'resolutionLevel',
  Relation = 'relation',
}

export type ProjectTask = Task & { type: TaskType.ProjectTask } & { [key: string]: unknown };

export function isProjectTask(task: Task): task is ProjectTask {
  return task.type === TaskType.ProjectTask;
}
export type PreparedNewProjectTask = PreparedNewTask<ProjectTask>;

export type ProjectTaskGridColumns = ColumnsSettings<ProjectTaskColumnKey>;
