import { forwardRef } from 'react';

import type { IconButtonProps, PaperProps, PopperProps } from '@mui/material';
import { Autocomplete, Popper, TextField } from '@mui/material';

import { UserAvatar, UserChip } from '../../../../../components/base';
import { useFullScreen } from '../../../../../components/FullScreen';
import type { User } from '../../../../../types';
import getFullName from '../../../../../utils/getFullName';

type Props = {
  values: User[] | undefined;
  onChange: (users: User[] | undefined) => void;
  label?: string;
  placeholder?: string;
  options: User[];
  disabled?: boolean;
  componentsProps?: {
    clearIndicator?: Partial<IconButtonProps>;
    paper?: PaperProps;
    popper?: Partial<PopperProps>;
    popupIndicator?: Partial<IconButtonProps>;
  };
  onBlur?: () => void;
};

const CustomPopper = (props: PopperProps) => {
  const { ref } = useFullScreen();
  return (
    <Popper
      {...props}
      container={ref.current}
      sx={{
        zIndex: 'drawer',
      }}
      className='ag-custom-component-popup'
    />
  );
};

export const MultiUsersEditor = forwardRef(
  ({ values, onChange, options, label, placeholder, componentsProps, onBlur, disabled }: Props, ref) => (
    <Autocomplete
      disabled={disabled}
      onBlur={onBlur}
      componentsProps={componentsProps}
      ref={ref}
      multiple
      disableCloseOnSelect
      options={options}
      filterSelectedOptions
      value={values}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(_, newValues) => onChange(newValues)}
      getOptionLabel={(user: User) => getFullName(user)}
      renderTags={(value, getTagProps) =>
        value.map((user, index) => <UserChip user={user} variant='outlined' size='small' {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextField sx={{ minWidth: '160px' }} {...params} label={label} placeholder={placeholder} />
      )}
      renderOption={(props, user) => (
        <li {...props}>
          <UserAvatar user={user} sx={{ marginRight: '8px' }} />
          {getFullName(user)}
        </li>
      )}
      PopperComponent={CustomPopper}
    />
  ),
);
