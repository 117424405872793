import type {
  ComponentClass,
  FC, FunctionComponent, ReactNode} from 'react';
import { useCallback,
} from 'react';

import { Stack } from '@mui/material';

import type { DrawerNavigationProps } from '../../state/drawer';
import type { Incident } from '../../types';
import { EditIncidentTableForm } from '../IncidentForm/EditIncidentForms';
import IncidentsList from './IncidentsList';

type EditIncidentFormProps = {
  incidentId: number,
  onEndAction: () => void,
}

type EditableIncidentsListProps = {
  incidents: Incident[],
  header: ReactNode,
  EditIncidentForm?: FunctionComponent<EditIncidentFormProps> | ComponentClass<EditIncidentFormProps>
} & Omit<DrawerNavigationProps, 'onEndAction'>

const EditableIncidentsList: FC<EditableIncidentsListProps> = ({
  incidents, header, EditIncidentForm = EditIncidentTableForm, goForward, goBack,
}) => {
  const onClickIncident = useCallback(
    (incident: Incident) => {
      if (goForward && goBack) {
        goForward(<EditIncidentForm incidentId={incident.id} onEndAction={goBack} />);
      }
    },
    [EditIncidentForm, goForward, goBack],
  );

  return (
    <Stack spacing={2}>
      {header}
      <IncidentsList
        incidents={incidents}
        onClick={onClickIncident}
      />
    </Stack>
  );
};

export default EditableIncidentsList;
