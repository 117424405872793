import type { ICellRendererParams } from 'ag-grid-community';

import type { User } from '../../../../types';
import { MultiUsersRenderer } from '../../components';

export const MultiUsersGridRenderer = ({ value }: ICellRendererParams) => {
  if (!value) {
    return null;
  }

  const users: User[] = value;

  return <MultiUsersRenderer users={users} />;
};
