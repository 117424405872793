import { useMemo } from 'react';

import { ruRU, enUS } from '@mui/material/locale';
import { enUS as enDatePicker } from '@mui/x-date-pickers';
import { ru as ruDateLocale, enUS as enDateLocale } from 'date-fns/locale';

import { ruRU as ruDatePicker } from '../i18n/ru/datePickerLocale';
import makeTheme from '../makeTheme';
import type { User } from '../types';

const LOCALE = {
  ru: ruRU,
  en: enUS,
};
const LOCALE_DATE_PICKER = {
  ru: ruDatePicker,
  en: enDatePicker,
};

const LOCALE_DATE_ADAPTER = {
  ru: ruDateLocale,
  en: enDateLocale,
};

const useLocale = (language: User['language']) => useMemo(() => {
  const [locales, dateLocale] = language
    ? [[LOCALE_DATE_PICKER[language], LOCALE[language]], LOCALE_DATE_ADAPTER[language]]
    : [[], undefined];

  return {
    theme: makeTheme(locales),
    dateLocale,
  };
}, [language]);

export default useLocale;
