import type { CellValueChangedEvent } from 'ag-grid-community';

import { getAllParentsNode } from '../../../../EntitiesGrid/lib/getAllParentsNode';
import { checkIsPrepareChildField } from './checkIsPrepareChildField';

export const updateDataAfterChangePreparedField = (event: CellValueChangedEvent) => {
  if (checkIsPrepareChildField(event.data.id)) {
    const updatedRows = [
      {
        ...event.data,
      },
    ];
    const updatedNodes = [event.node];

    if (event.colDef.field === 'endDatetime') {
      const parentNodes = getAllParentsNode(event.node);
      updatedNodes.push(...parentNodes);
      updatedRows.push(...parentNodes.map((node) => ({ ...node.data })));
    }

    event.api.applyTransaction({
      update: updatedRows,
    });

    event.api.refreshCells({
      rowNodes: updatedNodes,
      force: true,
    });
  }
};
