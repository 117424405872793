import type { FC } from 'react';

import type { BoxProps} from '@mui/material';
import { Box, Stack } from '@mui/material';

type Props = BoxProps;

const DrawerContainer: FC<Props> = ({ children, sx, ...props }) => (
  <Box sx={{ ...sx, overflowX: 'auto', height: '100%' }} {...props}>
    <Stack
      justifyContent='space-between'
      spacing={2}
      sx={{
        padding: '16px', width: 600, minHeight: '100%',
      }}
    >
      {children}
    </Stack>
  </Box>
);

export default DrawerContainer;
