import { Dialog, DialogContent } from '@mui/material';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { useTranslation } from 'react-i18next';

import { useFullScreen } from '../FullScreen';
import { translateKeys } from './lib/translate-keys';
import type { SavedImageData } from './lib/types';

interface ImageEditorProps {
  file: File;
  onSave: (editedImageObject: SavedImageData) => void;
  onClose: () => void;
}

export const ImageEditor = (props: ImageEditorProps) => {
  const { t } = useTranslation();
  const { ref } = useFullScreen();
  const { file, onSave, onClose } = props;

  return (
    <Dialog
      open
      className='ag-custom-component-popup'
      sx={{
        zIndex: 'drawer',
      }}
      container={ref.current}
      PaperProps={{
        sx: {
          maxWidth: '850px',
        },
      }}
    >
      <DialogContent style={{ height: 750 }}>
        <FilerobotImageEditor
          source={URL.createObjectURL(file)}
          onSave={(editedImageObject) => onSave(editedImageObject)}
          onClose={onClose}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.CROP}
          savingPixelRatio={1}
          previewPixelRatio={1}
          defaultSavedImageName={file.name}
          translations={translateKeys(t)}
          onBeforeSave={() => false}
          useBackendTranslations={false}
          theme={{
            palette: {
              'accent-stateless': '#AA96C8',
              'accent-primary-hover': '#AA96C8',
              'bg-primary-active': 'transparent',
              'accent-primary': '#AA96C8',
              'accent-primary-active': '#9d7ecc',
              warning: '#AA96C8',
              'warning-hover': '#AA96C8',
              'warning-active': '#AA96C8',
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
