import { Box } from '@mui/material';
import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { type FilePondProps } from 'react-filepond';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { FilePond } from '../base/FilePond';

function transformValues(value: (string | FilePondInitialFile)[]) {
  return value.map<FilePondInitialFile>((item) => {
    if (typeof item === 'string') {
      return {
        source: item,
        options: {
          type: 'local',
        },
      };
    }

    return item;
  });
}

interface ImageFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends UseControllerProps<TFieldValues, TName>,
    Omit<FilePondProps, 'name' | 'onupdatefiles' | 'files' | 'server'> {}

export const ImageField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: ImageFieldProps<TFieldValues, TName>,
) => {
  const { field } = useController(props);

  const handleUpdateFiles = (files: FilePondFile[]) => {
    field.onChange(files.map((file) => file.source));
  };

  return (
    <Box sx={{ '.filepond--image-preview': { backgroundColor: 'transparent' } }}>
      <FilePond
        {...props}
        files={transformValues(field.value)}
        name={field.name}
        onupdatefiles={handleUpdateFiles}
        server={{
          load: (src, load) => {
            fetch(src, {
              cache: 'no-cache',
            })
              .then((res) => res.blob())
              .then(load);
          },
        }}
      />
    </Box>
  );
};
