import { useEffect } from 'react';

import history from '../utils/history';

export function useChangeLocation(callback: () => void) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      callback();
    });

    return () => {
      unlisten();
    };
  }, [callback]);
}
