import { useTranslation } from 'react-i18next';

import { CheckboxField, type CheckboxFieldProps } from '../../fields/CheckboxField';
import type { FormIncidentsFilter } from '../lib/types';

type CreatedByMeFieldProps = Omit<CheckboxFieldProps<FormIncidentsFilter, 'isCreatedByMe'>, 'name' | 'label'>;
export const CreatedByMeField = (props: CreatedByMeFieldProps) => {
  const { t } = useTranslation();

  return <CheckboxField {...props} name='isCreatedByMe' label={t('created-by-me')} />;
};
