import { useTranslation } from 'react-i18next';

import { DateField, type DateFieldProps } from '../../fields/DateField';
import type { FormIncidentsFilter } from '../lib/types';

type EndDateFieldProps = Omit<DateFieldProps<FormIncidentsFilter, 'endDate'>, 'label' | 'name'>;

export const EndDateField = (props: EndDateFieldProps) => {
  const { t } = useTranslation();

  return <DateField {...props} name='endDate' label={t('end-date')} />;
};
