import type { TFunction } from 'i18next';
import { keys } from 'remeda';

import type {
  CreateActionDescription,
  Photo,
  ResolutionLevel, Status, UpdateActionDescription, User,
} from '../../../types';
import kebabCase from '../../../utils/kebabCase';

export const fieldsOrder = [
  'isUrgent',
  'title',
  'description',
  'resolutionLevel',
  'status',
  'responsible',
  'registeredBy',
  'userFunction',
  'location',
  'locationDescription',
  'photos',
  'isResponsibleInformed',
  'registeredDate',
  'resolvedDate',
  'endDatetime',
  'participants',
] as const;

export type FieldValueCellProps = {
  name: 'status',
  value: Status | undefined,
} | {
  name: 'title' | 'description' | 'locationDescription',
  value: string | undefined,
} | {
  name: 'responsible' | 'registeredBy',
  value: User | undefined,
} | {
  name: 'resolutionLevel',
  value: ResolutionLevel | undefined,
} | {
  name: 'location',
  value: number | undefined,
} | {
  name: 'userFunction',
  value: number | undefined,
} | {
  name: 'photos',
  value: Photo[] | undefined,
} | {
  name: 'isUrgent' | 'isResponsibleInformed',
  value: boolean | undefined,
} | {
  name: 'registeredDate' | 'resolvedDate' | 'endDatetime',
  value: Date | undefined,
} | {
  name: 'participants',
  value: User[] | undefined
};

export const convertCreateIncidentToFieldValue = (incident: CreateActionDescription['attrs']): FieldValueCellProps[] => {
  const fieldValues = fieldsOrder.map((field) => {
    switch (field) {
      case 'status':
        return { name: field, value: incident[field] };
      case 'responsible':
      case 'registeredBy':
        return { name: field, value: incident[field] };
      case 'resolutionLevel':
        return { name: field, value: incident[field] };
      case 'location':
        return { name: field, value: incident[field] };
      case 'userFunction':
        return { name: field, value: incident[field] };
      case 'photos':
        return { name: field, value: incident[field] };
      case 'isUrgent':
      case 'isResponsibleInformed':
        return { name: field, value: incident[field] };
      case 'registeredDate':
      case 'resolvedDate':
      case 'endDatetime':
        return { name: field, value: incident[field] };
      case 'participants':
        return { name: field, value: incident[field] };
      default:
        return { name: field, value: incident[field] };
    }
  });
  return fieldValues;
};

export const convertUpdateIncidentToFieldValue = (incident: UpdateActionDescription['attrs']): { old: FieldValueCellProps, new: FieldValueCellProps }[] => {
  const fields = keys.strict(incident);
  const orderedDiffFields = fieldsOrder.filter((field) => fields.includes(field));
  const fieldValues = orderedDiffFields.map((field) => {
    switch (field) {
      case 'status':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'responsible':
      case 'registeredBy':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'resolutionLevel':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'location':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'userFunction':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'photos':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'isUrgent':
      case 'isResponsibleInformed':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'registeredDate':
      case 'resolvedDate':
      case 'endDatetime':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      case 'participants':
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
      default:
        return {
          old: { name: field, value: incident[field]?.old },
          new: { name: field, value: incident[field]?.new },
        };
    }
  });
  return fieldValues;
};

export const fieldNameT = (fieldName: string) => {
  const withoutIs = fieldName.startsWith('is') ? fieldName.replace('is', '') : fieldName;

  return kebabCase(withoutIs);
};

export const actionTitle = {
  addComment: (t: TFunction<'translation'>) => t('incident-add-comment-at'),
  deleteComment: (t: TFunction<'translation'>) => t('incident-remove-comment-at'),
  editComment: (t: TFunction<'translation'>) => t('incident-edit-comment-at'),
  update: (t: TFunction<'translation'>) => t('incident-update-at'),
  create: (t: TFunction<'translation'>) => t('incident-create-at'),
};
