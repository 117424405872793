import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import type { ICellEditorParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { useRemoveComment, useUpdateComment } from '../../../../state/comments';
import { permissionState } from '../../../../state/event';
import { userState } from '../../../../state/user';
import type { CellCommentValue, Comment, CommentBody, Photo } from '../../../../types';
import { Status } from '../../../../types';
import { IncidentComments } from '../../../base';
import { cellСanBeEdited } from '../../utils';

const getInitialPreviewPhoto = (file: File): Photo => {
  const url = URL.createObjectURL(file);
  return {
    id: 0,
    name: file.name,
    alternativeText: '',
    caption: '',
    width: 0,
    height: 0,
    hash: '',
    ext: '',
    mime: file.type,
    size: 0,
    url,
    previewUrl: null,
    provider: '',
    provider_metadata: null,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

/**
 * This component is updated props only after closing the editor.
 * In this case we create local state for updating data.
 */
const CommentsEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [value, setValue] = useState<CellCommentValue>();
  const [comments, setComments] = useState<Comment[]>([...props.value]);
  const { execute: onRemoveComment } = useRemoveComment();
  const { execute: onUpdateComment } = useUpdateComment();
  const user = useRecoilValue(userState);
  const { canUpdateResolvedIncidentComment, canUpdateComment } = useRecoilValue(permissionState);

  const handleOnRemoveComment = () => (id: number) => {
    setComments((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            hidden: true,
            body: '',
            hiddenDate: new Date(),
            hiddenUser: user,
          };
        }

        return item;
      }),
    );
    setValue({ type: 'remove', data: { commentId: id } });
    onRemoveComment(id, props.data.id);
  };

  const onSendHandler = (data: CommentBody) => {
    setValue({ type: 'add', data });
  };

  const handleUpdateComment = () => (id: number, data: CommentBody) => {
    const currentComment = comments.find((item) => item.id === id);
    const updateComment = {
      ...currentComment,
      image: data.image ? getInitialPreviewPhoto(data.image) : currentComment?.image,
      edited: true,
      body: data.text,
      editedDate: new Date(),
      editedUser: user,
    };

    if (!(currentComment?.body !== updateComment.body || currentComment?.image?.url !== updateComment.image?.url)) {
      return;
    }

    setComments((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...updateComment,
          };
        }

        return item;
      }),
    );
    setValue({
      type: 'edit',
      data: {
        ...data,
        commentId: id,
      },
    });
    onUpdateComment(id, { body: data.text, image: data.image, incident: props.data.id });
  };

  useEffect(() => {
    if (value?.type === 'add' && (value.data.text || value.data.image)) {
      props.stopEditing();
    }
  }, [props, value]);

  useEffect(() => {
    setValue(undefined);
  }, [props]);

  useImperativeHandle(ref, () => ({
    getValue(): CellCommentValue | undefined {
      return value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
  }));

  return (
    <IncidentComments
      disabled={!cellСanBeEdited(props)}
      comments={comments}
      onRemove={handleOnRemoveComment()}
      onUpdate={handleUpdateComment()}
      canUpdateComment={props.data.status === Status.RESOLVED ? canUpdateResolvedIncidentComment : canUpdateComment}
      onSend={onSendHandler}
      sx={{ maxWidth: '360px', maxHeight: '300px' }}
      stylePanelAspectRatio='0.25'
    />
  );
});

export default CommentsEditor;
