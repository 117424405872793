import { Trans } from 'react-i18next';

import { CenteredText } from '../../../../../components/base';
import { useLocationMap } from '../model/LocationMapDataProvider';

export const NotificationLocation = () => {
  const { canAddNewLocation } = useLocationMap();
  return !canAddNewLocation ? (
    <CenteredText sx={{ marginBottom: '20px' }}>
      <Trans i18nKey='limit-locations-for-event-reached-out' />
    </CenteredText>
  ) : null;
};
