import { isObject } from 'remeda';

import type { User } from '../../../../../types';
import { Status } from '../../../../../types';

type RowForCheck = {
  registeredBy?: User;
  status?: Status;
  responsible?: User;
};

type Editable<T extends RowForCheck, K extends string> = {
  data: T;
  field: K;
  canUpdateResolvedTasks: boolean;
  canUpdateAllTask: boolean;
  canUpdateTask: boolean;
  currentUserId: number;
};

export const editableUserFunction = <T extends RowForCheck, K extends string>({
  field,
  data,
}: Pick<Editable<T, K>, 'field' | 'data'>) => {
  if (field.startsWith('userFunction') && 'responsible' in data && data.responsible) {
    return false;
  }
  return true;
};

export const editableResolved = <T extends RowForCheck, K extends string>({
  data,
  canUpdateResolvedTasks: canUpdateResolvedIncident,
}: Pick<Editable<T, K>, 'data' | 'canUpdateResolvedTasks'>) => {
  if ('status' in data && data.status === Status.RESOLVED && !canUpdateResolvedIncident) {
    return false;
  }
  return true;
};

export const editableStaff = <T extends RowForCheck, K extends string>({
  canUpdateAllTask,
  canUpdateTask,
  data,
  field,
  currentUserId,
}: Pick<Editable<T, K>, 'field' | 'data' | 'canUpdateAllTask' | 'canUpdateTask' | 'currentUserId'>) => {
  if (!canUpdateTask) {
    return false;
  }

  const registeredById =
    'registeredBy' in data && isObject(data.registeredBy) && 'id' in data.registeredBy && data.registeredBy.id;
  const responsibleById =
    'responsible' in data && isObject(data.responsible) && 'id' in data.responsible && data.responsible.id;

  if (!canUpdateAllTask) {
    const userRegistered = registeredById === currentUserId;
    const responsibleChangeStatus = responsibleById === currentUserId && field === 'status';
    const addingComments = field === 'comments';

    if (!userRegistered && !responsibleChangeStatus && !addingComments) {
      return false;
    }
  }
  return true;
};
