import type { GeoCoordinates, Location, WithMeta } from '../types';
import requestService from './requestService';

type LocationAddRequest = {
  location: Omit<Location, 'id' | 'geo'> & { geo: GeoCoordinates } & { event: number }
 };

const locationService = {
  getLocations: ({ eventId }: { eventId: number }) => requestService
    .get<WithMeta<Location[]>>(`/locations?populate=geo&filters[event][id]=${eventId}`)
    .then((response) => response.data),
  addLocation: ({ location }: LocationAddRequest) => requestService
    .post('/locations', { data: location })
    .then((response) => response.data),
  updateLocation: ({ location }: { location: Pick<Location, 'id' | 'description' | 'title' | 'geo'> }) => requestService
    .put(`/locations/${location.id}`, { data: location })
    .then((response) => response.data),
};

export default locationService;
