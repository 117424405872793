import { format } from 'date-fns';
import type { TFunction } from 'i18next';

import { dateType, multiUsersType, userFunctionType, userType } from '../../../modules/EntitiesGrid/cellTypes';
import type { DailyTask } from '../../../modules/Tasks/state/dailyTasks';
import type { ProjectTask } from '../../../modules/Tasks/state/projectTasks';
import type { Incident, Comment } from '../../../types';
import { commentsComparator } from '../../../utils/comparators';
import formatDate from '../../../utils/formatDate';
import getFullName from '../../../utils/getFullName';
import getStatusTitle from '../../../utils/getStatusTitle';

const getCommentInfo = (comment: Comment, t: TFunction<'translation'>) =>
  `${getFullName(comment.author)} ${t('at')} ${formatDate(comment.createdDate)}:\n "${comment.body}" \n ${
    comment.image?.name ?? ''
  }`;

const getLastCommentInfo = (comments: Comment[], t: TFunction<'translation'>) => {
  const availableComments = comments.filter((comment) => !comment.hidden);
  const lastComment = [...availableComments].sort(commentsComparator)[availableComments.length - 1];

  return lastComment ? getCommentInfo(lastComment, t) : '';
};

export const formatIncident =
  (hideDateTime = false) =>
  (
    {
      count,
      title,
      description,
      registeredBy,
      status,
      resolutionLevel,
      locationDescription,
      location,
      comments,
      registeredDate,
      resolvedDate,
      responsible,
      userFunction,
      endDatetime,
      participants,
    }: Incident,
    t: TFunction<'translation'>,
  ) => ({
    count: `${count}`,
    title,
    description: description ?? '',
    registeredBy: userType.toString(registeredBy),
    status: getStatusTitle(status, t),
    resolutionLevel,
    userFunction: userFunctionType.toString(userFunction),
    location: location?.title ?? '',
    locationDescription: locationDescription ?? '',
    responsible: userType.toString(responsible),
    lastComment: getLastCommentInfo(comments, t),
    registeredDate: dateType.toString(registeredDate, hideDateTime),
    resolvedDate: dateType.toString(resolvedDate, hideDateTime),
    endDatetime: dateType.toString(endDatetime, hideDateTime),
    participants: multiUsersType.toString(participants),
  });

export const formatProjects = (
  {
    count,
    startDatetime,
    endDatetime,
    title,
    description,
    responsible,
    userFunction,
    status,
    comments = [],
    resolvedDate,
    participants,
    ...rest
  }: ProjectTask,
  t: TFunction<'translation'>,
) => ({
  count: `${count}`,
  startDatetime: dateType.toString(startDatetime, true),
  endDatetime: dateType.toString(endDatetime, true),
  title,
  description: description ?? '',
  responsible: userType.toString(responsible),
  userFunction: userFunctionType.toString(userFunction),
  status: getStatusTitle(status, t),
  lastComment: getLastCommentInfo(comments, t),
  resolvedDate: dateType.toString(resolvedDate, true),
  participants: multiUsersType.toString(participants),
  ...rest,
});

export const formatDaily = (
  {
    count,
    startDatetime,
    endDatetime,
    title,
    description,
    responsible,
    userFunction,
    status,
    comments = [],
    resolvedDate,
    participants,
    location,
    resolutionLevel,
  }: DailyTask,
  t: TFunction<'translation'>,
): { [key: string]: string } => ({
  count: `${count}`,
  startDatetime: format(startDatetime || new Date(), 'HH:mm'),
  endDatetime: dateType.toString(endDatetime),
  title,
  description: description ?? '',
  responsible: userType.toString(responsible),
  userFunction: userFunctionType.toString(userFunction),
  status: getStatusTitle(status, t),
  lastComment: getLastCommentInfo(comments, t),
  resolvedDate: dateType.toString(resolvedDate),
  participants: multiUsersType.toString(participants),
  location: location?.title ?? '',
  resolutionLevel,
});
