import type { ResolutionLevel } from '../../../../types';
import {
  ResolutionLevelBlock,
} from '../../../base';

const ResolutionLevelRenderer = (value: ResolutionLevel) => (
  <ResolutionLevelBlock level={value} />
);

export default ResolutionLevelRenderer;
