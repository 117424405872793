import { useCallback } from 'react';

import type { FirstDataRenderedEvent } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  currentTabDailyTasksState,
  currentTabWithDefaultDailyTasksState,
  tabsDailyTasksState,
} from '../../../state/dailyTasks';

export function useScrollToRowByParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = useRecoilValue(tabsDailyTasksState);
  const setCurrentTab = useSetRecoilState(currentTabDailyTasksState);
  const currentTab = useRecoilValue(currentTabWithDefaultDailyTasksState);

  const scrollToRowByParams = useCallback(
    (event: FirstDataRenderedEvent) => {
      if (searchParams.has('taskId')) {
        let flag = true;
        const urlId = Number(searchParams.get('taskId'));

        tabs.forEach((tab) => {
          if ('tasks' in tab && tab.tasks.find((item) => item.id === urlId)) {
            if (currentTab && currentTab.key === tab.key) return;
            setCurrentTab(tab.key);
            searchParams.delete('taskId');
            setSearchParams(searchParams);
            // Flag toggle, because table will be rerender and then this callback will be called again
            flag = false;
          }
        }, {});

        if (flag) {
          const node = event.api.getRowNode(searchParams.get('taskId') ?? '');
          event.api.ensureNodeVisible(node, 'bottom');
          searchParams.delete('taskId');
          setSearchParams(searchParams);
        }
      }
    },
    [currentTab, searchParams, setCurrentTab, setSearchParams, tabs],
  );

  return {
    scrollToRowByParams,
  };
}
