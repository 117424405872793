import { useCallback, useMemo, type RefObject } from 'react';

import type { DomEventHandler, YMap, YMapMarkerEventHandler } from '@yandex/ymaps3-types';

import type { LocationForm } from '../../../../../state/locationsForm';
import type { YandexMapAutocompleteOption } from '../../../shared/components/YandexMap';
import type { LocationMapDataProviderApi } from '../model/LocationMapDataProvider';

export function useNormalizeYandexMapData(data: LocationMapDataProviderApi, ymap: RefObject<YMap>) {
  const { onMapClick, onDraggableMarker, locations, onChangeSearchLocation, ...rest } = data;

  const handleClickMap: DomEventHandler = useCallback(
    (object, { coordinates }) => {
      if (object?.type === 'marker') {
        return;
      }

      onMapClick({
        longitude: coordinates[0],
        latitude: coordinates[1],
      });
    },
    [onMapClick],
  );

  const handleDraggableMarker =
    (locationForm: LocationForm): YMapMarkerEventHandler | undefined =>
    (coordinates) => {
      if (coordinates) {
        onDraggableMarker(locationForm, {
          longitude: coordinates[0],
          latitude: coordinates[1],
        });
      }
    };

  const locationPoints = useMemo(
    () =>
      locations.map(({ title, ...location }) => ({
        ...location,
        properties: {
          hint: title,
        },
      })),
    [locations],
  );

  const handleChangeSearchLocation = async (value: YandexMapAutocompleteOption | null) => {
    if (value?.coordinates) {
      // We use async so that after changing the state, the map will correctly align the center.
      await onChangeSearchLocation([value.coordinates[0], value.coordinates[1]]);
      ymap.current?.setLocation({
        center: value.coordinates,
        zoom: 12,
      });
    }
  };

  return {
    ...rest,
    onMapClick: handleClickMap,
    onDraggableMarker: handleDraggableMarker,
    onChangeSearchLocation: handleChangeSearchLocation,
    locations: locationPoints,
  };
}
