import type { AutocompleteProps } from '@mui/material';
import { Autocomplete as AutocompleteBase } from '@mui/material';

import { TextField } from '../../../../../components/base';

type Props<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>;

export const Autocomplete = <T extends object = { label: string }>({ sx, ...props }: Props<T>) => (
  <AutocompleteBase
    renderInput={(params) => <TextField {...params} />}
    sx={{
      '& .MuiInputBase-root': {
        backgroundColor: 'white',
      },
      border: '1px solid transparent',
      ...sx,
    }}
    {...props}
  />
);
