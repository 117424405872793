import qs from 'qs';

import type { WithMeta, Incident, IncidentLog } from '../types';
import requestService from './requestService';
import { sessionService } from './sessionService';

const incidentService = {
  getIncident: ({ query, id }: { query: string; id: number }) =>
    requestService.get<WithMeta<Incident>>(`/incidents/${id}?${query}`).then((response) => response.data),
  getIncidents: (query: string) =>
    requestService.get<WithMeta<Incident[]>>(`/incidents?${query}`).then((response) => response.data),
  addIncident: (data: FormData) =>
    requestService
      .post<Incident>('/incidents', data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => response.data),
  editIncident: (id: number, data: FormData, populateQuery = '') =>
    requestService
      .put<WithMeta<Incident>>(`/incidents/${id}?${populateQuery}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data),
  getIncidentLogs: ({ incident }: { incident: number }) =>
    requestService
      .get<WithMeta<IncidentLog[]>>(
        `/incident-logs?${qs.stringify(
          {
            filters: { incident },
          },
          { encodeValuesOnly: true },
        )}`,
      )
      .then((response) => response.data),
  getTasks: ({ event }: { event: number }) =>
    requestService
      .get<WithMeta<Incident>>(`/incidents/tasks?${qs.stringify({ filters: { event } })}`)
      .then((response) => response.data),
  deleteTask: (id: string) => requestService.delete(`/incidents/${id}`),
};

const transformIncidentService: typeof incidentService = {
  ...incidentService,
  addIncident: (data: FormData) => {
    sessionService.setItem('notShowUpdatesSocket', 'true');
    return incidentService.addIncident(data);
  },
  editIncident: (id: number, data: FormData, populateQuery = '') => {
    sessionService.setItem('notShowUpdatesSocket', 'true');
    return incidentService.editIncident(id, data, populateQuery);
  },
};

export default transformIncidentService;
