import { useTranslation } from 'react-i18next';

import { DateTimeField, type DateTimeFieldProps } from '../../fields/DateTimeField';
import type { DailyTasksFormFilerValue } from '../lib/types';

type EndDateFieldProps = Omit<DateTimeFieldProps<DailyTasksFormFilerValue, 'endDate'>, 'label' | 'name'>;

export const EndDateField = (props: EndDateFieldProps) => {
  const { t } = useTranslation();

  return <DateTimeField {...props} name='endDate' label={t('end-date')} />;
};
