import { FullScreen } from '../../components/FullScreen';
import { Gantta } from '../../features/projectPlan/Gantta';
import { EntitiesPageWrapper } from '../../modules/EntitiesGrid';

const GantaPage = () => (
  <FullScreen>
    <EntitiesPageWrapper>
      <Gantta />
    </EntitiesPageWrapper>
  </FullScreen>
);

export default GantaPage;
