import { memo } from 'react';

import { Box, Grid, Stack } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';

import { eventState } from '../../../../../state/event';
import getFullUrl from '../../../../../utils/getFullUrl';
import { GoogleMapIncident } from '../components/GoogleMapIncident';
import { LeafletMapPlan } from '../components/LeafletMapPlan';
import { UpdateIncidentsButton } from '../components/UpdateIncidentsButton';
import { YandexMapIncident } from '../components/YandexMapIncident';
import { YandexMapIncidentPlan } from '../components/YandexMapIncidentPlan';
import { IncidentMapDataProvider } from '../model/IncidentMapProvider';

export const IncidentMap = memo(() => {
  const event = useRecoilValueLoadable(eventState).valueMaybe();

  if (!event) {
    return null;
  }

  return (
    <IncidentMapDataProvider>
      <Stack p={2}>
        <Grid xs={12} item>
          <Box
            sx={{
              height: '500px',
            }}
            pt={2}
          >
            {event.sitePlan?.url ? (
              <>
                {event.map === 'yandex' && <YandexMapIncidentPlan url={getFullUrl(event.sitePlan.url)} />}
                {event.map === 'google' && <LeafletMapPlan url={getFullUrl(event.sitePlan.url)} />}
              </>
            ) : (
              <>
                {event.map === 'yandex' && <YandexMapIncident />}
                {event.map === 'google' && <GoogleMapIncident />}
              </>
            )}
          </Box>
        </Grid>

        <UpdateIncidentsButton />
      </Stack>
    </IncidentMapDataProvider>
  );
});
