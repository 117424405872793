import { useRecoilTask } from 'recoil-toolkit';

import { addEventTask, archiveEventTask, updateEventTask } from './tasks';

export const useAddEvent = () =>
  useRecoilTask(addEventTask, [], {
    loaderStack: 'addEventTask',
    errorStack: true,
  });

export const useUpdateEvent = () =>
  useRecoilTask(updateEventTask, [], {
    loaderStack: 'updateEventTask',
    errorStack: true,
  });

export const useArchiveEvent = () =>
  useRecoilTask(archiveEventTask, [], {
    loaderStack: 'archiveEventTask',
    errorStack: true,
  });
