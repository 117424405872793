import type { FC } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import {
  Typography,
  List,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Trans } from 'react-i18next';

import { INCIDENT_COLORS } from '../../constants/color';
import { getColor } from '../../state/incidents';
import type { Incident } from '../../types';
import UserChip from '../base/UserChip';

type IncidentsListProps = {
  incidents: Incident[],
  onClick: (incident: Incident) => void,
}

const IncidentsList: FC<IncidentsListProps> = ({ incidents, onClick }) => (
  <List>
    {
      incidents.map((incident: Incident) => {
        const { responsible } = incident;
        const responsibleLink = responsible
          ? (
            <Stack direction='row' spacing={2} alignItems='baseline'>
              <Typography variant='body1'>
                <Trans i18nKey='responsible' />
                :
                {' '}
              </Typography>
              <UserChip user={responsible} />
            </Stack>
          )
          : null;

        return (
          <ListItem key={incident.id} disablePadding>
            <ListItemButton onClick={() => onClick(incident)}>
              <ListItemIcon>
                <CircleIcon style={{ color: INCIDENT_COLORS[getColor(incident)] }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant='h5'>{`#${incident.count} ${incident.title}`}</Typography>}
                secondary={responsibleLink}
              />
            </ListItemButton>
          </ListItem>
        );
      })
    }
  </List>
);

export default IncidentsList;
