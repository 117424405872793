const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const jsonDateReviver = (_: string, value: string) => {
  if (typeof value === 'string' && DATE_FORMAT.test(value)) {
    return new Date(value);
  }

  return value;
};

export default jsonDateReviver;
