import { TextField } from '@mui/material';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { usersState } from '../../../state/users';
import type { User } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { AutocompleteMultiple } from '../../AutocompleteMultiple';
import { UserChip, UserAvatar } from '../../base';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

type ParticipantsFieldProps = {
  form: UseFormReturn<UserEventSettingsInitialValues>;
};

export const ParticipantsField = ({ form }: ParticipantsFieldProps) => {
  const { t } = useTranslation();
  const users = useRecoilValue(usersState);

  return (
    <Controller
      name='filters.participants'
      control={form.control}
      render={({ field }) => (
        <AutocompleteMultiple
          {...field}
          disableCloseOnSelect
          options={users}
          limitTags={4}
          filterSelectedOptions
          onChange={(_, values) => field.onChange(values === 'all' ? users : values)}
          getOptionLabel={(participants: User) => getFullName(participants)}
          renderTags={(value, getTagProps) =>
            value.map((participants, index) => (
              <UserChip user={participants} variant='outlined' size='small' {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => <TextField {...params} label={t('participants')} />}
          renderOption={(props, participants) => (
            <li {...props}>
              <UserAvatar user={participants} sx={{ marginRight: '8px' }} />
              {getFullName(participants)}
            </li>
          )}
        />
      )}
    />
  );
};
