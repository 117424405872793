import type { FC, Ref } from 'react';

import { ImageListItem } from '@mui/material';
import { Item } from 'react-photoswipe-gallery';

import { FallbackImage } from '../../../../../base';
import { DEFAULT_SIZE, IMAGE_SIZE, MAX_IMAGE_INDEX } from '../constants';
import type { ImageItemType } from '../types';

type Props = {
  index: number,
  originalImage: ImageItemType,
  thumbnailImage: ImageItemType,
  maxImageIndex?: number | null;
};

const ImageItem: FC<Props> = ({
  index, originalImage, thumbnailImage, maxImageIndex = MAX_IMAGE_INDEX,
}) => (
  <ImageListItem
    key={index}
    sx={{
      width: IMAGE_SIZE,
      cursor: 'pointer',
      borderRadius: '4px',
      overflow: 'hidden',
      display: maxImageIndex !== null && index > maxImageIndex ? 'none' : 'flex',
    }}
  >
    <Item
      cropped
      original={originalImage.url}
      thumbnail={thumbnailImage.url}
      width={originalImage.width ?? DEFAULT_SIZE}
      height={originalImage.height ?? DEFAULT_SIZE}
    >
      {({ ref, open }) => (
        <FallbackImage
          sx={{
            height: IMAGE_SIZE,
            objectFit: 'cover',
          }}
          src={thumbnailImage.url}
          ref={ref as Ref<HTMLImageElement>}
          onClick={open}
          aria-hidden='true'
        />
      )}
    </Item>
  </ImageListItem>
);

export default ImageItem;
