import {
  createContext,
  useState,
  type PropsWithChildren,
  useMemo,
  useContext,
  useRef,
  type RefObject,
  useCallback,
  useEffect,
} from 'react';

type FullScreenContextType = {
  fullScreen: boolean;
  onToggleFullScreen: () => void;
  ref: RefObject<HTMLDivElement>;
};

const initialValues: FullScreenContextType = {
  fullScreen: false,
  onToggleFullScreen: () => null,
  ref: { current: null },
};

const FullScreenContext = createContext<FullScreenContextType>(initialValues);

export const FullScreenProvider = ({ children }: PropsWithChildren) => {
  const ref = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState(initialValues.fullScreen);

  const onToggleFullScreen = useCallback(() => {
    const newValue = !fullScreen;
    const currentRef = ref.current;
    setFullScreen(newValue);

    if (currentRef) {
      if (newValue) {
        currentRef.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  }, [fullScreen]);

  const value = useMemo<FullScreenContextType>(
    () => ({
      fullScreen,
      onToggleFullScreen,
      ref,
    }),
    [fullScreen, onToggleFullScreen],
  );

  // This useEffect is used to track the press of esc
  useEffect(() => {
    const onFullScreenChange = () => {
      setFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  return <FullScreenContext.Provider value={value}>{children}</FullScreenContext.Provider>;
};

export const useFullScreen = () => {
  const context = useContext(FullScreenContext);

  if (!context) {
    throw new Error('useFullScreen must be used within a FullScreenProvider');
  }

  return context;
};
