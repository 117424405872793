import type { ChangeEvent} from 'react';
import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { getIsFlaggedIncident } from '../../../../state/user';
import type { Incident } from '../../../../types';
import { CustomCheckbox } from '../../../base';
import { CheckboxType } from '../../../base/CustomCheckbox';

const FlaggedRenderer = ({ column, node, data }: ICellRendererParams) => {
  const columnId = column?.getColId();
  const incident = data as Incident;
  const isFlagged = useRecoilValue(getIsFlaggedIncident(incident.id));

  const checkedHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (columnId) {
      node.setDataValue(columnId, checked);
    }
  };

  return (
    <CustomCheckbox
      sx={{ padding: 0 }}
      checked={isFlagged}
      type={CheckboxType.FLAG}
      onChange={checkedHandler}
    />
  );
};
export default memo(FlaggedRenderer);
