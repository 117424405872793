import type { RowNode } from 'ag-grid-community';

import { ResolutionLevel } from '../../../types';
import { getAllParentsNode } from '../../EntitiesGrid/lib/getAllParentsNode';

export const orderResolutionLevel = {
  [ResolutionLevel.GREEN]: 0,
  [ResolutionLevel.AMBER]: 1,
  [ResolutionLevel.ORANGE]: 2,
  [ResolutionLevel.RED]: 3,
};

export function getResolutionLevelForChildrenNodes(node: RowNode): ResolutionLevel[] {
  const parents = getAllParentsNode(node);

  if (parents.length > 0) {
    const parentsLevels = orderResolutionLevel[parents[0].data.resolutionLevel as ResolutionLevel];
    return Object.values(ResolutionLevel).filter((level) => orderResolutionLevel[level] <= parentsLevels);
  }

  return Object.values(ResolutionLevel);
}
