import { useMemo } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import type { ColDef, GridApi, RowNode, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { format } from 'date-fns';
import type { TFunction } from 'i18next';
import { renderToString } from 'react-dom/server';
import { useRecoilValue } from 'recoil';
import { omit, pick } from 'remeda';

import { ResolutionLevelEditor } from '../../../../../components/IncidentTable/components/editor';
import { ResolutionLevelRenderer } from '../../../../../components/IncidentTable/components/renderer';
import { RelationRenderer } from '../../../../../components/IncidentTable/components/renderer/RelationRenderer';
import {
  titleField as incidentTitleField,
  descriptionField as incidentDescriptionField,
  responsibleField as incidentResponsible,
  userFunctionField as incidentUserFunctionField,
  statusByField,
  commentsField as incidentCommentsField,
} from '../../../../../components/IncidentTable/hooks';
import type { CellPermissions } from '../../../../../components/IncidentTable/utils/cellCanBeEdited';
import getHeaderTemplate from '../../../../../components/IncidentTable/utils/getHeaderTemplate';
import { DATETIME_FORMAT } from '../../../../../constants/base';
import { currentEventSettings, permissionState } from '../../../../../state/event';
import type { User } from '../../../../../types';
import {
  cellTypes,
  dateTimeField,
  defaultCellTypeFns,
  multiUsersGridField,
  useEditable,
  userFunctionTypeFns,
} from '../../../../EntitiesGrid';
import { defaultCellStyles } from '../../../../EntitiesGrid/lib';
import { getAllParentsNode } from '../../../../EntitiesGrid/lib/getAllParentsNode';
import { comparatorRelation, comparatorResolutionLevel, comparatorUserFunction } from '../../../lib/comparators';
import { getResolutionLevelForChildrenNodes } from '../../../lib/resolutionLevelForChildrenNodes';
import type { ProjectTask } from '../../../state/projectTasks';
import { ProjectTaskColumnKey, newRowProjectTask } from '../../../state/projectTasks';
import type { ProjectTaskState } from '../../../state/projectTasks/lib/addHierarchyField';
import { NON_EDITABLE_FIELDS, NON_EDITABLE_ON_ADD_FIELDS, isPreparedNewTask } from '../../../state/tasks';
import { newChildTaskState } from '../model/store/newChildTask.state';
import { editableStatus } from './editable/editableStatus';
import { getPreparedFieldFromChildren } from './getPreparedFieldFromChildren';

export const relationField = (): ColDef<ProjectTaskState> => ({
  headerComponentParams: {
    template: getHeaderTemplate({
      contentTemplate: renderToString(<LinkIcon htmlColor='rgba(0, 0, 0, 0.54)' viewBox='0 0 26 26' />),
    }),
  },
  field: 'relation',
  width: 70,
  resizable: false,
  editable: false,
  cellRenderer: RelationRenderer,
  valueGetter: (params) => params.data?.relation,
  comparator: comparatorRelation,
  sortable: true,
});

const taskDateTimeField = (cellEditorParams: Record<string, unknown>) =>
  dateTimeField({
    cellEditorParams,
    sortable: true,
  });

export const startDatetimeField = (t: TFunction<'translation'>) => ({
  ...taskDateTimeField({
    maxDateTime: 'endDatetime',
    inputFormat: DATETIME_FORMAT.short,
  }),
  headerName: t('start-datetime'),
  field: 'startDatetime',
});

export const endDatetimeField = (t: TFunction<'translation'>, showRequired = false): ColDef => ({
  ...taskDateTimeField({
    minDateTime: 'startDatetime',
    inputFormat: DATETIME_FORMAT.short,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeValue: (params: { value: any; node: RowNode; api: GridApi }) => {
      const { node, value, api } = params;
      const parents = getAllParentsNode(node);
      const updatedNodes = parents.reduce<RowNode[]>((acc, parentNode) => {
        const updatedParentNode = {
          ...parentNode,
          data: {
            ...parentNode.data,
            endDatetimeUpdated: false,
          },
        } as RowNode;

        if (updatedParentNode.data.endDatetime < value) {
          updatedParentNode.data.endDatetimeUpdated = true;
        }

        acc.push(updatedParentNode);

        return acc;
      }, []);

      if (updatedNodes.length) {
        api.applyTransaction({
          update: updatedNodes.map((item) => item.data),
        });
        api.refreshCells({
          rowNodes: updatedNodes,
          columns: ['endDatetime'],
          force: true,
        });
      }
    },
  }),
  headerName: showRequired ? t('end-datetime-required') : t('end-datetime'),
  field: 'endDatetime',
  cellStyle: (params) => {
    const { node, data, value } = params;
    const preparedField = getPreparedFieldFromChildren(node);
    const styles = () => {
      const valueFormat = format(value || new Date(), DATETIME_FORMAT.short);
      const preparedFieldValue = format(preparedField?.data?.endDatetime || new Date(), DATETIME_FORMAT.short);

      if (
        (preparedField && data.id !== preparedField?.id && valueFormat < preparedFieldValue) ||
        data.endDatetimeUpdated
      ) {
        return {
          backgroundColor: '#AA96C8',
        };
      }

      return {};
    };

    return {
      ...defaultCellStyles(params),
      ...styles(),
    };
  },
  tooltipValueGetter(params) {
    const { node, data, value } = params;
    const preparedField = getPreparedFieldFromChildren(node);
    const valueFormat = format(value || new Date(), DATETIME_FORMAT.short);
    const preparedFieldValue = format(preparedField?.data?.endDatetime || new Date(), DATETIME_FORMAT.short);

    if (
      (preparedField && data.id !== preparedField?.id && valueFormat < preparedFieldValue) ||
      data.endDatetimeUpdated
    ) {
      return t('tooltip-end-date-time-parent-changed');
    }

    return undefined;
  },
});

export const titleField = (t: TFunction<'translation'>, showRequired = false) => ({
  headerName: showRequired ? t('title-required') : t('title'),
  field: 'title',
  ...pick(incidentTitleField(t), [
    'cellRenderer',
    'cellClass',
    'cellEditorPopup',
    'cellEditor',
    'width',
    'suppressKeyboardEvent',
    'flex',
  ]),
  sortable: true,
  ...defaultCellTypeFns<string, ProjectTask>(cellTypes.stringType),
  cellEditorParams: {
    inputProps: {
      maxLength: 255,
    },
  },
});

export const descriptionField = (t: TFunction<'translation'>, showRequired = false) => ({
  headerName: showRequired ? t('description-required') : t('description'),
  field: 'description',
  ...pick(incidentDescriptionField(t), [
    'cellRenderer',
    'cellEditorPopup',
    'cellEditor',
    'minWidth',
    'suppressKeyboardEvent',
    'flex',
  ]),
  sortable: true,
  ...defaultCellTypeFns<string, ProjectTask>(cellTypes.stringType),
});

export const responsibleField = (t: TFunction<'translation'>) => ({
  headerName: t('resp.'),
  field: 'responsible',
  ...pick(incidentResponsible(t), [
    'cellRenderer',
    'cellEditor',
    'equals',
    'cellEditorPopup',
    'resizable',
    'singleClickEdit',
    'width',
    'minWidth',
  ]),
  sortable: true,
  ...defaultCellTypeFns<User, ProjectTask>(cellTypes.userType),
});

export const userFunctionField = (t: TFunction<'translation'>): ColDef => ({
  headerName: t('function'),
  field: 'userFunction',
  valueGetter: (params: ValueGetterParams) => params.data.userFunction?.name,
  ...pick(incidentUserFunctionField(t), ['cellRenderer', 'cellEditor', 'cellEditorPopup', 'singleClickEdit', 'width']),
  sortable: true,
  ...userFunctionTypeFns<ProjectTask>(),
  comparator: comparatorUserFunction,
});

export const statusField = (
  t: TFunction<'translation'>,
  valueSetter: (params: ValueSetterParams) => boolean,
  permissions: CellPermissions['permissions'],
): ColDef => ({
  ...statusByField(t, valueSetter, permissions),
  headerName: t('status'),
  field: 'status',
  comparator: cellTypes.statusType.comparator,
  sortable: true,
  editable: editableStatus,
});

export const commentsField = (
  t: TFunction<'translation'>,
  valueSetter: (params: ValueSetterParams) => boolean,
  permissions: CellPermissions['permissions'],
): ColDef => ({
  ...omit(incidentCommentsField(t, valueSetter, permissions), ['editable']),
  headerName: t('comments'),
  field: 'comments',
});

export const resolvedDateField = (t: TFunction<'translation'>) => ({
  ...dateTimeField({
    editable: false,
    sortable: true,
  }),
  headerName: t('resolved'),
  field: 'resolvedDate',
});

export const participantsField = (t: TFunction<'translation'>) => ({
  ...multiUsersGridField({ editable: true, sortable: true }),
  headerName: t('participants'),
  field: ProjectTaskColumnKey.Participants,
});

export const resolutionLevelField = (t: TFunction<'translation'>): ColDef => ({
  headerName: t('level'),
  field: 'resolutionLevel',
  cellEditorPopup: true,
  cellEditorSelector: (params) => {
    const levels = getResolutionLevelForChildrenNodes(params.node);
    return {
      component: ResolutionLevelEditor,
      params: {
        levels,
      },
    };
  },
  width: 84,
  resizable: false,
  cellRenderer: ResolutionLevelRenderer,
  singleClickEdit: true,
  sortable: true,
  comparator: comparatorResolutionLevel,
});

export function useColumnDefs(
  t: TFunction<'translation'>,
  statusSetter: (params: ValueSetterParams) => boolean,
  commentsSetter: (params: ValueSetterParams) => boolean,
  defaultValueSetter: ({ colDef, data, newValue, api }: ValueSetterParams<unknown>) => boolean,
) {
  const defaultSettings = useMemo<ColDef>(
    () => ({
      valueSetter: defaultValueSetter,
      comparator: () => 0,
    }),
    [defaultValueSetter],
  );
  const {
    canUpdateResolvedIncident,
    canUpdateIncident,
    canUpdateAllIncidents,
    canUpdateResolvedIncidentComment,
    canCreateComment,
  } = useRecoilValue(permissionState);
  const eventSettings = useRecoilValue(currentEventSettings);
  const newEntity = useRecoilValue(newRowProjectTask);
  const newChildTask = useRecoilValue(newChildTaskState);
  const isShowRequiredFields = Boolean(newEntity) || Boolean(newChildTask);

  const columnDefs = useMemo<ColDef[]>(
    () =>
      [
        ...(eventSettings.availableIncidentPlan ? [relationField()] : []),
        startDatetimeField(t),
        endDatetimeField(t, isShowRequiredFields),
        titleField(t, isShowRequiredFields),
        descriptionField(t, isShowRequiredFields),
        userFunctionField(t),
        responsibleField(t),
        statusField(t, statusSetter, {
          canUpdateResolvedIncident,
          canUpdateIncident,
          canUpdateAllIncidents,
          canUpdateResolvedIncidentComment,
          canCreateComment,
        }),
        resolutionLevelField(t),
        commentsField(t, commentsSetter, {
          canUpdateResolvedIncident,
          canUpdateIncident,
          canUpdateAllIncidents,
          canUpdateResolvedIncidentComment,
          canCreateComment,
        }),
        resolvedDateField(t),
        participantsField(t),
      ].map((colDef) => ({ ...defaultSettings, ...colDef })),
    [
      eventSettings.availableIncidentPlan,
      t,
      isShowRequiredFields,
      statusSetter,
      canUpdateResolvedIncident,
      canUpdateIncident,
      canUpdateAllIncidents,
      canUpdateResolvedIncidentComment,
      canCreateComment,
      commentsSetter,
      defaultSettings,
    ],
  );

  return columnDefs;
}

export function useDefaultColDef() {
  const { canUpdateAllProjectTask, canUpdateProjectTask, canUpdateResolvedProjectTask, canCreateComment } =
    useRecoilValue(permissionState);
  const { editable } = useEditable({
    isPreparedEntity: isPreparedNewTask,
    nonEditableFields: NON_EDITABLE_FIELDS,
    nonEditableOnPreparedFields: NON_EDITABLE_ON_ADD_FIELDS,
    permissions: {
      canUpdateAllTask: canUpdateAllProjectTask,
      canUpdateResolvedTasks: canUpdateResolvedProjectTask,
      canUpdateTask: canUpdateProjectTask,
      canCreateComment,
    },
  });
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: false,
      hide: false,
      resizable: true,
      editable,
      minWidth: 60,
      enableCellChangeFlash: true,
      cellStyle: defaultCellStyles,
      suppressMenu: true,
      cellRendererParams: {
        hideDateTime: true,
      },
    }),

    [editable],
  );

  return defaultColDef;
}
