import {
  CustomCheckbox,
} from '../../../base';
import { CheckboxType } from '../../../base/CustomCheckbox';

const BooleanRenderer = (value: boolean) => (
  <CustomCheckbox
    disabled
    sx={{ padding: 0 }}
    checked={value}
    type={CheckboxType.CIRCLE}
  />
);

export default BooleanRenderer;
