import { useCallback } from 'react';

import type { ValueSetterParams } from 'ag-grid-community';

import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { Status } from '../../../../../types';
import type { IsNewRowEntityFn } from '../../newEntity';
import { useGridSetterWithConfirm } from './useGridSetterWithConfirm';

type Params = {
  isPreparedEntity: IsNewRowEntityFn,
  defaultValueSetter: ({ colDef, data, newValue }: ValueSetterParams) => boolean,
  confirmCloseText: string,
  confirmReopenText: string,
}

export const useGridStatusSetter = <E extends object>({
  defaultValueSetter,
  isPreparedEntity,
  confirmCloseText,
  confirmReopenText,
}: Params) => {
  const { showConfirm: showConfirmClose, confirmModal: confirmModalClose } = useConfirmDialog({
    content: confirmCloseText,
  });
  const { showConfirm: showConfirmReopen, confirmModal: confirmModalReopen } = useConfirmDialog({
    content: confirmReopenText,
  });
  const getShowConfirmFn = useCallback(
    (oldValue: Status, newValue: Status, entity: E) => {
      const isClose = newValue === Status.RESOLVED;
      const isReopen = oldValue === Status.RESOLVED;

      if (!isPreparedEntity(entity)) {
        if (isClose) {
          return showConfirmClose;
        }
        if (isReopen) {
          return showConfirmReopen;
        }
      }
      return false;
    },
    [isPreparedEntity, showConfirmReopen, showConfirmClose],
  );
  const { setterWithConfirm } = useGridSetterWithConfirm({
    defaultValueSetter,
    getShowConfirmFn,
  });

  return {
    statusSetter: setterWithConfirm,
    modals: {
      close: confirmModalClose,
      reopen: confirmModalReopen,
    },
  };
};
