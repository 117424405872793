import { atom, selectorFamily } from 'recoil';

import type { IncidentLog } from '../../types';

export const incidentsLogsState = atom<Record<number, IncidentLog[]>>({
  key: 'incidentsLogs',
  default: {},
});

export const incidentLogsByIdState = selectorFamily({
  key: 'incidentLogsById',
  get: (incidentId: number) => ({ get }) => {
    const incidentLogs = get(incidentsLogsState);

    return incidentLogs[incidentId] || [];
  },
  set: (incidentId: number) => ({ set }, newIncidentLogs) => {
    set(incidentsLogsState, (incidentsLogs) => ({
      ...incidentsLogs,
      [incidentId]: newIncidentLogs as IncidentLog[],
    }));
  },
});
