import type { TFunction } from 'i18next';

export const translateKeys = (t: TFunction<'translation'>) => ({
  name: t('filerobot.name'),
  save: t('filerobot.save'),
  saveAs: t('filerobot.saveAs'),
  back: t('filerobot.back'),
  loading: t('filerobot.loading'),
  resetOperations: t('filerobot.resetOperations'),
  changesLoseWarningHint: t('filerobot.changesLoseWarningHint'),
  discardChangesWarningHint: t('filerobot.discardChangesWarningHint'),
  cancel: t('filerobot.cancel'),
  apply: t('filerobot.apply'),
  warning: t('filerobot.warning'),
  confirm: t('filerobot.confirm'),
  discardChanges: t('filerobot.discardChanges'),
  undoTitle: t('filerobot.undoTitle'),
  redoTitle: t('filerobot.redoTitle'),
  showImageTitle: t('filerobot.showImageTitle'),
  zoomInTitle: t('filerobot.zoomInTitle'),
  zoomOutTitle: t('filerobot.zoomOutTitle'),
  toggleZoomMenuTitle: t('filerobot.toggleZoomMenuTitle'),
  adjustTab: t('filerobot.adjustTab'),
  finetuneTab: t('filerobot.finetuneTab'),
  filtersTab: t('filerobot.filtersTab'),
  watermarkTab: t('filerobot.watermarkTab'),
  annotateTabLabel: t('filerobot.annotateTabLabel'),
  resize: t('filerobot.resize'),
  resizeTab: t('filerobot.resizeTab'),
  imageName: t('filerobot.imageName'),
  invalidImageError: t('filerobot.invalidImageError'),
  uploadImageError: t('filerobot.uploadImageError'),
  areNotImages: t('filerobot.areNotImages'),
  isNotImage: t('filerobot.isNotImage'),
  toBeUploaded: t('filerobot.toBeUploaded'),
  cropTool: t('filerobot.cropTool'),
  original: t('filerobot.original'),
  custom: t('filerobot.custom'),
  square: t('filerobot.square'),
  landscape: t('filerobot.landscape'),
  portrait: t('filerobot.portrait'),
  ellipse: t('filerobot.ellipse'),
  classicTv: t('filerobot.classicTv'),
  cinemascope: t('filerobot.cinemascope'),
  arrowTool: t('filerobot.arrowTool'),
  blurTool: t('filerobot.blurTool'),
  brightnessTool: t('filerobot.brightnessTool'),
  contrastTool: t('filerobot.contrastTool'),
  ellipseTool: t('filerobot.ellipseTool'),
  unFlipX: t('filerobot.unFlipX'),
  flipX: t('filerobot.flipX'),
  unFlipY: t('filerobot.unFlipY'),
  flipY: t('filerobot.flipY'),
  hsvTool: t('filerobot.hsvTool'),
  hue: t('filerobot.hue'),
  brightness: t('filerobot.brightness'),
  saturation: t('filerobot.saturation'),
  value: t('filerobot.value'),
  imageTool: t('filerobot.imageTool'),
  importing: t('filerobot.importing'),
  addImage: t('filerobot.addImage'),
  uploadImage: t('filerobot.uploadImage'),
  fromGallery: t('filerobot.fromGallery'),
  lineTool: t('filerobot.lineTool'),
  penTool: t('filerobot.penTool'),
  polygonTool: t('filerobot.polygonTool'),
  sides: t('filerobot.sides'),
  rectangleTool: t('filerobot.rectangleTool'),
  cornerRadius: t('filerobot.cornerRadius'),
  resizeWidthTitle: t('filerobot.resizeWidthTitle'),
  resizeHeightTitle: t('filerobot.resizeHeightTitle'),
  toggleRatioLockTitle: t('filerobot.toggleRatioLockTitle'),
  resetSize: t('filerobot.resetSize'),
  rotateTool: t('filerobot.rotateTool'),
  textTool: t('filerobot.textTool'),
  textSpacings: t('filerobot.textSpacings'),
  textAlignment: t('filerobot.textAlignment'),
  fontFamily: t('filerobot.fontFamily'),
  size: t('filerobot.size'),
  letterSpacing: t('filerobot.letterSpacing'),
  lineHeight: t('filerobot.lineHeight'),
  warmthTool: t('filerobot.warmthTool'),
  addWatermark: t('filerobot.addWatermark'),
  addTextWatermark: t('filerobot.addTextWatermark'),
  addWatermarkTitle: t('filerobot.addWatermarkTitle'),
  uploadWatermark: t('filerobot.uploadWatermark'),
  addWatermarkAsText: t('filerobot.addWatermarkAsText'),
  padding: t('filerobot.padding'),
  paddings: t('filerobot.paddings'),
  shadow: t('filerobot.shadow'),
  horizontal: t('filerobot.horizontal'),
  vertical: t('filerobot.vertical'),
  blur: t('filerobot.blur'),
  opacity: t('filerobot.opacity'),
  transparency: t('filerobot.transparency'),
  position: t('filerobot.position'),
  stroke: t('filerobot.stroke'),
  saveAsModalTitle: t('filerobot.saveAsModalTitle'),
  extension: t('filerobot.extension'),
  format: t('filerobot.format'),
  nameIsRequired: t('filerobot.nameIsRequired'),
  quality: t('filerobot.quality'),
  imageDimensionsHoverTitle: t('filerobot.imageDimensionsHoverTitle'),
  cropSizeLowerThanResizedWarning: t('filerobot.cropSizeLowerThanResizedWarning'),
  actualSize: t('filerobot.actualSize'),
  fitSize: t('filerobot.fitSize'),
  addImageTitle: t('filerobot.addImageTitle'),
  mutualizedFailedToLoadImg: t('filerobot.mutualizedFailedToLoadImg'),
  tabsMenu: t('filerobot.tabsMenu'),
  download: t('filerobot.download'),
  width: t('filerobot.width'),
  height: t('filerobot.height'),
  plus: t('filerobot.plus'),
  cropItemNoEffect: t('filerobot.cropItemNoEffect'),
});
