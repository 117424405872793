import { FullScreen } from '../../components/FullScreen';
import useExportToExcelRowsFromGrid from '../../hooks/useExportToExcelRowsFromGrid';
import { EntitiesPageWrapper } from '../../modules/EntitiesGrid';
import { DailyTasksGrid, DailyTasksControlPanel } from '../../modules/Tasks';
import { useImportDailyTasksFromExcel } from '../../modules/Tasks/state/dailyTasks';
import { useDefaultTasksGridSettings } from '../../modules/Tasks/state/tasks';
import { useExportToExcelDaily } from '../../state/export';

const DailyPlan = () => {
  const { ref: gridRef, ...gridDefaultSettings } = useDefaultTasksGridSettings();
  const { execute: exportToExcel } = useExportToExcelDaily();
  const exportToExcelCallback = useExportToExcelRowsFromGrid(gridRef, exportToExcel);
  const { execute: importFromExcelCallback, loading: loadingImportFromExcel } = useImportDailyTasksFromExcel();

  const onImportFromExcel = (file: File) => {
    importFromExcelCallback(file);
  };

  return (
    <FullScreen>
      <EntitiesPageWrapper>
        <DailyTasksControlPanel
          onExportToExcel={exportToExcelCallback}
          onImportFromExcel={onImportFromExcel}
          loadingImportFromExcel={loadingImportFromExcel}
        />
        <DailyTasksGrid gridRef={gridRef} {...gridDefaultSettings} />
      </EntitiesPageWrapper>
    </FullScreen>
  );
};

export default DailyPlan;
