import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { eventSettingService } from '../../../api';
import { getCurrentUserTask, userEventSettings, userState } from '../../../state/user';
import { transformFilterForBackend } from '../lib/transformFilterForBackend';
import type { ProjectTasksFormFilerValue } from '../lib/types';

const updateProjectTaskDefaultFilter = (rti: RecoilTaskInterface) => async (filters: ProjectTasksFormFilerValue) => {
  const { getLoadable } = rti;

  const eventSetting = getLoadable(userEventSettings).getValue();
  const currentUser = getLoadable(userState).getValue();

  const transformedFilters = transformFilterForBackend({ filters, currentUser });

  await eventSettingService.update({
    id: eventSetting.id,
    projectTaskDefaultFilters: {
      ...transformedFilters,
    },
  });

  await getCurrentUserTask(rti)();
};

export const useUpdateProjectTaskDefaultFilter = () =>
  useRecoilTask(updateProjectTaskDefaultFilter, [], {
    loaderStack: 'projectTaskDefaultFilterUpdate',
    errorStack: true,
  });
