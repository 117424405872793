import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { authState } from '../state/auth';
import { currentEventIdState } from '../state/event';
import { useRoles } from '../state/roles';
import { useUser } from '../state/user';
import { useUsersList } from '../state/users';
import useIncidentUpdates from './useIncidentUpdates';

const useInitState = () => {
  const auth = useRecoilValue(authState);
  const currentEventId = useRecoilValue(currentEventIdState);
  const { execute: initUser, data: user, loading: loadingUser } = useUser();
  const { i18n } = useTranslation();
  const { execute: initRoles, loading: loadingRoles } = useRoles();
  const { execute: initUsers, loading: loadingUsers } = useUsersList();

  useIncidentUpdates(auth);

  useEffect(() => {
    if (user && user.language && user.language !== i18n.language) {
      i18n.changeLanguage(user.language);
    }
  }, [user, i18n]);

  useEffect(() => {
    if (!auth) {
      return;
    }

    initUser();
    initRoles();
  }, [auth, initUser, initRoles]);

  useEffect(() => {
    if (currentEventId) {
      initUsers();
    }
  }, [currentEventId, initUsers, auth]);

  return {
    loading: loadingUser || loadingRoles || loadingUsers,
    auth,
  };
};

export default useInitState;
