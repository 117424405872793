export function getUrlBackend(url: string): string {
  const backendUrl = process.env.REACT_APP_API_URL ?? '';
  const currentUrl = window.location.origin;

  if (currentUrl === backendUrl) {
    return url;
  }

  return `${backendUrl}${url}`;
}
