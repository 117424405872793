import { useRef } from 'react';

import type { YMap } from '@yandex/ymaps3-types';

import { MapLoader } from '../../../shared/components/MapLoader';
import { Hint, LocationMarker, YandexMap, YandexMapProvider, useYandexMap } from '../../../shared/components/YandexMap';
import { useCreateSquareImage } from '../../../shared/hooks/useCreateSquareImage';
import { CROP_SIZE } from '../lib/constants';
import { useNormalizeYandexMapData } from '../lib/normalizeYandexMapData';
import { useLocationMap } from '../model/LocationMapDataProvider';

interface YandexMapLocationCustomProps {
  url: string;
}

const YandexMapLocationPlanBase = (props: YandexMapLocationCustomProps) => {
  const { url } = props;
  const { image } = useCreateSquareImage({ url });
  const ymapRef = useRef<YMap>(null);
  const { ymapsInstance } = useYandexMap();
  const data = useLocationMap();
  const { locations, onMarkerClick, onMapClick, onDraggableMarker } = useNormalizeYandexMapData(data, ymapRef);

  if (!ymapsInstance || image?.width === 0 || image?.height === 0 || !image) {
    return <MapLoader />;
  }

  const { YMapLayer, YMapTileDataSource, YMapListener } = ymapsInstance.ymaps;
  const { Cartesian } = ymapsInstance.projection;

  return (
    <YandexMap
      location={{ center: [0, 0], zoom: 1 }}
      mode='raster'
      worldOptions={{ cycledX: false }}
      projection={
        new Cartesian(
          [
            [-image.width, -image.height],
            [image.width, image.height],
          ],
          [false, false],
        )
      }
    >
      <YMapTileDataSource
        id='custom'
        raster={{
          type: 'ground',
          fetchTile: (x, y, z) =>
            new Promise((resolve) => {
              const canvas = document.createElement('canvas');
              canvas.width = CROP_SIZE;
              canvas.height = CROP_SIZE;

              const dimenssion = 2 ** z;
              const resize = CROP_SIZE * dimenssion;

              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(image, x * -CROP_SIZE, y * -CROP_SIZE, resize, resize);
              }

              resolve({ image: canvas });
            }),
        }}
      />
      <YMapLayer id='customLayer' source='custom' type='ground' zIndex={5} />
      <YMapListener onClick={onMapClick} />
      <Hint ymapsInstance={ymapsInstance} />
      {locations.map((marker) => (
        <LocationMarker
          view='primary'
          draggable
          key={marker.key}
          ymapsInstance={ymapsInstance}
          coordinates={[marker.geo.longitude, marker.geo.latitude]}
          onClick={onMarkerClick(marker)}
          properties={marker.properties}
          onDragEnd={onDraggableMarker(marker)}
        />
      ))}
    </YandexMap>
  );
};

export const YandexMapLocationPlan = (props: YandexMapLocationCustomProps) => (
  <YandexMapProvider>
    <YandexMapLocationPlanBase {...props} />
  </YandexMapProvider>
);
