import { PlaylistRemove } from '@mui/icons-material';
import { Box } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { t } from 'i18next';

import { Button } from '../../../../../components/base';
import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { useDailyTaskRemoveRow } from '../../../state/dailyTasks';

export const RemoveRowRenderer = (props: ICellRendererParams) => {
  const { data } = props;
  const { execute: onRemoveTaskRow } = useDailyTaskRemoveRow();
  const { showConfirm, confirmModal } = useConfirmDialog({
    content: t('confirm-remove-task'),
  });

  const onRemoveTask = async () => {
    showConfirm().then((isOk) => {
      if (isOk) {
        onRemoveTaskRow({ id: data.id });
      }
    });
  };

  return (
    <Box>
      <Button type='button' onClick={onRemoveTask} sx={{ minWidth: 40 }}>
        <PlaylistRemove />
      </Button>
      {confirmModal}
    </Box>
  );
};
