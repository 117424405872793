/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Trans } from 'react-i18next';

import type {
  CreateActionDescription,
} from '../../../../types';
import { FieldValueCell } from '../FieldValueCell/FieldValueCell';
import { convertCreateIncidentToFieldValue, fieldNameT } from '../utils';

type CreateIncidentActionProps = {
  actionDescription: CreateActionDescription
}

export const CreateIncidentAction: FC<CreateIncidentActionProps> = ({ actionDescription }) => {
  const { attrs } = actionDescription;
  const attrsList = convertCreateIncidentToFieldValue(attrs);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {attrsList.map((props) => (
            <TableRow
              key={props.name}
            >
              <TableCell width='50%' component='th' scope='row'>
                <Trans i18nKey={fieldNameT(props.name)} />
              </TableCell>
              <TableCell>
                <FieldValueCell {...props} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
