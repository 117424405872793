import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import { Icon } from '../../../../components/base';
import { baseInputSx } from './constants';

const SEARCH_DEBOUNCE = 500;

type ContainsTextProps<T extends { text?: string }> = {
  filtersState: RecoilState<T>;
};
export const ContainsText = <T extends { text?: string }>({ filtersState }: ContainsTextProps<T>) => {
  const [filters, setFilters] = useRecoilState(filtersState);
  const [searchText, setSearchText] = useState(filters.text);
  const { t } = useTranslation();

  useDebounce(
    () => {
      setFilters((oldFilters) => ({ ...oldFilters, text: searchText }));
    },
    SEARCH_DEBOUNCE,
    [setFilters, searchText],
  );

  const changeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <TextField
      value={searchText}
      placeholder={t('search')}
      onChange={changeHandler}
      InputProps={{
        ...baseInputSx.InputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <Icon icon='search' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};
