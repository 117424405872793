import { useRecoilTask } from 'recoil-toolkit';

import { incidentsState } from './atoms';
import {
  addIncidentTask,
  editPartIncidentTask,
  getIncidentsTaskWithFilters,
  editIncidentTask,
  viewedByTask,
  resetIncidentsTask,
  getIncidentTasksWithoutFilters,
} from './tasks';

export const useIncidentsListWithoutFilters = () =>
  useRecoilTask(getIncidentTasksWithoutFilters, [], {
    dataSelector: incidentsState,
    errorStack: true,
  });

export const useIncidentsList = () =>
  useRecoilTask(getIncidentsTaskWithFilters, [], {
    dataSelector: incidentsState,
    errorStack: true,
  });

export const useAddIncident = () =>
  useRecoilTask(addIncidentTask, [], {
    loaderStack: 'addIncidentTask',
    errorStack: true,
  });

export const useEditIncidentPart = () =>
  useRecoilTask(editPartIncidentTask, [], {
    loaderStack: 'editIncidentPartTask',
    errorStack: true,
  });

export const useEditIncident = () =>
  useRecoilTask(editIncidentTask, [], {
    loaderStack: 'editIncidentTask',
    errorStack: true,
  });

export const useViewedBy = () =>
  useRecoilTask(viewedByTask, [], {
    loaderStack: 'viewedByTask',
    errorStack: true,
  });

export const useResetIncidents = () => useRecoilTask(resetIncidentsTask, []);
