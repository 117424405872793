import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pick } from 'remeda';

import { userEventState } from '../../../../../state/event';
import type { LocationControlItem, LocationForm } from '../../../../../state/locationsForm';
import {
  TypeLocation,
  isEditLocationForm,
  locationsControlState,
  updateLocationsControl,
} from '../../../../../state/locationsForm';
import { userState } from '../../../../../state/user';
import type { GeoCoordinates } from '../../../../../types';
import checkLimit from '../../../../../utils/checkLimit';
import { getGeo, onlyWithGeo } from '../../../shared/config/geo';
import type { Center, Zoom } from '../../../shared/hooks/useMapCenterAndZoom';
import { useMapCenterAndZoom } from '../../../shared/hooks/useMapCenterAndZoom';
import { useLocationFormDrawer } from './useLocationFormDrawer';

type SearchLocation = Center | null;
type CustomLocationControlItem = LocationControlItem & { key: string };

export type LocationMapDataProviderApi = {
  center: Center;
  zoom: Zoom;
  canAddNewLocation: boolean;
  locations: CustomLocationControlItem[];
  onMarkerClick: (marker: LocationForm) => () => void;
  onMapClick: (latLng: GeoCoordinates) => void;
  onDraggableMarker: (locationForm: LocationForm, latLng: GeoCoordinates | null) => void;
  searchLocation: SearchLocation;
  onChangeSearchLocation: (latLng: SearchLocation) => void;
};

const LocationMapContext = createContext<LocationMapDataProviderApi>({
  center: [40, 34],
  zoom: 15,
  canAddNewLocation: false,
  locations: [],
  onMarkerClick: () => () => null,
  onMapClick: () => null,
  onDraggableMarker: () => null,
  searchLocation: null,
  onChangeSearchLocation: () => null,
});

function createLocationMarker(location: LocationControlItem): CustomLocationControlItem {
  const key = location.type === TypeLocation.New ? location.key : location.id.toString();
  return {
    ...location,
    key,
  };
}

function updatePrevLocationControl(
  prevLocationControl: LocationControlItem,
  latLng: GeoCoordinates,
): LocationControlItem {
  if (isEditLocationForm(prevLocationControl)) {
    return {
      ...pick(prevLocationControl, ['id', 'type', 'title', 'description']),
      geo: {
        id: prevLocationControl.geo.id,
        ...latLng,
      },
    };
  }
  return {
    ...prevLocationControl,
    geo: latLng,
  };
}

export const LocationMapDataProvider = ({ children }: PropsWithChildren) => {
  const [searchLocation, setSearchLocation] = useState<SearchLocation>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [locationsControl, setLocationsControl] = useRecoilState(locationsControlState);
  const user = useRecoilValue(userState);
  const userEvent = useRecoilValue(userEventState);

  const { onMarkerClick, onMapClick } = useLocationFormDrawer();

  const locations = onlyWithGeo(locationsControl);
  const allGeo = [...locations.map(getGeo)];
  const { center, zoom } = useMapCenterAndZoom(allGeo, refContainer);
  const canAddNewLocation = user?.isOwner || checkLimit(userEvent?.event.limitLocations, locations.length);

  const onDraggableMarker = useCallback(
    (locationForm: LocationForm, latLng: GeoCoordinates | null) => {
      setLocationsControl((prevLocationsControl) =>
        updateLocationsControl(prevLocationsControl, locationForm, (prevLocationControl) =>
          latLng ? updatePrevLocationControl(prevLocationControl, latLng) : prevLocationControl,
        ),
      );
    },
    [setLocationsControl],
  );

  const value = useMemo(
    () => ({
      center: searchLocation ?? center,
      zoom,
      canAddNewLocation,
      locations: locations.map(createLocationMarker),
      onMarkerClick,
      onMapClick: canAddNewLocation ? onMapClick : () => null,
      onDraggableMarker,
      searchLocation,
      onChangeSearchLocation: setSearchLocation,
    }),
    [canAddNewLocation, center, locations, onDraggableMarker, onMapClick, onMarkerClick, searchLocation, zoom],
  );

  return (
    <LocationMapContext.Provider value={value}>
      <Box ref={refContainer} sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        {children}
      </Box>
    </LocationMapContext.Provider>
  );
};

export const useLocationMap = () => {
  const context = useContext(LocationMapContext);

  if (!context) {
    throw new Error('useLocationMap must be used within an LocationMapDataProvider');
  }

  return context;
};
