import { type PropsWithChildren, useState, useCallback, useRef, forwardRef } from 'react';

import { Box } from '@mui/material';
import type { YMap, YMapProps } from '@yandex/ymaps3-types';

import { mergeRefs } from '../../lib/mergeRefs';
import { useYandexMap } from './model/YandexMapProvider';

export const YandexMap = forwardRef<YMap, PropsWithChildren<YMapProps>>(({ children, location, ...props }, ref) => {
  const mapRef = useRef<YMap>(null);
  const [currentLocation, setCurrentLocation] = useState(location);
  const { ymapsInstance } = useYandexMap();

  // After updating state, the map resets center to the initial position.
  // So after drag we keep the current center position.
  const handleUpdateCenter = useCallback(() => {
    const currentRef = mapRef.current;

    if (currentRef) {
      setCurrentLocation({
        center: [currentRef.center[0], currentRef.center[1]],
        zoom: currentRef.zoom,
      });
    }
  }, []);

  const handleZoomIn = () => {
    const mapRefCurrent = mapRef.current;
    if (mapRefCurrent) {
      const range = mapRefCurrent.zoomRange;
      const newZoom = mapRefCurrent.zoom + 1;

      if (range.max > newZoom) {
        mapRefCurrent.setLocation({
          zoom: newZoom,
        });
      }
    }
  };

  const handleZoomOut = () => {
    const mapRefCurrent = mapRef.current;
    if (mapRefCurrent) {
      const range = mapRefCurrent.zoomRange;
      const newZoom = mapRefCurrent.zoom - 1;

      if (range.min < newZoom) {
        mapRefCurrent.setLocation({
          zoom: newZoom,
        });
      }
    }
  };

  if (!ymapsInstance) {
    return null;
  }

  const { YMap, YMapDefaultFeaturesLayer, YMapControls, YMapListener, YMapControl } = ymapsInstance.ymaps;

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <YMap ref={mergeRefs([mapRef, ref])} location={currentLocation} {...props}>
        <YMapDefaultFeaturesLayer />
        <YMapListener onTouchEnd={handleUpdateCenter} onClick={handleUpdateCenter} />
        <YMapControls position='bottom right' orientation='vertical'>
          {/**
           * Added custom zoom controls, since native buttons do not contain the type="button" attribute.
           * We have cases, when form tag contain the map. And absence attributed type=«button» call submitted form.
           */}
          <YMapControl>
            <button type='button' className='ymaps3x0--button ymaps3x0--control-button' onClick={handleZoomIn}>
              <span className='ymaps3x0--zoom-control__in' />
            </button>
            <button type='button' className='ymaps3x0--button ymaps3x0--control-button' onClick={handleZoomOut}>
              <span className='ymaps3x0--zoom-control__out' />
            </button>
          </YMapControl>
        </YMapControls>
        {children}
      </YMap>
    </Box>
  );
});
