import type { FC } from 'react';

import { Stack } from '@mui/material';

import type { CommentActionRemoveDescription } from '../../../../types';
import getPhotoFormats from '../../../../utils/getPhotoFormats';
import {
  ClampedTypography, FallbackImage,
} from '../../../base';

type RemoveCommentDataProps = {
  actionDescription: CommentActionRemoveDescription
}

export const RemoveIncidentAction: FC<RemoveCommentDataProps> = ({ actionDescription }) => {
  const { image, body } = actionDescription.attrs.comment;
  const thumbnailImage = image ? getPhotoFormats(image).thumbnailSizeImage : undefined;

  return (
    <Stack gap='8px' spacing={2} direction='row' alignItems='center'>
      {thumbnailImage && (
        <FallbackImage
          sx={{
            marginBottom: '8px',
            height: '55px',
            maxHeight: '55px',
            borderRadius: '8px',
            objectFit: 'contain',
            width: 'fit-content',
          }}
          src={thumbnailImage.url}
          alt={thumbnailImage.name}
          aria-hidden='true'
        />
      )}

      <ClampedTypography lineclamp={3} variant='body2'>
        {body}
      </ClampedTypography>
    </Stack>
  );
};
