import { useRecoilValueLoadable } from 'recoil';

import { Dashboards } from '../../features/incident/Dashborads';
import { userState } from '../../state/user';

const DashboardsPage = () => {
  const currentUser = useRecoilValueLoadable(userState).valueMaybe();

  return currentUser ? <Dashboards /> : null;
};

export default DashboardsPage;
