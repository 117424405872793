import type { RefObject} from 'react';
import { useEffect } from 'react';

import type { AgGridReact } from 'ag-grid-react';
import { useSetRecoilState } from 'recoil';

import { useNewEntityRow, isNewRowEntity } from '../state/newEntity';
import type { UseNewEntityRowParams } from '../state/newEntity/types';

export function useInlineAdding<T>(
  gridRef: RefObject<AgGridReact<unknown>>,
  newEntityParams: UseNewEntityRowParams<T>,
) {
  const { newEntity } = useNewEntityRow(newEntityParams);
  const setNewEntity = useSetRecoilState(newEntityParams.newEntityAtom);
  const editNewEntity = (field: string, value: unknown) =>
    setNewEntity((entity) => {
      if (entity) {
        return { ...entity, [field]: value };
      }

      return undefined;
    });

  useEffect(() => {
    if (gridRef.current?.api) {
      if (!newEntity
          && gridRef.current.api.getPinnedTopRowCount() > 0
          && gridRef.current.api.getFocusedCell()?.rowPinned === 'top') {
        gridRef.current.api.clearFocusedCell();
      }
      gridRef.current.api.setPinnedTopRowData(newEntity ? [newEntity] : undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEntity]);

  return {
    isNewRowEntity,
    editNewEntity,
  };
}
