import { useState, type MouseEvent } from 'react';

import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';

import { eventState } from '../../../state/event';
import { getRenderItems } from '../libs/getMenuTypes';

export const TypeChangerControl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentEvent = useRecoilValue(eventState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (url: string) => () => {
    navigate(url);
  };

  const { activeItem, otherItems } = getRenderItems({
    canViewDailyPlan: currentEvent.dailyPlan ?? false,
    canViewProjectPlan: currentEvent.projectPlan ?? false,
    canViewIncidentPlan: currentEvent.incidentPlan ?? false,
    location,
  });

  return otherItems.length > 0 ? (
    <div>
      <IconButton
        sx={{
          borderRadius: '8px',
          height: '40px',
          display: 'flex',
          width: 'fit-content',
          border: '1px solid #C7D1D4',
        }}
        onClick={handleClick}
      >
        <Stack direction='row' sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
          <Typography variant='body2'>{activeItem?.title}</Typography>
        </Stack>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {otherItems.map((item) => (
          <MenuItem key={item.id} onClick={handleItemClick(item.baseUrl)}>
            <Typography variant='body1'>{item.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : null;
};
