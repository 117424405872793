import { atom, selectorFamily } from 'recoil';

import type { Incident } from '../../types';

export const incidentsState = atom<Incident[]>({
  key: 'incidents',
  default: [],
});

export const getIncidentsWithoutRelations = selectorFamily({
  key: 'getIncidentsWithoutRelations',
  get:
    () =>
    ({ get }) =>
      get(incidentsState).filter((item) => !item.relations?.length || item.relations.length === 0),
});

export const getIncident = selectorFamily({
  key: 'getIncident',
  get:
    (id: number) =>
    ({ get }) =>
      get(incidentsState).find((item) => item.id === id),
});
