import type { Layouts } from 'react-grid-layout';
import { atom } from 'recoil';

import type { DashboardDataSet } from '../../../../shared/lib/types/dashborad.types';
import { initialLayoutsSettings } from '../config/initialLayouts';

type DashboardIncidentState = {
  dashboards: DashboardDataSet[];
  layoutsSetting: Layouts;
};

export const dashboardsIncidentState = atom<DashboardIncidentState>({
  key: 'dashboards',
  default: {
    dashboards: [],
    layoutsSetting: initialLayoutsSettings,
  },
});
