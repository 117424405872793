import qs from 'qs';

import type { Comment, Incident, NewComment, WithMeta } from '../types';
import requestService from './requestService';
import { sessionService } from './sessionService';

const populations = qs.stringify(
  {
    populate: ['author', 'image', 'hiddenUser', 'editedUser'],
  },
  { encodeValuesOnly: true },
);

const commentsService = {
  addComment: (newComment: NewComment, author: number) => {
    const { image, ...data } = newComment;
    const formData = new FormData();
    if (image) {
      formData.append('files.image', image, image.name);
    }

    formData.append('data', JSON.stringify({ ...data, author, createdDate: new Date() }));

    return requestService
      .post<WithMeta<Comment>>(`/comments?${populations}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data);
  },
  removeComment: (id: number, incidentId: number, userId: number) =>
    requestService
      .put<WithMeta<Comment & { incident: Incident }>>(`/comments/${id}?${populations}`, {
        data: {
          incident: incidentId,
          hidden: true,
          hiddenDate: new Date(),
          hiddenUser: userId,
        },
      })
      .then((response) => response.data),
  editComment: async (id: number, updatedComment: NewComment, userId: number) => {
    const { image, ...data } = updatedComment;

    let uploadImage;

    if (image) {
      const formImageData = new FormData();
      formImageData.append('files', image, image.name);
      const { data: dataImage } = await requestService.post('/upload', formImageData);
      // eslint-disable-next-line prefer-destructuring
      uploadImage = dataImage[0];
    }

    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        ...(uploadImage && {
          image: uploadImage,
        }),
        ...data,
        edited: true,
        editedDate: new Date(),
        editedUser: userId,
      }),
    );

    return requestService
      .put<WithMeta<Comment>>(`/comments/${id}?${populations}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data);
  },
};

const transformCommentsService: typeof commentsService = {
  ...commentsService,
  addComment: (newComment: NewComment, author: number) => {
    sessionService.setItem('notShowUpdatesSocket', 'true');
    return commentsService.addComment(newComment, author);
  },
  editComment: (id: number, updatedComment: NewComment, userId: number) => {
    sessionService.setItem('notShowUpdatesSocket', 'true');
    return commentsService.editComment(id, updatedComment, userId);
  },
};

export default transformCommentsService;
