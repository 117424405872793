import type { Incident as IncidentType } from '../../../../types';

export enum TaskType {
  Incident = 'incident',
  ProjectTask = 'projectTask',
  OperationalTask = 'operationalTask',
  Info = 'info',
}

export interface Task
  extends Omit<IncidentType, 'isHaveSeenByOperator' | 'isResponsibleInformed' | 'isUrgent' | 'count'> {
  count: string | number;
  type: TaskType;
  endDatetime?: Date;
  startDatetime?: Date;
  orgHierarchy?: string[];
}

export type PreparedNewTask<T extends Task> = Partial<
  Omit<T, 'id' | 'registeredDate' | 'registeredBy' | 'registeredDate' | 'viewedBy' | 'count'>
>;

export const isPreparedNewTask = <T extends Task>(task: T | PreparedNewTask<T>): task is PreparedNewTask<T> =>
  !('id' in task && task.id);
