import { useTranslation } from 'react-i18next';

import { CheckboxField, type CheckboxFieldProps } from '../../fields/CheckboxField';
import type { FormIncidentsFilter } from '../lib/types';

type ResponsibleInformedFieldProps = Omit<CheckboxFieldProps<FormIncidentsFilter, 'isAssignedToMe'>, 'name' | 'label'>;

export const ResponsibleInformedField = (props: ResponsibleInformedFieldProps) => {
  const { t } = useTranslation();

  return <CheckboxField {...props} name='responsibleIsInformed' label={t('responsible-informed')} />;
};
