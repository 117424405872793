import type { YMapMarkerProps } from '@yandex/ymaps3-types';

import { ClusterMarker, type ClusterMarkerProps } from '../../Markers/ClusterMarker';
import type { YandexMapApiLoader } from '../model/YandexMapProvider';

interface ClusterProps extends ClusterMarkerProps, YMapMarkerProps {
  ymapsInstance: YandexMapApiLoader;
  count: number;
}

export const Cluster = ({ ymapsInstance, count, ...props }: ClusterProps) => {
  const { YMapMarker } = ymapsInstance.ymaps;

  return (
    <YMapMarker {...props}>
      <ClusterMarker count={count} />
    </YMapMarker>
  );
};
