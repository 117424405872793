import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import type { User } from '../../types';
import getFullName from '../../utils/getFullName';
import { UserAvatar, UserChip } from '../base';
import { CustomPopper } from './components/CustomPopper';

export type UserSelectMultipleFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options extends User,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<User, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'isOptionEqualToValue' | 'getOptionLabel' | 'renderOption' | 'onChange'
> &
  UseControllerProps<TFieldValues, TName> & {
    label: string;
    onChange?: (values: Options[]) => void;
    getOptionDisabled?: (value: TFieldValues[]) => boolean;
  };

export const UserSelectMultipleField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: UserSelectMultipleFieldProps<TFieldValues, TName, User>,
) => {
  const { label, placeholder, onChange, ...rest } = props;
  const { field } = useController(props);

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...rest}
        {...field}
        onChange={(_, value) => {
          field.onChange(value);
          onChange?.(value);
        }}
        PopperComponent={CustomPopper}
        renderInput={(params: TextFieldProps) => <TextField {...params} label={label} placeholder={placeholder} />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(user) => getFullName(user)}
        renderOption={(liProps, user) => (
          <li {...liProps}>
            <UserAvatar user={user} sx={{ marginRight: '8px' }} />
            {getFullName(user)}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((user, index) => (
            <UserChip user={user} variant='outlined' size='small' {...getTagProps({ index })} />
          ))
        }
        multiple
      />
    </FormControl>
  );
};
