import type {
  FC, Ref} from 'react';
import { memo
} from 'react';

import { Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Anchorme } from 'react-anchorme';
import { renderToString } from 'react-dom/server';
import { Trans, useTranslation } from 'react-i18next';
import { Item } from 'react-photoswipe-gallery';

import type { Comment, Language } from '../../types';
import formatDate from '../../utils/formatDate';
import formatDistanceToNow from '../../utils/formatDistanceToNow';
import getFullName from '../../utils/getFullName';
import getPhotoFormats from '../../utils/getPhotoFormats';
import Button from './Button';
import FallbackImage from './FallbackImage';
import Icon from './Icon';
import TruncatedTypography from './TruncatedTypography';
import UserAvatar from './UserAvatar';

type Props = {
  comment: Comment;
  canUpdateComment?: boolean
  onRemove?: (id: number) => void;
  onUpdate?: (id: number) => void;
};

const TRUNCATE_URL_LENGTH = 30;

const captionContent = (comment: Comment) => renderToString(
  <div style={{ backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '8px' }}>
    <p>
      <Trans i18nKey='author' />
      :&nbsp;
      {getFullName(comment.author)}
    </p>
    <p>
      <Trans i18nKey='date' />
      :&nbsp;
      {formatDate(comment.createdDate)}
    </p>
  </div>,
);

function getShortName(firstName = '', lastName = '') {
  if (!firstName && !lastName) {
    return '';
  }

  return `${lastName} ${firstName.split('')[0]}.`;
}

const UserComment: FC<Props> = ({
  comment, onRemove, onUpdate, canUpdateComment,
}) => {
  const {
    author, createdDate, body, image, id, hidden, hiddenDate, hiddenUser, edited, editedDate, editedUser,
  } = comment;
  const imageFormats = image ? getPhotoFormats(image) : undefined;
  const { i18n, t } = useTranslation();
  const shortHiddenUser = getShortName(hiddenUser?.firstName, hiddenUser?.lastName);
  const shortEditedUser = getShortName(editedUser?.firstName, editedUser?.lastName);

  const handleUpdate = () => {
    if (onUpdate) {
      onUpdate(id);
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Grid container wrap='nowrap' sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
        <Grid item>
          <UserAvatar sx={{ marginRight: '5px' }} size='s' user={author} />
        </Grid>

        <Grid item sx={{ flex: 1, minWidth: 0 }}>
          <TruncatedTypography variant='subtitle2' sx={{ color: '#808080' }}>
            {getFullName(author)}
          </TruncatedTypography>
        </Grid>

        <Grid item sx={{ marginLeft: '8px' }}>
          <Tooltip disableInteractive PopperProps={{ disablePortal: true }} title={formatDate(createdDate)}>
            <TruncatedTypography
              variant='body2'
              sx={{
                cursor: 'default',
                color: '#868686',
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              {formatDistanceToNow(createdDate, i18n.language as Language, t)}
            </TruncatedTypography>
          </Tooltip>
        </Grid>
        {!hidden && canUpdateComment && (
          <Grid item sx={{ marginLeft: '8px' }}>
            <Grid container sx={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <Button onClick={handleUpdate} sx={{ minWidth: 24, height: 24 }}>
                  <Icon icon='edit' sx={{ width: 18 }} />
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleRemove} sx={{ minWidth: 24, height: 24 }}>
                  <Icon icon='trash' sx={{ width: 18 }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Stack sx={{ padding: '8px', borderRadius: '8px', background: hidden ? '#edc8c8' : '#F6F6F6' }}>
        {imageFormats && !hidden && (
          <Item
            cropped
            caption={captionContent(comment)}
            original={imageFormats.originalSizeImage.url}
            thumbnail={imageFormats.originalSizeImage.url}
            width={imageFormats.originalSizeImage.width}
            height={imageFormats.originalSizeImage.height}
          >
            {({ ref, open }) => (
              <FallbackImage
                sx={{
                  cursor: 'zoom-in',
                  marginBottom: '8px',
                  height: '150px',
                  maxHeight: '150px',
                  borderRadius: '8px',
                  objectFit: 'contain',
                  width: 'fit-content',
                }}
                src={imageFormats.thumbnailSizeImage.url}
                alt={imageFormats.thumbnailSizeImage.name}
                mime={imageFormats.thumbnailSizeImage.mime}
                name={imageFormats.thumbnailSizeImage.name}
                url={imageFormats.thumbnailSizeImage.url}
                ref={ref as Ref<HTMLImageElement>}
                onClick={open}
                aria-hidden='true'
              />
            )}
          </Item>
        )}
        <Typography variant={hidden ? 'caption' : 'body2'} sx={{ whiteSpace: 'pre-line' }}>
          <Anchorme target='_blank' rel='noreferrer noopener' truncate={TRUNCATE_URL_LENGTH}>
            { hidden ? `${t('remove-comment')}, ${shortHiddenUser} ${formatDate(hiddenDate)}` : body }
          </Anchorme>

        </Typography>
        {edited && !hidden && (
          <Grid container sx={{ justifyContent: 'flex-end', marginTop: '8px' }}>
            <Typography variant='date'>
              <Trans i18nKey='edited-comment' />
              {', '}
              {shortEditedUser}
              {formatDate(editedDate)}
            </Typography>
          </Grid>
        )}

      </Stack>

    </Stack>
  );
};

export default memo(UserComment);
