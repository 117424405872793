import type { RecoilTaskInterface } from 'recoil-toolkit';
import { updateObj } from 'recoil-toolkit';

import { commentsService } from '../../api';
import { getTask, tasksState, TaskType } from '../../modules/Tasks/state/tasks';
import type { NewComment, Incident } from '../../types';
import { getIncident, incidentsState } from '../incidents';
import { userState } from '../user';

export const addCommentTask = (rti: RecoilTaskInterface) => async (newComment: NewComment) => {
  const { set, snapshot } = rti;
  const user = snapshot.getLoadable(userState).getValue();
  const responseData = await commentsService.addComment(newComment, user.id);
  const incident = snapshot.getLoadable(getIncident(newComment.incident)).getValue();
  const task = snapshot.getLoadable(getTask(newComment.incident)).getValue();
  const oldComments = incident?.comments ?? [];

  if (task && task.type !== TaskType.Incident) {
    set(tasksState, (tasks) => ({
      ...tasks,
      [task.id]: {
        ...tasks[task.id],
        comments: [...tasks[task.id].comments, responseData.data],
      },
    }));
  } else {
    set(
      incidentsState,
      updateObj<Incident>({ comments: [...oldComments, responseData.data] }, { id: newComment.incident }),
    );
  }
};

export const removeCommentTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async (id: number, incidentId: number) => {
    const user = snapshot.getLoadable(userState).getValue();

    const { data: comment } = await commentsService.removeComment(id, incidentId, user.id);

    const currentIncident = snapshot.getLoadable(getIncident(incidentId)).getValue();
    const task = snapshot.getLoadable(getTask(incidentId)).getValue();
    const updatedComments = (task?.comments || currentIncident?.comments || []).map((item) => {
      if (item.id === comment.id) {
        return {
          ...item,
          ...comment,
        };
      }

      return item;
    });

    if (task && task.type !== TaskType.Incident) {
      set(tasksState, (tasks) => ({
        ...tasks,
        [task.id]: {
          ...tasks[task.id],
          comments: updatedComments,
        },
      }));
    } else {
      set(incidentsState, updateObj<Incident>({ comments: updatedComments }, { id: incidentId }));
    }
  };

export const updateCommentTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async (id: number, updatedComment: NewComment) => {
    const user = snapshot.getLoadable(userState).getValue();
    const { data: comment } = await commentsService.editComment(id, updatedComment, user.id);

    const currentIncident = snapshot.getLoadable(getIncident(updatedComment.incident)).getValue();
    const task = snapshot.getLoadable(getTask(updatedComment.incident)).getValue();
    const updatedComments = (task?.comments || currentIncident?.comments || []).map((item) => {
      if (item.id === comment.id) {
        return {
          ...item,
          ...comment,
        };
      }

      return item;
    });

    if (task && task.type !== TaskType.Incident) {
      set(tasksState, (tasks) => ({
        ...tasks,
        [task.id]: {
          ...tasks[task.id],
          comments: updatedComments,
        },
      }));
    } else {
      set(incidentsState, updateObj<Incident>({ comments: updatedComments }, { id: updatedComment.incident }));
    }
  };
