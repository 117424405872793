import {
  type LatLng,
  type LeafletEventHandlerFn,
  type LeafletMouseEventHandlerFn,
  type Marker,
  type MarkerOptions,
} from 'leaflet';
import { useMapEvents } from 'react-leaflet';

import type { IncidentMapDataProviderApi } from '../model/IncidentMapProvider';
import type { IncidentMarker, MetaData } from './types';

interface ExtendedMarkerOptions extends MarkerOptions {
  'data-metadata': MetaData;
}

export function useNormalizeLeafletMapData(data: IncidentMapDataProviderApi) {
  const { onDraggableMarker, onClusterClick, ...rest } = data;

  // Need create as component, because Leaflet use Context as MapContainer
  const Config = () => {
    const map = useMapEvents({});

    // Remove Ukranian flag in copyright
    map.attributionControl.setPrefix('');

    return null;
  };

  const handleDraggableMarker =
    (incident: IncidentMarker): LeafletEventHandlerFn =>
    (event) => {
      const updatedEvent = event as unknown as { latlng: LatLng };
      onDraggableMarker(incident.metadata.id, {
        longitude: updatedEvent.latlng.lng,
        latitude: updatedEvent.latlng.lat,
      });
    };

  const handleClickCluster: LeafletMouseEventHandlerFn = (event) => {
    const markersInCluster = event.propagatedFrom.getAllChildMarkers().map((marker: Marker) => {
      const updateMarkerOptions = marker.options as ExtendedMarkerOptions;
      return updateMarkerOptions['data-metadata'];
    });

    onClusterClick(markersInCluster)();
  };

  return {
    ...rest,
    Config,
    onDraggableMarker: handleDraggableMarker,
    onClusterClick: handleClickCluster,
  };
}
