import { TextField, type TextFieldProps } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

export type TextFieldFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = TextFieldProps & UseControllerProps<TFieldValues, TName>;

export const TextFieldField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: TextFieldFieldProps<TFieldValues, TName>,
) => {
  const { field } = useController(props);
  return <TextField {...props} {...field} />;
};
