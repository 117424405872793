import type { HTMLAttributes } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, Typography } from '@mui/material';

type AutocompletePlaceOptionProps<T extends object = { label: string; secondaryText: string }> = {
  props: HTMLAttributes<HTMLLIElement>;
  option: T;
};

export const AutocompletePlaceOption = ({ props, option }: AutocompletePlaceOptionProps) => (
  <li {...props}>
    <Grid container alignItems='center' flexWrap='nowrap'>
      <Grid item sx={{ display: 'flex', width: '24px' }}>
        <LocationOnIcon sx={{ color: 'text.secondary' }} />
      </Grid>
      <Grid item sx={{ marginLeft: 2, wordWrap: 'break-word' }}>
        <Box component='span' sx={{ fontWeight: 'bold' }}>
          {option.label}
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          {option.secondaryText}
        </Typography>
      </Grid>
    </Grid>
  </li>
);
