import { useRecoilValue } from 'recoil';

import { currentEventIdState } from '../state/event';
import type { User } from '../types';
import findCurrentUserEvent from '../utils/findCurrentUserEvent';

const useCurrentUserEvent = (user?: User) => {
  const currentEventId = useRecoilValue(currentEventIdState);
  return currentEventId && user ? findCurrentUserEvent(currentEventId)(user.userEvents) : undefined;
};

export default useCurrentUserEvent;
