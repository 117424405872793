import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Typography } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { locationsState } from '../../../../state/locations';
import type { Incident, Location } from '../../../../types';
import { TableSelect } from '../../../base';

const Item = ({ title }: Location) => <Typography component='span'>{title}</Typography>;

const LocationEditor = forwardRef((props: ICellEditorParams, ref) => {
  const incident: Incident = props.data;
  const locations = useRecoilValue(locationsState);
  const [currentLocation, setCurrentLocation] = useState<Location | null>();

  const onSelect = (location: Location | null) => {
    setCurrentLocation(location);
  };

  useEffect(() => {
    if (currentLocation !== undefined) {
      props.stopEditing();
    }
  }, [currentLocation, props]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return currentLocation !== undefined ? currentLocation : incident.location;
    },
  }));

  return (
    <TableSelect
      data={locations}
      value={incident.location}
      onSelect={onSelect}
      itemRenderer={Item}
      getOptionLabel={(item) => item.title}
    />
  );
});

export default LocationEditor;
