import { useTranslation } from 'react-i18next';

import { CheckboxField, type CheckboxFieldProps } from '../../fields/CheckboxField';
import type { FormIncidentsFilter } from '../lib/types';

type IncidentUrgentFieldProps = Omit<CheckboxFieldProps<FormIncidentsFilter, 'isAssignedToMe'>, 'name' | 'label'>;

export const IncidentUrgentField = (props: IncidentUrgentFieldProps) => {
  const { t } = useTranslation();

  return <CheckboxField {...props} name='incidentIsUrgent' label={t('incident-is-urgent')} />;
};
