import type { ChangeEvent} from 'react';
import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import { CustomCheckbox } from '../../../base';
import { CheckboxType } from '../../../base/CustomCheckbox';
import { cellСanBeEdited } from '../../utils';

const UrgentRenderer = (props: ICellRendererParams) => {
  const {
    value,
    column,
    node,
  } = props;
  const columnId = column?.getColId();
  const { t } = useTranslation();
  const { showConfirm, confirmModal } = useConfirmDialog({ content: t('set-priority-incident-to-urgent') });

  const checkedHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!columnId) {
      return;
    }

    if (checked === false) {
      node.setDataValue(columnId, checked);
    } else {
      showConfirm().then((isOk) => {
        if (isOk) {
          node.setDataValue(columnId, checked);
        }
      });
    }
  };

  return (
    <>
      <CustomCheckbox
        disabled={!cellСanBeEdited(props)}
        sx={{ padding: 0 }}
        checked={value}
        type={CheckboxType.EXCLAMATION}
        onChange={checkedHandler}
      />
      {confirmModal}
    </>
  );
};

export default memo(UrgentRenderer);
