import { atom, selector } from 'recoil';

import type { Role } from '../../types';

const defaultRoles = ['authenticated', 'public', 'owner'];

export const rolesState = atom<Role[]>({
  key: 'roles',
  default: [],
});

export const userRolesState = selector<Role[]>({
  key: 'userRoles',
  get: ({ get }) => {
    const roles = get(rolesState);

    return roles.filter(({ type }) => !defaultRoles.includes(type));
  },
});
