import type { ReactNode } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

export type ButtonGroupFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  options: { value: string; children: ReactNode }[];
  disabled?: boolean;
  exclusive?: boolean;
} & UseControllerProps<TFieldValues, TName>;

export const ButtonGroupField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: ButtonGroupFieldProps<TFieldValues, TName>,
) => {
  const { options } = props;
  const { field } = useController(props);
  return (
    <ToggleButtonGroup {...props} {...field} onChange={(_, value) => field.onChange(value)}>
      {options.map(({ value, children }) => (
        <ToggleButton key={value} value={value}>
          {children}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
