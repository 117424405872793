import { ResolutionLevel, Status } from '../../../../types';

export const DEFAULT_PREPARED_TASK = {
  title: '',
  description: '',
  comments: [],
  status: Status.REGISTERED,
  resolutionLevel: ResolutionLevel.GREEN,
};

export const NON_EDITABLE_FIELDS = ['count', 'resolvedDate'];

export const NON_EDITABLE_ON_ADD_FIELDS = ['comments', 'status'];

export const DEFAULT_SORT = [
  {
    colId: 'endDatetime',
    sort: 'asc' as const,
  },
];
