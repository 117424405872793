import { useRecoilTask } from 'recoil-toolkit';
import type { RecoilTaskInterface } from 'recoil-toolkit';

import { eventSettingService, userEventService } from '../../../api';
import { getUsersTask } from '../../../state/users';
import type { EventSettings, User } from '../../../types';
import { type UserEvent } from '../../../types';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

const updateUserEventSettings =
  (rti: RecoilTaskInterface) =>
  async ({
    user,
    userEvent,
    data: { permissions, filters },
  }: {
    user: User;
    userEvent: UserEvent;
    data: UserEventSettingsInitialValues;
  }) => {
    const eventSettings = user.eventSettings.find((item) => item.event.id === userEvent.event.id) as EventSettings;

    await userEventService.edit({
      id: userEvent.id,
      function: userEvent.function.id,
      permissions: {
        canViewMaps: permissions.canViewMaps,
        canViewDashboard: permissions.canViewDashboard,
      },
    });
    await eventSettingService.update({
      id: eventSettings.id,
      customUserFilters: {
        ...eventSettings?.customUserFilters,
        resolutionLevel: filters.resolutionLevel?.length !== 0 ? filters.resolutionLevel : undefined,
        userFunctions: filters.userFunctions?.length !== 0 ? filters.userFunctions?.map((item) => item.id) : undefined,
        locations: filters.locations?.length !== 0 ? filters.locations?.map((item) => item.id) : undefined,
        responsible: filters.responsible?.length !== 0 ? filters.responsible?.map((item) => item.id) : undefined,
        participants: filters.participants?.length !== 0 ? filters.participants?.map((item) => item.id) : undefined,
        registeredBy: filters.registeredBy?.length !== 0 ? filters.registeredBy?.map((item) => item.id) : undefined,
        onlyOwnIncidents: filters.onlyOwnIncidents ? true : undefined,
      },
    });
    await getUsersTask(rti)();
  };

export const useUpdateUserEventSettings = () =>
  useRecoilTask(updateUserEventSettings, [], {
    loaderStack: 'updateUserEventSettings',
    errorStack: true,
  });
