import { FormControl, FormGroup, FormLabel, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useDrawer from '../../hooks/useDrawer';
import { projectTasksFiltersState } from '../../modules/Tasks/state/projectTasks';
import { userEventSettings, userState } from '../../state/user';
import { userFunctionsState } from '../../state/userFunctions';
import { usersState } from '../../state/users';
import { Button, DrawerContainer } from '../base';
import { AssignedToMeField } from './components/AssignedToMeField';
import { EndDateField } from './components/EndDateField';
import { ParticipantsField } from './components/ParticipantsField';
import { ResolutionLevelField } from './components/ResolutionLevelField';
import { ResponsibleField } from './components/ResponsibleField';
import { StartDateField } from './components/StartDateField';
import { StatusField } from './components/StatusField';
import { TypeDateField } from './components/TypeDateField';
import { UserFunctionField } from './components/UserFunctionField';
import type { ProjectTasksFormFilerValue } from './lib/types';
import { useDefaultFilters } from './lib/useDefaultFilters';
import { useGetProjectTaskByFilters } from './model/getProjectTaskByFilters';
import { useUpdateProjectTaskDefaultFilter } from './model/updateProjectTaskDefaultFilter';

export const FilterFormProjectTasks = () => {
  const { t } = useTranslation();
  const users = useRecoilValue(usersState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const currentUser = useRecoilValue(userState);
  const userEventSetting = useRecoilValue(userEventSettings);
  const { execute: onGetProjectTaskByFilters } = useGetProjectTaskByFilters();
  const { execute: onUpdateProjectTaskDefaultFilter } = useUpdateProjectTaskDefaultFilter();
  const defaultFiltersState = useRecoilValue(projectTasksFiltersState);
  const initialValues = useDefaultFilters(defaultFiltersState);
  const defaultValues = useDefaultFilters(userEventSetting.projectTaskDefaultFilters || defaultFiltersState);
  const { closeDrawer } = useDrawer();

  const { handleSubmit, control, reset, getValues, resetField } = useForm<ProjectTasksFormFilerValue>({
    defaultValues: initialValues,
  });

  const clearFilters = () => {
    reset({
      startDate: null,
      endDate: null,
      responsible: [],
      userFunctions: [],
      status: [],
      participants: [],
      typeDate: 'registeredDate',
      resolutionLevel: [],
      isAssignedToMe: false,
    });
  };

  const loadDefaultFilter = () => {
    reset({
      ...defaultValues,
    });
  };

  const updateDefaultFilter = () => {
    onUpdateProjectTaskDefaultFilter(getValues());
  };

  const onSubmit = (values: ProjectTasksFormFilerValue) => {
    onGetProjectTaskByFilters(values);
    closeDrawer();
  };

  const handleChangeAssignedToMe = (checked: boolean) => {
    resetField('responsible', { defaultValue: currentUser && checked ? [currentUser] : [] });
  };

  const handleChangeResponsible = () => {
    if (getValues('isAssignedToMe')) {
      resetField('isAssignedToMe', { defaultValue: false });
    }
  };

  return (
    <DrawerContainer component='form' onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Typography variant='h4'>{t('filters')}</Typography>
          <Button variant='text' onClick={clearFilters}>
            {t('clear-filters')}
          </Button>
        </Stack>

        <FormControl>
          <FormLabel sx={{ marginBottom: '8px' }}>{t('date-range')}</FormLabel>
          <Stack spacing={2}>
            <Stack>
              <TypeDateField control={control} />
            </Stack>
            <Stack direction='row' spacing={2}>
              <StartDateField control={control} />
              <EndDateField control={control} />
            </Stack>
          </Stack>
        </FormControl>

        <FormControl>
          <FormGroup>
            <AssignedToMeField control={control} onChange={handleChangeAssignedToMe} />
          </FormGroup>
        </FormControl>

        <FormControl>
          <FormLabel>{t('resolution-level')}</FormLabel>
          <ResolutionLevelField control={control} />
        </FormControl>

        <FormControl>
          <FormLabel>{t('status')}</FormLabel>
          <StatusField control={control} />
        </FormControl>

        <ParticipantsField control={control} options={users} />

        <ResponsibleField control={control} options={users} onChange={handleChangeResponsible} />

        <UserFunctionField control={control} options={userFunctions} />
      </Stack>

      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Button fullWidth variant='outlined' onClick={loadDefaultFilter}>
            {t('load-default-filters')}
          </Button>
          <Button fullWidth variant='outlined' onClick={updateDefaultFilter}>
            {t('set-selection-as-default')}
          </Button>
        </Stack>
        <Button type='submit' sx={{ marginTop: 'auto', color: 'white' }} variant='contained'>
          {t('show-incidents')}
        </Button>
      </Stack>
    </DrawerContainer>
  );
};
