import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { taskService } from '../../../api';
import { getProjectTasks } from '../../../state/projectTasks/tasks';
import type { ProjectTaskFormInitialValues } from '../lib/types';

interface UpdateProjectTaskFormProps {
  id: number;
  values: ProjectTaskFormInitialValues;
  successCallback: () => void;
}

const updateProjectTaskForm = (rti: RecoilTaskInterface) => async (props: UpdateProjectTaskFormProps) => {
  const { id, values, successCallback } = props;
  await taskService.editTask(id, {
    startDatetime: values.startDatetime ?? null,
    endDatetime: values.endDatetime ?? null,
    title: values.title,
    description: values.description,
    status: values.status,
    resolutionLevel: values.resolutionLevel,
    responsible: values.responsible?.id ?? null,
    participants: values.participants.map((item) => item?.id),
    userFunction: values.userFunction?.id ?? null,
    relation: values.relation[0]?.id ?? null,
  });

  await getProjectTasks(rti)();
  successCallback();
};

export const useUpdateProjectTaskForm = () =>
  useRecoilTask(updateProjectTaskForm, [], {
    loaderStack: 'updateProjectTaskForm',
    errorStack: true,
  });
