import { useCallback } from 'react';

import type { GridReadyFn, SortState } from './types';

type Props = {
  onGridReady: GridReadyFn,
  defaultSort: SortState
};

export const useDefaultGridSort = ({ onGridReady, defaultSort }: Props) => {
  const onGridReadyHandler: GridReadyFn = useCallback((event) => {
    onGridReady(event);
    const { columnApi } = event;
    columnApi.applyColumnState({
      state: defaultSort,
    });
  }, [onGridReady, defaultSort]);

  return {
    onGridReady: onGridReadyHandler,
  };
};
