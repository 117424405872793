import { useCallback, useMemo, useRef } from 'react';

import type { GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';

import {
  HEADER_HEIGHT, ROW_HEIGHT, TOOLTIP_HIDE_DELAY, TOOLTIP_SHOW_DELAY,
} from './constants';

export function useDefaultGridSettings() {
  const gridRef = useRef<AgGridReact>(null);
  const getRowId = useMemo(() => (params: GetRowIdParams) => params.data.id, []);

  const onGridReadyHandler = useCallback((event: GridReadyEvent) => {
    event.api.showLoadingOverlay();
  }, []);

  return {
    ref: gridRef,
    getRowId,
    onGridReady: onGridReadyHandler,
    rowSelection: 'multiple' as const,
    headerHeight: HEADER_HEIGHT,
    rowHeight: ROW_HEIGHT,
    tooltipShowDelay: TOOLTIP_SHOW_DELAY,
    tooltipHideDelay: TOOLTIP_HIDE_DELAY,
    suppressColumnMoveAnimation: true,
    suppressAnimationFrame: true,
    suppressColumnVirtualisation: true,
    suppressRowVirtualisation: true,
    stopEditingWhenCellsLoseFocus: true,
  };
}
