import type { Incident } from '../../../../../types';
import type { ProjectTask } from '../types';

type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

function getAllParentsTasks(projectTask: ProjectTask): ProjectTask[] {
  const result = [];
  const { parent } = projectTask;

  if (parent) {
    const currentParent = parent as unknown as ProjectTask;
    result.push(currentParent);
    result.push(...getAllParentsTasks(currentParent));
  }

  return result;
}

export function createTree(projectTasks: ProjectTask[]) {
  const hash: { [key: number]: RequiredFields<ProjectTask, 'children'> } = {};
  const updatedProjectTasks = [...projectTasks].reduce<ProjectTask[]>((acc, projectTask) => {
    // This is to show in the tree, the correct hierarchy of elements, that used filters.
    if (projectTask.parent) {
      const parents = getAllParentsTasks(projectTask);
      parents.forEach((parent) => {
        if (!acc.find((item) => item.id === parent.id)) {
          acc.push(parent);
        }
      });
    }

    if (!acc.find((item) => item.id === projectTask.id)) {
      acc.push(projectTask);
    }

    return acc;
  }, []);

  updatedProjectTasks.forEach((projectTask) => {
    hash[projectTask.id] = { ...projectTask, children: [] };
  });

  const dataTree: ProjectTask[] = [];
  updatedProjectTasks.forEach((projectTask) => {
    if (projectTask.parent) hash[projectTask.parent.id].children.push(hash[projectTask.id] as unknown as Incident);
    else dataTree.push(hash[projectTask.id]);
  });

  return dataTree;
}
