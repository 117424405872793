import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';

import { useTooltip, Tooltip } from '../../../../modules/EntitiesGrid';
import { ClampedTypography } from '../../../base';

const TextRenderer = ({ value }: ICellRendererParams) => {
  const { ref, tooltipProps } = useTooltip();

  return (
    <Tooltip title={value} {...tooltipProps}>
      <ClampedTypography ref={ref} variant='body2' lineclamp={5}>
        {value}
      </ClampedTypography>
    </Tooltip>
  );
};

export default memo(TextRenderer);
