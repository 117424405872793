import { TextField } from '@mui/material';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { locationsState } from '../../../state/locations';
import { AutocompleteMultiple } from '../../AutocompleteMultiple';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

type LocationsFieldProps = {
  form: UseFormReturn<UserEventSettingsInitialValues>;
};

export const LocationsField = ({ form }: LocationsFieldProps) => {
  const { t } = useTranslation();
  const locations = useRecoilValue(locationsState);
  return (
    <Controller
      name='filters.locations'
      control={form.control}
      render={({ field }) => (
        <AutocompleteMultiple
          {...field}
          disableCloseOnSelect
          options={locations}
          limitTags={4}
          filterSelectedOptions
          onChange={(_, values) => field.onChange(values === 'all' ? locations : values)}
          getOptionLabel={(location) => location.title}
          renderInput={(params) => <TextField {...params} label={t('location')} />}
          renderOption={(props, location) => <li {...props}>{location.title}</li>}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      )}
    />
  );
};
