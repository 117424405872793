import { useState } from 'react';

import { ListItem, ListItemButton, Stack, ListItemIcon, ListItemText, List, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';

import { Button, Checkbox, DrawerContainer } from '../../../../components/base';
import useDrawer from '../../../../hooks/useDrawer';
import { currentEventSettings } from '../../../../state/event';
import type { ColumnsSettings, ColumnValue } from '../../state';

type GridColumnsSettingsDrawerProps<T extends string> = {
  setColumns: (newColumns: ColumnsSettings<T>) => void;
  columnsState: RecoilState<ColumnsSettings<T>>;
  columnsNameTexts: Record<T, string>;
  disabledColumns?: string[];
};

export const GridColumnsSettingsDrawer = <T extends string>({
  setColumns,
  columnsState,
  columnsNameTexts,
  disabledColumns = [],
}: GridColumnsSettingsDrawerProps<T>) => {
  const columns = useRecoilValue(columnsState);
  const [currentColumns, setCurrentColumns] = useState(columns);
  const { availableIncidentPlan } = useRecoilValue(currentEventSettings);
  const { closeDrawer } = useDrawer();

  const setColumnHandler = (field: T) => {
    setCurrentColumns((old) => ({
      ...old,
      [field]: { ...old[field], isShow: !old[field].isShow },
    }));
  };

  const updateColumnsStateHandler = () => {
    setColumns(currentColumns);
    closeDrawer();
  };

  return (
    <DrawerContainer>
      <Stack spacing={2}>
        <Typography variant='h4'>
          <Trans i18nKey='columns' />
        </Typography>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {Object.entries(currentColumns)
            .filter((item) => {
              if (item[0] === 'relation' && !availableIncidentPlan) {
                return false;
              }
              return true;
            })
            .map(([field, props]) => {
              const columnSettings = props as ColumnValue;
              const column = field as T;
              const fieldText = columnsNameTexts[column];
              const isDisabledField = disabledColumns.includes(column);

              return (
                <ListItem key={field} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => setColumnHandler(field as T)}
                    dense
                    disabled={isDisabledField}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={columnSettings.isShow}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': field }}
                      />
                    </ListItemIcon>
                    <ListItemText id={field} primary={fieldText} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Stack>

      <Button sx={{ marginTop: 'auto', color: 'white' }} variant='contained' onClick={updateColumnsStateHandler}>
        <Trans i18nKey='apply-settings' />
      </Button>
    </DrawerContainer>
  );
};
