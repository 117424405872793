import { useTranslation } from 'react-i18next';

import { LANGUAGES, USED_DEVICE } from '../../constants/base';
import { NotificationType, useShowNotification } from '../../state/notification';
import { useAddUser } from '../../state/user';
import type { Language } from '../../types';

export type UserFormValues = {
  firstName: string;
  lastName: string;
  language: {
    name: string;
    value: Language;
    id: number;
  };
  usedDevice: {
    name: string;
    value: string;
    id: number;
  };
}

const useUserCreate = () => {
  const { execute: addUser, loading } = useAddUser();
  const { t } = useTranslation();

  const { execute: showNotification } = useShowNotification();
  const onSubmit = (
    {
      language,
      usedDevice,
      ...values
    }: UserFormValues & { email: string },
    onComplete: ({ user }: {user: {id: number }}) => void,
  ) => addUser({
    newUser: {
      language: language.value,
      usedDevice: usedDevice.value,
      ...values,
    },
    successCallback: (data) => {
      showNotification({ type: NotificationType.SUCCESS, text: t('user-create-success-message') });
      onComplete(data);
    },
  });
  return {
    onSubmit,
    loading,
    title: t('create-user'),
    defaultValues: {
      firstName: '',
      lastName: '',
      language: LANGUAGES[0],
      usedDevice: USED_DEVICE[0],
    },
  };
};

export default useUserCreate;
