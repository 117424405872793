import type { RecoilTaskInterface } from 'recoil-toolkit';

import { userEventService, userService } from '../../api';
import { dailyTasksFiltersState } from '../../modules/Tasks/state/dailyTasks';
import { projectTasksFiltersState } from '../../modules/Tasks/state/projectTasks';
import type { NewUser, NewUserEvent, User, UserUpdate } from '../../types';
import { RolesEnum } from '../../types';
import { currentEventIdState, eventState } from '../event/atoms';
import { incidentsFilterState } from '../incidentsFilter';
import { rolesState } from '../roles';
import { getUsersTask } from '../users';
import { userByEmailState, userState } from './atoms';
import type { UpdateFlaggedIncidentsTaskParams } from './types';

export const getCurrentUserTask = (recoilTaskEvent: RecoilTaskInterface) => async () => {
  const { set } = recoilTaskEvent;
  const user = await userService.getMe();
  const currentEventId: number | null = user.currentEvent?.id || null;
  const currentEventSettings = user.eventSettings.find((item) => item.event?.id === currentEventId);

  set(currentEventIdState, currentEventId);
  set(userState, user);

  if (currentEventSettings?.defaultDesktopFilters) {
    set(incidentsFilterState, currentEventSettings.defaultDesktopFilters);
  }

  if (currentEventSettings?.projectTaskDefaultFilters) {
    set(projectTasksFiltersState, currentEventSettings.projectTaskDefaultFilters);
  }

  if (currentEventSettings?.dailyTaskDefaultFilter) {
    set(dailyTasksFiltersState, currentEventSettings.dailyTaskDefaultFilter);
  }
};

export const addUserTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (params: {
    newUser: NewUser & { usedDevice: string };
    successCallback: (user: { user: { id: number } }) => void;
  }) => {
    const {
      newUser: { email, lastName, firstName, language, usedDevice },
      successCallback,
    } = params;
    const roles = snapshot.getLoadable(rolesState).getValue();
    const currentEventId = snapshot.getLoadable(currentEventIdState).getValue();
    const defaultRole = roles.find((role) => role.type === RolesEnum.AUTHENTICATED);

    if (defaultRole && currentEventId) {
      return userService
        .register({
          data: {
            email,
            lastName,
            firstName,
            language,
            currentEvent: currentEventId,
            role: defaultRole.id,
            isMobile: usedDevice === 'mobile',
          },
        })
        .then(successCallback);
    }
    throw new Error('Default role not exists');
  };

export const addUserEventTask = (recoilTaskEvent: RecoilTaskInterface) => async (params: NewUserEvent) => {
  await userEventService.add(params);

  return getUsersTask(recoilTaskEvent)();
};

export const deleteUserEventTask = (recoilTaskEvent: RecoilTaskInterface) => async (id: number) => {
  await userEventService.delete(id);

  return getUsersTask(recoilTaskEvent)();
};

export const editUserEventTask =
  (recoilTaskEvent: RecoilTaskInterface) => async (params: { id: number; function: number; role: RolesEnum }) => {
    await userEventService.edit(params);

    return getUsersTask(recoilTaskEvent)();
  };

export const updateUserTask =
  (recoilTaskEvent: RecoilTaskInterface) =>
  async (params: { id: number; data: UserUpdate; successCallback: () => void }) => {
    const { id, data, successCallback } = params;

    await userService.update({
      id,
      data,
    });
    await getUsersTask(recoilTaskEvent)();

    successCallback?.();
  };

export const updateFlaggedIncidentsTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async ({ incidentId, isFlagged }: UpdateFlaggedIncidentsTaskParams) => {
    const { id, flaggedIncidents } = snapshot.getLoadable(userState).getValue();

    const newFlaggedIncidents = isFlagged
      ? [...flaggedIncidents, { id: incidentId }]
      : flaggedIncidents.filter((incident) => incident.id !== incidentId);

    await userService.update({
      id,
      data: { flaggedIncidents: newFlaggedIncidents.map((item) => item.id) },
    });

    set(userState, (state) => ({ ...state, flaggedIncidents: newFlaggedIncidents }));
  };

export const updateCurrentEventTask =
  (recoilTaskEvent: RecoilTaskInterface) => async (params: { eventId: number; successCallback: () => void }) => {
    const { eventId, successCallback } = params;
    const user = recoilTaskEvent.snapshot.getLoadable(userState).getValue();

    await userService.update({
      id: user.id,
      data: {
        currentEvent: eventId,
      },
    });

    await getUsersTask(recoilTaskEvent)();

    successCallback?.();
  };

export const updateLanguageTask = (recoilTaskEvent: RecoilTaskInterface) => async (language: User['language']) => {
  const user = recoilTaskEvent.snapshot.getLoadable(userState).getValue();

  await userService.changeLanguage({
    id: user.id,
    language,
  });
  await getCurrentUserTask(recoilTaskEvent)();
};
export const findUserByEmailTask =
  ({ set }: RecoilTaskInterface) =>
  async (email: string) => {
    const [user] = await userService.findUserByEmail({ email });

    set(userByEmailState, user);
  };

export const resetPasswordTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (params: { id: number; successCallback: () => void }) => {
    const { id, successCallback } = params;
    const event = snapshot.getLoadable(eventState).getValue();

    await userService.resendRegistrationEmail({ id, event: event.id });
    successCallback?.();
  };

export const updateSendNotificationTask =
  (recoilTaskEvent: RecoilTaskInterface) => async (notificationSettings: Partial<User['notificationSettings']>) => {
    const user = recoilTaskEvent.snapshot.getLoadable(userState).getValue();
    const currentNotificationSettings = user.notificationSettings ?? { email: false, push: false };

    await userService.changeSendNotification({
      id: user.id,
      notificationSettings: {
        ...currentNotificationSettings,
        ...notificationSettings,
      },
    });
    await getCurrentUserTask(recoilTaskEvent)();
  };
