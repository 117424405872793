import type { RecoilTaskInterface } from 'recoil-toolkit';

import { drawerState } from './atoms';
import type { DrawerType } from './types';

export const showDrawerTask = ({ set }: RecoilTaskInterface) => (drawer: DrawerType) => {
  set(drawerState, { isOpen: true, drawer });
};

export const closeDrawerTask = ({ set }: RecoilTaskInterface) => () => {
  set(drawerState, (state) => ({ ...state, isOpen: false }));
};
