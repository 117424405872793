import type { ReactNode } from 'react';

import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { incidentsState } from '../../../../../state/incidents';
import { locationsState } from '../../../../../state/locations';
import type { Incident, Location } from '../../../../../types';
import type { MapForm, MetaData } from '../lib/types';

type ReturnMapFormIncident =
  | {
      type: 'cluster';
      data: {
        incidenstT: ReactNode;
        incidentsInCluster: Incident[];
      };
    }
  | {
      type: 'incident';
      data: {
        id: MetaData['id'];
      };
    }
  | {
      type: 'location';
      data: {
        location: Location;
        incidenstT: ReactNode;
        incidentsInLocation: Incident[];
      };
    };

function isClusterMarker(state: MapForm): state is MetaData[] {
  return Array.isArray(state);
}

export function useMapFormIncident(mapForm: MapForm): ReturnMapFormIncident | null {
  const incidents = useRecoilValue(incidentsState);
  const locations = useRecoilValue(locationsState);
  const incidenstT = <Trans i18nKey='incidents' />;

  if (isClusterMarker(mapForm)) {
    const incidentsInCluster = incidents.filter(({ id }) => mapForm.find((marker) => marker.id === id));

    return {
      type: 'cluster',
      data: {
        incidenstT,
        incidentsInCluster,
      },
    } as const;
  }

  if (mapForm?.type === 'incident') {
    return {
      type: 'incident',
      data: {
        id: mapForm.id,
      },
    } as const;
  }

  if (mapForm?.type === 'location') {
    const location = locations.find(({ id }) => mapForm.id === id);

    if (location) {
      const incidentsInLocation = incidents.filter((incident) => incident.location?.id === location.id);

      return {
        type: 'location',
        data: {
          location,
          incidenstT,
          incidentsInLocation,
        },
      } as const;
    }
  }

  return null;
}
