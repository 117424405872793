import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Chip } from '@mui/material';
import { t } from 'i18next';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { TaskType } from '../../modules/Tasks/state/tasks';
import type { Incident } from '../../types';
import type { AutocompleteMultipleProps } from '../AutocompleteMultiple';
import { AutocompleteMultipleField } from './AutocompleteMultipleField';

function getUrlByTaskType(task: Incident) {
  switch (task.type) {
    case TaskType.OperationalTask:
      return `/dailyPlan?taskId=${task.id}`;
    case TaskType.ProjectTask:
      return `/projectPlan?taskId=${task.id}`;
    case TaskType.Incident:
      return `/incidents?taskId=${task.id}`;
    default:
      return '';
  }
}

function getLabelByGroups(task: Incident) {
  switch (task.type) {
    case TaskType.OperationalTask:
      return t('daily-plan');
    case TaskType.ProjectTask:
      return t('project-plan');
    default:
      return '';
  }
}

export type RelationsFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options,
> = Omit<
  AutocompleteMultipleProps<Options>,
  'disableCloseOnSelect' | 'filterSelectedOptions' | 'onChange' | 'renderInput' | 'getOptionDisabled'
> &
  UseControllerProps<TFieldValues, TName> & {
    label: string;
    onChange?: (values: Options[]) => void;
    getOptionDisabled?: (value: TFieldValues[]) => boolean;
  };

export const RelationsField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: RelationsFieldProps<TFieldValues, TName, Incident>,
) => {
  const { field } = useController(props);

  return (
    <AutocompleteMultipleField
      {...props}
      {...field}
      getOptionLabel={(task) => `${task.count}: ${task.title}`}
      renderOption={(liProps, task) => <li {...liProps}>{`${task.count}: ${task.title}`}</li>}
      groupBy={(task) => getLabelByGroups(task)}
      getOptionDisabled={(value) => (value ? value.length >= 1 : false)}
      renderTags={(value, getTagProps) =>
        value.map((task, index) => (
          <Chip
            label={`${task.count}: ${task.title}`}
            sx={{
              '.MuiChip-avatar': {
                height: '32px',
                width: '32px',
                marginLeft: '-5px',
                flexShrink: 0,
              },
            }}
            avatar={
              <Box
                component='a'
                href={getUrlByTaskType(task)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#757ce8',
                  borderTopLeftRadius: 'inherit',
                  borderBottomLeftRadius: 'inherit',
                }}
              >
                <LaunchIcon sx={{ width: '16px', height: '16px', color: '#fff' }} />
              </Box>
            }
            {...getTagProps({ index })}
          />
        ))
      }
      disableSelectAll
      disableCloseOnSelect={false}
    />
  );
};
