import type { MouseEvent } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';

import type { ResolutionLevel } from '../../../../types';
import { ResolutionLevelBlock } from '../../../base';

type ResolutionLevelEditorProps = ICellEditorParams & {
  levels: ResolutionLevel[];
};

const ResolutionLevelEditor = forwardRef((props: ResolutionLevelEditorProps, ref) => {
  const { value, stopEditing, levels = [] } = props;
  const cellData: ResolutionLevel = value;
  const [currentLevel, setCurrentLevel] = useState<ResolutionLevel>();

  const handleChange = (_: MouseEvent<HTMLElement>, val: ResolutionLevel | null) => {
    setCurrentLevel(val ?? cellData);
  };

  useEffect(() => {
    if (currentLevel !== undefined) {
      stopEditing();
    }
  }, [currentLevel, stopEditing]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return currentLevel || cellData;
    },
  }));

  return (
    <ToggleButtonGroup value={cellData} orientation='vertical' exclusive onChange={handleChange}>
      {levels.map((level) => (
        <ToggleButton value={level} key={level}>
          <ResolutionLevelBlock sx={{ width: '100%' }} level={level} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
});

export default ResolutionLevelEditor;
