import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { locationsState } from '../../../state/locations';
import { userState } from '../../../state/user';
import { userFunctionsState } from '../../../state/userFunctions';
import { usersState } from '../../../state/users';
import type { UserDesktopFilters } from '../../../types';
import type { FormIncidentsFilter } from '../lib/types';
import { mapUserFiltersToFormFilters } from './utils';

export const useFormatFilters = () => {
  const allUsers = useRecoilValue(usersState);
  const allLocations = useRecoilValue(locationsState);
  const allUserFunctions = useRecoilValue(userFunctionsState);
  const currentUser = useRecoilValue(userState);

  const getFormIncidentFilters = useCallback(
    (filters: UserDesktopFilters) =>
      mapUserFiltersToFormFilters({
        userFilters: filters,
        allLocations,
        allUsers,
        allUserFunctions,
        currentUser,
      }),
    [allUsers, allLocations, allUserFunctions, currentUser],
  );

  const getUserIncidentFilters = useCallback(
    ({
      registeredBy,
      responsible,
      userFunctions,
      locations,
      participants,
      ...otherFilters
    }: FormIncidentsFilter): UserDesktopFilters => ({
      ...otherFilters,
      registeredBy: registeredBy.map((item) => item.id),
      responsible: responsible.map((item) => item.id),
      userFunctions: userFunctions?.map((item) => item.id),
      locations: locations.map((item) => item.id),
      participants: participants.map((item) => item.id),
    }),
    [],
  );

  return {
    getFormIncidentFilters,
    getUserIncidentFilters,
  };
};
