import { createTheme } from '@mui/material';
import type { Localization } from '@mui/material/locale';
import type { enUS } from '@mui/x-date-pickers';

const makeTheme = (locales: Array<Localization | typeof enUS>) => createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C7D1D4',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: '#C7D1D4',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#AA96C8',
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#23A48B',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: 'unset',
    },
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    date: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#181818',
      opacity: 0.4,
    },
    fieldLabel: {
      marginBottom: '8px',
      fontWeight: 500,
      fontSize: '14px',
      display: 'block',
    },
    h1: {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '52px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
}, ...locales);

export default makeTheme;
