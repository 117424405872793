import { useTranslation } from 'react-i18next';

import type { UserFunction } from '../../../types';
import { AutocompleteMultipleField, type AutocompleteMultipleFieldProps } from '../../fields/AutocompleteMultipleField';
import type { ProjectTasksFormFilerValue } from '../lib/types';

export type UserFunctionFieldProps = Omit<
  AutocompleteMultipleFieldProps<ProjectTasksFormFilerValue, 'userFunctions', UserFunction>,
  'name' | 'label' | 'getOptionLabel' | 'renderOption'
>;

export const UserFunctionField = (props: UserFunctionFieldProps) => {
  const { t } = useTranslation();

  return (
    <AutocompleteMultipleField
      {...props}
      name='userFunctions'
      label={t('user-function')}
      getOptionLabel={(userFunction) => userFunction.name}
      renderOption={(liProps, userFunction) => <li {...liProps}>{userFunction.name}</li>}
    />
  );
};
