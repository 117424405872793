import { cloneElement, isValidElement, type ReactNode } from 'react';

function addPropsToReactElement<T extends object>(element: ReactNode, props: T) {
  if (isValidElement(element)) {
    return cloneElement(element, props);
  }
  return element;
}

export function addPropsToChildren<T extends object>(children: ReactNode, props: T) {
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props);
  }
  return children.map((childElement) => addPropsToReactElement(childElement, props));
}
