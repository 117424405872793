import { CRS } from 'leaflet';
import { MapContainer, ImageOverlay, Tooltip } from 'react-leaflet';

import { LocationMarker } from '../../../shared/components/LeafletMap';
import { MapLoader } from '../../../shared/components/MapLoader';
import { useCreateSquareImage } from '../../../shared/hooks/useCreateSquareImage';
import { useNormalizeLeafletMapData } from '../lib/normalizeLeafletMapData';
import { useLocationMap } from '../model/LocationMapDataProvider';

interface LeafletMapPlanProps {
  url: string;
}

export const LeafletMapPlan = (props: LeafletMapPlanProps) => {
  const { url } = props;
  const { image, imageUrl } = useCreateSquareImage({ url });
  const data = useLocationMap();
  const { Config, locations, onMarkerClick, onDraggableMarker } = useNormalizeLeafletMapData(data);

  if (!image || image.width === 0 || image.height === 0 || !imageUrl) {
    return <MapLoader />;
  }

  return (
    <MapContainer
      style={{ height: '100%', width: '100%' }}
      center={[0, 0]}
      minZoom={-3}
      maxZoom={4}
      crs={CRS.Simple}
      zoom={0}
    >
      <Config />
      <ImageOverlay
        url={imageUrl}
        bounds={[
          [-image.width, -image.height],
          [image.width, image.height],
        ]}
      />
      {locations.map((marker) => (
        <LocationMarker
          key={marker.key}
          position={[marker.geo.latitude, marker.geo.longitude]}
          draggable
          eventHandlers={{
            click: onMarkerClick(marker),
            dragend: onDraggableMarker(marker),
          }}
          view='primary'
        >
          <Tooltip>{marker.title}</Tooltip>
        </LocationMarker>
      ))}
    </MapContainer>
  );
};
