import type { ICellEditorParams } from 'ag-grid-community';

import type { CellPermissions } from '../../../../../components/IncidentTable/utils/cellCanBeEdited';
import { Status } from '../../../../../types';

const statusMap: Record<Status, Status[]> = {
  [Status.REGISTERED]: [Status.REGISTERED, Status.RESOLVED],
  [Status.RESOLVED]: [Status.RESOLVED],
  [Status.IN_PROGRESS]: [Status.REGISTERED],
  [Status.DRAFT]: [Status.REGISTERED],
};

export function getStatusForCellStatus(params: ICellEditorParams, permissions: CellPermissions['permissions']) {
  const { data } = params;

  return {
    statuses: permissions?.canUpdateAllIncidents ? statusMap[Status.REGISTERED] : statusMap[data.status as Status],
  };
}
