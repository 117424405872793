import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { incidentService } from '../../../api';
import { tabsDailyTasksState } from '../../../modules/Tasks/state/dailyTasks';
import { getDailyTasks } from '../../../modules/Tasks/state/dailyTasks/tasks';

const removeDailyTab =
  (rti: RecoilTaskInterface) => async (props: { key: string; successCallback: () => Promise<void> }) => {
    const { key, successCallback } = props;
    const { snapshot } = rti;
    const tabs = await snapshot.getLoadable(tabsDailyTasksState).getValue();

    const currentTab = tabs.find((item) => item.key === key);
    if (currentTab && 'tasks' in currentTab) {
      await Promise.all(currentTab.tasks.map((task) => incidentService.deleteTask(task.id.toString())));
      successCallback();
      await getDailyTasks(rti)();
    }
  };

export const useRemoveDailyTab = () =>
  useRecoilTask(removeDailyTab, [], {
    loaderStack: 'removeDailyTab',
    errorStack: true,
  });
