import axios from 'axios';

import { TOKEN_KEY } from '../constants/base';
import { API_URL } from '../constants/env';
import { getAuthToken, removeAuthToken } from '../utils/authUtils';
import jsonDateReviver from '../utils/jsonDateReviever';
import normalizeData from '../utils/normalizeData';
import authService from './authService';
import cookieService from './cookieService';

const requestService = axios.create({
  baseURL: `${API_URL}/api`,
  withCredentials: true,
  transformResponse: (data) => (data ? JSON.parse(data, jsonDateReviver) : undefined),
});

requestService.interceptors.request.use(
  async (config) => {
    const token = getAuthToken();
    const headers = { ...config.headers };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return {
      ...config,
      headers,
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

requestService.interceptors.response.use(
  (response) => ({ ...response, data: normalizeData(response.data) }),
  async (error) => {
    const originalRequest = error.config;

    // eslint-disable-next-line no-underscore-dangle
    if (error?.response.status === 401 && originalRequest && !originalRequest._isRetry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._isRetry = true;
      try {
        const { data } = await authService.refreshToken();
        cookieService.set(TOKEN_KEY, data.jwt);
        return requestService.request(originalRequest);
      } catch (e) {
        window.location.href = '/login';
        removeAuthToken();
      }
    }

    // If user authenticated but has been after blocked
    if (error.response.status === 401) {
      window.location.href = '/login';
      removeAuthToken();
    }

    throw error;
  },
);

export default requestService;
