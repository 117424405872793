import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import useIncidentsMap from '../../../../../hooks/useIncidentsMap';
import { incidentsState } from '../../../../../state/incidents';
import { locationsState } from '../../../../../state/locations';
import type { GeoCoordinates, Incident, Location } from '../../../../../types';
import type { WithGeo } from '../../../shared/config/geo';
import { getGeo, onlyWithGeo } from '../../../shared/config/geo';
import type { Center, Zoom } from '../../../shared/hooks/useMapCenterAndZoom';
import { useMapCenterAndZoom } from '../../../shared/hooks/useMapCenterAndZoom';
import { INCIDENT_COLORS } from '../config/incidentColors';
import { getColor } from '../lib/getColor';
import type { MetaData, LocationMarker, IncidentMarker } from '../lib/types';
import { useIncidentMapDrawer } from './useIncidentMapDrawer';

export type IncidentMapDataProviderApi = {
  locations: LocationMarker[];
  incidents: IncidentMarker[];
  updatedIncidents: Incident[];
  setUpdatedIncidents: Dispatch<SetStateAction<Incident[]>>;
  onMarkerClick: (marker: MetaData) => () => void;
  onClusterClick: (markers: MetaData[]) => () => void;
  center: Center;
  zoom: Zoom;
  onDraggableMarker: (id: IncidentMarker['metadata']['id'], latLng: GeoCoordinates | null) => void;
};

function getIncidentMarker(incident: WithGeo<Incident>): IncidentMarker {
  return {
    metadata: { id: incident.id, type: 'incident' },
    key: `incident_${incident.geo.id}`,
    position: [incident.geo.longitude, incident.geo.latitude],
    hint: `#${incident.count} ${incident.title}`,
    color: INCIDENT_COLORS[getColor(incident)],
  };
}

function countIncidentsInLocation(incidents: Incident[], location: Location) {
  return incidents.filter((incident) => incident.location?.id === location.id).length;
}

function getLocationMarker(location: WithGeo<Location>, incidents: Incident[]): LocationMarker {
  const countIncidents = countIncidentsInLocation(incidents, location);

  return {
    metadata: { id: location.id, type: 'location' },
    key: `location_${location.geo.id}`,
    position: [location.geo.longitude, location.geo.latitude],
    hint: location.title,
    label: countIncidents > 0 ? countIncidents.toString() : undefined,
  };
}

const IncidentMapContext = createContext<IncidentMapDataProviderApi>({
  locations: [],
  incidents: [],
  updatedIncidents: [],
  setUpdatedIncidents: () => null,
  onMarkerClick: () => () => null,
  onClusterClick: () => () => null,
  center: [40, 34],
  zoom: 2,
  onDraggableMarker: () => null,
});

export const IncidentMapDataProvider = ({ children }: PropsWithChildren) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [updatedIncidents, setUpdatedIncidents] = useState<Incident[]>([]);
  const { data: allIncidents } = useIncidentsMap();
  const [, setIncidents] = useRecoilState(incidentsState);
  const allLocations = useRecoilValue(locationsState);
  const locations = onlyWithGeo(allLocations);
  const incidents = onlyWithGeo(allIncidents);
  const allGeos = [...incidents.map(getGeo), ...locations.map(getGeo)];
  const { center, zoom } = useMapCenterAndZoom(allGeos, refContainer);

  const { onMarkerClick, onClusterClick } = useIncidentMapDrawer();

  const onDraggableMarker: IncidentMapDataProviderApi['onDraggableMarker'] = useCallback(
    (id, latLng) => {
      setIncidents((prevIncidents) =>
        prevIncidents.map((item) => {
          if (item.id === id && latLng) {
            const updatedIncident = {
              ...item,
              geo: item?.geo
                ? {
                    ...item.geo,
                    latitude: latLng.latitude,
                    longitude: latLng.longitude,
                  }
                : undefined,
            };

            setUpdatedIncidents((prev) => [...prev, updatedIncident]);
            return updatedIncident;
          }

          return item;
        }),
      );
    },
    [setIncidents],
  );

  const value = useMemo<IncidentMapDataProviderApi>(
    () => ({
      locations: locations.map((location) => getLocationMarker(location, allIncidents)),
      incidents: incidents.map(getIncidentMarker),
      updatedIncidents,
      setUpdatedIncidents,
      onMarkerClick,
      onClusterClick,
      center,
      zoom,
      onDraggableMarker,
    }),
    [
      allIncidents,
      center,
      incidents,
      locations,
      onClusterClick,
      onDraggableMarker,
      onMarkerClick,
      updatedIncidents,
      zoom,
    ],
  );

  return (
    <IncidentMapContext.Provider value={value}>
      <Box ref={refContainer} sx={{ width: '100%', height: '100%' }}>
        {children}
      </Box>
    </IncidentMapContext.Provider>
  );
};

export const useIncidentMap = () => {
  const context = useContext(IncidentMapContext);

  if (!context) {
    throw new Error('useIncidentMap must be used within an IncidentMapDataProvider');
  }

  return context;
};
