import { useRecoilTask } from 'recoil-toolkit';

import { loginTask, logoutTask } from './tasks';

export const useLogin = () => useRecoilTask(loginTask, [], {
  loaderStack: 'loginTask',
  errorStack: true,
});

export const useLogout = () => useRecoilTask(logoutTask, [], {
  loaderStack: 'logoutTask',
  errorStack: true,
});
