import type { TFunction } from 'i18next';

import { ResolutionLevel } from '../types';

const LEVEL_TITLE_MAP = {
  [ResolutionLevel.RED]: (t: TFunction<'translation'>) => t('red'),
  [ResolutionLevel.ORANGE]: (t: TFunction<'translation'>) => t('orange'),
  [ResolutionLevel.AMBER]: (t: TFunction<'translation'>) => t('amber'),
  [ResolutionLevel.GREEN]: (t: TFunction<'translation'>) => t('green'),
};

const getResolutionLevelTitle = (level: ResolutionLevel, t: TFunction<'translation'>) => LEVEL_TITLE_MAP[level](t);

export default getResolutionLevelTitle;
