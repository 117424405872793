import type { UseGridDragColumns } from './useGridDragColumns';
import { useGridDragColumns } from './useGridDragColumns';
import type { UseGridSortProps } from './useGridSort';
import { useGridSort } from './useGridSort';

type UseColumnStateProps<T extends string> = UseGridSortProps<T> & UseGridDragColumns<T>

export const useColumnsSettings = <T extends string>({
  columnDefs,
  columnRecoilState,
  useSetRecoilColumnsSettings,
  columnSortRecoilState,
  defaultColumnSettingsSort,
  sortMapField,
}: UseColumnStateProps<T>) => {
  const { columnDefs: updatedColDefs, onDragStopped } = useGridDragColumns({
    columnDefs,
    columnRecoilState,
    useSetRecoilColumnsSettings,
  });
  const { columnDefs: colDefsWithSort, onSortHandler } = useGridSort({
    columnDefs: updatedColDefs,
    columnSortRecoilState,
    defaultColumnSettingsSort,
    sortMapField,
  });
  return {
    columnDefs: colDefsWithSort,
    onSortHandler,
    onDragStopped,
  };
};
