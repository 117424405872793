/* eslint-disable react/no-array-index-key */
import type {
  FC, ReactNode} from 'react';
import { useRef, useState,
} from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type {
  Theme,
  SxProps} from '@mui/material';
import {
  Popper,
  Paper,
  MenuList,
  MenuItem,
  Grow,
  ClickAwayListener,
  ButtonGroup,
  Button
} from '@mui/material';

type Option = {
  content: ReactNode;
  action: () => void;
};

type Props = {
  options: Option[];
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

const SplitButton: FC<Props> = ({ options, sx, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedOption = options[selectedIndex];

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        variant='contained'
        sx={{ height: '40px', ...sx }}
        ref={anchorRef}
        aria-label='split button'
      >
        <Button sx={{ color: 'inherit' }} onClick={selectedOption.action}>
          {selectedOption.content}
        </Button>
        <Button
          size='small'
          sx={{ color: 'inherit' }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ zIndex: 100 }} disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={index} selected={index === selectedIndex} onClick={() => handleMenuItemClick(index)}>
                      {option.content}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
