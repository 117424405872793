import requestService from './requestService';

export type LoginParams = {
  identifier: string;
  password: string;
};

export type LoginResponse = {
  jwt: string;
  user: {
    id: number;
    username: string;
    email: string;
    provider: string;
    confirmed: boolean;
    blocked: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
};

const authService = {
  login: ({ identifier, password }: LoginParams) =>
    requestService.post<LoginResponse>('/auth/local', { identifier, password }),
  refreshToken: () =>
    requestService.post<{ jwt: string }>('/auth/refreshToken', null, {
      transformRequest: [
        (data, headers = {}) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.Authorization;
          return JSON.stringify(data);
        },
      ],
    }),
  logout: () => requestService.post('/auth/logout'),
};

export default authService;
