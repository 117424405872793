import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box, List, ListItem, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { Gallery } from 'react-photoswipe-gallery';

import type { Comment, CommentBody } from '../../types';
import { commentsComparator } from '../../utils/comparators';
import { useFullScreen } from '../FullScreen';
import CommentInput from './CommentInput';
import UserComment from './UserComment';

type Props = {
  comments: Comment[];
  onSend: (commentBody: CommentBody) => void;
  onRemove?: (id: number) => void;
  onUpdate?: (id: number, data: CommentBody) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  canUpdateComment?: boolean;
  stylePanelAspectRatio?: string;
};

const IncidentComments: FC<Props> = ({
  comments,
  onSend,
  sx,
  disabled,
  onRemove,
  onUpdate,
  canUpdateComment,
  stylePanelAspectRatio,
}) => {
  const { ref } = useFullScreen();
  const [updateComment, setUpdateComment] = useState<number>();
  const commentsContainer = useRef<HTMLUListElement>(null);
  const updatedComment = useMemo(
    () => comments.find((comment) => comment.id === updateComment) as Comment,
    [comments, updateComment],
  );
  const dataInput = useMemo(
    () => ({
      text: updatedComment?.body,
      image: updatedComment?.image
        ? new File([updatedComment.image.name], updatedComment.image.url, { type: updatedComment.image.mime })
        : undefined,
    }),
    [updatedComment],
  );

  useEffect(() => {
    if (commentsContainer.current) {
      commentsContainer.current.scrollTop = commentsContainer.current.scrollHeight;
    }
  }, [comments]);

  const handleUpdate = (id: number) => {
    setUpdateComment(id);
  };

  const handleUpdateComment = (data: CommentBody) => {
    if (updateComment && onUpdate) {
      onUpdate(updateComment, data);
      setUpdateComment(undefined);
    }
  };

  return (
    <Stack>
      <Gallery withCaption options={{ appendToEl: ref.current ?? undefined }}>
        <List
          ref={commentsContainer}
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            ...sx,
          }}
        >
          {comments.length === 0 && (
            <Box sx={{ padding: '40px', width: '100%', textAlign: 'center' }}>
              <Trans i18nKey='no-message-found' />
            </Box>
          )}
          {[...comments].sort(commentsComparator).map((comment) => (
            <ListItem key={comment.id}>
              <UserComment
                comment={comment}
                onRemove={onRemove}
                onUpdate={handleUpdate}
                canUpdateComment={canUpdateComment}
              />
            </ListItem>
          ))}
        </List>
      </Gallery>

      <CommentInput
        disabled={disabled}
        onSend={updateComment ? handleUpdateComment : onSend}
        data={updateComment ? dataInput : undefined}
        stylePanelAspectRatio={stylePanelAspectRatio}
      />
    </Stack>
  );
};

export default IncidentComments;
