import type { ReactElement } from 'react';

import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { useFullScreen } from '../FullScreen';

type Props<T> = {
  name: string;
  label?: string;
  data: T[];
  itemRenderer: (value: T) => string | ReactElement;
  required?: boolean;
  disabled?: boolean;
};

export const ObjectSelectController = <T extends { id: number }>({
  data,
  itemRenderer,
  name,
  label,
  required = false,
  disabled = false,
}: Props<T>) => {
  const { ref: refFullScreen } = useFullScreen();
  const { setValue, control } = useFormContext();
  const labelId = `${name}-control-label`;

  const onChangeHandler = (event: SelectChangeEvent<number | string>) => {
    setValue(name, data.find((item) => item.id.toString() === String(event.target.value)) ?? null);
  };

  return (
    <FormControl fullWidth>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ref, onBlur } }) => (
          <Select
            disabled={disabled}
            label={label}
            labelId={labelId}
            ref={ref}
            name={name}
            onBlur={onBlur}
            value={value?.id ?? ''}
            onChange={onChangeHandler}
            sx={{ height: '56px' }}
            MenuProps={{
              container: refFullScreen.current,
            }}
          >
            {!required && (
              <MenuItem key='empty' value=''>
                <em>
                  <Trans i18nKey='none' />
                </em>
              </MenuItem>
            )}
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {itemRenderer(item)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
