import { useId, useMemo } from 'react';

import { PlaylistAdd, PlaylistRemove } from '@mui/icons-material';
import { Box } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { t } from 'i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from '../../../../../components/base';
import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { permissionState } from '../../../../../state/event';
import { getCountByLevel } from '../../../../EntitiesGrid/lib/getCountByLevel';
import { getResolutionLevelForChildrenNodes } from '../../../lib/resolutionLevelForChildrenNodes';
import { useProjectTaskRemoveRow } from '../../../state/projectTasks';
import { DEFAULT_PREPARED_PROJECT_TASK } from '../../../state/projectTasks/constants';
import { getIsMaxLevel } from '../lib/maxLevel';
import { newChildTaskState } from '../model/store/newChildTask.state';
import { CountWrapper } from './CountWrapper';

export const ParentNewRowRenderer = (props: ICellRendererParams) => {
  const id = useId();
  const { node, api, data } = props;
  const newRowId = `prepared-${id}`;
  const { execute: onRemoveTaskRow } = useProjectTaskRemoveRow();
  const [childData, setChildData] = useRecoilState(newChildTaskState);
  const { showConfirm, confirmModal } = useConfirmDialog({
    content: t(data.children.length > 0 ? 'confirm-remove-task-with-children' : 'confirm-remove-task'),
  });
  const isMaxLevel = getIsMaxLevel(node.uiLevel);
  const { canCreateProjectTask } = useRecoilValue(permissionState);

  const onAddRow = () => {
    const newRow = {
      ...DEFAULT_PREPARED_PROJECT_TASK,
      id: newRowId,
      orgHierarchy: [...data.orgHierarchy, '-'],
      parent: data,
      children: [],
    };

    setChildData(newRow);
    api.setRowNodeExpanded(node, true);
  };

  const onRemoveTask = async () => {
    showConfirm().then((isOk) => {
      if (isOk) {
        onRemoveTaskRow({ id: data.id });
      }
    });
  };

  const count = getCountByLevel(data.count, data.orgHierarchy.length);
  const availableResolutionLevels = useMemo(() => getResolutionLevelForChildrenNodes(node), [node]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ paddingInline: '8px' }}>
        <CountWrapper id={data.id} count={count} availableResolutionLevels={availableResolutionLevels}>
          {count}
        </CountWrapper>
      </Box>
      {canCreateProjectTask && (
        <Box>
          {!isMaxLevel && (
            <Button type='button' onClick={onAddRow} disabled={Boolean(childData)} sx={{ minWidth: 40 }}>
              <PlaylistAdd />
            </Button>
          )}
          <Button type='button' onClick={onRemoveTask} sx={{ minWidth: 40 }}>
            <PlaylistRemove />
          </Button>
        </Box>
      )}
      {confirmModal}
    </Box>
  );
};
