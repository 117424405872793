import type { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

export const AutocompleteBox = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      left: 0,
      top: 2,
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
    }}
  >
    {children}
  </Box>
);
