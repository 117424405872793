import { useCallback, useMemo } from 'react';

import type { ColDef, SortChangedEvent } from 'ag-grid-community';
import type { RecoilState } from 'recoil';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

type GridSort = { field: string; order: 'asc' | 'desc' };

export type UseGridSortProps<T extends string> = {
  columnDefs: ColDef[];
  columnSortRecoilState: RecoilState<GridSort>;
  defaultColumnSettingsSort: GridSort;
  sortMapField: Partial<Record<T, string>>;
};

const addSortToColDefs = (columnDefs: ColDef[], sort: GridSort) =>
  columnDefs.map((colDef) => (colDef.field === sort.field ? { ...colDef, sort: sort.order } : colDef));

const getField = (field: string) => {
  switch (field) {
    case 'userFunction':
      return 'userFunction.name';

    case 'relations':
      return 'relations.id';

    default:
      return field;
  }
};

export const useGridSort = <T extends string>({
  columnDefs,
  columnSortRecoilState,
  defaultColumnSettingsSort,
  sortMapField,
}: UseGridSortProps<T>) => {
  const sortStateValue = useRecoilValue(columnSortRecoilState);
  const setSortState = useSetRecoilState(columnSortRecoilState);
  const resetSortState = useResetRecoilState(columnSortRecoilState);

  const onSortHandler = useCallback(
    ({ columnApi }: SortChangedEvent) => {
      const columnState = columnApi.getColumnState();
      const column = columnState.find((item) => item.sort);

      if (column && column.sort) {
        const field = sortMapField[column.colId as T] ?? column.colId;
        setSortState({ field: getField(field), order: column.sort });
      } else {
        resetSortState();
        columnApi.applyColumnState({
          state: [{ colId: defaultColumnSettingsSort.field, sort: defaultColumnSettingsSort.order }],
        });
      }
    },
    [setSortState, resetSortState, defaultColumnSettingsSort, sortMapField],
  );

  const updatedColDefs = useMemo(() => addSortToColDefs(columnDefs, sortStateValue), [columnDefs, sortStateValue]);

  return {
    columnDefs: updatedColDefs,
    onSortHandler,
  };
};
