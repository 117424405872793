import { useMemo } from 'react';

import type { LngLat } from '@yandex/ymaps3-types';
import type { Feature } from '@yandex/ymaps3-types/packages/clusterer';

import { MapLoader } from '../../../shared/components/MapLoader';
import {
  Cluster,
  Hint,
  IncidentMarker,
  LocationMarker,
  YandexMap,
  YandexMapProvider,
  useYandexMap,
} from '../../../shared/components/YandexMap';
import type { CustomFeatureIncident, CustomFeatureLocation } from '../lib/normalizeYandexMapData';
import { normalizeYandexMapData } from '../lib/normalizeYandexMapData';
import { useIncidentMap } from '../model/IncidentMapProvider';

const SOURCE = 'source';

const YandexMapIncidentBase = () => {
  const data = useIncidentMap();
  const { ymapsInstance } = useYandexMap();
  const { onMarkerClick, locationPoints, incidentPoints, onClusterClick, center, zoom, onDraggableMarker } = useMemo(
    () => normalizeYandexMapData(data),
    [data],
  );

  if (!ymapsInstance) {
    return <MapLoader />;
  }

  const { YMapFeatureDataSource, YMapLayer, YMapDefaultSchemeLayer } = ymapsInstance.ymaps;
  const { YMapClusterer, clusterByGrid } = ymapsInstance.clusterer;

  const incidentMarker = (feature: Feature) => {
    const updateFeature = feature as CustomFeatureIncident;
    return (
      <IncidentMarker
        draggable
        onDragEnd={onDraggableMarker(updateFeature.properties)}
        ymapsInstance={ymapsInstance}
        coordinates={updateFeature.geometry.coordinates}
        source={SOURCE}
        onClick={onMarkerClick(updateFeature.properties.metadata)}
        color={updateFeature.properties.color}
        properties={{
          hint: updateFeature.properties.hint,
        }}
      />
    );
  };

  const cluster = (coordinates: LngLat, features: Feature[]) => {
    const updateFeature = features as (CustomFeatureIncident | CustomFeatureLocation)[];
    const metadata = updateFeature.map((feature) => feature.properties.metadata);
    return (
      <Cluster
        count={features.length}
        ymapsInstance={ymapsInstance}
        coordinates={coordinates}
        source={SOURCE}
        onClick={onClusterClick(metadata)}
      />
    );
  };

  const method = clusterByGrid({ gridSize: 64 });

  return (
    <YandexMap location={{ center, zoom }}>
      <YMapDefaultSchemeLayer />
      <YMapFeatureDataSource id={SOURCE} />
      <YMapLayer source={SOURCE} type='markers' zIndex={1800} />
      <Hint ymapsInstance={ymapsInstance} />

      <YMapClusterer method={method} features={incidentPoints} marker={incidentMarker} cluster={cluster} />

      {locationPoints.map((locationMarker) => (
        <LocationMarker
          view='primary'
          key={locationMarker.properties.key}
          ymapsInstance={ymapsInstance}
          label={locationMarker.properties.label}
          coordinates={locationMarker.geometry.coordinates}
          onClick={onMarkerClick(locationMarker.properties.metadata)}
          properties={{
            hint: locationMarker.properties.hint,
          }}
        />
      ))}
    </YandexMap>
  );
};

export const YandexMapIncident = () => (
  <YandexMapProvider>
    <YandexMapIncidentBase />
  </YandexMapProvider>
);
