import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { FormControl, TextField, Autocomplete, Stack } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import type { User } from '../../types';
import getFullName from '../../utils/getFullName';
import { ClampedTypography, UserAvatar } from '../base';
import { CustomPopper } from './components/CustomPopper';

export type UserSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Options extends User,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<User, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'isOptionEqualToValue' | 'getOptionLabel' | 'renderOption' | 'onChange'
> &
  UseControllerProps<TFieldValues, TName> & {
    label: string;
    onChange?: (values: Options | null) => void;
    getOptionDisabled?: (value: TFieldValues[]) => boolean;
  };

export const UserSelectField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: UserSelectFieldProps<TFieldValues, TName, User>,
) => {
  const { label, placeholder, onChange, ...rest } = props;
  const { field } = useController(props);

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...rest}
        {...field}
        onChange={(_, value) => {
          field.onChange(value);
          onChange?.(value);
        }}
        PopperComponent={CustomPopper}
        renderInput={(params: TextFieldProps) => <TextField {...params} label={label} placeholder={placeholder} />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(user) => getFullName(user)}
        renderOption={(liProps, user) => (
          <li {...liProps}>
            <Stack direction='row' spacing={2}>
              <UserAvatar user={user} size='s' />
              <ClampedTypography lineclamp={1} wordBreak='break-all'>
                {getFullName(user)}
              </ClampedTypography>
            </Stack>
          </li>
        )}
      />
    </FormControl>
  );
};
