import { useRecoilValueLoadable } from 'recoil';

import { eventState } from '../../../../../state/event';
import { GoogleMapLocation } from '../components/GoogleMapLocation';
import { LeafletMapPlan } from '../components/LeafletMapPlan';
import { NotificationLocation } from '../components/NotificationLocation';
import { YandexMapLocation } from '../components/YandexMapLocation';
import { YandexMapLocationPlan } from '../components/YandexMapLocationPlan';
import { LocationMapDataProvider } from '../model/LocationMapDataProvider';

interface LocationMapProps {
  imageMap?: string;
}

export const LocationMap = (props: LocationMapProps) => {
  const { imageMap } = props;
  const event = useRecoilValueLoadable(eventState).valueMaybe();

  if (!event) {
    return null;
  }

  return (
    <LocationMapDataProvider>
      <NotificationLocation />
      {imageMap ? (
        <>
          {event.map === 'yandex' && <YandexMapLocationPlan url={imageMap} />}
          {event.map === 'google' && <LeafletMapPlan url={imageMap} />}
        </>
      ) : (
        <>
          {event.map === 'yandex' && <YandexMapLocation />}
          {event.map === 'google' && <GoogleMapLocation />}
        </>
      )}
    </LocationMapDataProvider>
  );
};
