import { Box } from '@mui/material';
import { Trans } from 'react-i18next';

import { Button } from '../../../../../components/base';
import { useUpdateIncidents } from '../model/useUpdateIncidents';

export const UpdateIncidentsButton = () => {
  const { onUpdateIncidents, loading, isDisabled } = useUpdateIncidents();

  return (
    <Box pt={2}>
      <Button
        fullWidth
        type='button'
        sx={{ marginTop: 'auto', color: 'white' }}
        variant='contained'
        onClick={onUpdateIncidents}
        loading={loading}
        disabled={isDisabled}
      >
        <Trans i18nKey='update-incidents' />
      </Button>
    </Box>
  );
};
