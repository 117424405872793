import type { CellEditingStartedEvent } from 'ag-grid-community';

import type { Incident } from '../../../types';

export function scrollToColumnEdited(params: CellEditingStartedEvent<Incident>) {
  const { colDef, api } = params;
  if (colDef.field) {
    api.ensureColumnVisible(colDef.field, 'auto');
  }
}
