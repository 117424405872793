import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Typography } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { userFunctionsState } from '../../../../state/userFunctions';
import type { Incident, UserFunction } from '../../../../types';
import { TableSelect } from '../../../base';

const Item = ({ name }: UserFunction) => (
  <Typography component='span' whiteSpace='normal' sx={{ wordBreak: 'break-word' }}>
    {name}
  </Typography>
);

const UserFunctionEditor = forwardRef((props: ICellEditorParams, ref) => {
  const incident: Incident = props.data;
  const userFunctions = useRecoilValue(userFunctionsState);
  const [currentUserFunction, setCurrentUserFunction] = useState<UserFunction | null>();

  const onSelect = (userFunction: UserFunction | null) => {
    setCurrentUserFunction(userFunction);
  };

  useEffect(() => {
    if (currentUserFunction !== undefined) {
      props.stopEditing();
    }
  }, [currentUserFunction, props]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return currentUserFunction !== undefined ? currentUserFunction : incident.userFunction;
    },
  }));

  return (
    <TableSelect
      data={userFunctions}
      value={incident.userFunction}
      onSelect={onSelect}
      itemRenderer={Item}
      getOptionLabel={(item) => item.name}
    />
  );
});

export default UserFunctionEditor;
