import type { GetQuickFilterTextParams } from 'ag-grid-community';

import type { CellTypeFns} from '../../cellTypes';
import { dateType, stringType } from '../../cellTypes';

export const defaultCellTypeFns = <T, E>(cellType: CellTypeFns<T>) => ({
  comparator: cellType.comparator,
  equals: cellType.equals,
  getQuickFilterText: (params: GetQuickFilterTextParams<E>) => cellType.toString(params.value),
});

export const dateCellTypeFns = () => ({
  comparator: dateType.comparator,
  equals: dateType.equals,
  getQuickFilterText: () => '',
});

export const userFunctionTypeFns = <E>() => defaultCellTypeFns<string, E>(stringType);

export const makeQuickFilterOptions = (containsText: string) => ({
  excludeHiddenColumnsFromQuickFilter: true,
  quickFilterText: containsText,
});
