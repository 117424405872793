import { useContext } from 'react';
import type { Context } from 'react';

import { Box } from '@mui/material';

import type { YandexMapApiLoader } from '../model/YandexMapProvider';

type HintProps = {
  ymapsInstance: YandexMapApiLoader;
};

type ContextHint = {
  hint: string;
};

export const HintBase = ({ ymapsInstance }: HintProps) => {
  const { YMapHintContext } = ymapsInstance.hint;
  const ctx = useContext(YMapHintContext as unknown as Context<ContextHint | null>);

  return (
    <Box
      sx={{
        position: 'absolute',
        padding: '8px',
        background: 'white',
        whiteSpace: 'nowrap',
        fontFamily: 'sans-serif',
        transform: 'translate(8px, -50%)',
        borderRadius: '4px',
      }}
    >
      {ctx?.hint}
    </Box>
  );
};

export const Hint = ({ ymapsInstance }: HintProps) => {
  const { YMapHint } = ymapsInstance.hint;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <YMapHint hint={(object) => object && object.properties && object.properties.hint}>
      <HintBase ymapsInstance={ymapsInstance} />
    </YMapHint>
  );
};
