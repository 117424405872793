import { useTranslation } from 'react-i18next';

import { DateTimeField, type DateTimeFieldProps } from '../../fields/DateTimeField';
import type { DailyTasksFormFilerValue } from '../lib/types';

type StartDateFieldProps = Omit<DateTimeFieldProps<DailyTasksFormFilerValue, 'startDate'>, 'label' | 'name'>;

export const StartDateField = (props: StartDateFieldProps) => {
  const { t } = useTranslation();

  return <DateTimeField {...props} name='startDate' label={t('start-date')} />;
};
