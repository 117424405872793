import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { eventSettingService } from '../../../api';
import { getCurrentUserTask, userEventSettings, userState } from '../../../state/user';
import { transformFilterForBackend } from '../lib/transformFilterForBackend';
import type { DailyTasksFormFilerValue } from '../lib/types';

const updateDailyTaskDefaultFilter = (rti: RecoilTaskInterface) => async (filters: DailyTasksFormFilerValue) => {
  const { getLoadable } = rti;

  const eventSetting = getLoadable(userEventSettings).getValue();
  const currentUser = getLoadable(userState).getValue();

  const transformedFilters = transformFilterForBackend({ filters, currentUser });

  await eventSettingService.update({
    id: eventSetting.id,
    dailyTaskDefaultFilter: transformedFilters,
  });

  await getCurrentUserTask(rti)();
};

export const useUpdateDailyTaskDefaultFilter = () =>
  useRecoilTask(updateDailyTaskDefaultFilter, [], {
    loaderStack: 'projectTaskDefaultFilterUpdate',
    errorStack: true,
  });
