import type { ChangeEvent } from 'react';

import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { incidentsFilterState, useAddFilterByUnread, useClearFilterByUnread } from '../../../../state/incidentsFilter';
import { CheckboxControl } from '../CheckboxControl';

export const UnreadControl = () => {
  const { viewedUser } = useRecoilValue(incidentsFilterState);
  const { execute: addFilterByUnread } = useAddFilterByUnread();
  const { execute: clearFilterByUnread } = useClearFilterByUnread();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addFilterByUnread();
    } else {
      clearFilterByUnread();
    }
  };

  return (
    <CheckboxControl
      name='isUnread'
      label={<Trans i18nKey='unread' />}
      checked={Boolean(viewedUser)}
      onChange={onChangeHandler}
    />
  );
};
