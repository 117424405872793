import { useRecoilTask } from 'recoil-toolkit';

import {
  exportToExcelDaily,
  exportToExcelProjects,
  exportToExcelTask,
  exportToPdfCardsTask,
  exportToPdfDashboardsTask,
  exportToPdfLogTask,
  exportToPdfTableProjectTasks,
  exportToPdfTableTask,
  exportToPdfTableDailyTasks,
} from './tasks';

export const useExportToExcel = () =>
  useRecoilTask(exportToExcelTask, [], {
    errorStack: true,
  });

export const useExportToExcelProjects = () =>
  useRecoilTask(exportToExcelProjects, [], {
    errorStack: true,
  });
export const useExportToExcelDaily = () =>
  useRecoilTask(exportToExcelDaily, [], {
    errorStack: true,
  });

export const useExportToPdfTable = () =>
  useRecoilTask(exportToPdfTableTask, [], {
    errorStack: true,
  });

export const useExportToPdfCards = () =>
  useRecoilTask(exportToPdfCardsTask, [], {
    errorStack: true,
  });

export const useExportToPdfDashboards = () =>
  useRecoilTask(exportToPdfDashboardsTask, [], {
    errorStack: true,
  });

export const useExportToPdfLogs = () =>
  useRecoilTask(exportToPdfLogTask, [], {
    errorStack: true,
  });

export const useExportToPdfTableProjectTasks = () =>
  useRecoilTask(exportToPdfTableProjectTasks, [], {
    errorStack: true,
  });

export const useExportToPdfTableDailyTasks = () =>
  useRecoilTask(exportToPdfTableDailyTasks, [], {
    errorStack: true,
  });
