import { atom, selector } from 'recoil';
import { values } from 'remeda';

import type { ProjectTaskDefaultFilters } from '../../../../types';
import { columnsSettingsState, newRowEntityState } from '../../../EntitiesGrid';
import { tasksState } from '../tasks';
import { DEFAULT_PROJECT_TASKS_COLUMNS } from './constants';
import { addHierarchyField, type ProjectTaskState } from './lib/addHierarchyField';
import { createTree } from './lib/createTree';
import type { PreparedNewProjectTask, ProjectTask } from './types';
import { isProjectTask } from './types';

export const projectTasksState = selector<ProjectTask[]>({
  key: 'projectTasks',
  get: ({ get }) => {
    const tasks = get(tasksState);
    const projectTasks = values(tasks).filter(isProjectTask);

    return projectTasks;
  },
});

export const projectTasksStateWithHierarchy = selector<ProjectTaskState[]>({
  key: 'projectTasksWithHierarchy',
  get: ({ get }) => {
    const tasks = get(projectTasksState);

    return addHierarchyField(createTree(tasks));
  },
});

export const projectTasksStateTree = selector<ProjectTask[]>({
  key: 'constprojectTasksStateTree',
  get: ({ get }) => {
    const tasks = get(projectTasksState);

    return createTree(tasks);
  },
});

export const newRowProjectTask = newRowEntityState<PreparedNewProjectTask>({ key: 'projectTasksNewRow' });

export const projectTasksColumnsState = columnsSettingsState({
  default: DEFAULT_PROJECT_TASKS_COLUMNS,
  key: 'projectTasksColumnSettings',
});

export const projectTasksFiltersState = atom<ProjectTaskDefaultFilters>({
  key: 'projectTasksFilters',
  default: {
    typeDate: 'registeredDate',
  },
});
