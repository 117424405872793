import { TextField } from '@mui/material';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { usersState } from '../../../state/users';
import type { User } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { AutocompleteMultiple } from '../../AutocompleteMultiple';
import { UserChip, UserAvatar } from '../../base';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

type ResponsibleFieldProps = {
  form: UseFormReturn<UserEventSettingsInitialValues>;
  disabled?: boolean;
};

export const ResponsibleField = ({ form, disabled }: ResponsibleFieldProps) => {
  const { t } = useTranslation();
  const users = useRecoilValue(usersState);

  return (
    <Controller
      name='filters.responsible'
      control={form.control}
      render={({ field }) => (
        <AutocompleteMultiple
          {...field}
          disabled={disabled}
          disableCloseOnSelect
          options={users}
          limitTags={4}
          filterSelectedOptions
          onChange={(_, values) => field.onChange(values === 'all' ? users : values)}
          getOptionLabel={(responsible: User) => getFullName(responsible)}
          renderTags={(value, getTagProps) =>
            value.map((responsible, index) => (
              <UserChip user={responsible} variant='outlined' size='small' {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => <TextField {...params} label={t('responsible')} />}
          renderOption={(props, responsible) => (
            <li {...props}>
              <UserAvatar user={responsible} sx={{ marginRight: '8px' }} />
              {getFullName(responsible)}
            </li>
          )}
        />
      )}
    />
  );
};
