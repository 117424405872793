import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseManager } from 'ag-grid-enterprise';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { registerPlugin } from 'react-filepond';
import { Routes, Route, Navigate, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import 'photoswipe/dist/photoswipe.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'leaflet/dist/leaflet.css';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { DrawerStack } from './components/DrawerStack';
import { ErrorStack } from './components/ErrorStack';
import { FullScreenProvider } from './components/FullScreen';
import { LayoutDailyPlan } from './components/Layout/LayoutDailyPlan';
import { LayoutIncident } from './components/Layout/LayoutIncident';
import { LayoutProjectPlan } from './components/Layout/LayoutProjectPlan';
import { NotificationStack } from './components/NotificationStack';
import { PrivateRoute } from './components/PrivateRoute';
import useInitState from './hooks/useInitState';
import useLocale from './hooks/useLocale';
import DailyPlan from './pages/daily-plan/DailyPlan';
import DashboardsIncident from './pages/incidents/Dashboards';
import Events from './pages/incidents/Events';
import Incidents from './pages/incidents/Incidents';
import IncidentsMap from './pages/incidents/IncidentsMap';
import Users from './pages/incidents/Users';
import Login from './pages/Login';
import { DashboardsPage as DashboardsProjectPlan } from './pages/project-plan/Dashboards';
import Gantta from './pages/project-plan/Gantta';
import ProjectPlan from './pages/project-plan/ProjectPlan';
import { currentEventSettings, permissionState } from './state/event';
import { userState } from './state/user';
import { AppRoute } from './types';
import history from './utils/history';

import './App.css';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit,
);

const App = () => {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE ?? '');
  const { loading, auth } = useInitState();
  const user = useRecoilValue(userState);
  const { canViewDashboard, canViewMaps, canViewEvent, canViewUsers } = useRecoilValue(permissionState);
  const { state: settingsState, contents: settings } = useRecoilValueLoadable(currentEventSettings);
  const { theme, dateLocale } = useLocale(user?.language);

  if ((loading || settingsState !== 'hasValue') && auth) {
    return null;
  }

  return (
    <div className='App'>
      <FullScreenProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <HistoryRouter history={history}>
              <Routes>
                <Route path={AppRoute.LOGIN} element={<Login />} />
                <Route path='*' element={<Navigate to='/' replace />} />
                <Route
                  element={
                    <PrivateRoute
                      component={LayoutIncident}
                      forbidden={!settings?.availableIncidentPlan}
                      redirect={AppRoute.DAILY_PLAN}
                    />
                  }
                  path='/'
                >
                  <Route element={<PrivateRoute component={Incidents} />} />
                  <Route path={AppRoute.INCIDENTS} element={<PrivateRoute component={Incidents} />} />
                  <Route index element={<PrivateRoute component={Incidents} />} />
                  <Route
                    path={AppRoute.DASHBOARD}
                    element={<PrivateRoute component={DashboardsIncident} forbidden={!canViewDashboard} />}
                  />
                  <Route
                    path={AppRoute.MAP}
                    element={<PrivateRoute component={IncidentsMap} forbidden={!canViewMaps} />}
                  />
                  <Route
                    path={AppRoute.EVENTS}
                    element={<PrivateRoute forbidden={user && !canViewEvent} component={Events} />}
                  />
                  <Route
                    path={AppRoute.USERS}
                    element={<PrivateRoute forbidden={user && !canViewUsers} component={Users} />}
                  />

                  <Route path={AppRoute.CHECK} />
                  <Route path={AppRoute.CREW} />
                </Route>

                <Route
                  element={<PrivateRoute component={LayoutProjectPlan} forbidden={!settings?.availableProjectPlan} />}
                  path={AppRoute.PROJECT_PLAN}
                >
                  <Route index element={<PrivateRoute component={ProjectPlan} />} />
                  <Route
                    path={AppRoute.PROJECT_PLAN_USERS}
                    element={<PrivateRoute forbidden={user && !canViewUsers} component={Users} />}
                  />
                  <Route
                    path={AppRoute.PROJECT_PLAN_DASHBOARD}
                    element={<PrivateRoute forbidden={!canViewDashboard} component={DashboardsProjectPlan} />}
                  />
                  <Route path={AppRoute.PROJECT_PLAN_GANTTA} element={<PrivateRoute component={Gantta} />} />
                </Route>

                <Route
                  element={<PrivateRoute component={LayoutDailyPlan} forbidden={!settings?.availableDailyPlan} />}
                  path={AppRoute.DAILY_PLAN}
                >
                  <Route index element={<PrivateRoute component={DailyPlan} />} />
                  <Route path={AppRoute.DAILY_PLAN_PARAMS} element={<PrivateRoute component={DailyPlan} />} />
                  <Route
                    path={AppRoute.DAILY_PLAN_USERS}
                    element={<PrivateRoute forbidden={user && !canViewUsers} component={Users} />}
                  />
                  <Route
                    path={AppRoute.DAILY_PLAN_EVENTS}
                    element={<PrivateRoute forbidden={user && !canViewEvent} component={Events} />}
                  />
                </Route>
              </Routes>
            </HistoryRouter>
            <ErrorStack />
            <DrawerStack />
            <NotificationStack />
          </LocalizationProvider>
        </ThemeProvider>
      </FullScreenProvider>
    </div>
  );
};

export default App;
