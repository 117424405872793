import { useTranslation } from 'react-i18next';

import { ButtonGroupField, type ButtonGroupFieldProps } from '../../fields/ButtonGroupField';
import type { ProjectTasksFormFilerValue } from '../lib/types';

type TypeDateFieldProps = Omit<
  ButtonGroupFieldProps<ProjectTasksFormFilerValue, 'typeDate'>,
  'name' | 'exclusive' | 'options'
>;

export const TypeDateField = (props: TypeDateFieldProps) => {
  const { t } = useTranslation();

  return (
    <ButtonGroupField
      {...props}
      name='typeDate'
      exclusive
      options={[
        { value: 'registeredDate', children: t('registered-date') },
        { value: 'resolvedDate', children: t('resolved-date') },
      ]}
    />
  );
};
