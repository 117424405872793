import type { TFunction } from 'i18next';

import { Status } from '../types';

const STATUSES_TITLE_MAP = {
  [Status.DRAFT]: (t: TFunction<'translation'>) => t('draft'),
  [Status.IN_PROGRESS]: (t: TFunction<'translation'>) => t('in-progress'),
  [Status.REGISTERED]: (t: TFunction<'translation'>) => t('registered'),
  [Status.RESOLVED]: (t: TFunction<'translation'>) => t('resolved'),
};

const getStatusTitle = (status: Status, t: TFunction<'translation'>) => STATUSES_TITLE_MAP[status](t);

export default getStatusTitle;
