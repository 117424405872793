import { createContext, useContext, useEffect, useMemo, type PropsWithChildren } from 'react';

import { useRecoilValue } from 'recoil';

import { currentEventIdState, permissionState } from '../../../state/event';
import { useUser } from '../../../state/user';
import history from '../../../utils/history';

interface LayoutContextProps {
  hideNavigation: boolean;
  hasNoEvent: boolean;
  canViewEvent: boolean;
}

const initialValues: LayoutContextProps = {
  hideNavigation: true,
  hasNoEvent: true,
  canViewEvent: false,
};

const Context = createContext<LayoutContextProps>(initialValues);

export const LayoutProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const { data: user } = useUser();
  const currentEventId = useRecoilValue(currentEventIdState);
  const { canViewEvent } = useRecoilValue(permissionState);
  const hasNoEvent = user && !currentEventId;

  useEffect(() => {
    if (hasNoEvent && canViewEvent) {
      history.push('/events');
    }
  }, [canViewEvent, hasNoEvent]);

  const values = useMemo<LayoutContextProps>(
    () => ({
      hideNavigation: !canViewEvent && (!user || hasNoEvent),
      hasNoEvent,
      canViewEvent,
    }),
    [canViewEvent, hasNoEvent, user],
  );
  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const useLayoutContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('');
  }

  return context;
};
