import type { ChangeEvent, FC } from 'react';
import { useEffect } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HistoryIcon from '@mui/icons-material/History';
import MenuIcon from '@mui/icons-material/Menu';
import { FormControlLabel, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { compact, equals, noop, pick } from 'remeda';

import { getTasksWithoutRelation } from '../../../../modules/Tasks/state/tasks';
import type { DrawerNavigationProps } from '../../../../state/drawer';
import { currentEventSettings, eventState, permissionState } from '../../../../state/event';
import { locationsState } from '../../../../state/locations';
import { userState } from '../../../../state/user';
import { userFunctionsState } from '../../../../state/userFunctions';
import { usersState } from '../../../../state/users';
import type { EditIncident, Incident } from '../../../../types';
import { ResolutionLevel } from '../../../../types';
import formatDate from '../../../../utils/formatDate';
import getAvailableStatuses from '../../../../utils/getAvailableStatuses';
import getFullName from '../../../../utils/getFullName';
import { Button, Checkbox, MenuButton } from '../../../base';
import CustomCheckbox, { CheckboxType } from '../../../base/CustomCheckbox';
import { CopyGMapLink } from '../../../CopyGMapLink';
import { RelationsField } from '../../../fields/RelationField';
import { AutocompleteController } from '../../../form/Autocomplete';
import { IncidentLog } from '../../../IncidentLog';
import {
  CommentsController,
  PhotosController,
  ResolutionLevelRenderer,
  SelectController,
  StatusRenderer,
  UserRenderer,
} from '../../components';
import EndDatetimeController from '../../components/EndDatetimeController';
import { MultiUsersController } from '../../components/MultiUsersController';
import useCanBeEditedFields, { omitDisabledFields } from '../../hooks/useCanBeEditedFields';
import useUserFunction from '../../hooks/useUserFunction';

type Props = {
  incident: Incident;
  editIncident: (newIncident: EditIncident) => Promise<void>;
  loading: boolean;
  isFlagged?: boolean;
  onChangeFlaggedHandler?: (_: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onExportPdfCardsHandler?: () => void;
} & Omit<DrawerNavigationProps, 'onEndAction'>;

export const EditIncidentForm: FC<Props> = ({
  incident,
  editIncident,
  isFlagged,
  onChangeFlaggedHandler,
  onExportPdfCardsHandler,
  loading,
  goBack,
  goForward,
  isChild,
}) => {
  const locations = useRecoilValue(locationsState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const users = useRecoilValue(usersState);
  const { t } = useTranslation();
  const permissions = useRecoilValue(permissionState);
  const {
    canViewLogIncident,
    canUpdateResolvedIncident,
    canUpdateIncident,
    canUpdateAllIncidents,
    canUpdateResolvedIncidentComment,
    canCreateComment,
  } = permissions;
  const currentUser = useRecoilValue(userState);
  const currentUserId = currentUser?.id;
  const availableStatuses = getAvailableStatuses(canUpdateAllIncidents, incident.status);
  const tasks = useRecoilValue(getTasksWithoutRelation(null));
  const event = useRecoilValue(eventState);
  const eventSettings = useRecoilValue(currentEventSettings);

  const isShowRelationField = eventSettings.availableDailyPlan || eventSettings.availableProjectPlan;
  const initialFormValues: EditIncident = {
    title: incident.title || '',
    description: incident.description || '',
    locationDescription: incident.locationDescription || '',
    ...pick(incident, [
      'id',
      'status',
      'resolutionLevel',
      'responsible',
      'userFunction',
      'photos',
      'location',
      'isResponsibleInformed',
      'isUrgent',
      'endDatetime',
      'participants',
    ]),
    relations: incident.relations ?? [],
    photoSources: incident.photos
      ? incident.photos.map((photo) => ({ id: String(photo.id), source: `${photo.url}?id=${photo.id}` }))
      : [],
  };

  const form = useForm<EditIncident>({
    defaultValues: initialFormValues,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    resetField,
  } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const watchResponsible = watch('responsible');

  useUserFunction(watchResponsible, setValue, users);

  useEffect(() => {
    if (initialFormValues.responsible && watchResponsible === null) {
      resetField('userFunction', { defaultValue: null });
    } else if (initialFormValues.userFunction && watchResponsible === null) {
      resetField('userFunction', { defaultValue: initialFormValues.userFunction });
    } else if (initialFormValues.userFunction === null && watchResponsible === null) {
      resetField('userFunction', { defaultValue: null });
    }
  }, [initialFormValues.responsible, initialFormValues.userFunction, resetField, watchResponsible]);

  const { fields, formCanBeEdited } = useCanBeEditedFields({
    incident: {
      ...incident,
      responsible: watchResponsible,
    },
    canUpdateIncident,
    canUpdateResolvedIncident,
    canUpdateAllIncidents,
    currentUserId,
    canUpdateResolvedIncidentComment,
    canCreateComment,
  });

  const availableResolutionLevels = !fields.resolutionLevel
    ? Object.values(ResolutionLevel)
    : Object.values(ResolutionLevel).filter((item) => permissions[`canCreateIncidentResolutionLevel${item}`]);

  const onSubmit = async (data: EditIncident) => {
    if (!equals(data, initialFormValues)) {
      await editIncident(omitDisabledFields(fields, data));
    }
  };

  const geo = incident.geo || incident.location?.geo;
  const showLogButton = goForward && goBack;
  const onClickLog = () => {
    if (goForward && goBack) {
      goForward(<IncidentLog incident={incident} onBack={goBack} />);
    }
  };
  const backButton = isChild && (
    <Grid xs={12} sm={1} item>
      <IconButton style={{ width: '50px' }} size='large' onClick={goBack} color='primary'>
        <ArrowBackIcon />
      </IconButton>
    </Grid>
  );
  const logButton = canViewLogIncident && showLogButton && (
    <Grid xs={12} sm={1} item>
      <IconButton onClick={onClickLog} size='large'>
        <HistoryIcon fontSize='large' color='primary' />
      </IconButton>
    </Grid>
  );

  const geoButton = geo && (
    <Grid xs={12} sm={1} item>
      <CopyGMapLink geo={geo} size='large' />
    </Grid>
  );

  const flaggedButton = onChangeFlaggedHandler && (
    <Grid xs={12} sm={1} item>
      <CustomCheckbox checked={isFlagged} type={CheckboxType.FLAG} onChange={onChangeFlaggedHandler} />
    </Grid>
  );

  const exportButton = onExportPdfCardsHandler && (
    <Grid xs={12} sm={1} item>
      <MenuButton
        sx={{
          border: 'none',
          padding: '0px',
          width: '40px',
          minWidth: '40px',
          borderRadius: '50%',
          height: '40px',
          fontWeight: 400,
        }}
        buttonContent={<MenuIcon htmlColor='#C7D1D4' />}
        commands={[{ content: t('export-card'), onClick: onExportPdfCardsHandler }]}
      />
    </Grid>
  );
  const buttonCount = compact([backButton, logButton, geoButton, flaggedButton, exportButton]).length;

  return (
    <FormProvider {...form}>
      <form onSubmit={formCanBeEdited ? handleSubmit(onSubmit) : noop}>
        <Stack>
          <Grid container columnSpacing={1} rowSpacing={3} alignItems='center'>
            {backButton}

            <Grid xs={12} sm={12 - buttonCount} item>
              <Typography variant='h4' sx={{ marginBottom: '16px' }}>
                {formCanBeEdited ? t('edit-incident') : t('view-incident')}
                &nbsp;#
                {incident.count}
              </Typography>
            </Grid>

            {logButton}

            {!event.sitePlan?.url ? geoButton : null}

            {flaggedButton}

            {exportButton}

            <Grid xs={12} sm={6} item>
              <Typography variant='fieldLabel'>{t('registered-by')}</Typography>
              {UserRenderer(incident.registeredBy)}
            </Grid>

            <Grid xs={12} sm={3} item>
              <Typography variant='fieldLabel'>{t('registered-date')}</Typography>
              <Typography variant='date'>{formatDate(incident.registeredDate)}</Typography>
            </Grid>

            {incident.resolvedDate && (
              <Grid xs={12} sm={3} item>
                <Typography variant='fieldLabel'>{t('resolved-date')}:</Typography>
                <Typography variant='date'>{formatDate(incident.resolvedDate)}</Typography>
              </Grid>
            )}

            <Grid xs={12} item>
              <Controller
                name='title'
                rules={{ required: true, validate: (value) => Boolean((value || '').trim()) }}
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!fields.title}
                    error={Boolean(errors.title)}
                    placeholder={t('title')}
                    label={t('title')}
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 250,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='description'
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!fields.description}
                    placeholder={t('description')}
                    label={t('description')}
                    fullWidth
                    maxRows={5}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} sm={3} item>
              <SelectController
                disabled={!fields.status}
                name='status'
                label={t('status')}
                required
                data={availableStatuses}
                itemRenderer={StatusRenderer}
              />
            </Grid>

            <Grid xs={12} sm={3} item>
              <SelectController
                disabled={!fields.resolutionLevel}
                name='resolutionLevel'
                label={t('level')}
                required
                data={availableResolutionLevels}
                itemRenderer={ResolutionLevelRenderer}
              />
            </Grid>

            <Grid xs={12} sm={6} item>
              <AutocompleteController
                disabled={!fields.responsible}
                name='responsible'
                label={t('responsible')}
                placeholder={t('responsible')}
                options={users}
                getOptionLabel={(user) => getFullName(user)}
                renderOption={(props, user) => <li {...props}>{UserRenderer(user)}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <MultiUsersController
                disabled={!fields.participants}
                name='participants'
                label={t('participants')}
                options={users}
              />
            </Grid>

            <Grid xs={12} item>
              <AutocompleteController
                disabled={!fields.userFunction}
                name='userFunction'
                label={t('user-function')}
                placeholder={t('user-function')}
                options={userFunctions}
                getOptionLabel={(userFunction) => userFunction.name}
                renderOption={(props, userFunction) => <li {...props}>{userFunction.name}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <AutocompleteController
                disabled={!fields.location}
                name='location'
                label={t('location')}
                placeholder={t('location')}
                options={locations}
                getOptionLabel={(location) => location.title}
                renderOption={(props, location) => <li {...props}>{location.title}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='locationDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!fields.locationDescription}
                    placeholder={t('location-details')}
                    label={t('location-details')}
                    fullWidth
                    maxRows={5}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <EndDatetimeController
                disabled={!fields.endDatetime}
                name='endDatetime'
                label={t('end-datetime')}
                minDateTime={incident.registeredDate}
              />
            </Grid>

            {isShowRelationField && (
              <Grid xs={12} item>
                <RelationsField
                  control={form.control}
                  name='relations'
                  label={t('relations')}
                  placeholder={t('relations')}
                  options={[...tasks].sort((a, b) => a.type.localeCompare(b.type))}
                  disabled={!fields.relations}
                />
              </Grid>
            )}

            <Grid xs={12} item>
              <Controller
                name='isResponsibleInformed'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    disabled={!fields.isResponsibleInformed}
                    control={<Checkbox name='isResponsibleInformed' />}
                    label={t('responsible-informed')}
                    checked={field.value}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='isUrgent'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    disabled={!fields.isUrgent}
                    control={<Checkbox name='isUrgent' />}
                    label={t('urgent')}
                    checked={field.value}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} sm={12} item>
              <Typography variant='fieldLabel'>{t('photos')}:</Typography>
              <PhotosController disabled={!fields.photos} photos={incident.photos} />
            </Grid>

            <Grid xs={12} sm={12} item>
              <Typography variant='fieldLabel'>{t('comments')}:</Typography>
              <CommentsController disabled={!fields.comments} data={incident.comments} incidentId={incident.id} />
            </Grid>
          </Grid>
        </Stack>

        {formCanBeEdited && (
          <Button
            fullWidth
            disabled={Object.keys(errors).length > 0}
            loading={loading}
            type='submit'
            sx={{ color: 'white', marginTop: '30px' }}
            variant='contained'
          >
            {t('edit-incident')}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};
