import type { ReactElement } from 'react';

import type { PhotosAddFormProps } from '../../components/PhotosEditorForm/PhotosAddForm';
import type { PhotosEditFormProps } from '../../components/PhotosEditorForm/PhotosEditForm';
import type { UserFormSettingsProps } from '../../components/UserFormSettings';
import type { MetaData } from '../../modules/Map/feature/IncidentMap';
import type { MapFormLocationProps } from '../../modules/Map/feature/LocationMap';
import type { ProjectTaskFormProps } from '../../modules/Tasks/components/ProjectTaskForm/ProjectTaskForm';
import type { User } from '../../types';

export enum Drawer {
  INCIDENT_PHOTO_EDIT = 'incidentPhotoEdit',
  INCIDENT_PHOTO_ADD = 'incidentPhotoAdd',
  INCIDENT_ADD = 'incidentAdd',
  INCIDENT_EDIT = 'incidentEdit',
  INCIDENT_FILTER = 'incidentFilter',
  INCIDENT_TABLE_COLUMNS = 'incidentTableColumns',
  MAP = 'map',
  EVENT_LOCATION = 'eventLocation',
  USER_CREATE = 'userCreate',
  INCIDENTS_UPDATES = 'incidentsUpdates',
  USER_EDIT = 'userEdit',
  PROJECT_TASKS_COLUMNS = 'projectTasksColumnsDrawer',
  PROJECT_TASKS_FILTER = 'projectTasksFilterDrawer',
  PROJECT_TASK_FORM = 'projectTaskForm',
  DAILY_TASKS_COLUMNS = 'dailyTasksColumnsDrawer',
  DAILY_TASKS_FILTER = 'dailyTasksFilterDrawer',
  USES_FORM_SETTINGS = 'usesFormSettings',
}

export type DrawerType =
  | {
      type: Drawer.INCIDENT_ADD;
    }
  | {
      type: Drawer.INCIDENT_EDIT;
      props: { incidentId: number };
    }
  | {
      type: Drawer.INCIDENT_FILTER;
    }
  | {
      type: Drawer.INCIDENT_TABLE_COLUMNS;
    }
  | {
      type: Drawer.MAP;
      props: { mapForm: MetaData | MetaData[] };
    }
  | {
      type: Drawer.INCIDENT_PHOTO_EDIT;
      props: Omit<PhotosEditFormProps, 'onEndAction'>;
    }
  | {
      type: Drawer.INCIDENT_PHOTO_ADD;
      props: Omit<PhotosAddFormProps, 'onEndAction'>;
    }
  | {
      type: Drawer.EVENT_LOCATION;
      props: Pick<MapFormLocationProps, 'locationForm'>;
    }
  | {
      type: Drawer.USER_CREATE;
    }
  | {
      type: Drawer.INCIDENTS_UPDATES;
    }
  | {
      type: Drawer.USER_EDIT;
      props: { user: User };
    }
  | {
      type: Drawer.PROJECT_TASKS_COLUMNS;
    }
  | {
      type: Drawer.PROJECT_TASKS_FILTER;
    }
  | {
      type: Drawer.DAILY_TASKS_COLUMNS;
    }
  | {
      type: Drawer.DAILY_TASKS_FILTER;
    }
  | {
      type: Drawer.USES_FORM_SETTINGS;
      props: UserFormSettingsProps;
    }
  | {
      type: Drawer.PROJECT_TASK_FORM;
      props: ProjectTaskFormProps;
    };

export type DrawerState = {
  drawer?: DrawerType;
  isOpen: boolean;
};

export type DrawerNavigationProps = {
  onEndAction: () => void;
  goBack?: () => void;
  goForward?: (element: ReactElement) => void;
  isChild?: boolean;
};
