import type { RowNode } from 'ag-grid-community';

export function getAllParentsNode(node: RowNode): RowNode[] {
  const result = [];
  const { parent } = node;

  // Exclude root node
  if (parent && parent.rowIndex !== null) {
    result.push(parent);
    result.push(...getAllParentsNode(parent));
  }

  return result;
}
