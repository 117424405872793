import type { FC } from 'react';
import { useMemo } from 'react';

import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { API_URL } from '../../constants/env';
import { getIncident, useEditIncident } from '../../state/incidents';
import { NotificationType, useShowNotification } from '../../state/notification';
import { PhotosEditorBase } from './PhotosEditorBase';

export type PhotosEditFormProps = {
  id: number;
  count: number;
  onEndAction: () => void;
};

export const PhotosEditForm: FC<PhotosEditFormProps> = ({ id, count, onEndAction }) => {
  const incident = useRecoilValue(getIncident(id));
  const { t } = useTranslation();
  const { execute: editIncident, loading } = useEditIncident();
  const { execute: showNotification } = useShowNotification();

  const initialState = useMemo(
    () =>
      (incident?.photos ?? []).map(
        (photo) =>
          ({
            source: `${photo.url}?id=${photo.id}`,
            options: {
              type: 'local',
            },
          } as FilePondInitialFile),
      ),
    [incident],
  );
  const title = t('edit-incident-photos', { count });

  const updatePhotos = (data: FilePondFile[]) => {
    if (!incident) {
      return;
    }

    editIncident({
      incident: {
        ...incident,
        photoSources: data.map((file) => {
          const photoId =
            typeof file.source === 'string' ? new URL(file.source, API_URL).searchParams.get('id') : file.id;

          return { id: photoId, source: file.source as File | string };
        }),
      },
      successCallback: () => {
        onEndAction();
        showNotification({ type: NotificationType.SUCCESS, text: t('incident-photos-edited-successfully') });
      },
    });
  };

  return (
    <PhotosEditorBase
      initialState={initialState}
      title={title}
      onUpdate={updatePhotos}
      isLoading={loading}
      buttonText={t('update-photos')}
    />
  );
};
