import type { Layouts } from 'react-grid-layout';
import { mapValues } from 'remeda';

import type { DashboardType } from '../../types';

export const addNamesToLayout = (dashboards: DashboardType[], layout: Layouts) =>
  mapValues(layout, (settingsForMedia) =>
    settingsForMedia.map((settings, index) => ({
      ...settings,
      i: dashboards[index].name,
    })),
  );
