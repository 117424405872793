import { useRecoilValue } from 'recoil';

import { userState } from '../../../state/user';

export function useCurrentUser() {
  const currentUser = useRecoilValue(userState);
  const currentUserId = currentUser?.id;
  return {
    currentUser,
    currentUserId,
  };
}
