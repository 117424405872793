const sessionValues = ['notShowUpdatesSocket'] as const;
type SessionValues = typeof sessionValues[number];

export const sessionService = {
  getItem: (key: SessionValues) => sessionStorage.getItem(key),
  setItem: (key: SessionValues, value: string) => {
    sessionStorage.setItem(key, value);
  },
  removeItem: (key: SessionValues) => {
    sessionStorage.removeItem(key);
  },
  clear: () => {
    sessionStorage.clear();
  },
};
