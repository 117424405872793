import type {
  FC} from 'react';
import { useEffect, useState,
} from 'react';

import {
  Grid,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import useCurrentUserEvent from '../../hooks/useCurrentUserEvent';
import useDrawer from '../../hooks/useDrawer';
import { useFindUserByEmail, userByEmailState } from '../../state/user';
import { Button, DrawerContainer } from '../base';
import type { UserFormValues } from '../UserCreateFields';
import { UserCreateFields, useUserCreate } from '../UserCreateFields';
import type { UserEventFormValues } from '../UserEventFields';
import { useAddUserToEvent, UserEventFields } from '../UserEventFields';

type UserCreateOrAddFormValues = {email: string} & UserFormValues & UserEventFormValues

export const UserCreateOrAddForm: FC = () => {
  const { t } = useTranslation();
  const userByEmail = useRecoilValue(userByEmailState);
  const clearUserByEmail = useResetRecoilState(userByEmailState);
  const userEventByEmail = useCurrentUserEvent(userByEmail);
  const isUserAlreadyOnEvent = Boolean(userEventByEmail);
  const [createdUser, setCreatedUser] = useState<{id: number}>();
  const isCreating = !userByEmail && !createdUser;
  const { closeDrawer } = useDrawer();
  const { execute: findUserByEmail, loading: finding } = useFindUserByEmail();
  const {
    onSubmit: onCreate, loading: loadingCreate, defaultValues: defaultValuesCreate, title: titleCreate,
  } = useUserCreate();
  const {
    onSubmit: onAddUser, loading: loadingAdd, defaultValues: defaultValuesAdd, title: titleAdd,
  } = useAddUserToEvent();

  const form = useForm<UserCreateOrAddFormValues>({
    defaultValues: {
      email: '',
      ...defaultValuesCreate,
      ...defaultValuesAdd,
    },
  });
  const {
    control, watch, handleSubmit,
  } = form;
  const watchEmail = watch('email');
  useDebounce(
    () => {
      if (watchEmail) {
        findUserByEmail(watchEmail);
      }
    },
    1000,
    [findUserByEmail, watchEmail],
  );
  useEffect(() => () => { clearUserByEmail(); }, [clearUserByEmail]);

  const onSubmit = (values: UserCreateOrAddFormValues) => {
    if (isCreating) {
      onCreate(values, ({ user }) => setCreatedUser(user));
    } else {
      const userId = createdUser ? createdUser.id : userByEmail.id;
      onAddUser({ ...values, userId }, () => closeDrawer());
    }
  };

  const title = isCreating ? titleCreate : titleAdd;
  const isLoading = loadingCreate || loadingAdd || finding;

  return (
    <FormProvider {...form}>
      <DrawerContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography variant='h4' sx={{ marginBottom: '16px' }}>
            {title}
          </Typography>

          <Grid container columnSpacing={1} rowSpacing={2}>

            <Grid xs={12} item>
              <Controller
                name='email'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={Boolean(createdUser)}
                    placeholder={t('email')}
                    label={t('email')}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>

            {isCreating
              ? <UserCreateFields />
              : <UserEventFields />}

          </Grid>
        </Stack>
        <Button
          fullWidth
          disabled={isUserAlreadyOnEvent}
          loading={isLoading}
          type='submit'
          sx={{ marginTop: 'auto', color: 'white' }}
          variant='contained'
        >
          {title}
        </Button>
      </DrawerContainer>
    </FormProvider>
  );
};
