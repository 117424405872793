import type { TFunction } from 'i18next';
import type { GanttLocale } from 'mrreds-rc-gantt/dist/types/Gantt';

export const getLocale = (t: TFunction<'translation'>): GanttLocale => ({
  today: t('ganttaTable.today'),
  day: t('ganttaTable.day'),
  week: t('ganttaTable.week'),
  month: t('ganttaTable.month'),
  quarter: t('ganttaTable.quarter'),
  halfYear: t('ganttaTable.halfYear'),
  firstHalf: t('ganttaTable.firstHalf'),
  secondHalf: t('ganttaTable.secondHalf'),
  majorFormat: {
    day: 'YYYY, MMMM',
    week: 'YYYY, MMMM',
    month: 'YYYY',
    quarter: 'YYYY',
    halfYear: 'YYYY',
  },
  minorFormat: {
    day: 'D',
    week: `wo [${t('ganttaTable.week').toLowerCase()}]`,
    month: 'MMMM',
    quarter: '[Q]Q',
    halfYear: 'YYYY-',
  },
  locale: t('ganttaTable.locale'),
  days: t('ganttaTable.days'),
  notData: t('ganttaTable.notData'),
});
