import { useCallback } from 'react';

import type { ValueSetterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { useViewedBy } from '../../../../../state/incidents';
import { useUpdateFlaggedIncidents, userState } from '../../../../../state/user';
import type { Incident } from '../../../../../types';

export function useGridFlaggedSetter() {
  const { id: userId } = useRecoilValue(userState);
  const { execute: updateFlaggedIncidents } = useUpdateFlaggedIncidents();
  const { execute: updateViewedBy } = useViewedBy();
  const updateViewedByIfNotYet = useCallback(
    (incident: Incident) => {
      const isViewedByUser = incident.viewedBy.some((user) => user.id === userId);
      if (!isViewedByUser) {
        updateViewedBy({ userId, incidentId: incident.id });
      }
    },
    [userId, updateViewedBy],
  );

  const flaggedSetter = useCallback(
    ({ colDef, data, newValue }: ValueSetterParams) => {
      if (!colDef.field) {
        return false;
      }

      const incident: Incident = data;
      updateViewedByIfNotYet(incident);
      updateFlaggedIncidents({ incidentId: incident.id, isFlagged: newValue });

      return true;
    },
    [updateFlaggedIncidents, updateViewedByIfNotYet],
  );

  return flaggedSetter;
}
