import type { ChangeEvent } from 'react';
import { useCallback } from 'react';

import { Badge, Button } from '@mui/material';
import { Trans } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Drawer, useShowDrawer } from '../../../state/drawer';
import { permissionState } from '../../../state/event';
import { useIncidentsList } from '../../../state/incidents';
import { incidentsFilterState } from '../../../state/incidentsFilter';
import { Status } from '../../../types';
import { AddNewIncidentButton } from '../components/AddNewIncidentButton';
import { CheckboxControl } from '../components/CheckboxControl';
import { FilterButton } from '../components/FilterButton';
import { WrapperBox } from '../components/WrapperBox';

export const ControlPanelMap = () => {
  const { execute: showDrawer } = useShowDrawer();
  const [filters, setFiltersState] = useRecoilState(incidentsFilterState);
  const { canCreateIncident } = useRecoilValue(permissionState);
  const showResolved = Boolean(filters?.status?.find((status) => Status.RESOLVED === status));
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFiltersState((prevFilters) => {
        const prevStatus = prevFilters?.status ? prevFilters.status : [];
        const newStatus = event.target.checked
          ? [...prevStatus, Status.RESOLVED]
          : prevStatus?.filter((status) => Status.RESOLVED !== status);

        return {
          ...prevFilters,
          status: newStatus,
        };
      });
    },
    [setFiltersState],
  );
  const { data: incidents } = useIncidentsList();
  const incidentsWithoutLocation = incidents.filter((incident) => !incident.location && !incident.geo);
  const onClickIncidentsWithoutLocation = useCallback(() => {
    if (incidentsWithoutLocation.length) {
      showDrawer({
        type: Drawer.MAP,
        props: {
          mapForm: incidentsWithoutLocation.map(({ id }) => ({ id, type: 'incident' })),
        },
      });
    }
  }, [incidentsWithoutLocation, showDrawer]);

  return (
    <WrapperBox>
      <AddNewIncidentButton onClick={() => showDrawer({ type: Drawer.INCIDENT_ADD })} disabled={!canCreateIncident} />
      <CheckboxControl
        name='isShowResolved'
        label={<Trans i18nKey='show-resolved' />}
        checked={showResolved}
        onChange={onChange}
      />
      <Badge badgeContent={incidentsWithoutLocation.length} color='primary' showZero>
        <Button
          sx={{ color: '#FFFFFF' }}
          variant='contained'
          disabled={!incidentsWithoutLocation.length}
          onClick={onClickIncidentsWithoutLocation}
        >
          <Trans i18nKey='show-incidents-without-location' />
        </Button>
      </Badge>
      <FilterButton onClick={() => showDrawer({ type: Drawer.INCIDENT_FILTER })} />
    </WrapperBox>
  );
};
