import { atom } from 'recoil';

import { getAuthToken, removeAuthToken, setAuthToken } from '../../utils/authUtils';

export const authState = atom({
  key: 'auth',
  default: getAuthToken(),
  effects: [
    ({ setSelf, onSet }) => {
      const token = getAuthToken();

      if (token) {
        setSelf(token);
      }

      onSet((newValue, _, isReset) => {
        const isRemove = isReset || !newValue;

        if (isRemove) {
          removeAuthToken();
        } else {
          setAuthToken(newValue);
        }
      });
    },
  ],
});
