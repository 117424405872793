import { useRecoilValue } from 'recoil';

import { drawerState, useCloseDrawer, useShowDrawer } from '../state/drawer';

const useDrawer = () => {
  const { isOpen, drawer } = useRecoilValue(drawerState);
  const { execute: showDrawer } = useShowDrawer();
  const { execute: closeDrawer } = useCloseDrawer();

  return {
    showDrawer, closeDrawer, isOpen, drawer,
  };
};

export default useDrawer;
