import { endOfDay, startOfDay } from 'date-fns/esm';
import * as qs from 'qs';

import type { INCIDENT_COLORS } from '../../constants/color';
import type { Incident, UserDesktopFilters } from '../../types';
import { Status } from '../../types';
import type { GetIncidentsParams } from './types';

export const getFilters = (
  {
    responsibleIsInformed,
    incidentIsUrgent,
    locations,
    registeredBy,
    responsible,
    resolutionLevel,
    userFunctions,
    status,
    text,
    flagged,
    viewedUser,
    participants,
    typeDate,
    endDate,
    startDate,
  }: UserDesktopFilters,
  eventId: number,
) => {
  const filters: Record<string, unknown> = {
    [typeDate]: {
      $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
      $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
    },
    isResponsibleInformed: responsibleIsInformed || undefined,
    isUrgent: incidentIsUrgent || undefined,
    location: {
      id: locations,
    },
    registeredBy: {
      id: registeredBy,
    },
    userFunction: userFunctions,
    responsible: {
      id: responsible,
    },
    event: {
      id: [eventId],
    },
    isUnread: Boolean(viewedUser),
    isFlagged: Boolean(flagged),
    resolutionLevel,
    status: {
      $notIn: [Status.DRAFT],
      $in: status,
    },
    participants: {
      id: participants,
    },
    containsText: text,
  };

  return filters;
};

const POPULATIONS = [
  'comments',
  'responsible',
  'registeredBy',
  'photos',
  'location',
  'geo',
  'location.geo',
  'comments.author',
  'comments.image',
  'comments.hiddenUser',
  'comments.editedUser',
  'userFunction',
  'viewedBy',
  'participants',
  'relations',
];

export const getQuery = ({ sort, filter, page, pageSize, eventId }: GetIncidentsParams) =>
  qs.stringify(
    {
      sort: sort ? [`${sort.field}:${sort.order}`] : ['count:asc'],
      filters: getFilters(filter, eventId),
      populate: POPULATIONS,
      pagination: {
        pageSize,
        page,
      },
    },
    { encodeValuesOnly: true },
  );

export const getQueryAll = ({ filter, eventId }: Pick<GetIncidentsParams, 'filter' | 'eventId'>) =>
  qs.stringify(
    {
      filters: getFilters(filter, eventId),
      populate: POPULATIONS,
      pagination: {
        limit: -1,
      },
    },
    { encodeValuesOnly: true },
  );

export const getEditIncidentPopulate = () =>
  qs.stringify(
    {
      populate: POPULATIONS,
    },
    { encodeValuesOnly: true },
  );

export const getQueryByIds = ({ eventId, incidentsIds }: { eventId: number; incidentsIds: number[] }) =>
  qs.stringify(
    {
      filters: {
        event: {
          id: [eventId],
        },
        id: incidentsIds,
      },
      populate: POPULATIONS,
      pagination: {
        limit: -1,
      },
    },
    { encodeValuesOnly: true },
  );

export const getColor = (incident: Incident): keyof typeof INCIDENT_COLORS =>
  incident.status === Status.RESOLVED ? 'Grey' : incident.resolutionLevel;
