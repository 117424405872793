/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */

import type { RowNode } from 'ag-grid-community';
import { format, intervalToDuration } from 'date-fns';
import type { TFunction } from 'i18next';
import type { ColumnInput, RowInput } from 'jspdf-autotable';
import type { RecoilTaskInterface } from 'recoil-toolkit';
import { last } from 'remeda';
import type { ColInfo } from 'xlsx-js-style';

import { incidentService } from '../../api';
import { actionTitle } from '../../components/IncidentLog/components/utils';
import { columnsState as incidentTableColumnsState } from '../../components/IncidentTable/state';
import { getCountByLevel } from '../../modules/EntitiesGrid/lib/getCountByLevel';
import {
  currentTabWithDefaultDailyTasksState,
  dailyTasksColumnsState,
  type DailyTask,
} from '../../modules/Tasks/state/dailyTasks';
import { projectTasksColumnsState, projectTasksStateWithHierarchy } from '../../modules/Tasks/state/projectTasks';
import { TaskType } from '../../modules/Tasks/state/tasks';
import type { IncidentLog } from '../../types';
import { commentsComparator } from '../../utils/comparators';
import formatDate from '../../utils/formatDate';
import getFullName from '../../utils/getFullName';
import getFullUrl from '../../utils/getFullUrl';
import indexById from '../../utils/indexedById';
import { getFormatFileByMime } from '../../utils/mime';
import { eventState } from '../event';
import { incidentsFilterState } from '../incidentsFilter';
import { countState, pageSizeState, pageState } from '../incidentTable/pagination';
import { sortState } from '../incidentTable/sort';
import { locationsState } from '../locations';
import { userEventBrand } from '../user';
import { userFunctionsState } from '../userFunctions';
import {
  NOT_AVAILABLE_TEXT,
  makeXlsxHeaderSettings,
  makePdfColumnsSettingsIncident,
  makeXlsxHeaderIncidents,
  makeXlsxHeaderProjects,
  makeXlsxHeaderDaily,
  makePdfColumnsSettingsProjectTask,
  makePdfColumnsSettingsDailyTask,
} from './constants';
import {
  createHeader as createCreateIncidentActionHeader,
  convertAttrsToRows as convertCreateIncidentActionToBodyRows,
  isPhotosCell,
  parsePhotosCell,
} from './exportIncidentLog/exportCreateIncidentAction';
import {
  createHeader as createEditCommentActionHeader,
  convertAttrsToRows as convertEditCommentActionToBodyRows,
} from './exportIncidentLog/exportEditCommentAction';
import {
  createHeader as createUpdateIncidentActionHeader,
  convertAttrsToRows as convertUpdateIncidentActionToBodyRows,
} from './exportIncidentLog/exportUpdateIncidentAction';
import type { CANVAS_PROPS } from './utils';
import {
  getExportAllIncidentsQuery,
  mapToPdfRowIncident,
  mapToExcelRow,
  getExportCardIncidentsQuery,
  loadResource,
  loadPhoto,
  truncateText,
  prepareDoc,
  addHeaderToPage,
  makePageInc,
  getCanvasProperties,
  TEXT_HEADER_HEIGHT,
  calcMarginXForPaddingRight,
  calcMarginXForPaddingAuto,
  loadImages,
  resizeImage,
  mapToPdfRowProjectTask,
  mapToPdfRowDailyTask,
  excelCell,
} from './utils';
import { calculateAspectRatioAndOffset } from './utils/calculateAspectRatioAndOffset';
import { formatProjects, formatDaily, formatIncident } from './utils/formats';
import { getPreparedPdfTableDocOptions } from './utils/getPreparedPdfTableDocOptions';
import { getSubTitleNameField } from './utils/subTitle';

const exportToExcel = async (
  headers: ColInfo[],
  rows: unknown[][],
  nameWorkbook: string,
  delimiterFileName = ' ',
  inludeHeaders = true,
) => {
  const { writeFile, utils } = await import('xlsx-js-style');

  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet([inludeHeaders ? headers : [], ...rows].filter((item) => Boolean(item.length)));
  worksheet['!cols'] = headers;

  utils.book_append_sheet(workbook, worksheet, nameWorkbook);
  const currentDate = format(Date.now(), 'dd-LL-yyyy HH-mm-ss');
  writeFile(workbook, `${nameWorkbook}${delimiterFileName}${currentDate}.xlsx`);
};

export const exportToExcelTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>) => {
    const pageSize = 200;
    const count = snapshot.getLoadable(countState).getValue();
    const pages = Math.ceil(count / pageSize);
    const sort = snapshot.getLoadable(sortState).getValue();
    const columnsState = snapshot.getLoadable(incidentTableColumnsState).getValue();
    const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
      .filter((key) => columnsState[key].isShow)
      .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
      .map((key) => {
        const currentKey = key.includes('.') ? key.split('.')[0] : key;

        if (key === 'comments') {
          return 'lastComment';
        }
        return currentKey;
      });

    const filter = snapshot.getLoadable(incidentsFilterState).getValue();
    const event = snapshot.getLoadable(eventState).getValue();

    const results = await Promise.all(
      Array.from({ length: pages }, (_, i) => i + 1).map(async (page) => {
        const query = getExportAllIncidentsQuery({ filter, eventId: event.id, page, pageSize, sort });
        const responseData = await incidentService.getIncidents(query);
        return responseData.data;
      }),
    );

    const xlsxHeader = makeXlsxHeaderSettings(t)(makeXlsxHeaderIncidents(fields));
    const mapper = mapToExcelRow(t, xlsxHeader, formatIncident(filter.hideDateTime));
    const rows = results.flat().map(mapper);

    await exportToExcel(xlsxHeader, rows, t('incidents'));
  };

export const exportToExcelProjects =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>, rows: RowNode['data'][]) => {
    const columnsState = snapshot.getLoadable(projectTasksColumnsState).getValue();
    const maxNestingLevel = rows.reduce((acc, row) => Math.max(acc, row.orgHierarchy.length), 0);
    const transformedRows = rows.map((row) => {
      const levelRow = row.orgHierarchy.length;
      const transformedRow = { ...row };

      if (maxNestingLevel > 1) {
        transformedRow.count = getCountByLevel(transformedRow.count, levelRow);

        new Array(maxNestingLevel).fill('').forEach((_, index) => {
          transformedRow[getSubTitleNameField(index + 1)] = '';
        });

        if (levelRow >= 1) {
          transformedRow[getSubTitleNameField(levelRow)] = row.title;
          transformedRow.title = '';
        }
      }

      return transformedRow;
    });
    const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
      .filter((key) => columnsState[key].isShow)
      .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
      .reduce<string[]>((acc, key) => {
        let currentKey = key.includes('.') ? key.split('.')[0] : key;

        if (key === 'comments') {
          currentKey = 'lastComment';
        }

        acc.push(currentKey);

        if (currentKey === 'title' && maxNestingLevel > 1) {
          acc.push(...new Array(maxNestingLevel).fill('').map((_, index) => getSubTitleNameField(index + 1)));
        }

        return acc;
      }, []);

    const xlsxHeader = makeXlsxHeaderSettings(t)(makeXlsxHeaderProjects(fields, maxNestingLevel));
    const mapper = mapToExcelRow(t, xlsxHeader, formatProjects);
    const cellRows = transformedRows.map(mapper);

    await exportToExcel(xlsxHeader, cellRows, t('projects'));
  };

export const exportToExcelDaily =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>, rows: RowNode['data'][]) => {
    const exportDay = rows[rows.length - 1]?.day || new Date();
    const columnsState = snapshot.getLoadable(dailyTasksColumnsState).getValue();
    const event = snapshot.getLoadable(eventState).getValue();

    const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
      .filter((key) => columnsState[key].isShow)
      .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
      .map((key) => {
        const currentKey = key.includes('.') ? key.split('.')[0] : key;

        if (key === 'comments') {
          return 'lastComment';
        }
        return currentKey;
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowsByDate = rows.reduce<{ [key: string]: any[] }>((acc, row) => {
      const startDatetime = format(row.startDatetime ?? exportDay, 'dd.MM.yyyy');
      return {
        ...acc,
        [startDatetime]: [...(acc[startDatetime] ?? []), row],
      };
    }, {});
    const listDates = Object.keys(rowsByDate);
    const xlsxHeader = makeXlsxHeaderSettings(t)(makeXlsxHeaderDaily(fields));
    const mapper = mapToExcelRow(t, xlsxHeader, formatDaily);
    const resultCellRows: unknown[][] = [];

    listDates.forEach((date, idx) => {
      resultCellRows.push([excelCell(false)(date), idx === 0 ? excelCell(false)(`${event.name} DRS`) : []]);
      resultCellRows.push(xlsxHeader);
      rowsByDate[date].map(mapper).forEach((item) => resultCellRows.push([...item]));

      // Here is adding empty rows for create divider between dates
      Array(2)
        .fill(excelCell(false)(''))
        .forEach((item) => resultCellRows.push([item]));
    });

    await exportToExcel(
      xlsxHeader,
      resultCellRows,
      `${t('daily-plan')} ${format(exportDay, 'dd-LL-yyyy')}`,
      '_',
      false,
    );
  };

export const exportToPdfTableTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>) => {
    const AutoTable = (await import('jspdf-autotable')).default;

    const currentDate = format(Date.now(), 'dd-LL-yyyy HH:mm');

    const sort = snapshot.getLoadable(sortState).getValue();
    const columnsState = snapshot.getLoadable(incidentTableColumnsState).getValue();
    const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
      .filter((key) => columnsState[key].isShow)
      .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
      .map((key) => {
        const currentKey = key.includes('.') ? key.split('.')[0] : key;

        if (key === 'comments') {
          return 'lastComment';
        }
        return currentKey;
      });
    const pageSize = 200;
    const count = snapshot.getLoadable(countState).getValue();
    const pages = Math.ceil(count / pageSize);

    const event = snapshot.getLoadable(eventState).getValue();
    const filter = snapshot.getLoadable(incidentsFilterState).getValue();
    const brand = snapshot.getLoadable(userEventBrand).getValue();

    const results = await Promise.all(
      Array.from({ length: pages }, (_, i) => i + 1).map(async (page) => {
        const query = getExportAllIncidentsQuery({ filter, eventId: event.id, page, pageSize, sort });
        const responseData = await incidentService.getIncidents(query);
        return responseData.data;
      }),
    );

    const { doc, options } = await getPreparedPdfTableDocOptions({
      event,
      brand,
      t,
      currentDate,
      taskType: TaskType.Incident,
    });

    AutoTable(doc, {
      ...options,
      body: results.flat().map((incident) => mapToPdfRowIncident(incident, t, fields, filter.hideDateTime)),
      columns: makePdfColumnsSettingsIncident(t, fields),
    });

    doc.save(`${t('incidents')} ${currentDate.replace(':', '-')}.pdf`);
  };

export const exportToPdfTableProjectTasks = (rti: RecoilTaskInterface) => async (t: TFunction<'translation'>) => {
  const { snapshot } = rti;

  const AutoTable = (await import('jspdf-autotable')).default;
  const currentDate = format(Date.now(), 'dd-LL-yyyy HH:mm');

  const projectTasks = snapshot.getLoadable(projectTasksStateWithHierarchy).getValue();
  const maxNestingLevel = projectTasks.reduce((acc, task) => Math.max(acc, task.orgHierarchy.length), 0);
  const transformedRows = projectTasks.map((task) => {
    const levelRow = task.orgHierarchy.length;
    const transformedRow = { ...task };

    if (maxNestingLevel > 1) {
      transformedRow.count = getCountByLevel(transformedRow.count, levelRow);
    }

    return transformedRow;
  });
  const columnsState = snapshot.getLoadable(projectTasksColumnsState).getValue();

  const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
    .filter((key) => columnsState[key].isShow)
    .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
    .reduce<string[]>((acc, key) => {
      let currentKey = key.includes('.') ? key.split('.')[0] : key;

      if (key === 'comments') {
        currentKey = 'lastComment';
      }

      acc.push(currentKey);

      return acc;
    }, []);

  const event = snapshot.getLoadable(eventState).getValue();
  const brand = snapshot.getLoadable(userEventBrand).getValue();

  const { doc, options } = await getPreparedPdfTableDocOptions({
    event,
    brand,
    t,
    currentDate,
    taskType: TaskType.ProjectTask,
  });

  AutoTable(doc, {
    ...options,
    body: transformedRows.map((task) => mapToPdfRowProjectTask(task, t, fields)),
    columns: makePdfColumnsSettingsProjectTask(t, fields),
  });

  doc.save(`${t('project-plan')} ${currentDate.replace(':', '-')}.pdf`);
};

export const exportToPdfTableDailyTasks = (rti: RecoilTaskInterface) => async (t: TFunction<'translation'>) => {
  const { snapshot } = rti;

  const AutoTable = (await import('jspdf-autotable')).default;

  const dailyTasksCurrentTab = snapshot.getLoadable(currentTabWithDefaultDailyTasksState).getValue();
  const columnsState = snapshot.getLoadable(dailyTasksColumnsState).getValue();
  const event = snapshot.getLoadable(eventState).getValue();
  const brand = snapshot.getLoadable(userEventBrand).getValue();
  const result: { day: Date; tasks: DailyTask[] } = {
    day: new Date(),
    tasks: [],
  };

  if (dailyTasksCurrentTab && 'tasks' in dailyTasksCurrentTab) {
    result.tasks = dailyTasksCurrentTab.tasks;
    result.day = dailyTasksCurrentTab.day;
  }

  const currentDate = format(result.day, 'dd-LL-yyyy');

  const fields = (Object.keys(columnsState) as (keyof typeof columnsState)[])
    .filter((key) => columnsState[key].isShow)
    .sort((a, b) => (columnsState[a].index || 0) - (columnsState[b].index || 0))
    .map((key) => {
      const currentKey = key.includes('.') ? key.split('.')[0] : key;

      if (key === 'comments') {
        return 'lastComment';
      }
      return currentKey;
    });

  const { doc, options } = await getPreparedPdfTableDocOptions({
    event,
    brand,
    t,
    currentDate,
    taskType: TaskType.OperationalTask,
  });

  AutoTable(doc, {
    ...options,
    body: result.tasks.map((task) => mapToPdfRowDailyTask(task, t, fields)),
    columns: makePdfColumnsSettingsDailyTask(t, fields),
  });

  doc.save(`${t('daily-plan')} ${currentDate.replace(':', '-')}.pdf`);
};

export const exportToPdfCardsTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>, incidentIds?: number[]) => {
    const JsPDF = (await import('jspdf')).default;

    const doc = new JsPDF();

    const event = snapshot.getLoadable(eventState).getValue();
    const filter = snapshot.getLoadable(incidentsFilterState).getValue();
    const pageSize = snapshot.getLoadable(pageSizeState).getValue();
    const sort = snapshot.getLoadable(sortState).getValue();
    const page = snapshot.getLoadable(pageState).getValue();
    const brand = snapshot.getLoadable(userEventBrand).getValue();
    const query = getExportCardIncidentsQuery({
      filter,
      eventId: event.id,
      page,
      pageSize,
      sort,
      incidentIds,
    });
    const responseData = await incidentService.getIncidents(query);

    const regularFontContent = await loadResource('./assets/IBMPlexSans-Regular.ttf');
    const boldFontContent = await loadResource('./assets/IBMPlexSans-Bold.ttf');
    const logoImage = brand.enable
      ? brand.logo && (await loadResource(`${brand.logo.url}`))
      : await loadResource('./assets/logo.png');

    doc
      .addFileToVFS('IBMPlexSans-Regular.ttf', regularFontContent)
      .addFileToVFS('IBMPlexSans-Bold.ttf', boldFontContent);
    doc.addFont('IBMPlexSans-Regular.ttf', 'IBMPlexSans', 'normal');
    doc.addFont('IBMPlexSans-Bold.ttf', 'IBMPlexSans', 'bold');

    const imagePromises = responseData.data.map((incident) => {
      const mainPhoto = incident.photos?.[0];
      const commentPhotos = [...incident.comments]
        .sort(commentsComparator)
        .slice(-3)
        .map((comment) => loadPhoto(comment.image, 25));

      return Promise.all([loadPhoto(mainPhoto), ...commentPhotos]);
    });

    const incidentImages = await Promise.all(imagePromises);
    const currentDate = format(Date.now(), 'dd-LL-yyyy HH:mm');

    responseData.data.forEach((incident, index) => {
      const {
        count,
        title,
        description,
        registeredBy,
        status,
        resolutionLevel,
        location,
        responsible,
        registeredDate,
        resolvedDate,
        endDatetime,
        participants,
      } = formatIncident(filter.hideDateTime)(incident, t);
      const { width: pageWidth } = doc.internal.pageSize;

      const titleContent = truncateText(title, 150);
      const descriptionContent = truncateText(description, 900);
      const eventNameContext = truncateText(event.name, 30);
      const eventNamePosition = pageWidth / 2 - (event.name.length * 2.4) / 2;

      doc.setFont('IBMPlexSans', 'bold');

      if (logoImage) {
        const imageProperties = doc.getImageProperties(logoImage);
        const { width, height, y } = calculateAspectRatioAndOffset(imageProperties.width, imageProperties.height);
        doc.addImage(logoImage || '', 'jpeg', 5, y, width, height);
      }

      doc
        .setFontSize(16)
        .text(t('ims') || '', logoImage ? 20 : 5, 12)
        .text(eventNameContext, eventNamePosition, 12)
        .setFontSize(12)
        .text(currentDate, pageWidth - 40, 12)
        .setFontSize(12)
        .text(`#${count} – ${titleContent} | ${resolutionLevel}`, 5, 30, { maxWidth: 100 });

      doc
        .text(status, 5, 55)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('responsible')}: `, 5, 65)
        .setFont('IBMPlexSans', 'normal')
        .text(responsible, 50, 65)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('participants')}: `, 5, 75)
        .setFont('IBMPlexSans', 'normal')
        .text(participants, 50, 75)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('registered-on')}: `, 5, 85)
        .setFont('IBMPlexSans', 'normal')
        .text(registeredDate, 50, 85)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('registered-by')}: `, 5, 95)
        .setFont('IBMPlexSans', 'normal')
        .text(registeredBy, 50, 95)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('closed-on')}: `, 5, 105)
        .setFont('IBMPlexSans', 'normal')
        .text(resolvedDate || NOT_AVAILABLE_TEXT, 50, 105)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('end-datetime')}: `, 5, 115)
        .setFont('IBMPlexSans', 'normal')
        .text(endDatetime || NOT_AVAILABLE_TEXT, 50, 115)
        .line(5, 120, pageWidth - 5, 120);

      doc
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('location')}: `, 5, 125)
        .setFont('IBMPlexSans', 'normal')
        .text(location || NOT_AVAILABLE_TEXT, 50, 125)
        .setFont('IBMPlexSans', 'bold')
        .text(`${t('description')}: `, 5, 135)
        .setFont('IBMPlexSans', 'normal')
        .text(descriptionContent, 50, 135, { maxWidth: pageWidth - 50 - 10 });

      const availableComments = incident.comments.filter((comment) => !comment.hidden);
      const lastComments = [...availableComments].sort(commentsComparator).slice(-3);
      let currentPos = 185;

      doc
        .line(5, currentPos, pageWidth - 5, currentPos)
        .setFont('IBMPlexSans', 'bold')
        .text(
          `${t('three-last-comments')} ${!lastComments.length ? `: ${NOT_AVAILABLE_TEXT}` : ''}`,
          5,
          currentPos + 5,
        );

      currentPos = currentPos + 5 + 10;
      if (lastComments.length) {
        const [, ...commentPhotos] = incidentImages[index];
        lastComments.forEach((lastComment, commentIndex) => {
          const commentBody = truncateText(lastComment?.body ?? '', 200);
          const commentAuthor = getFullName(lastComment.author);
          const commentDate = formatDate(lastComment.createdDate);

          const commentFile = commentPhotos[commentIndex];
          const fileType = getFormatFileByMime(commentFile?.mime ?? '');
          const textOpts = {
            maxWidth: pageWidth,
          };

          doc
            .setFont('IBMPlexSans', 'normal')
            .text(`${t('submitted-by')}: ${commentAuthor} ${commentDate}`, 5, currentPos)
            .text(commentBody, 5, currentPos + 10, {
              maxWidth: pageWidth - (commentFile?.width || 0) - 20,
            });

          const textDimensions = doc.getTextDimensions(commentBody, textOpts);

          if (commentFile && fileType === 'image') {
            doc.addImage(
              commentFile.photoSrc,
              'jpeg',
              pageWidth - commentFile.width - 5,
              currentPos + textDimensions.h - 15,
              commentFile.width,
              commentFile.height,
            );
          }

          if (commentFile && fileType && fileType !== 'image') {
            doc.textWithLink(commentFile.name, 5, currentPos + textDimensions.h - 15, {
              url: getFullUrl(commentFile.url),
            });
          }

          currentPos += 30;
        });
      }
      if (incident.resolvedDate) {
        const { days, hours, minutes } = intervalToDuration({
          start: incident.registeredDate,
          end: incident.resolvedDate,
        });

        doc
          .setFont('IBMPlexSans', 'bold')
          .text(`${t('resolution-time')}:`, 5, currentPos)
          .setFont('IBMPlexSans', 'normal')
          .text(`${days}d ${hours}h ${minutes}m`, 50, currentPos);
      }
      const mainImage = incidentImages[index][0];

      if (mainImage) {
        doc.addImage(
          mainImage.photoSrc,
          'jpeg',
          pageWidth - mainImage.width - 5,
          20,
          mainImage.width,
          mainImage.height,
        );
      }

      if (index < responseData.data.length - 1) {
        doc.addPage('a4');
      }
    });

    doc.save(`${t('incidents')} ${currentDate.replace(':', '-')}.pdf`);
  };

export const exportToPdfDashboardsTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>, charts: Record<string, HTMLElement>, taskType: TaskType) => {
    const html2canvas = (await import('html2canvas')).default;
    const { doc, logoImage } = await prepareDoc(snapshot, { orientation: 'l' });
    const goToNextPage = makePageInc(doc);
    const currentDate = format(Date.now(), 'dd-LL-yyyy HH:mm');
    const addHeader = async () => addHeaderToPage(doc, logoImage, snapshot, t, currentDate, taskType);
    const addPng = (props: CANVAS_PROPS, marginX: number, marginY: number) => {
      doc.addImage(props.png, 'PNG', marginX, marginY, props.width, props.height);
    };

    const padding = 20;
    const defaultMarginY = TEXT_HEADER_HEIGHT + padding;
    const ratioForSingle = 0.75;
    if (charts.activeVsResolved) {
      await addHeader();
      const activeVsResolvedProps = await getCanvasProperties(
        doc,
        html2canvas,
        charts.activeVsResolved,
        ratioForSingle,
      );
      const dashboardTitle = t('dashboard-export');
      const titleMarginX = calcMarginXForPaddingAuto({
        ...activeVsResolvedProps,
        width: dashboardTitle.length * 2.4,
      });
      doc.setFont('IBMPlexSans', 'bold').setFontSize(16).text(dashboardTitle, titleMarginX, defaultMarginY);
      addPng(activeVsResolvedProps, calcMarginXForPaddingAuto(activeVsResolvedProps), defaultMarginY + padding);
      goToNextPage();
    }
    if (charts.status && charts.resolutionLevel) {
      await addHeader();

      const statusProps = await getCanvasProperties(doc, html2canvas, charts.status, ratioForSingle);
      const resolutionLevelProps = await getCanvasProperties(doc, html2canvas, charts.resolutionLevel, ratioForSingle);

      addPng(statusProps, padding, defaultMarginY);
      addPng(resolutionLevelProps, calcMarginXForPaddingRight(resolutionLevelProps, padding), defaultMarginY);
      goToNextPage();
    }

    const restOfCharts = ['registeredVsResolved', 'topResponsibles', 'userFunctions', 'location'];
    // eslint-disable-next-line no-restricted-syntax
    for (const chartName of restOfCharts) {
      if (charts[chartName]) {
        await addHeader();

        const props = await getCanvasProperties(doc, html2canvas, charts[chartName], ratioForSingle);

        addPng(props, calcMarginXForPaddingAuto(props), defaultMarginY);
        if (chartName !== last(restOfCharts)) {
          goToNextPage();
        }
      }
    }

    doc.save(`${t('dashboard')} ${currentDate.replace(':', '-')}.pdf`);
  };

export const exportToPdfLogTask =
  ({ snapshot }: RecoilTaskInterface) =>
  async (t: TFunction<'translation'>, incidentCount: number, incidentLogs: IncidentLog[]) => {
    const AutoTable = (await import('jspdf-autotable')).default;
    const locations = indexById(snapshot.getLoadable(locationsState).getValue());
    const userFunctions = indexById(snapshot.getLoadable(userFunctionsState).getValue());
    const { doc, logoImage } = await prepareDoc(snapshot, { orientation: 'p' });
    const goToNextPage = makePageInc(doc);
    const currentDate = format(Date.now(), 'dd-LL-yyyy HH:mm');
    const addHeader = async () => addHeaderToPage(doc, logoImage, snapshot, t, currentDate, TaskType.Incident);
    const padding = 15;

    const lastLog = last(incidentLogs);
    const firstLog = incidentLogs[0];
    const allPhotos = incidentLogs.flatMap((log) => {
      let photos;

      switch (log.actionDescription.type) {
        case 'create':
          return log.actionDescription?.attrs?.photos || [];
        case 'addComment':
          return log.actionDescription?.attrs?.comment?.image ? [log.actionDescription?.attrs?.comment?.image] : [];
        case 'update':
          photos = log.actionDescription?.attrs?.photos;
          return [...(photos?.old || []), ...(photos?.new || [])];
        default:
          return [];
      }
    });
    const images = await loadImages(allPhotos);
    const { width: pageWidth } = doc.internal.pageSize;
    const tableWidth = pageWidth - padding * 2;
    const addTable = (columns: ColumnInput[] | undefined, body: RowInput[] | undefined, currentPos: number) =>
      AutoTable(doc, {
        columns,
        rowPageBreak: 'avoid',
        margin: {
          top: currentPos,
          left: padding,
          right: padding,
          bottom: padding,
        },
        styles: {
          font: 'IBMPlexSans',
        },
        body,
        didDrawCell(data) {
          const { text } = data.cell;
          const imageHeight = 18;
          const imagePadding = 2;
          if (isPhotosCell(text[0])) {
            const photosIds = parsePhotosCell(text[0]).filter((id) => images[id]);
            const photoImages = photosIds.map((id) => images[id]);
            const textPos = data.cell.getTextPos();
            const cellWidth = data.cell.width;
            let currentPosX = textPos.x;
            let currentPosY = textPos.y;
            // eslint-disable-next-line no-restricted-syntax
            for (const photoImage of photoImages) {
              const linkOptions = { url: getFullUrl(photoImage.photo.url) };
              const sizes = resizeImage(photoImage.photo, imageHeight, imageHeight);
              const currentWidth = currentPosX + sizes.width + imagePadding - textPos.x;
              if (currentWidth > cellWidth) {
                currentPosX = textPos.x;
                currentPosY += imageHeight + imagePadding;
              }
              doc.addImage(photoImage.photoSrc, 'jpeg', currentPosX, currentPosY, sizes.width, sizes.height);
              doc.link(currentPosX, currentPosY, sizes.width, sizes.height, linkOptions);

              currentPosX += sizes.width + imagePadding;
            }
          }
        },
      });
    // eslint-disable-next-line no-restricted-syntax
    for (const log of incidentLogs) {
      await addHeader();
      let currentPos = padding;
      const addPadding = () => {
        currentPos += padding;
      };

      if (log.id === firstLog.id) {
        const titleOfDoc = `${t('incident-history')} #${incidentCount}`;
        const titlePosition = pageWidth / 2 - (titleOfDoc.length * 2.4) / 2;
        addPadding();
        doc.setFont('IBMPlexSans', 'bold').setFontSize(14).text(titleOfDoc, titlePosition, currentPos);
      }
      addPadding();

      const actionDescription = `${getFullName(log.user)} ${actionTitle[log?.actionDescription?.type](t)} ${formatDate(
        log.createdAt,
      )}`;
      doc.setFont('IBMPlexSans', 'normal').setFontSize(12).text(actionDescription, padding, currentPos);

      addPadding();

      let commentFile;
      let header;
      let commentBody;
      let fileType: ReturnType<typeof getFormatFileByMime> = null;
      const textOpts = { maxWidth: tableWidth };
      switch (log.actionDescription.type) {
        case 'create':
          header = createCreateIncidentActionHeader(t, tableWidth);
          addTable(
            header,
            convertCreateIncidentActionToBodyRows(log?.actionDescription.attrs, t, locations, userFunctions, header),
            currentPos,
          );
          break;
        case 'addComment':
        case 'deleteComment':
          commentBody = log?.actionDescription.attrs.comment.body;
          doc.text(commentBody, padding, currentPos, textOpts);
          addPadding();
          commentFile =
            log?.actionDescription.attrs?.comment?.image && images[log?.actionDescription.attrs?.comment?.image.id];
          fileType = getFormatFileByMime(commentFile?.photo.mime ?? '');

          if (commentFile && fileType === 'image') {
            const sizes = resizeImage(commentFile.photo, 70, 200);
            const textDimensions = doc.getTextDimensions(commentBody, textOpts);
            doc.addImage(
              commentFile.photoSrc,
              'jpeg',
              padding,
              currentPos + textDimensions.h + padding,
              sizes.width,
              sizes.height,
            );
          }

          if (commentFile && fileType && fileType !== 'image') {
            const textDimensions = doc.getTextDimensions(commentBody, textOpts);

            doc.textWithLink(commentFile.photo.name, padding, currentPos + textDimensions.h + padding, {
              url: getFullUrl(commentFile.photo.url),
            });
          }
          break;
        case 'editComment':
          header = createEditCommentActionHeader(t, tableWidth);
          addTable(header, convertEditCommentActionToBodyRows(log?.actionDescription.attrs, t, header), currentPos);
          break;
        case 'update':
          header = createUpdateIncidentActionHeader(t, tableWidth);
          addTable(
            header,
            convertUpdateIncidentActionToBodyRows(log?.actionDescription.attrs, t, locations, userFunctions, header),
            currentPos,
          );
          break;
        default:
          break;
      }
      if (log.id !== lastLog?.id) {
        goToNextPage();
      }
    }
    doc.save(`${t('incident-history')} #${incidentCount} ${currentDate.replace(':', '-')}.pdf`);
  };
