import type { Options } from 'html2canvas';
import type { TFunction } from 'i18next';
import type { jsPDFOptions } from 'jspdf';
import type jsPDF from 'jspdf';
import * as qs from 'qs';
import type { RecoilTaskInterface } from 'recoil-toolkit';
import { fromPairs } from 'remeda';

import type { DailyTask } from '../../../modules/Tasks/state/dailyTasks';
import type { ProjectTask } from '../../../modules/Tasks/state/projectTasks';
import type { TaskType } from '../../../modules/Tasks/state/tasks';
import type { Incident, Photo, ResolutionLevel } from '../../../types';
import getBase64 from '../../../utils/getBase64';
import { getResolutionLevelColor } from '../../../utils/resolutionLevelColor';
import { eventState } from '../../event';
import { getFilters } from '../../incidents/utils';
import { userEventBrand } from '../../user';
import type { HeaderFieldXLSX } from '../constants';
import {
  makePdfColumnsSettingsDailyTask,
  makePdfColumnsSettingsIncident,
  makePdfColumnsSettingsProjectTask,
} from '../constants';
import type { ExportAllParams, ExportCardsParams, PdfCellProps, TruncateTextProps } from '../types';
import { formatDaily, formatIncident, formatProjects } from './formats';
import { getTitleForDocument } from './getTitleForDocument';

export const TEXT_HEADER_HEIGHT = 12;

export type CANVAS_PROPS = {
  ratio: number;
  width: number;
  height: number;
  pageWidth: number;
  pageHeight: number;
  png: string;
};

export const getExportAllIncidentsQuery = ({ filter, eventId, page, pageSize, sort }: ExportAllParams) =>
  qs.stringify(
    {
      sort: [`${sort.field}:${sort.order}`],
      pagination: {
        page,
        pageSize,
      },
      filters: getFilters(filter, eventId),
      fields: [
        'registeredDate',
        'resolvedDate',
        'description',
        'locationDescription',
        'resolutionLevel',
        'status',
        'title',
        'count',
      ],
      populate: {
        comments: {
          fields: ['body', 'createdDate'],
          populate: {
            author: {
              fields: ['firstName', 'lastName'],
            },
          },
        },
        responsible: {
          fields: ['firstName', 'lastName'],
          populate: {
            function: {
              fields: ['name'],
            },
          },
        },
        registeredBy: {
          fields: ['firstName', 'lastName'],
        },
        location: {
          fields: ['title'],
        },
      },
    },
    { encodeValuesOnly: true },
  );

export const getExportCardIncidentsQuery = ({
  filter,
  eventId,
  page,
  pageSize,
  sort,
  incidentIds,
}: ExportCardsParams) => {
  const filters = getFilters(filter, eventId);

  if (incidentIds) {
    filters.id = {
      $in: incidentIds,
    };
  }

  return qs.stringify(
    {
      sort: [`${sort.field}:${sort.order}`],
      pagination: {
        pageSize,
        page: incidentIds ? 1 : page,
      },
      filters,
      fields: [
        'registeredDate',
        'resolvedDate',
        'description',
        'locationDescription',
        'resolutionLevel',
        'status',
        'title',
        'count',
      ],
      populate: {
        photos: {
          fields: ['url', 'formats', 'width', 'height'],
        },
        comments: {
          fields: ['body', 'createdDate'],
          populate: {
            image: {
              fields: ['url', 'formats', 'width', 'height'],
            },
            author: {
              fields: ['firstName', 'lastName'],
            },
          },
        },
        responsible: {
          fields: ['firstName', 'lastName'],
          populate: {
            function: {
              fields: ['name'],
            },
          },
        },
        registeredBy: {
          fields: ['firstName', 'lastName'],
        },
        location: {
          fields: ['title'],
        },
      },
    },
    { encodeValuesOnly: true },
  );
};

export const loadResource = (path: string) =>
  fetch(path, {
    cache: 'no-cache',
  })
    .then((response) => response.blob())
    .then((response) => getBase64(response))
    .then((response) => (response as string).split(',')[1]);

export const loadPhoto = async (photo?: Photo, height = 70, maxWidth = 100) => {
  if (photo) {
    const widthCoefficient = photo.width / photo.height;
    const src = await loadResource(photo.url);
    return {
      photoSrc: src,
      width: Math.min(widthCoefficient * height, maxWidth),
      height,
      mime: photo.mime,
      name: photo.name,
      url: photo.url,
    };
  }

  return Promise.resolve(undefined);
};

export const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

const truncateCellText = ({ width, content, rows }: TruncateTextProps) => {
  const coef = 1.8;
  const maxLength = (width * rows) / coef;

  return content.length > maxLength ? `${content.slice(0, maxLength - 3)}...` : content;
};

export const excelCell =
  (isOdd: boolean) =>
  (value?: string | number, style: object = {}) => ({
    v: value ?? '',
    t: 's',
    s: {
      alignment: { wrapText: true },
      fill: { fgColor: { rgb: isOdd ? 'F5F5F5' : 'FFFFFF' } },
      border: {
        top: { color: { rgb: 'A7A7A7' }, style: 'thin' },
        right: { color: { rgb: 'A7A7A7' }, style: 'thin' },
        bottom: { color: { rgb: 'A7A7A7' }, style: 'thin' },
        left: { color: { rgb: 'A7A7A7' }, style: 'thin' },
      },
      ...style,
    },
  });

const pdfCell = ({ content, width, styles }: PdfCellProps) => ({
  content: truncateCellText({ content, width, rows: 10 }),
  styles: {
    ...styles,
    font: 'IBMPlexSans',
    fontSize: 8,
    minCellWidth: width,
    minCellHeight: 10,
  },
});

export const mapToPdfRowIncident = (
  incident: Incident,
  t: TFunction<'translation'>,
  fields: string[],
  hideDateTime = false,
) => {
  const pdfColumnsSettings = makePdfColumnsSettingsIncident(t, fields);
  const formattedIncident = formatIncident(hideDateTime)(incident, t) as Record<string, string>;
  const content = pdfColumnsSettings.map((data) =>
    pdfCell({ content: data.dataKey in formattedIncident ? formattedIncident[data.dataKey] : '', width: data.width }),
  );

  return content;
};

export const mapToPdfRowProjectTask = (task: ProjectTask, t: TFunction<'translation'>, fields: string[]) => {
  const pdfColumnsSettings = makePdfColumnsSettingsProjectTask(t, fields);
  const formattedIncident = formatProjects(task, t) as unknown as Record<string, string>;
  const content = pdfColumnsSettings.map((data) => {
    const levelRow = formattedIncident.orgHierarchy.length;
    const advancedStyles = {
      cellPadding: {
        left: 2,
        right: 2,
      },
    };

    if (data.dataKey === 'title' && levelRow > 1) {
      advancedStyles.cellPadding.left = (advancedStyles.cellPadding.left + 2) * levelRow;
    }

    return pdfCell({
      content: data.dataKey in formattedIncident ? formattedIncident[data.dataKey] : '',
      width: data.width,
      styles: advancedStyles,
    });
  });

  return content;
};

export const mapToPdfRowDailyTask = (incident: DailyTask, t: TFunction<'translation'>, fields: string[]) => {
  const pdfColumnsSettings = makePdfColumnsSettingsDailyTask(t, fields);
  const formattedIncident = formatDaily(incident, t) as Record<string, string>;
  const content = pdfColumnsSettings.map((data) =>
    pdfCell({ content: data.dataKey in formattedIncident ? formattedIncident[data.dataKey] : '', width: data.width }),
  );

  return content;
};

export const mapToExcelRow =
  (
    t: TFunction<'translation'>,
    header: HeaderFieldXLSX[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatter: (data: any, t: TFunction<'translation'>) => any,
  ) =>
  (incident: Incident, index: number) => {
    const formattedIncident = formatter(incident, t) as Record<string, string>;
    const cell = excelCell(index % 2 !== 0);
    const cells = header.map(({ key }) => {
      const value = formattedIncident[key] ?? '';

      if (key === 'resolutionLevel') {
        return cell('', {
          fill: { fgColor: { rgb: getResolutionLevelColor(value as ResolutionLevel).replace('#', '') } },
        });
      }

      if (key === 'title') {
        return cell(value, { font: { bold: true } });
      }

      return cell(value);
    });

    return cells;
  };

export const prepareDoc = async (snapshot: RecoilTaskInterface['snapshot'], options?: jsPDFOptions | undefined) => {
  const brand = snapshot.getLoadable(userEventBrand).getValue();
  const JsPDF = (await import('jspdf')).default;

  const doc = new JsPDF(options);
  const regularFontContent = await loadResource('/assets/IBMPlexSans-Regular.ttf');
  const boldFontContent = await loadResource('/assets/IBMPlexSans-Bold.ttf');
  const logoImage = brand.enable
    ? brand.logo && (await loadResource(`${brand.logo.url}`))
    : await loadResource('/assets/logo.png');

  doc.addFileToVFS('IBMPlexSans-Regular.ttf', regularFontContent).addFileToVFS('IBMPlexSans-Bold.ttf', boldFontContent);
  doc.addFont('IBMPlexSans-Regular.ttf', 'IBMPlexSans', 'normal');
  doc.addFont('IBMPlexSans-Bold.ttf', 'IBMPlexSans', 'bold');

  return {
    doc,
    logoImage,
  };
};

export const addHeaderToPage = async (
  doc: jsPDF,
  logoImage: string | undefined,
  snapshot: RecoilTaskInterface['snapshot'],
  t: TFunction<'translation'>,
  currentDate: string,
  taskType: TaskType,
) => {
  const event = snapshot.getLoadable(eventState).getValue();
  const { width: pageWidth } = doc.internal.pageSize;

  const eventNameContext = truncateText(event.name, 30);
  const eventNamePosition = pageWidth / 2 - (event.name.length * 2.4) / 2;

  doc.setFont('IBMPlexSans', 'bold');

  if (logoImage) {
    doc.addImage(logoImage, 'jpeg', 5, 5, 10, 10);
  }

  return doc
    .setFontSize(16)
    .text(getTitleForDocument(taskType, t) || '', logoImage ? 20 : 5, TEXT_HEADER_HEIGHT)
    .text(eventNameContext, eventNamePosition, TEXT_HEADER_HEIGHT)
    .setFontSize(12)
    .text(currentDate, pageWidth - 40, TEXT_HEADER_HEIGHT);
};

export const getCanvasProperties = async (
  doc: jsPDF,
  html2canvasInst: (element: HTMLElement, options?: Partial<Options>) => Promise<HTMLCanvasElement>,
  htmlEl: HTMLElement,
  ratioCoeff: number,
) => {
  // scale = 2 is a fix this html2canvas issue https://github.com/niklasvh/html2canvas/issues/2302
  // onclone is a fix this html2canvas issue https://github.com/niklasvh/html2canvas/issues/2959
  const canvasEl = await html2canvasInst(htmlEl, {
    scale: 2,
    onclone: (document, element) => {
      element.style.setProperty('zoom', `${(1 / window.devicePixelRatio) * 100}%`);
    },
  });
  const png = canvasEl.toDataURL('image/png');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const widthRatio = pageWidth / canvasEl.width;
  const heightRatio = pageHeight / canvasEl.height;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  const canvasWidth = canvasEl.width * (ratio * ratioCoeff);
  const canvasHeight = canvasEl.height * (ratio * ratioCoeff);
  return {
    ratio,
    width: canvasWidth,
    height: canvasHeight,
    pageWidth,
    pageHeight,
    png,
  };
};

export const makePageInc = (doc: jsPDF) => {
  let i = 1;
  return () => {
    i += 1;
    doc.addPage();
    doc.setPage(i);
  };
};

export const calcMarginXForPaddingRight = (props: CANVAS_PROPS, padding: number) =>
  props.pageWidth - props.width - padding;

export const calcMarginXForPaddingAuto = (props: CANVAS_PROPS) => (props.pageWidth - props.width) / 2;

export const loadImages = (photos: Photo[]) => {
  const pairs = photos.map((photo) =>
    loadResource(photo.url).then(
      (photoSrc) => [photo.id, { photoSrc, photo }] as [number, { photoSrc: string; photo: Photo }],
    ),
  );

  return Promise.all(pairs).then((pairedPhotos) => fromPairs(pairedPhotos));
};

type Dimension = { width: number; height: number };

export const resizeImage = (dimension: Dimension, newHeight = 70, maxWidth = 100) => {
  const widthCoefficient = dimension.width / dimension.height;
  return {
    width: Math.min(widthCoefficient * newHeight, maxWidth),
    height: newHeight,
  };
};
