import type { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

type Props = {
  children: ReactNode,
  sx: Record<string, string>,
}

const CenteredText: FC<Props> = ({ children, sx }) => (
  <Stack alignItems='center' sx={sx}>
    <Typography variant='body1'>{children}</Typography>
  </Stack>
);

export default CenteredText;
