import { useState } from 'react';

import { Stack, styled, Typography } from '@mui/material';
import { useHarmonicIntervalFn } from 'react-use';

import formatDate from '../../utils/formatDate';

const StyledTypography = styled(Typography)(() => ({
  fontSize: '20px',
  lineHeight: '24px',
  color: '#808080',
}));

type Props = {
  inline?: boolean;
}

const Clock = ({ inline }: Props) => {
  const [currentTime, setCurrentTime] = useState(() => formatDate(new Date()));
  const [time, date] = currentTime.split(' ');

  useHarmonicIntervalFn(() => {
    setCurrentTime(formatDate(new Date()));
  }, 1000);

  return (
    <Stack sx={{
      alignItems: 'flex-end',
      fontSize: '20px',
      lineHeight: '24px',
    }}
    >
      <Stack direction={inline ? 'row' : undefined}>
        <StyledTypography>{time}</StyledTypography>
        {inline && <>&nbsp;</>}
        <StyledTypography>{date}</StyledTypography>
      </Stack>
    </Stack>
  );
};

export default Clock;
