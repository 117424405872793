import type { LocationForm } from '../../../../../state/locationsForm';
import type { GoogleMapAutocompleteValue } from '../../../shared/components/GoogleMap';
import type { LocationMapDataProviderApi } from '../model/LocationMapDataProvider';

export function normalizeGoogleMapData(data: LocationMapDataProviderApi, map: google.maps.Map | undefined) {
  const { center, locations, onDraggableMarker, onMapClick, onChangeSearchLocation, ...rest } = data;

  const googleCenter: google.maps.LatLngLiteral = {
    lng: center[0],
    lat: center[1],
  };

  const locationPoints = locations.map(({ ...location }) => ({
    ...location,
    position: {
      lng: location.geo.longitude,
      lat: location.geo.latitude,
    },
  }));

  const handleClickMap = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      onMapClick({
        longitude: event.latLng.lng(),
        latitude: event.latLng.lat(),
      });
    }
  };

  const handleDraggableMarker = (locationForm: LocationForm) => (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      onDraggableMarker(locationForm, {
        longitude: event.latLng.lng(),
        latitude: event.latLng.lat(),
      });
    }
  };

  const handleChangeSearchLocation = async (value: GoogleMapAutocompleteValue | null) => {
    if (value && map) {
      // We use async so that after changing the state, the map will correctly align the center.
      await onChangeSearchLocation([value.lng, value.lat]);
      map.setCenter(value);
      map.setZoom(12);
    }
  };

  return {
    ...rest,
    center: googleCenter,
    locations: locationPoints,
    onDraggableMarker: handleDraggableMarker,
    onMapClick: handleClickMap,
    onChangeSearchLocation: handleChangeSearchLocation,
  };
}
