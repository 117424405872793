import type { FC } from 'react';
import { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { useTranslation } from 'react-i18next';

import {
  FILEPOND_FILE_TYPES_IMAGE,
  FILEPOND_IMAGE_PREVIEW_HEIGHT,
  FILEPOND_MAX_FILES,
  FILEPOND_MAX_SIZE,
} from '../../constants/base';
import { Button, DrawerContainer } from '../base';
import { FilePond } from '../base/FilePond';
import { ImageEditor } from '../ImageEditor/ImageEditor';
import { useImageEditor } from '../ImageEditor/useImageEditor';

type PhotosState = (FilePondFile | FilePondInitialFile)[];

export type PhotosEditorFormProps = {
  initialState: PhotosState;
  title: string;
  onUpdate: (state: FilePondFile[]) => void;
  isLoading?: boolean;
  buttonText?: string;
};

export const PhotosEditorBase: FC<PhotosEditorFormProps> = ({
  initialState,
  title,
  onUpdate,
  buttonText = 'Apply',
  isLoading,
}) => {
  const { t } = useTranslation();
  const [currentPhotos, setCurrentPhotos] = useState<PhotosState>(initialState);
  const { imageEditor, imageToEdit } = useImageEditor({ photos: currentPhotos, onConfirm: setCurrentPhotos });

  const onChangeHandler = (files: FilePondFile[]) => {
    setCurrentPhotos(files);
  };

  const updateHandler = () => {
    onUpdate(currentPhotos as FilePondFile[]);
  };

  return (
    <DrawerContainer>
      <Stack
        spacing={2}
        sx={{
          '.filepond--item': {
            width: 'calc(50% - 0.5em)',
          },
        }}
      >
        <Typography variant='h4'>{title}</Typography>

        <FilePond
          files={currentPhotos as FilePondInitialFile[]}
          allowReorder
          allowMultiple
          acceptedFileTypes={FILEPOND_FILE_TYPES_IMAGE}
          maxFileSize={FILEPOND_MAX_SIZE}
          imagePreviewHeight={FILEPOND_IMAGE_PREVIEW_HEIGHT}
          onreorderfiles={onChangeHandler}
          onupdatefiles={onChangeHandler}
          maxFiles={FILEPOND_MAX_FILES}
          server={{
            load: (src, load) => {
              fetch(src)
                .then((res) => res.blob())
                .then(load);
            },
          }}
          name='files'
          labelIdle={t('drap-n-drop-pictures-or-browseUnescaped', {
            wrapperStart: '<span class="filepond--label-action">',
            wrapperEnd: '</span>',
          })}
          imageEditEditor={imageEditor}
        />
        {imageToEdit && <ImageEditor file={imageToEdit} onClose={imageEditor.onclose} onSave={imageEditor.onconfirm} />}
      </Stack>

      <Button
        sx={{ marginTop: 'auto', color: 'white' }}
        loading={isLoading}
        onClick={updateHandler}
        variant='contained'
      >
        {buttonText}
      </Button>
    </DrawerContainer>
  );
};
