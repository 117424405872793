import type { TFunction } from 'i18next';
import type jsPDF from 'jspdf';
import type { UserOptions } from 'jspdf-autotable';

import { loadResource, truncateText } from '.';
import type { TaskType } from '../../../modules/Tasks/state/tasks';
import type { Brand, Event } from '../../../types';
import { calculateAspectRatioAndOffset } from './calculateAspectRatioAndOffset';
import { getTitleForDocument } from './getTitleForDocument';

export const getPreparedPdfTableDocOptions = async ({
  event,
  brand,
  t,
  currentDate,
  taskType,
}: {
  event: Event;
  brand: Brand;
  t: TFunction<'translation'>;
  currentDate: string;
  taskType: TaskType;
}): Promise<{
  doc: jsPDF;
  options: UserOptions;
}> => {
  const JsPDF = (await import('jspdf')).default;

  const doc = new JsPDF({ orientation: 'l' });

  const regularFontContent = await loadResource('/assets/IBMPlexSans-Regular.ttf');
  const boldFontContent = await loadResource('/assets/IBMPlexSans-Bold.ttf');
  const logoImage = brand.enable
    ? brand.logo && (await loadResource(`${brand.logo.url}`))
    : await loadResource('/assets/logo.png');

  doc.addFileToVFS('IBMPlexSans-Regular.ttf', regularFontContent).addFileToVFS('IBMPlexSans-Bold.ttf', boldFontContent);
  doc.addFont('IBMPlexSans-Regular.ttf', 'IBMPlexSans', 'normal');
  doc.addFont('IBMPlexSans-Bold.ttf', 'IBMPlexSans', 'bold');

  const { width: pageWidth, height: pageHeight } = doc.internal.pageSize;

  const eventNameContent = truncateText(event.name, 30);
  const eventNamePosition = doc.internal.pageSize.width / 2 - (doc.getStringUnitWidth(eventNameContent) * 8) / 2;

  return {
    doc,
    options: {
      rowPageBreak: 'avoid',
      margin: {
        top: 20,
        left: 2,
        right: 2,
        bottom: 6,
      },
      headStyles: {
        fillColor: [170, 150, 200],
      },
      tableWidth: pageWidth - 4,
      styles: {
        font: 'IBMPlexSans',
      },
      didDrawPage: (data) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore type error
        const currentPage = doc.internal.getNumberOfPages();

        doc.setFont('IBMPlexSans', 'bold');

        if (logoImage) {
          const imageProperties = doc.getImageProperties(logoImage);
          const { width, height, y } = calculateAspectRatioAndOffset(imageProperties.width, imageProperties.height);
          doc.addImage(logoImage, 'jpeg', data.settings.margin.left, y, width, height);
        }

        doc
          .setFontSize(20)
          .text(getTitleForDocument(taskType, t) || '', data.settings.margin.left + (logoImage ? 15 : 0), 12)
          .text(eventNameContent, eventNamePosition, 12)
          .setFontSize(16)
          .text(
            `${t('report')} ${currentDate}`,
            pageWidth - doc.getStringUnitWidth(`${t('report')} ${currentDate}`) * 6,
            12,
          );

        doc
          .setFontSize(8)
          .setFont('IBMPlexSans', 'normal')
          .text(`${t('page')} ${currentPage}`, data.settings.margin.left, pageHeight - 2);
      },
    },
  };
};
