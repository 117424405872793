import { Box } from '@mui/material';

type Props = {
  count: number,
  isViewedByUser: boolean,
  onClick: () => void,
};

export const CountRenderer = ({ count, onClick, isViewedByUser }: Props) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Box
    component='button'
    sx={{
      width: '100%',
      height: '100%',
      cursor: 'inherit',
      background: 'transparent',
      border: 'none',
      display: 'flex',
      color: 'black',
    }}
    onClick={onClick}
  >
    {count}
    {!isViewedByUser && (
    <Box sx={{
      marginLeft: '2px',
      width: '6px',
      height: '6px',
      background: '#F2A861',
      borderRadius: '50%',
    }}
    />
    )}
  </Box>
);
