import type { Status } from '../../../../types';
import {
  StatusBadge,
} from '../../../base';

const StatusRenderer = (value: Status) => (
  <StatusBadge status={value} />
);

export default StatusRenderer;
