/* eslint-disable react/no-array-index-key */
import type { ReactNode, MouseEvent, FC } from 'react';
import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import type { SxProps, Theme } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';

import { addPropsToChildren } from '../../utils/addPropsToChildren';
import { useFullScreen } from '../FullScreen';

interface MenuButtonProps {
  commands: {
    content: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    disableAutoClose?: boolean;
  }[];
  buttonContent: ReactNode;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

const MenuButton: FC<MenuButtonProps> = ({
  buttonContent,
  commands = [],
  isLoading = false,
  sx = { borderColor: '#C7D1D4', height: '40px', fontWeight: 400 },
}) => {
  const { ref } = useFullScreen();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        variant='outlined'
        color='inherit'
        sx={sx}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {buttonContent}
      </LoadingButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} container={ref.current}>
        {commands.map((command, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (!command.disableAutoClose) {
                handleClose();
              }
              command.onClick?.();
            }}
            disabled={command.disabled}
          >
            {addPropsToChildren(command.content, { onClose: handleClose })}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuButton;
