import type { ReactElement } from 'react';

import { Autocomplete, TextField, Typography, type AutocompleteProps, type PopperProps, Popper } from '@mui/material';
import { Trans } from 'react-i18next';

import { useFullScreen } from '../FullScreen';

type Props<T> = {
  value?: T;
  data: T[];
  onSelect: (value: T | null) => void;
  itemRenderer: (value: T) => string | ReactElement;
  required?: boolean;
} & Pick<AutocompleteProps<T, false, false, false>, 'getOptionLabel'>;

const CustomPopper = (props: PopperProps) => {
  const { ref } = useFullScreen();
  return (
    <Popper
      {...props}
      container={ref.current}
      sx={{
        zIndex: 1,
      }}
      className='ag-custom-component-popup'
    />
  );
};

const TableSelect = <T extends { id: number | string }>({
  value,
  data,
  onSelect,
  itemRenderer,
  getOptionLabel,
}: Props<T>) => (
  <Autocomplete
    open
    filterSelectedOptions
    sx={{ maxHeight: 300, minWidth: 200 }}
    options={[{ id: 'empty' } as T, ...data]}
    value={value}
    getOptionLabel={(item) => {
      if (item.id !== undefined && item.id !== 'empty' && getOptionLabel) {
        return getOptionLabel(item);
      }

      return '';
    }}
    onChange={(_, item, reason) => {
      if (reason === 'selectOption') {
        onSelect(item?.id === 'empty' ? null : item);
      }
    }}
    renderOption={(props, item) => (
      <Typography component='li' noWrap {...props}>
        {item.id === 'empty' ? (
          <Typography>
            <Trans i18nKey='none' />
          </Typography>
        ) : (
          itemRenderer(item)
        )}
      </Typography>
    )}
    renderInput={(params) => <TextField {...params} />}
    isOptionEqualToValue={(a, b) => a.id === b.id}
    PopperComponent={CustomPopper}
  />
);

export default TableSelect;
