import { useRecoilValue } from 'recoil';

import { locationsState } from '../../../state/locations';
import { userFunctionsState } from '../../../state/userFunctions';
import { usersState } from '../../../state/users';
import type { DailyTaskDefaultFilters } from '../../../types';
import type { DailyTasksFormFilerValue } from './types';

export function useDefaultFilters(filtersState: DailyTaskDefaultFilters): DailyTasksFormFilerValue {
  const allUsers = useRecoilValue(usersState);
  const allUserFunctions = useRecoilValue(userFunctionsState);
  const allLocations = useRecoilValue(locationsState);

  const {
    startDate,
    status,
    endDate,
    typeDate,
    responsible,
    userFunctions,
    participants,
    resolutionLevel,
    isAssignedToMe,
    locations,
  } = filtersState;

  return {
    typeDate,
    endDate: endDate ?? null,
    startDate: startDate ?? null,
    status: status ?? undefined,
    userFunctions: allUserFunctions.filter((item) => userFunctions?.includes(item.id)),
    responsible: allUsers.filter((item) => responsible?.includes(item.id)),
    participants: allUsers.filter((item) => participants?.includes(item.id)),
    locations: allLocations.filter((item) => locations?.includes(item.id)),
    resolutionLevel: resolutionLevel ?? [],
    isAssignedToMe: isAssignedToMe ?? false,
  };
}
