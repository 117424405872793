import { useMemo, type FC, type RefObject } from 'react';

import type { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { permissionState } from '../../../../state/event';
import { userState } from '../../../../state/user';
import { EntitiesGridBox, useGetLocaleText } from '../../../EntitiesGrid';
import { useDailyTaskInlineAdding, useDailyTasksData } from '../../state/dailyTasks';
import { useGridSetters } from '../../state/tasks';
import { RemoveRowRenderer } from './components/RemoveRowRenderer';
import type { DailyTasksGridContext } from './lib/types';
import { useDefaultColDef, useColumnDefs } from './lib/useColumnDefs';
import { useColumnsState } from './lib/useColumnsState';
import { useScrollToRowByParams } from './lib/useScrollToRowByParams';

type Props<T extends object = object> = {
  gridRef: RefObject<AgGridReact<unknown>>;
} & T;

export const DailyTasksGrid: FC<Props> = ({ gridRef, ...gridDefaultSettings }) => {
  const { t } = useTranslation();
  const { editTaskPart, currentTab } = useDailyTasksData();
  const permissions = useRecoilValue(permissionState);
  const currentUser = useRecoilValue(userState);
  const { scrollToRowByParams } = useScrollToRowByParams();

  const { editPreparedDailyTask } = useDailyTaskInlineAdding(gridRef);
  const { defaultValueSetter, statusSetter, commentsSetter, modals } = useGridSetters({
    editTaskPart,
    editPreparedTask: editPreparedDailyTask,
  });
  const columnDefs = useColumnDefs(t, statusSetter, commentsSetter, defaultValueSetter);

  const defaultColDef = useDefaultColDef();

  const { columnDefs: updatedColDefs, onDragStopped } = useColumnsState(columnDefs);
  const getLocaleText = useGetLocaleText(t);
  const dailyTasks = currentTab && 'tasks' in currentTab ? currentTab.tasks : [];

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: '',
      fields: '',
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: permissions.canRemoveOperationalTask ? RemoveRowRenderer : () => null,
      },
    }),
    [permissions.canRemoveOperationalTask],
  );

  const context = useMemo<DailyTasksGridContext>(
    () => ({
      permissions,
      currentUser,
    }),
    [permissions, currentUser],
  );

  return (
    <EntitiesGridBox>
      <AgGridReact
        {...gridDefaultSettings}
        ref={gridRef}
        treeData
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={(data) => data.orgHierarchy}
        rowData={dailyTasks}
        columnDefs={updatedColDefs}
        onDragStopped={onDragStopped}
        defaultColDef={defaultColDef}
        overlayNoRowsTemplate={t('no-tasks-found')}
        getLocaleText={getLocaleText}
        context={context}
        onFirstDataRendered={scrollToRowByParams}
      />
      {modals.close}
      {modals.reopen}
    </EntitiesGridBox>
  );
};
