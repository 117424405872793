import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AddEntityControl } from '../../../../modules/EntitiesGrid';
import { hasTitle } from '../../../../modules/Tasks/state/tasks';
import { Drawer, useShowDrawer } from '../../../../state/drawer';
import { permissionState } from '../../../../state/event';
import { useNewIncidentRow } from '../../../IncidentTable/state/hooks';

export const AddIncidentControl = () => {
  const { t } = useTranslation();
  const { prepareNewIncident, destroyNewIncident, addNewIncident, newIncident, loading } = useNewIncidentRow();
  const { canCreateIncident } = useRecoilValue(permissionState);
  const { execute: showDrawer } = useShowDrawer();

  return (
    <AddEntityControl
      loading={loading}
      newEntity={newIncident}
      destroyNewEntity={destroyNewIncident}
      addNewEntity={addNewIncident}
      disabled={!canCreateIncident}
      addNewButtons={[
        {
          text: t('add-new-incident'),
          action: () => showDrawer({ type: Drawer.INCIDENT_ADD }),
        },
        {
          text: t('add-new-incident-inline'),
          action: prepareNewIncident,
        },
      ]}
      newEntityReady={hasTitle}
    />
  );
};
