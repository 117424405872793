import type { ChangeEvent } from 'react';

import type { TextFieldProps } from '@mui/material';
import { TextField as TextFieldBase } from '@mui/material';

type Props = {
  onFocus?(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
} & Omit<TextFieldProps, 'onFocus'>

const TextField = ({ onFocus, ...props }: Props) => {
  const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // https://github.com/mui/material-ui/issues/12779#issuecomment-829085601
    const lengthOfInput = event.target.value.length;
    event.target.setSelectionRange(lengthOfInput, lengthOfInput);

    onFocus?.(event);
  };

  return (
    <TextFieldBase {...props} onFocus={handleFocus} />
  );
};

export default TextField;
