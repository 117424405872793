import { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLastError } from 'recoil-toolkit';

import { IS_DEVELOPMENT } from '../../constants/env';
import { getErrorMessage, translateServerMessage } from './getErrorMessage';

const HIDE_DURATION = 5000;

export const ErrorStack = () => {
  const lastError = useLastError();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  if (IS_DEVELOPMENT && lastError?.error) {
    // eslint-disable-next-line no-console
    console.error('Recoil catch', lastError?.error);
  }

  useEffect(() => {
    setOpen(true);
  }, [lastError]);
  const errorMessage = getErrorMessage(lastError);

  if (lastError) {
    return (
      <Snackbar open={open} autoHideDuration={HIDE_DURATION} onClose={() => setOpen(false)}>
        <Alert severity='error' sx={{ width: '100%' }}>
          {errorMessage ? translateServerMessage(errorMessage, t) : `[${lastError.taskId}] ${lastError.error}`}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};
