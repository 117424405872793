export const initialLayoutsSettings = {
  lg: [
    {
      h: 6,
      i: 'Location',
      w: 3,
      x: 4,
      y: 11,
      moved: false,
      static: false,
    },
    {
      h: 14,
      i: 'Resolution level',
      w: 2,
      x: 2,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 14,
      i: 'Status',
      w: 2,
      x: 0,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 8,
      i: 'Top Responsibles',
      w: 3,
      x: 8,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 6,
      i: 'User functions',
      w: 4,
      x: 7,
      y: 11,
      moved: false,
      static: false,
    },
    {
      h: 11,
      i: 'Registered vs Resolved',
      w: 4,
      x: 4,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'Active vs Resolved',
      w: 4,
      x: 0,
      y: 14,
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      h: 7,
      i: 'Location',
      w: 3,
      x: 0,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 13,
      i: 'Resolution level',
      w: 3,
      x: 3,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 14,
      i: 'Status',
      w: 3,
      x: 0,
      y: 7,
      moved: false,
      static: false,
    },
    {
      h: 7,
      i: 'Top Responsibles',
      w: 4,
      x: 6,
      y: 14,
      moved: false,
      static: false,
    },
    {
      h: 6,
      i: 'User functions',
      w: 4,
      x: 6,
      y: 8,
      moved: false,
      static: false,
    },
    {
      h: 8,
      i: 'Registered vs Resolved',
      w: 4,
      x: 6,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'Active vs Resolved',
      w: 3,
      x: 3,
      y: 13,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      h: 6,
      i: 'Location',
      w: 3,
      x: 0,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 15,
      i: 'Resolution level',
      w: 2,
      x: 3,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 14,
      i: 'Status',
      w: 2,
      x: 3,
      y: 15,
      moved: false,
      static: false,
    },
    {
      h: 7,
      i: 'Top Responsibles',
      w: 3,
      x: 0,
      y: 6,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'User functions',
      w: 3,
      x: 0,
      y: 13,
      moved: false,
      static: false,
    },
    {
      h: 9,
      i: 'Registered vs Resolved',
      w: 3,
      x: 0,
      y: 18,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'Active vs Resolved',
      w: 3,
      x: 0,
      y: 27,
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      h: 6,
      i: 'Location',
      w: 4,
      x: 0,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 13,
      i: 'Resolution level',
      w: 2,
      x: 0,
      y: 6,
      moved: false,
      static: false,
    },
    {
      h: 13,
      i: 'Status',
      w: 2,
      x: 2,
      y: 12,
      moved: false,
      static: false,
    },
    {
      h: 8,
      i: 'Top Responsibles',
      w: 2,
      x: 0,
      y: 19,
      moved: false,
      static: false,
    },
    {
      h: 6,
      i: 'User functions',
      w: 2,
      x: 2,
      y: 6,
      moved: false,
      static: false,
    },
    {
      h: 10,
      i: 'Registered vs Resolved',
      w: 4,
      x: 0,
      y: 27,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'Active vs Resolved',
      w: 4,
      x: 0,
      y: 37,
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      h: 5,
      i: 'Location',
      w: 2,
      x: 0,
      y: 0,
      moved: false,
      static: false,
    },
    {
      h: 18,
      i: 'Resolution level',
      w: 2,
      x: 0,
      y: 5,
      moved: false,
      static: false,
    },
    {
      h: 17,
      i: 'Status',
      w: 2,
      x: 0,
      y: 23,
      moved: false,
      static: false,
    },
    {
      h: 8,
      i: 'Top Responsibles',
      w: 2,
      x: 0,
      y: 40,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'User functions',
      w: 2,
      x: 0,
      y: 48,
      moved: false,
      static: false,
    },
    {
      h: 9,
      i: 'Registered vs Resolved',
      w: 2,
      x: 0,
      y: 53,
      moved: false,
      static: false,
    },
    {
      h: 5,
      i: 'Active vs Resolved',
      w: 2,
      x: 0,
      y: 62,
      moved: false,
      static: false,
    },
  ],
};
