import { LinearProgress, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { Trans } from 'react-i18next';
import { prop, sortBy } from 'remeda';

import type { HorizontalBarChartType } from '../../types';
import { getPercentage } from '../lib/getPercentage';
import type { KeyNameType } from '../lib/types/dashborad.types';

type HorizontalBarChartProps = HorizontalBarChartType;

export const HorizontalBarChart = ({ dataset, total, legendTitle, keyName }: HorizontalBarChartProps & KeyNameType) => {
  const rows = dataset
    .filter(({ count }) => count > 0)
    .map(({ value, count }) => ({
      name: value,
      value: count,
      percent: getPercentage(count, total),
    }));
  const sortedRows = sortBy(rows, [prop('value'), 'desc']);

  return (
    <Box p={1} id={keyName}>
      <Table
        size='small'
        sx={{
          '& th, td': {
            padding: '6px',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell width='40%' component='th'>
              {legendTitle}
            </TableCell>
            <TableCell width='10%' component='th'>
              <Trans i18nKey='count' />
            </TableCell>
            <TableCell width='45%' component='th'>
              <Trans i18nKey='percentage' />
            </TableCell>
            <TableCell width='5%' />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '& th, & td': {
                  border: 'none',
                },
              }}
            >
              <TableCell component='th'>{row.name}</TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>
                <LinearProgress variant='determinate' value={Number(row.percent)} />
              </TableCell>
              <TableCell>{row.percent}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          sx={{
            backgroundColor: 'white',
            '& td': {
              border: 'none',
            },
            left: 0,
            bottom: 0,
            position: 'sticky',
            boxShadow: 'inset 0 1px 0 #e0e0e0',
          }}
        >
          <TableRow>
            <TableCell>
              <Trans i18nKey='total' />
            </TableCell>
            <TableCell>{total}</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
};
