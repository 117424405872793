import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import type { DrawerNavigationProps } from '../../../state/drawer';
import { useExportToPdfCards } from '../../../state/export';
import { getIncident, useEditIncident, useViewedBy } from '../../../state/incidents';
import { NotificationType, useShowNotification } from '../../../state/notification';
import { getIsFlaggedIncident, getIsViewedIncident, useUpdateFlaggedIncidents, userState } from '../../../state/user';
import type { EditIncident } from '../../../types';
import { EditIncidentForm } from './EditIncidentForm';

type Props = {
  incidentId: number;
} & DrawerNavigationProps;

export const EditIncidentTableForm: FC<Props> = ({ incidentId, onEndAction, goBack, goForward, isChild }) => {
  const incident = useRecoilValue(getIncident(incidentId));
  const isFlagged = useRecoilValue(getIsFlaggedIncident(incidentId));
  const currentUser = useRecoilValue(userState);
  const isViewedByUser = useRecoilValue(getIsViewedIncident(incidentId));
  const { execute: updateFlaggedIncidents } = useUpdateFlaggedIncidents();
  const { execute: exportToPdfCards } = useExportToPdfCards();
  const { execute: editIncidentTask, loading } = useEditIncident();
  const { execute: updateViewedBy } = useViewedBy();
  const { t } = useTranslation();
  const { execute: showNotification } = useShowNotification();
  const onChangeFlaggedHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateFlaggedIncidents({ incidentId, isFlagged: checked });
  };

  const onExportPdfCardsHandler = useCallback(() => {
    exportToPdfCards(t, [incidentId]);
  }, [exportToPdfCards, incidentId, t]);

  useEffect(() => {
    if (!isViewedByUser) {
      updateViewedBy({ userId: currentUser.id, incidentId });
    }
  }, [currentUser.id, incidentId, isViewedByUser, updateViewedBy]);

  const editIncident = (data: EditIncident) =>
    editIncidentTask({
      incident: data,
      successCallback: () => {
        showNotification({
          type: NotificationType.SUCCESS,
          text: t('incident-was-edited-successfully'),
        });
        onEndAction();
      },
    });

  if (!incident) {
    return null;
  }

  const navigationProps = { goBack, goForward, isChild };

  return (
    <EditIncidentForm
      incident={incident}
      isFlagged={isFlagged}
      onChangeFlaggedHandler={onChangeFlaggedHandler}
      onExportPdfCardsHandler={onExportPdfCardsHandler}
      editIncident={editIncident}
      loading={loading}
      {...navigationProps}
    />
  );
};
