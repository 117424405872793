import { useState, useCallback, createElement } from 'react';

import { InfoWindow } from '@react-google-maps/api';

const INFO_WINDOW_POSITION = {
  lat: 0,
  lng: 0,
};
type MarkersById = Record<string, google.maps.Marker>;

export function useGoogleMapInfoWindow({ map }: { map: google.maps.Map | undefined }) {
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | undefined>();
  const [markers, setMarkers] = useState<MarkersById>({});
  const createOnLoadMarker = useCallback(
    (key: string) => (marker: google.maps.Marker) => {
      setMarkers((prevMarkers) => ({
        ...prevMarkers,
        [key]: marker,
      }));
    },
    [setMarkers],
  );
  const onLoadInfoWindow = useCallback(
    (infoWindowInstance: google.maps.InfoWindow) => {
      setInfoWindow(infoWindowInstance);
      infoWindowInstance.close();
    },
    [setInfoWindow],
  );
  const onMouseOut = useCallback(() => {
    infoWindow?.close();
  }, [infoWindow]);
  const createOnMouseOver = useCallback(
    (key: string, content: string) => () => {
      const marker = markers[key];
      if (infoWindow) {
        infoWindow.setContent(content);
        infoWindow.open(map, marker);
      }
    },
    [infoWindow, map, markers],
  );
  const infoWindowInstance = createElement(
    InfoWindow,
    { key: 'infoWindow', onLoad: onLoadInfoWindow, position: INFO_WINDOW_POSITION },
    createElement('div'),
  );

  return {
    createOnLoadMarker,
    onMouseOut,
    createOnMouseOver,
    infoWindowInstance,
  };
}
