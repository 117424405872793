import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useController, type FieldPath, type UseControllerProps, type FieldValues } from 'react-hook-form';

import { useFullScreen } from '../FullScreen';

export type DateFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  disabled?: boolean;
  label: string;
} & UseControllerProps<TFieldValues, TName>;

export const DateField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: DateFieldProps<TFieldValues, TName>,
) => {
  const { disabled, label } = props;
  const { field } = useController(props);
  const { ref } = useFullScreen();

  return (
    <DatePicker
      {...field}
      disabled={disabled}
      label={label}
      inputFormat='dd/MM/yyyy'
      value={field.value ?? null}
      renderInput={(params) => <TextField {...params} fullWidth />}
      PopperProps={{
        container: ref.current,
      }}
    />
  );
};
