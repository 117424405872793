import type { TFunction } from 'i18next';

import { getSubTitleNameField, getSubTitleTranslateKey } from './utils/subTitle';

export type HeaderFieldXLSX = {
  title: string;
  key: string;
  width: number;
};

type HeaderFieldPDF = {
  header: string;
  dataKey: string;
  width: number;
};

function getHeaderFieldsByOrderFieldsExcel(fields: string[], columnFields: HeaderFieldXLSX[]) {
  return fields.reduce<HeaderFieldXLSX[]>((acc, field) => {
    const fieldFinder = columnFields.find((item) => item.key === field);

    if (fieldFinder) {
      acc.push(fieldFinder);
    }

    return acc;
  }, []);
}

function getHeaderFieldsByOrderFieldsPdf(fields: string[], columnFields: HeaderFieldPDF[]) {
  return fields.reduce<HeaderFieldPDF[]>((acc, field) => {
    const fieldFinder = columnFields.find((item) => item.dataKey === field);

    if (fieldFinder) {
      acc.push(fieldFinder);
    }

    return acc;
  }, []);
}

function getHeaderSubTitlesExcel(maxNestingLevel: number): HeaderFieldXLSX[] {
  return new Array(maxNestingLevel).fill('').map((_, index) => ({
    title: getSubTitleTranslateKey(index + 1),
    key: getSubTitleNameField(index + 1),
    width: 30,
  }));
}

export const makeXlsxHeaderIncidents = (fields: string[]) => (t: TFunction<'translation'>) => {
  const defaultFields = [
    { title: '#', key: 'count', width: 5 },
    { title: t('title'), key: 'title', width: 30 },
    { title: t('description'), key: 'description', width: 30 },
    { title: t('by'), key: 'registeredBy', width: 15 },
    { title: t('status'), key: 'status', width: 10 },
    { title: t('level'), key: 'resolutionLevel', width: 10 },
    { title: t('function'), key: 'userFunction', width: 10 },
    { title: t('location'), key: 'location', width: 15 },
    { title: t('location-details'), key: 'locationDescription', width: 20 },
    { title: t('responsible'), key: 'responsible', width: 15 },
    { title: t('last-comment'), key: 'lastComment', width: 50 },
    { title: t('registration-date'), key: 'registeredDate', width: 20 },
    { title: t('resolution-date'), key: 'resolvedDate', width: 20 },
    { title: t('end-datetime'), key: 'endDatetime', width: 20 },
    { title: t('participants'), key: 'participants', width: 25 },
  ];

  return getHeaderFieldsByOrderFieldsExcel(fields, defaultFields);
};

export const makeXlsxHeaderProjects =
  (fields: string[], maxNestingLevel = 1) =>
  (t: TFunction<'translation'>) => {
    const subTitles = getHeaderSubTitlesExcel(maxNestingLevel);
    const defaultFields = [
      { title: '#', key: 'count', width: 5 },
      { title: t('start-datetime'), key: 'startDatetime', width: 20 },
      { title: t('end-datetime'), key: 'endDatetime', width: 20 },
      ...(maxNestingLevel > 1 ? [] : [{ title: t('title'), key: 'title', width: 30 }]),
      { title: t('description'), key: 'description', width: 30 },
      { title: t('responsible'), key: 'responsible', width: 15 },
      { title: t('function'), key: 'userFunction', width: 10 },
      { title: t('status'), key: 'status', width: 10 },
      { title: t('last-comment'), key: 'comments', width: 50 },
      { title: t('resolution-date'), key: 'resolvedDate', width: 20 },
      { title: t('participants'), key: 'participants', width: 25 },
    ];

    return getHeaderFieldsByOrderFieldsExcel(fields, defaultFields.concat(subTitles));
  };

export const makeXlsxHeaderDaily = (fields: string[]) => (t: TFunction<'translation'>) => {
  const defaultFields = [
    { title: '#', key: 'count', width: 5 },
    { title: t('start-datetime'), key: 'startDatetime', width: 20 },
    { title: t('end-datetime'), key: 'endDatetime', width: 20 },
    { title: t('title'), key: 'title', width: 30 },
    { title: t('description'), key: 'description', width: 30 },
    { title: t('responsible'), key: 'responsible', width: 15 },
    { title: t('function'), key: 'userFunction', width: 10 },
    { title: t('status'), key: 'status', width: 10 },
    { title: t('last-comment'), key: 'comments', width: 50 },
    { title: t('resolution-date'), key: 'resolvedDate', width: 20 },
    { title: t('participants'), key: 'participants', width: 25 },
    { title: t('location'), key: 'location', width: 25 },
  ];

  return getHeaderFieldsByOrderFieldsExcel(fields, defaultFields);
};

export const makeXlsxHeaderSettings =
  (t: TFunction<'translation'>) => (func: (t: TFunction<'translation'>) => HeaderFieldXLSX[]) =>
    func(t).map((item) => ({
      ...item,
      v: item.title,
      wch: item.width,
      s: {
        fill: { fgColor: { rgb: 'AA96C8' } },
        font: { bold: true, sz: 12 },
      },
    }));

export const makePdfColumnsSettingsIncident = (t: TFunction<'translation'>, fields: string[] = []) => {
  const defaultFields: HeaderFieldPDF[] = [
    { header: '#', dataKey: 'count', width: 7 },
    { header: t('title'), dataKey: 'title', width: 27 },
    { header: t('description'), dataKey: 'description', width: 74 },
    { header: t('status'), dataKey: 'status', width: 20 },
    { header: t('level'), dataKey: 'resolutionLevel', width: 21 },
    { header: t('function'), dataKey: 'userFunction', width: 19 },
    { header: t('location'), dataKey: 'location', width: 21 },
    { header: t('resp.'), dataKey: 'responsible', width: 18 },
    { header: t('last-comment'), dataKey: 'lastComment', width: 81 },
  ];

  return getHeaderFieldsByOrderFieldsPdf(fields, defaultFields);
};

export const makePdfColumnsSettingsDailyTask = (t: TFunction<'translation'>, fields: string[] = []) => {
  const defaultFields: HeaderFieldPDF[] = [
    { header: '#', dataKey: 'count', width: 5 },
    { header: t('start-datetime'), dataKey: 'startDatetime', width: 20 },
    { header: t('end-datetime'), dataKey: 'endDatetime', width: 20 },
    { header: t('title'), dataKey: 'title', width: 30 },
    { header: t('description'), dataKey: 'description', width: 30 },
    { header: t('location'), dataKey: 'location', width: 21 },
    { header: t('responsible'), dataKey: 'responsible', width: 15 },
    { header: t('function'), dataKey: 'userFunction', width: 10 },
    { header: t('status'), dataKey: 'status', width: 10 },
    { header: t('level'), dataKey: 'resolutionLevel', width: 21 },
    { header: t('last-comment'), dataKey: 'lastComment', width: 50 },
    { header: t('resolution-date'), dataKey: 'resolvedDate', width: 20 },
    { header: t('participants'), dataKey: 'participants', width: 25 },
  ];

  return getHeaderFieldsByOrderFieldsPdf(fields, defaultFields);
};

export const makePdfColumnsSettingsProjectTask = (t: TFunction<'translation'>, fields: string[] = []) => {
  const defaultFields: HeaderFieldPDF[] = [
    { header: '#', dataKey: 'count', width: 15 },
    { header: t('start-datetime'), dataKey: 'startDatetime', width: 20 },
    { header: t('end-datetime'), dataKey: 'endDatetime', width: 20 },
    { header: t('title'), dataKey: 'title', width: 45 },
    { header: t('description'), dataKey: 'description', width: 30 },
    { header: t('level'), dataKey: 'resolutionLevel', width: 21 },
    { header: t('responsible'), dataKey: 'responsible', width: 15 },
    { header: t('function'), dataKey: 'userFunction', width: 25 },
    { header: t('status'), dataKey: 'status', width: 20 },
    { header: t('last-comment'), dataKey: 'lastComment', width: 40 },
    { header: t('resolution-date'), dataKey: 'resolvedDate', width: 20 },
    { header: t('participants'), dataKey: 'participants', width: 25 },
  ];

  return getHeaderFieldsByOrderFieldsPdf(fields, defaultFields);
};

export const NOT_AVAILABLE_TEXT = 'N/A';
