import type { ProjectTaskDefaultFilters, User } from '../../../types';
import type { ProjectTasksFormFilerValue } from './types';

export function transformFilterForBackend({
  filters,
  currentUser,
}: {
  filters: ProjectTasksFormFilerValue;
  currentUser: User;
}): ProjectTaskDefaultFilters {
  const {
    startDate,
    endDate,
    responsible,
    userFunctions,
    status,
    participants,
    typeDate,
    resolutionLevel,
    isAssignedToMe,
  } = filters;

  return {
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    typeDate,
    status: status?.map((item) => item),
    responsible: responsible?.map((item) => item.id),
    userFunctions: userFunctions?.map((item) => item.id),
    participants: participants?.map((item) => item.id),
    resolutionLevel: resolutionLevel?.map((item) => item),
    isAssignedToMe: isAssignedToMe ?? false,
    ...(isAssignedToMe && currentUser ? { responsible: [currentUser.id] } : {}),
  };
}
