import stringToColor from './stringToColor';

const stringAvatar = (name: string) => {
  const words = name.split(' ');
  const firstName = words[0];
  const lastName = words[1];

  return ({
    sx: {
      bgcolor: stringToColor(name),
      fontWeight: 500,
    },
    children: `${firstName[0]}${lastName?.[0] ?? ''}`,
  });
};

export default stringAvatar;
