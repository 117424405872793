import { ResolutionLevel } from '../types';

const colors: Record<ResolutionLevel, string> = {
  [ResolutionLevel.AMBER]: '#F4D14C',
  [ResolutionLevel.GREEN]: '#23A48B',
  [ResolutionLevel.ORANGE]: '#F2A861',
  [ResolutionLevel.RED]: '#FF0000',
};

export function getResolutionLevelColor(resolutionLevel: ResolutionLevel) {
  return colors[resolutionLevel];
}
