import { makeInitialColumnSettings } from '../../../EntitiesGrid';
import { DEFAULT_PREPARED_TASK, TaskType } from '../tasks';
import { type PreparedNewDailyTask, type DailyTaskGridColumns, DailyTaskColumnKey } from './types';

export const DEFAULT_DAILY_TASKS_COLUMNS: DailyTaskGridColumns = makeInitialColumnSettings([
  DailyTaskColumnKey.StartDatetime,
  DailyTaskColumnKey.EndDatetime,
  DailyTaskColumnKey.Title,
  DailyTaskColumnKey.Relation,
  DailyTaskColumnKey.Description,
  DailyTaskColumnKey.Responsible,
  DailyTaskColumnKey.UserFunction,
  DailyTaskColumnKey.Status,
  DailyTaskColumnKey.ResolutionLevel,
  DailyTaskColumnKey.Location,
  DailyTaskColumnKey.Comments,
  DailyTaskColumnKey.ResolvedDate,
]);

export const DEFAULT_PREPARED_DAILY_TASK: PreparedNewDailyTask = {
  type: TaskType.OperationalTask,
  ...DEFAULT_PREPARED_TASK,
};
