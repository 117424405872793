import { useCallback, useMemo } from 'react';

import type { ColDef, ColGroupDef, DragStoppedEvent } from 'ag-grid-community';
import type { RecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { equals, fromPairs, mapValues, pipe, sortBy } from 'remeda';

import type { ColumnsSettings, UseSetColumnsSettings } from '../state/columnsSettings';

export type UseGridDragColumns<T extends string> = {
  columnDefs: ColDef[];
  columnRecoilState: RecoilState<ColumnsSettings<T>>;
  useSetRecoilColumnsSettings: UseSetColumnsSettings<T>;
};

const PERMANENT_COLUMNS = {
  isFlagged: {
    index: 2,
  },
};
type PermanentColumnKey = keyof typeof PERMANENT_COLUMNS;

const sortColDefsByColumns = <T extends string>(columnDefs: ColDef[], columns: ColumnsSettings<T>) => {
  const defaultIndexes = fromPairs(columnDefs.map(({ field }, index) => [field || '', index]));

  return sortBy(columnDefs, ({ field }) => {
    if (field) {
      if (field in columns) {
        return columns[field as T].index ?? 0;
      }
      if (field in PERMANENT_COLUMNS) {
        return PERMANENT_COLUMNS[field as PermanentColumnKey].index;
      }
      return defaultIndexes[field] ?? 0;
    }
    return 0;
  });
};

const addWidthColDefs = <T extends string>(columnDefs: ColDef[], columns: ColumnsSettings<T>) =>
  columnDefs.map((colDef) => {
    const key = colDef.field as T;
    if (columns[key]?.width && colDef.resizable !== false) {
      /*
      FIXUP ошибка в аннотациях типа в библиотеке, чтобы сбросить значение надо ставить null а не undefined
      https://www.ag-grid.com/react-data-grid/column-state/#width-and-flex
      https://www.ag-grid.com/react-data-grid/column-updating-definitions/#null-vs-undefined
      */
      const disableFlexOptions = 'flex' in colDef ? { flex: null as unknown as undefined } : {};
      return { ...colDef, width: columns[key].width, ...disableFlexOptions };
    }
    return colDef;
  });

const hideColDefsByColumns = <T extends string>(columnDefs: ColDef[], columns: ColumnsSettings<T>) =>
  columnDefs.map((colDef) => {
    const key = colDef.field as T;

    return columns[key] ? { ...colDef, hide: !columns[key].isShow } : colDef;
  });

const isColDef = <T>(colDefOrGroup: ColGroupDef<T> | ColDef<T>): colDefOrGroup is ColDef<T> => 'colId' in colDefOrGroup;

export const useGridDragColumns = <T extends string>({
  columnDefs,
  columnRecoilState,
  useSetRecoilColumnsSettings,
}: UseGridDragColumns<T>) => {
  const columns = useRecoilValue(columnRecoilState);

  const { execute: setColumns } = useSetRecoilColumnsSettings();

  const onDragStopped = useCallback(
    (event: DragStoppedEvent) => {
      const colDefs = event.api.getColumnDefs()?.filter(isColDef) || [];
      const hideColumns = fromPairs(colDefs.map((colDef) => [colDef.colId as string, colDef.hide]));

      const columnIndexes = fromPairs(
        event.columnApi
          .getColumnState()
          .map((column, index) => [
            column.colId === 'ag-Grid-AutoColumn' ? 'count' : column.colId,
            { index: index + 1, width: column.width },
          ]),
      );

      const newColumns = mapValues(columns, (column, key) => ({
        ...column,
        ...(columnIndexes[key] && hideColumns[key] !== true
          ? {
              ...columnIndexes[key],
              isShow: true,
            }
          : {
              isShow: false,
            }),
      }));
      if (!equals(columns, newColumns)) {
        setColumns(newColumns);
      }
    },
    [setColumns, columns],
  );

  const updatedColDefs = useMemo(
    () =>
      pipe(
        columnDefs,
        (colDefs) => sortColDefsByColumns(colDefs, columns),
        (colDefs) => addWidthColDefs(colDefs, columns),
        (colDefs) => hideColDefsByColumns(colDefs, columns),
      ),
    [columns, columnDefs],
  );

  return {
    columnDefs: updatedColDefs,
    onDragStopped,
  };
};
