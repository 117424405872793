import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { eventState } from '../../state/event';
import { NotificationType, useShowNotification } from '../../state/notification';
import { userRolesState } from '../../state/roles';
import { useAddUserEvent } from '../../state/user';
import { userFunctionsState } from '../../state/userFunctions';
import type { Role, UserFunction } from '../../types';
import { RolesEnum } from '../../types';

export type UserEventFormValues = {
  role: Role;
  userFunction: UserFunction;
};

const useAddUserToEvent = () => {
  const { execute: addUserEvent, loading } = useAddUserEvent();
  const { execute: showNotification } = useShowNotification();
  const { t } = useTranslation();
  const roles = useRecoilValue(userRolesState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const event = useRecoilValue(eventState);

  const onSubmit = async (
    { role, userFunction, userId }: UserEventFormValues & { userId: number },
    onComplete: () => void,
  ) => {
    await addUserEvent({
      user: userId,
      event: event.id,
      role: role.type,
      function: userFunction.id,
    });
    showNotification({ type: NotificationType.SUCCESS, text: t('user-add-success-message') });
    onComplete();
  };

  return {
    defaultValues: {
      role: roles.find(({ type }) => type === RolesEnum.USER) || roles[0],
      userFunction: userFunctions[0],
    },
    title: t('add-user-to-event'),
    onSubmit,
    loading,
  };
};

export default useAddUserToEvent;
