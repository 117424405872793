import { endOfDay, startOfDay } from 'date-fns';

import { TaskType } from '../../../../modules/Tasks/state/tasks';
import { Status, type UserDesktopFilters } from '../../../../types';

export const getFilters = (
  {
    responsibleIsInformed,
    incidentIsUrgent,
    locations,
    registeredBy,
    responsible,
    resolutionLevel,
    userFunctions,
    status,
    text,
    flagged,
    viewedUser,
    participants,
    typeDate,
    endDate,
    startDate,
  }: UserDesktopFilters,
  eventId: number,
) => {
  const filters: Record<string, unknown> = {
    [typeDate]: {
      $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
      $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
    },
    isResponsibleInformed: responsibleIsInformed || undefined,
    isUrgent: incidentIsUrgent || undefined,
    location: {
      id: locations,
    },
    registeredBy: {
      id: registeredBy,
    },
    userFunction: userFunctions,
    responsible: {
      id: responsible,
    },
    event: {
      id: [eventId],
    },
    isUnread: Boolean(viewedUser),
    isFlagged: Boolean(flagged),
    resolutionLevel,
    status: {
      $notIn: [Status.DRAFT],
      $in: status,
    },
    participants: {
      id: participants,
    },
    containsText: text,
    type: TaskType.Incident,
  };

  return filters;
};
