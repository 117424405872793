/* eslint-disable import/no-duplicates */
import type { Locale } from 'date-fns';
import { formatDistanceToNowStrict } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import type { TFunction } from 'i18next';
import { isString } from 'remeda';

import type { Language } from '../types';

const locales = {
  en: enUS,
  ru,
};

export type FormatDistanceToken =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears'

export type FormatDistanceLocale<Value> = {
  [token in FormatDistanceToken]: Value
}

type FormatDistanceTokenValue =
  | string
  | {
      one: string
      few?: string,
      other: string
    }

type FormatDistanceLocaleDict = FormatDistanceLocale<FormatDistanceTokenValue>

const FORMAT_DISTANCE_LOCALE_EN: FormatDistanceLocaleDict = {
  lessThanXSeconds: 'now',
  xSeconds: 'now',
  halfAMinute: 'now',
  lessThanXMinutes: '{{count}} minutes',
  xMinutes: '{{count}} minutes',
  aboutXHours: '{{count}} hours',
  xHours: '{{count}} hours',
  xDays: '{{count}} days',
  aboutXWeeks: '{{count}} weeks',
  xWeeks: '{{count}} weeks',
  aboutXMonths: '{{count}} months',
  xMonths: '{{count}} months',
  aboutXYears: '{{count}} years',
  xYears: '{{count}} years',
  overXYears: '{{count}} years',
  almostXYears: '{{count}} years',
};

const FORMAT_DISTANCE_LOCALE_RU: FormatDistanceLocaleDict = {
  lessThanXSeconds: 'сейчас',
  xSeconds: 'сейчас',
  halfAMinute: 'сейчас',
  lessThanXMinutes: {
    one: 'меньше минуты',
    other: 'менее {{count}} минут',
  },
  xMinutes: {
    one: 'минута',
    few: '{{count}} минуты',
    other: '{{count}} минут',
  },
  aboutXHours: {
    one: 'час',
    few: '{{count}} часа',
    other: '{{count}} часов',
  },
  xHours: {
    one: 'час',
    few: '{{count}} часа',
    other: '{{count}} часов',
  },
  xDays: {
    one: 'день',
    few: '{{count}} дня',
    other: '{{count}} дней',
  },
  aboutXWeeks: {
    one: 'неделя',
    few: '{{count}} недели',
    other: '{{count}} недель',
  },
  xWeeks: {
    one: 'неделя',
    few: '{{count}} недели',
    other: '{{count}} недель',
  },
  aboutXMonths: {
    one: 'месяц',
    few: '{{count}} месяца',
    other: '{{count}} месяцев',
  },
  xMonths: {
    one: 'месяц',
    few: '{{count}} месяца',
    other: '{{count}} месяцев',
  },
  aboutXYears: {
    one: 'год',
    few: '{{count}} года',
    other: '{{count}} лет',
  },
  xYears: {
    one: 'год',
    few: '{{count}} года',
    other: '{{count}} лет',
  },
  overXYears: {
    one: 'больше года',
    other: 'больше {{count}} лет',
  },
  almostXYears: {
    few: 'почти {{count}} года',
    one: 'почти год',
    other: 'почти {{count}} лет',
  },
};

const FORMAT_DISTANCE_LOCALE = {
  en: FORMAT_DISTANCE_LOCALE_EN,
  ru: FORMAT_DISTANCE_LOCALE_RU,
};

type Options = {
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  roundingMethod?: 'floor' | 'ceil' | 'round';
  locale?: Locale;
  comparison?: number;
};

const NOW_TOKENS = ['lessThanXSeconds', 'xSeconds', 'halfAMinute'];

const makeFormatDistance = (language: Language, t: TFunction<'translation'>) => (
  token: keyof FormatDistanceLocaleDict,
  count: string,
  options: Options = {},
) => {
  let result = FORMAT_DISTANCE_LOCALE[language][token];
  const countValue = Math.round(parseFloat(count));
  if (isString(result)) {
    result = result.replace('{{count}}', count);
  } else if (countValue > 1 && countValue < 5 && 'few' in result && isString(result.few)) {
    result = result.few.replace('{{count}}', count);
  } else if (countValue > 1) {
    result = result.other.replace('{{count}}', count);
  } else {
    result = result.one.replace('{{count}}', count);
  }

  if (options?.addSuffix) {
    if (NOW_TOKENS.includes(token)) {
      return result;
    }

    if (options.comparison && options.comparison > 0) {
      return `${t('in-time-comp')} ${result}`;
    }
    return `${result} ${t('ago')}`;
  }

  return result;
};

const formatDistanceToNow = (date: Date, language: Language, t: TFunction<'translation'>) => formatDistanceToNowStrict(date, {
  addSuffix: true,
  locale: {
    ...locales[language],
    formatDistance: makeFormatDistance(language, t),
  },
});

export default formatDistanceToNow;
