import type { RecoilTaskInterface } from 'recoil-toolkit';
import { updateObj } from 'recoil-toolkit';

import { incidentService } from '../../../api';
import { incidentToApi } from '../../../components/IncidentTable/state/adapters';
import type { Incident, Photo, User } from '../../../types';
import { getCurrentUserTask } from '../../user';
import { incidentsState } from '../atoms';
import type { EditIncidentTaskParams } from '../types';
import { getEditIncidentPopulate } from '../utils';

export const editPartIncidentTask =
  ({ set }: RecoilTaskInterface) =>
  async (id: number, data: Partial<Incident>) => {
    const updateData = { ...data };

    const formData = new FormData();
    formData.append('data', JSON.stringify(incidentToApi(updateData)));

    const responseData = await incidentService.editIncident(id, formData, getEditIncidentPopulate());
    set(incidentsState, updateObj<Incident>(responseData.data, { id }));
  };

export const viewedByTask =
  ({ set }: RecoilTaskInterface) =>
  async ({
    userId,
    incidentId,
    setViewedBy,
  }: {
    userId: number;
    incidentId: number;
    setViewedBy?: (viewedBy: User[]) => void;
  }) => {
    const { data: incident } = await incidentService.getIncident({ id: incidentId, query: 'populate=viewedBy' });
    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({
        viewedBy: [userId, ...incident.viewedBy.map(({ id }) => id)],
      }),
    );

    const {
      data: { viewedBy },
    } = await incidentService.editIncident(incidentId, formData, 'populate=viewedBy');

    if (setViewedBy) {
      setViewedBy(viewedBy);
    } else {
      set(incidentsState, updateObj<Incident>({ viewedBy }, { id: incidentId }));
    }
  };

export const editIncidentTask = (rti: RecoilTaskInterface) => async (params: EditIncidentTaskParams) => {
  const { set } = rti;
  const { incident, setNewIncident, successCallback } = params;
  const { photoSources, id, photos } = incident;

  if (photoSources) {
    const editedIncidentData = incidentToApi(incident);
    const data = { ...editedIncidentData, photos: [] as Photo[] };

    const formData = new FormData();

    photoSources.forEach((sourceItem) => {
      const photo = photos?.find((item) => item.id === Number(sourceItem.id));

      if (photo) {
        data.photos.push(photo);
      } else {
        formData.append('files.photos', sourceItem.source, (sourceItem.source as File).name);
      }
    });

    formData.append('data', JSON.stringify(data));

    const responseData = await incidentService.editIncident(Number(id), formData, getEditIncidentPopulate());
    if (setNewIncident) {
      setNewIncident(responseData.data);
    } else {
      set(incidentsState, updateObj<Incident>(responseData.data, { id }));
    }
  } else {
    editPartIncidentTask(rti)(Number(id), incident);
  }

  await getCurrentUserTask(rti)();
  successCallback();
};
