import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { useTooltip, Tooltip } from '../../../../modules/EntitiesGrid';
import { currentEventIdState } from '../../../../state/event';
import findCurrentUserEvent from '../../../../utils/findCurrentUserEvent';
import { ClampedTypography } from '../../../base';

const UserFunctionRenderer = (props: ICellRendererParams) => {
  const { value, data } = props;
  let showedValue = value;
  const currentEventId = useRecoilValue(currentEventIdState);
  const { ref, tooltipProps } = useTooltip();

  if (data?.responsible?.userEvents && currentEventId) {
    const userEvent = findCurrentUserEvent(currentEventId)(data.responsible.userEvents);

    showedValue = userEvent?.function?.name;
  }
  return (
    <Tooltip title={showedValue} {...tooltipProps}>
      <ClampedTypography ref={ref} variant='body2' lineclamp={5}>
        {showedValue}
      </ClampedTypography>
    </Tooltip>
  );
};

export default memo(UserFunctionRenderer);
