import { createPipe, pick } from 'remeda';

import type { Incident } from '../../../types';
import updateIfExists from '../../../utils/updateIfExists';
import { ColumnKey } from './types';

export const EDITABLE_FIELDS = [
  ColumnKey.Title,
  ColumnKey.Description,
  ColumnKey.Responsible,
  ColumnKey.ResolutionLevel,
  ColumnKey.Location,
  ColumnKey.LocationDescription,
  ColumnKey.Status,
  ColumnKey.Urgent,
  ColumnKey.ResponsibleInformed,
  ColumnKey.UserFunction,
  ColumnKey.EndDatetime,
  ColumnKey.Participants,
  ColumnKey.Relations,
] as const;

export const incidentToApi = createPipe(
  pick<Partial<Incident>, keyof Incident>(EDITABLE_FIELDS),
  updateIfExists('location', (location) => location.id),
  updateIfExists('responsible', (responsible) => responsible.id),
  updateIfExists('registeredBy', (registeredBy) => registeredBy.id),
  updateIfExists('userFunction', (userFunction) => userFunction.id),
  updateIfExists(ColumnKey.Participants, (participants) => participants.map((user) => user.id)),
  updateIfExists('relations', (relations) => relations.map((relation) => relation.id)),
);
