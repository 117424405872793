import * as qs from 'qs';
import type { RecoilTaskInterface } from 'recoil-toolkit';

import { userFunctionService } from '../../api';
import { currentEventIdState } from '../event';
import { userFunctionsDefaultState } from './atoms';

export const getUserFunctionsDefaultTask =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async () => {
    const eventId = snapshot.getLoadable(currentEventIdState).getValue();
    const query = {
      filters: {
        events: { id: eventId },
      },
      pagination: {
        limit: -1,
      },
    };

    const { data } = await userFunctionService.getUserFunctions({ query: qs.stringify(query) });
    set(userFunctionsDefaultState, data);
  };
