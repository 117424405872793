import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { userService } from '../../../../api';
import { userState } from '../../../../state/user';
import { dashboardsIncidentState } from './atom';

const saveDashboardSettings =
  ({ set, snapshot }: RecoilTaskInterface) =>
  async () => {
    const currentUser = snapshot.getLoadable(userState).getValue();
    const { layoutsSetting } = snapshot.getLoadable(dashboardsIncidentState).getValue();
    const { dashboardSettings } = await userService.update({
      id: currentUser.id,
      data: {
        dashboardSettings: layoutsSetting,
      },
    });

    set(userState, (prevState) => ({
      ...prevState,
      dashboardSettings,
    }));
  };

export const useSaveDashboardSettings = () =>
  useRecoilTask(saveDashboardSettings, [], {
    dataSelector: dashboardsIncidentState,
    errorStack: true,
  });
