import type { ChangeEvent} from 'react';
import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';

import { CustomCheckbox } from '../../../base';
import { CheckboxType } from '../../../base/CustomCheckbox';
import { cellСanBeEdited } from '../../utils';

const BooleanRenderer = (params: ICellRendererParams) => {
  const {
    value,
    column,
    node,
  } = params;
  const columnId = column?.getColId();

  const checkedHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (columnId) {
      node.setDataValue(columnId, checked);
    }
  };

  return (
    <CustomCheckbox
      disabled={!cellСanBeEdited(params)}
      sx={{ padding: 0 }}
      checked={value}
      type={CheckboxType.CIRCLE}
      onChange={checkedHandler}
    />
  );
};
export default memo(BooleanRenderer);
