import * as qs from 'qs';

import type { DashboardDataSet, Event, WithMeta } from '../types';
import requestService from './requestService';

type EventUpdate = {
  id: number;
  data: FormData;
};

const EVENTS_POPULATION = qs.stringify(
  {
    populate: ['locations', 'locations.geo', 'userFunctions'],
  },
  { encodeValuesOnly: true },
);

const eventService = {
  getEvents: () => requestService.get('/events').then((response) => response.data),
  getDashboards: ({ eventId, query }: { eventId: number; query: string }) =>
    requestService.get<DashboardDataSet[]>(`/events/${eventId}/dashboards?${query}`).then((response) => response.data),
  addEvent: (data: FormData) =>
    requestService
      .post('/events', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data),
  updateEvent: ({ id, data }: EventUpdate) =>
    requestService
      .put(`/events/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data),
  getAllEvents: () =>
    requestService.get<WithMeta<Event[]>>(`/events?${EVENTS_POPULATION}`).then((response) => response.data),
};

export default eventService;
