import { Box } from '@mui/material';
import RcGantt from 'mrreds-rc-gantt';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../../../../components/base/Tooltip';
import { getLocale } from '../lib/getLocale';
import { getStatusByColor } from '../lib/getStatusByColor';
import { useGanttData } from '../model/useGanttData';
import { Bar } from '../ui/Bar';
import { ControlPanel } from '../ui/ControlPanel';
import { UserAvatar } from '../ui/UserAvatar';

import './Gantta.css';

export const Gantta = () => {
  const { t } = useTranslation();
  const { data } = useGanttData();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <ControlPanel />

      <Box sx={{ flexGrow: 1 }}>
        <RcGantt
          data={data}
          columns={[
            {
              name: 'name',
              label: t('title'),
              flex: 3,
              minWidth: 250,
              render(item) {
                return (
                  <Tooltip title={item.name}>
                    <Box display='inline'>{item.name}</Box>
                  </Tooltip>
                );
              },
            },
            {
              name: 'startDate',
              label: t('start-datetime'),
              minWidth: 120,
            },
            {
              name: 'endDate',
              label: t('end-datetime'),
              minWidth: 120,
            },
          ]}
          locale={getLocale(t)}
          onUpdate={async () => true}
          renderLeftText={() => null}
          renderRightText={(barInfo) =>
            barInfo.task.record.responsible ? <UserAvatar user={barInfo.task.record.responsible} /> : null
          }
          renderBar={(barInfo, { width, height }) => (
            <Bar
              width={width}
              height={height}
              status={getStatusByColor(barInfo.task.startDate, barInfo.task.endDate)}
              userFunction={barInfo.task.record.userFunction}
            />
          )}
          disabled
          tableCollapseAble={false}
        />
      </Box>
    </Box>
  );
};
