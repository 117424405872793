/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FormIncidentsFilter, MapFiltersProps } from '../lib/types';

export const mapUserFiltersToFormFilters = ({
  userFilters,
  allLocations,
  allUserFunctions,
  allUsers,
  currentUser,
}: MapFiltersProps): FormIncidentsFilter => {
  const {
    userFunctions,
    registeredBy,
    responsible,
    locations,
    viewedUser,
    flagged,
    participants,
    ...otherUserFilters
  } = userFilters;

  return {
    ...otherUserFilters,
    userFunctions: allUserFunctions.filter((item) => userFunctions?.includes(item.id)),
    registeredBy: allUsers.filter((item) => registeredBy?.includes(item.id)),
    responsible: allUsers.filter((item) => responsible?.includes(item.id)),
    locations: allLocations.filter((item) => locations?.includes(item.id)),
    participants: allUsers.filter((item) => participants?.includes(item.id)),
    ...(userFilters.isAssignedToMe && currentUser ? { responsible: [currentUser] } : {}),
    ...(userFilters.isCreatedByMe && currentUser ? { registeredBy: [currentUser] } : {}),
    ...(userFilters.typeDate ? {} : { typeDate: 'registeredDate' }),
  };
};
