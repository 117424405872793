import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { currentEventSettings, permissionState } from '../../state/event';
import { AppRoute } from '../../types';
import { CustomTab } from './components/CustomTab';
import { Menu, type MenuProps } from './components/Menu';

type MenuProjectPlanProps = MenuProps;

export const MenuProjectPlan = (props: MenuProjectPlanProps) => {
  const { t } = useTranslation();
  const { canViewUsers, canViewDashboard } = useRecoilValue(permissionState);
  const settings = useRecoilValueLoadable(currentEventSettings).valueMaybe();
  return (
    <Menu {...props}>
      {settings?.availableProjectPlan && <CustomTab label={t('project-plan')} value={AppRoute.PROJECT_PLAN} />}
      <CustomTab label={t('gantta')} value={AppRoute.PROJECT_PLAN_GANTTA} />
      {canViewDashboard && <CustomTab label={t('dashboard')} value={AppRoute.PROJECT_PLAN_DASHBOARD} />}
      {canViewUsers && <CustomTab label={t('users')} value={AppRoute.PROJECT_PLAN_USERS} />}
    </Menu>
  );
};
