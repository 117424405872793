import type { TFunction } from 'i18next';

import type { CommentActionEditDescription } from '../../../types';
import type { HeaderItem} from './exportCreateIncidentAction';
import { addWidthToCell } from './exportCreateIncidentAction';

export const createHeader = (t: TFunction<'translation'>, tableWidth: number) => ([
  {
    header: t('field'),
    dataKey: 'field',
    width: tableWidth * 0.15,
  },
  {
    header: t('before'),
    dataKey: 'before',
    width: tableWidth * 0.425,
  },
  {
    header: t('after'),
    dataKey: 'after',
    width: tableWidth * 0.425,
  },
]);

export const convertAttrsToRows = (
  comment: CommentActionEditDescription['attrs'],
  t: TFunction<'translation'>,
  header: HeaderItem[],
) => {
  const bodyRows = [{
    field: t('comments'),
    before: comment.old.body,
    after: comment.new.body,
  }];

  return addWidthToCell(bodyRows, header);
};
