import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Trans, useTranslation } from 'react-i18next';

import type { TaskType } from '../../../modules/Tasks/state/tasks';
import { useShowDrawer, type DrawerType } from '../../../state/drawer';
import { useExportToPdfDashboards } from '../../../state/export';
import { Icon } from '../../base';
import { FullScreenButton } from '../../FullScreen';
import { FilterButton } from '../components/FilterButton';
import { WrapperBox } from '../components/WrapperBox';

type Props = {
  saveDashboardSettings: () => void;
  saving: boolean;
  drawerType: DrawerType;
  taskType: TaskType;
};

const dashboardKeys = [
  'location',
  'resolutionLevel',
  'status',
  'topResponsibles',
  'userFunctions',
  'registeredVsResolved',
  'activeVsResolved',
];

export const ControlPanelDashbords = ({ saveDashboardSettings, saving, drawerType, taskType }: Props) => {
  const { execute: showDrawer } = useShowDrawer();
  const { execute: exportToPdf } = useExportToPdfDashboards();
  const { t } = useTranslation();
  const handlePdf = async () => {
    const charts: Record<string, HTMLElement> = {};
    dashboardKeys.forEach((keyName) => {
      const chart = document.getElementById(keyName);
      if (chart) {
        charts[keyName] = chart;
      }
    });
    exportToPdf(t, charts, taskType);
  };
  return (
    <WrapperBox>
      <span />
      <Box>
        <Stack direction='row' spacing={2}>
          <FilterButton color='violet' onClick={() => showDrawer(drawerType)} />
          <LoadingButton variant='outlined' onClick={saveDashboardSettings} loading={saving}>
            <Trans i18nKey='save-settings' />
          </LoadingButton>
          <Button variant='outlined' onClick={handlePdf} sx={{ borderColor: '#AA96C8' }}>
            <Icon icon='pdf' htmlColor='#AA96C8' sx={{ width: '17px', height: '17px' }} />
          </Button>
          <FullScreenButton sx={{ ml: 1 }} />
        </Stack>
      </Box>
    </WrapperBox>
  );
};
