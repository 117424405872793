import type {
  FC, ReactElement} from 'react';
import { useState, cloneElement
} from 'react';

import { dropLast, last } from 'remeda';

import { DrawerContainer } from '../base';

type DrawerNavigationProps = {
  children: ReactElement,
}

export const DrawerNavigation: FC<DrawerNavigationProps> = ({ children }) => {
  const [navigationStack, setNavigationStack] = useState([{
    element: children,
  }]);
  const goBack = () => {
    setNavigationStack((stack) => dropLast(stack, 1));
  };
  const goForward = (element: ReactElement) => {
    setNavigationStack((stack) => ([...stack, { element }]));
  };
  const lastElement = last(navigationStack);

  return (
    lastElement ? (
      <DrawerContainer>
        {
      cloneElement(
        lastElement.element,
        { goBack, goForward, isChild: navigationStack.length > 1 },
      )
    }
      </DrawerContainer>
    ) : null
  );
};
