import type { RefObject} from 'react';
import { useCallback } from 'react';

import type { RowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { TFunction} from 'i18next';
import { t } from 'i18next';

const useExportToExcelRowsFromGrid = (
  gridRef: RefObject<AgGridReact>,
  callback: (t: TFunction<'translation', undefined>, rows: unknown[]) => Promise<void>,
) => useCallback(() => {
  if (gridRef.current?.api) {
    const rows: RowNode[] = [];

    gridRef.current.api.forEachNodeAfterFilterAndSort((rowNode) => rows.push(rowNode.data));

    callback(t, rows);
  }
}, [callback, gridRef]);

export default useExportToExcelRowsFromGrid;
