import type { Photo } from '../types';

const getPhotoFormats = (photo: Photo) => {
  const {
    large, medium, small, thumbnail,
  } = photo.formats ?? {};
  const originalSizeImage = large ?? medium ?? photo;
  const thumbnailSizeImage = thumbnail ?? small ?? medium ?? photo;

  return {
    originalSizeImage,
    thumbnailSizeImage,
  };
};

export default getPhotoFormats;
