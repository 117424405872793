import { ResolutionLevel } from '../../../types';
import { ResolutionLevelBlock } from '../../base';
import { ButtonGroupField, type ButtonGroupFieldProps } from '../../fields/ButtonGroupField';
import type { ProjectTasksFormFilerValue } from '../lib/types';

type ResolutionLevelFieldProps = Omit<
  ButtonGroupFieldProps<ProjectTasksFormFilerValue, 'resolutionLevel'>,
  'name' | 'options'
>;

export const ResolutionLevelField = (props: ResolutionLevelFieldProps) => (
  <ButtonGroupField
    {...props}
    name='resolutionLevel'
    options={[
      { value: ResolutionLevel.GREEN, children: <ResolutionLevelBlock level={ResolutionLevel.GREEN} /> },
      { value: ResolutionLevel.AMBER, children: <ResolutionLevelBlock level={ResolutionLevel.AMBER} /> },
      { value: ResolutionLevel.ORANGE, children: <ResolutionLevelBlock level={ResolutionLevel.ORANGE} /> },
      { value: ResolutionLevel.RED, children: <ResolutionLevelBlock level={ResolutionLevel.RED} /> },
    ]}
  />
);
