import type { SyntheticEvent } from 'react';

import {
  type AutocompleteProps,
  type AutocompleteValue,
  type AutocompleteChangeReason,
  type AutocompleteChangeDetails,
  Autocomplete,
  createFilterOptions,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';

export type AutocompleteMultipleAdvancedValues = 'all';

export type AutocompleteMultipleProps<
  T,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'multiple'> & {
  onChange?: (
    event: SyntheticEvent,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | AutocompleteMultipleAdvancedValues,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>,
  ) => void;
  disableSelectAll?: boolean;
};

export const AutocompleteMultiple = <T extends { id: number | string }>({
  onChange,
  renderOption,
  disableSelectAll,
  ...props
}: AutocompleteMultipleProps<T>) => (
  <Autocomplete
    {...props}
    multiple
    onChange={(event, value, reason, details) => {
      const isAll = value.find((item) => item.id === 'all');
      onChange?.(event, isAll ? 'all' : value, reason, details);
    }}
    filterOptions={(options, state) => {
      const filterOptions = createFilterOptions<T>();
      const filtered = filterOptions(options, state);

      return disableSelectAll
        ? filtered
        : [
            {
              id: 'all',
            } as T,
            ...filtered,
          ];
    }}
    renderOption={(params, option, state) => {
      if (option.id === 'all') {
        return (
          <Typography component='li' {...params}>
            <Trans i18nKey='select-all' />
          </Typography>
        );
      }

      return renderOption?.(params, option, state);
    }}
  />
);
