import type { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import useDrawer from '../../../../../hooks/useDrawer';
import { Drawer } from '../../../../../state/drawer';
import type { ResolutionLevel } from '../../../../../types';

interface CountWrapperProps extends PropsWithChildren {
  id: number;
  count: number | string;
  availableResolutionLevels: ResolutionLevel[];
}

export const CountWrapper = (props: CountWrapperProps) => {
  const { children, id, count, availableResolutionLevels } = props;

  const { showDrawer } = useDrawer();

  const onClickHandler = () => {
    showDrawer({ type: Drawer.PROJECT_TASK_FORM, props: { id, count, availableResolutionLevels } });
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={onClickHandler}>
      {children}
    </Box>
  );
};
