import type { RecoilTaskInterface } from 'recoil-toolkit';

import { incidentService } from '../../../api';
import type { Incident, WithMeta } from '../../../types';
import { currentEventIdState } from '../../event/atoms';
import { incidentsFilterState } from '../../incidentsFilter';
import { clearUpdatedIncidents } from '../../incidentsUpdates/tasks';
import { countState, limitState, pageSizeState, pageState } from '../../incidentTable/pagination';
import { sortState } from '../../incidentTable/sort';
import { incidentsState } from '../atoms';
import { getQuery, getQueryAll } from '../utils';

export const getIncidentTasksWithoutFilters = (rti: RecoilTaskInterface) => async () => {
  const { set, snapshot } = rti;

  const eventId = snapshot.getLoadable(currentEventIdState).getValue();

  if (eventId) {
    const responseData = await incidentService.getIncidents(
      getQueryAll({ eventId, filter: { typeDate: 'registeredDate' } }),
    );
    set(incidentsState, responseData.data);
  }
};

export const getIncidentsTaskWithFilters = (rti: RecoilTaskInterface) => async () => {
  const { set, snapshot } = rti;
  const filter = snapshot.getLoadable(incidentsFilterState).getValue();
  const pageSize = snapshot.getLoadable(pageSizeState).getValue();
  const page = snapshot.getLoadable(pageState).getValue();
  const limit = snapshot.getLoadable(limitState).getValue();
  const sort = snapshot.getLoadable(sortState).getValue();
  const eventId = snapshot.getLoadable(currentEventIdState).getValue();
  if (eventId) {
    const limitAll = limit === -1;
    const query = limitAll
      ? getQueryAll({
          filter,
          eventId,
        })
      : getQuery({
          filter,
          page,
          pageSize,
          sort,
          eventId,
        });
    const result: WithMeta<Incident[]> = {
      data: [],
      meta: {
        pagination: {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          total: 0,
        },
      },
    };
    const responseData = await incidentService.getIncidents(query);
    result.data = responseData.data;
    result.meta = responseData.meta;

    if (responseData.meta.pagination.page !== responseData.meta.pagination.pageCount) {
      await Promise.all(
        Array.from({ length: responseData.meta.pagination.pageCount - 1 }, (_, i) => i + 2).map(async (currentPage) => {
          const nextQuery = getQuery({
            filter,
            page: currentPage,
            pageSize,
            sort,
            eventId,
          });

          const data = await incidentService.getIncidents(nextQuery);
          result.data.push(...data.data);
          result.meta = data.meta;
        }),
      );
    }

    set(countState, responseData.meta.pagination.total);
    set(incidentsState, responseData.data);
    clearUpdatedIncidents(rti)();
  }
};

export const resetIncidentsTask =
  ({ reset }: RecoilTaskInterface) =>
  () => {
    reset(incidentsState);
  };
