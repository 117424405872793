import type { ReactNode } from 'react';

import { Trans } from 'react-i18next';
import type { Location } from 'react-router';

import { AppRoute } from '../../../types';

type Item = {
  id: string;
  title: ReactNode;
  baseUrl: AppRoute | string;
  availible: boolean;
};

interface GetMenuItemsProps {
  canViewDailyPlan: boolean;
  canViewProjectPlan: boolean;
  canViewIncidentPlan: boolean;
}

const getMenuItems = (params: GetMenuItemsProps): Item[] => {
  const { canViewDailyPlan, canViewProjectPlan, canViewIncidentPlan } = params;
  return [
    { id: 'drs', title: <Trans i18nKey='drs' />, baseUrl: AppRoute.DAILY_PLAN, availible: canViewDailyPlan },
    { id: 'pmp', title: <Trans i18nKey='pmp' />, baseUrl: AppRoute.PROJECT_PLAN, availible: canViewProjectPlan },
    // Last because can use without url specific address: host/
    { id: 'ims', title: <Trans i18nKey='ims' />, baseUrl: '/', availible: canViewIncidentPlan },
  ];
};

interface GetRenderItemsProps extends GetMenuItemsProps {
  location: Location;
}

export const getRenderItems = (params: GetRenderItemsProps) => {
  const { location, canViewDailyPlan, canViewProjectPlan, canViewIncidentPlan } = params;
  return getMenuItems({
    canViewDailyPlan,
    canViewProjectPlan,
    canViewIncidentPlan,
  })
    .filter((item) => item.availible)
    .reduce<{ activeItem: Item | undefined; otherItems: Item[] }>(
      (acc, item) => {
        if (location.pathname.includes(item.baseUrl) && !acc.activeItem) {
          return {
            ...acc,
            activeItem: item,
          };
        }

        return {
          ...acc,
          otherItems: [...acc.otherItems, item],
        };
      },
      { activeItem: undefined, otherItems: [] },
    );
};
