import { Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';
import { pick } from 'remeda';

import { DATETIME_FORMAT } from '../../../../constants/base';
import { baseInputSx } from './constants';

type FromTo = { start?: Date; end?: Date };

type DateTimePeriodProps<T extends FromTo> = {
  filtersState: RecoilState<T>;
};

const inputSx = { sx: pick(baseInputSx.InputProps.sx, ['width']) };

export const DateTimePeriod = <T extends FromTo>({ filtersState }: DateTimePeriodProps<T>) => {
  const [filters, setFilters] = useRecoilState(filtersState);
  const { t } = useTranslation();

  const onChangeHandler = (fieldName: 'start' | 'end') => (value: Date | null) => {
    const validDate = value && isValid(value);
    if (validDate || !value) {
      setFilters((oldFilters) => ({
        ...oldFilters,
        [fieldName]: value || undefined,
      }));
    }
  };
  const onStartHandler = onChangeHandler('start');
  const onEndHandler = onChangeHandler('end');

  return (
    <Stack direction='row' spacing={2}>
      <DateTimePicker
        label={t('start-date')}
        inputFormat={DATETIME_FORMAT.full}
        ampm={false}
        value={filters.start ?? null}
        onChange={onStartHandler}
        renderInput={(params) => <TextField {...inputSx} {...params} />}
      />
      <DateTimePicker
        label={t('end-date')}
        inputFormat={DATETIME_FORMAT.full}
        ampm={false}
        value={filters.end ?? null}
        minDateTime={filters.start}
        onChange={onEndHandler}
        renderInput={(params) => <TextField {...inputSx} {...params} />}
      />
    </Stack>
  );
};
