import type { ChangeEvent, MouseEvent, RefObject } from 'react';

import { TablePagination } from '@mui/material';
import type { AgGridReact } from 'ag-grid-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { countState, pageSizeState, pageState } from '../../../state/incidentTable/pagination';

interface PaginationProps {
  gridRef: RefObject<AgGridReact>;
}

export const Pagination = (props: PaginationProps) => {
  const { gridRef } = props;
  const [pageSize, setPageSize] = useRecoilState(pageSizeState);
  const [page, setPage] = useRecoilState(pageState);
  const count = useRecoilValue(countState);

  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    gridRef.current?.api.paginationGoToPage(newPage);
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <TablePagination
      sx={{ borderTop: '1px solid #e2e2e2' }}
      component='div'
      showFirstButton
      showLastButton
      count={count}
      page={page - 1}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
