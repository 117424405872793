import { useEffect } from 'react';

import { useUserFunctionsDefaultList } from '../state/userFunctions';

const useDefaultUserFunctions = () => {
  const {
    data: defaultUserFunctions,
    execute: loadDefaultUserFunctions,
    loading,
  } = useUserFunctionsDefaultList();

  useEffect(
    () => {
      loadDefaultUserFunctions();
    },
    [loadDefaultUserFunctions],
  );

  return {
    defaultUserFunctions,
    loading,
  };
};

export default useDefaultUserFunctions;
