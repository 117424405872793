import type { ReactNode } from 'react';

import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';

import type { KeyNameType } from '../lib/types/dashborad.types';

type DashboardItemProps = {
  children: ReactNode;
  title: ReactNode;
};

export const DashboardItem = ({ children, title, keyName }: DashboardItemProps & KeyNameType) => (
  <Card
    id={keyName}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography align='left' variant='h6' gutterBottom component='div' mt={1} ml={1}>
        {title}
      </Typography>
    </Box>
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {children}
    </Box>
  </Card>
);
