import type { TFunction } from 'i18next';
import { mapKeys, mapValues, pick, sortBy } from 'remeda';

import {
  ChartTypes,
  type DashboardDataSet,
  type DoughnutChartDataType,
} from '../../../../shared/lib/types/dashborad.types';
import { ResolutionLevel, Status, type DashboardType } from '../../../../types';
import getResolutionLevelTitle from '../../../../utils/getResolutionLevelTitle';
import getStatusTitle from '../../../../utils/getStatusTitle';

const OTHER = '__other_entity';

const STATUSES_COLORS = {
  [Status.REGISTERED]: 'rgb(221, 227, 229)',
  [Status.IN_PROGRESS]: 'rgb(170, 150, 200)',
  [Status.RESOLVED]: 'rgb(199, 209, 212)',
  [Status.DRAFT]: '',
};

const RESOLUTION_LEVEL_COLORS = {
  [ResolutionLevel.GREEN]: 'rgb(35, 164, 139)',
  [ResolutionLevel.AMBER]: 'rgb(244, 209, 76)',
  [ResolutionLevel.ORANGE]: 'rgb(242, 168, 97)',
  [ResolutionLevel.RED]: 'rgb(255, 0, 0)',
};

export type KeyNameType = {
  keyName: string;
};

const DASHBOARD_OPTIONS = {
  location: {
    type: ChartTypes.HORIZONTAL_BAR_CHART,
    name: (t: TFunction<'translation'>) => t('location'),
    legendTitle: (t: TFunction<'translation'>) => t('location'),
    otherTitle: (t: TFunction<'translation'>) => t('no-location'),
    order: 1,
  },
  resolutionLevel: {
    type: ChartTypes.DOUGHNUT_CHART,
    name: (t: TFunction<'translation'>) => t('resolution-level'),
    backgroundColor: (dataset: DoughnutChartDataType['dataset']) =>
      dataset.map((data) => RESOLUTION_LEVEL_COLORS[data.value as ResolutionLevel]),
    order: 2,
  },
  status: {
    type: ChartTypes.DOUGHNUT_CHART,
    name: (t: TFunction<'translation'>) => t('status'),
    backgroundColor: (dataset: DoughnutChartDataType['dataset']) =>
      dataset.map((data) => STATUSES_COLORS[data.value as Status]),
    order: 3,
  },
  topResponsibles: {
    type: ChartTypes.HORIZONTAL_BAR_CHART,
    name: (t: TFunction<'translation'>) => t('top-responsibles'),
    legendTitle: (t: TFunction<'translation'>) => t('responsible'),
    otherTitle: (t: TFunction<'translation'>) => t('no-responsible'),
    order: 4,
  },
  userFunctions: {
    type: ChartTypes.HORIZONTAL_BAR_CHART,
    name: (t: TFunction<'translation'>) => t('user-functions'),
    legendTitle: (t: TFunction<'translation'>) => t('function'),
    otherTitle: (t: TFunction<'translation'>) => t('no-user-function'),
    order: 5,
  },
  registeredVsResolved: {
    type: ChartTypes.LINE_CHART,
    name: (t: TFunction<'translation'>) => t('registered-vs-resolved'),
    datasetNames: [(t: TFunction<'translation'>) => t('resolved'), (t: TFunction<'translation'>) => t('added')],
    backgroundColor: ['rgb(170, 150, 200)', 'rgb(242, 168, 97)'],
    order: 6,
  },
  activeVsResolved: {
    type: ChartTypes.LINEAR_PROGRESS_CHART,
    name: (t: TFunction<'translation'>) => t('active-vs-resolved'),
    legendTitle: (t: TFunction<'translation'>) => t('total-registered'),
    keysMapping: {
      Active: (t: TFunction<'translation'>) => t('active'),
      Resolved: (t: TFunction<'translation'>) => t('resolved'),
    },
    order: 7,
  },
};

function replaceTransInDoughnut<T>(
  dataset: DoughnutChartDataType['dataset'],
  titleGetter: (value: T, t: TFunction<'translation'>) => string,
  t: TFunction<'translation'>,
) {
  return dataset.map(({ value, count }) => ({
    count,
    value: titleGetter(value as T, t),
  }));
}

const datasetToDashboard = (datasets: DashboardDataSet[], t: TFunction<'translation'>): DashboardType[] => {
  // eslint-disable-next-line array-callback-return, consistent-return
  const dashboards = datasets.map((dataset) => {
    // eslint-disable-next-line default-case
    switch (dataset.type) {
      case ChartTypes.DOUGHNUT_CHART: {
        const options = DASHBOARD_OPTIONS[dataset.key];
        let processedDataset = dataset.dataset;
        if (dataset.key === 'resolutionLevel') {
          processedDataset = replaceTransInDoughnut(processedDataset, getResolutionLevelTitle, t);
        }
        if (dataset.key === 'status') {
          processedDataset = replaceTransInDoughnut(processedDataset, getStatusTitle, t);
        }

        return {
          name: options.name(t),
          dataset: processedDataset,
          backgroundColor: options.backgroundColor(dataset.dataset),
          ...pick(options, ['order']),
          ...pick(dataset, ['type', 'total', 'key']),
        };
      }
      case ChartTypes.HORIZONTAL_BAR_CHART: {
        const options = DASHBOARD_OPTIONS[dataset.key];
        const other = options.otherTitle(t);

        return {
          name: options.name(t),
          legendTitle: options.legendTitle(t),
          dataset: dataset.dataset.map((entity) => (entity.value === OTHER ? { ...entity, value: other } : entity)),
          ...pick(options, ['order']),
          ...pick(dataset, ['type', 'total', 'key']),
        };
      }
      case ChartTypes.LINEAR_PROGRESS_CHART: {
        const options = DASHBOARD_OPTIONS[dataset.key];
        const translatedKeysMapping = mapValues(options.keysMapping, (item) => item(t));

        return {
          name: options.name(t),
          legendTitle: options.legendTitle(t),
          dataset: dataset.dataset.map((entity) =>
            mapKeys(entity, (key) =>
              key in translatedKeysMapping ? translatedKeysMapping[key as 'Resolved' | 'Active'] : key,
            ),
          ),
          ...pick(options, ['order']),
          ...pick(dataset, ['type', 'total', 'key']),
        };
      }
      case ChartTypes.LINE_CHART: {
        const options = DASHBOARD_OPTIONS[dataset.key];

        return {
          name: options.name(t),
          datasetNames: options.datasetNames.map((item) => item(t)),
          ...pick(options, ['order', 'backgroundColor']),
          ...pick(dataset, ['type', 'total', 'key', 'dataset']),
        };
      }
    }
  });

  return sortBy(dashboards, (dashboard) => dashboard.order);
};

export default datasetToDashboard;
