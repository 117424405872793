import { type ComponentType, type PropsWithChildren, type SyntheticEvent } from 'react';

import { Box, Stack, Tabs, type TabsProps } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import { AppRoute } from '../../../types';
import { Clock } from '../../base';

export interface MenuProps {
  hideNavigation: boolean;
  CustomTabsComponent?: ComponentType<TabsProps>;
}

export const Menu = (props: PropsWithChildren<MenuProps>) => {
  const { hideNavigation, children, CustomTabsComponent } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeRoute = (_: SyntheticEvent<Element, Event>, value: AppRoute) => {
    navigate(value);
  };

  const tabsValue = location.pathname === '/' ? AppRoute.INCIDENTS : location.pathname;
  const RendererTabs = CustomTabsComponent ?? Tabs;

  return !hideNavigation ? (
    <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
      <RendererTabs
        TabIndicatorProps={{
          sx: {
            height: '5px',
          },
        }}
        value={tabsValue}
        onChange={onChangeRoute}
      >
        {children}
      </RendererTabs>
      <Box pb={1}>
        <Clock />
      </Box>
    </Stack>
  ) : null;
};
