import type { ChangeEvent } from 'react';

import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { incidentsFilterState, useAddFilterByFlagged, useClearFilterByFlagged } from '../../../../state/incidentsFilter';
import { CheckboxControl } from '../CheckboxControl';

export const FlaggedControl = () => {
  const { flagged } = useRecoilValue(incidentsFilterState);

  const { execute: addFilterByFlagged } = useAddFilterByFlagged();
  const { execute: clearFilterByFlagged } = useClearFilterByFlagged();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addFilterByFlagged();
    } else {
      clearFilterByFlagged();
    }
  };

  return (
    <CheckboxControl
      name='isFlagged'
      label={<Trans i18nKey='flagged' />}
      checked={Boolean(flagged)}
      onChange={onChangeHandler}
    />
  );
};
