import { useCallback, type FC } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { MenuButton } from '../../../../components/base';
import { DownloadExcelTemplate } from '../../../../components/ControlPanel/components/DownloadExcelTemplate';
import { ImportExcelButton } from '../../../../components/ControlPanel/components/ImportExcelButton';
import { FullScreenButton } from '../../../../components/FullScreen';
import useDrawer from '../../../../hooks/useDrawer';
import { Drawer } from '../../../../state/drawer';
import { permissionState } from '../../../../state/event';
import { useExportToPdfTableProjectTasks } from '../../../../state/export';
import { AddEntityControl, ButtonGridColumns, ControlPanelWrapper } from '../../../EntitiesGrid';
import { newEntityReady, projectTasksFiltersState } from '../../state/projectTasks';
import { useProjectTaskNewRow } from '../../state/projectTasks/hooks';
import { TaskFilters } from '../TaskFilters';

type Props = {
  onExportToExcel: () => void;
  onImportFromExcel: (file: File) => void;
  loadingImportFromExcel?: boolean;
};

export const ProjectTasksControlPanel: FC<Props> = ({ onExportToExcel, onImportFromExcel, loadingImportFromExcel }) => {
  const { t } = useTranslation();
  const { showDrawer } = useDrawer();
  const {
    loading: loadingNewRow,
    newEntity,
    prepareNewEntity,
    destroyNewEntity,
    addNewEntity,
  } = useProjectTaskNewRow();
  const { canCreateProjectTask } = useRecoilValue(permissionState);
  const { loading: loadingExportPdfTable, execute: exportToPdfTable } = useExportToPdfTableProjectTasks();

  const exportToPdfTableCallback = useCallback(() => exportToPdfTable(t), [exportToPdfTable, t]);
  const loading = loadingImportFromExcel || loadingExportPdfTable;

  return (
    <ControlPanelWrapper
      leftSlot={
        <AddEntityControl
          disabled={!canCreateProjectTask}
          loading={loadingNewRow}
          newEntity={newEntity}
          destroyNewEntity={destroyNewEntity}
          addNewEntity={addNewEntity}
          newEntityReady={newEntityReady}
          addNewButtons={[
            {
              text: t('add-new-project-task-inline'),
              action: prepareNewEntity,
            },
          ]}
        />
      }
      rightSlot={
        <>
          <TaskFilters
            filtersState={projectTasksFiltersState}
            onClickFilterButton={() => showDrawer({ type: Drawer.PROJECT_TASKS_FILTER })}
          />
          <ButtonGridColumns
            openGridColumnsSettings={() => showDrawer({ type: Drawer.PROJECT_TASKS_COLUMNS })}
            columnsText={t('columns')}
          />
          <FullScreenButton />
          <MenuButton
            buttonContent={<MenuIcon />}
            isLoading={loading}
            commands={[
              ...(canCreateProjectTask
                ? [
                    {
                      content: <ImportExcelButton onClick={onImportFromExcel} />,
                      disableAutoClose: true,
                    },
                  ]
                : []),
              ...(canCreateProjectTask
                ? [
                    {
                      content: <DownloadExcelTemplate link='/excelTemplates/projectTasksTemplate.xlsx' />,
                    },
                  ]
                : []),
              ...(onExportToExcel
                ? [
                    {
                      content: <Trans i18nKey='export-to-excel' />,
                      onClick: onExportToExcel,
                    },
                  ]
                : []),
              {
                content: <Trans i18nKey='export-table' />,
                onClick: exportToPdfTableCallback,
              },
            ]}
          />
        </>
      }
    />
  );
};
