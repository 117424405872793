import { useMemo } from 'react';

import type { ColDef, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import useDrawer from '../../hooks/useDrawer';
import useIncidents from '../../hooks/useIncidents';
import {
  EntitiesGridBox,
  useCurrentUser,
  useDefaultGridSettings,
  useGetLocaleText,
  useGridFlaggedSetter,
} from '../../modules/EntitiesGrid';
import { defaultCellStyles } from '../../modules/EntitiesGrid/lib';
import { useGridSetters } from '../../modules/Tasks/state/tasks';
import { Drawer } from '../../state/drawer';
import { permissionState } from '../../state/event';
import { useEditIncidentPart } from '../../state/incidents';
import { incidentsFilterState } from '../../state/incidentsFilter';
import { pageSizeState, pageState } from '../../state/incidentTable/pagination';
import type { Incident } from '../../types';
import { Status } from '../../types';
import Pagination from './components/Pagination';
import { useColumnState } from './hooks';
import useColumnDefs from './hooks/useColumnDefs';
import { useIncidentInlineAdding } from './hooks/useIncidentInlineAdding';
import './index.css';
import { cellСanBeEdited } from './utils/cellCanBeEdited';
import { scrollToColumnEdited } from './utils/scrollToColumnEdited';
import { useScrollToRowByParams } from './utils/useScrollToRowByParams';

const rowClassRules = {
  'row-draft': (params: RowClassParams<Incident>) => params.data?.status === Status.DRAFT,
};

const CONTAINER_STYLE = { height: 'calc(100% - 53px)' };

const IncidentTable = () => {
  const { data } = useIncidents();
  const { t } = useTranslation();
  const { currentUserId } = useCurrentUser();
  const { scrollToRowByParams } = useScrollToRowByParams();
  const { execute: editIncidentPart } = useEditIncidentPart();
  const { ref: gridRef, ...gridDefaultSettings } = useDefaultGridSettings();
  const { canUpdateResolvedIncident, canUpdateIncident, canUpdateAllIncidents, canUpdateResolvedIncidentComment } =
    useRecoilValue(permissionState);
  const { hideDateTime } = useRecoilValue(incidentsFilterState);
  const pagesSize = useRecoilValue(pageSizeState);
  const [, setPage] = useRecoilState(pageState);
  const { showDrawer } = useDrawer();

  const { editPreparedIncident } = useIncidentInlineAdding(gridRef);
  const flaggedSetter = useGridFlaggedSetter();
  const { defaultValueSetter, commentsSetter, modals, statusSetter } = useGridSetters({
    editTaskPart: editIncidentPart,
    editPreparedTask: editPreparedIncident,
  });

  const columnDefs = useColumnDefs({
    t,
    commentsSetter,
    flaggedSetter,
    statusSetter,
  });

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      hide: false,
      resizable: true,
      editable: cellСanBeEdited,
      minWidth: 60,
      enableCellChangeFlash: true,
      valueSetter: defaultValueSetter,
      comparator: () => 0,
      cellEditorParams: {
        permissions: {
          canUpdateResolvedIncident,
          canUpdateIncident,
          canUpdateAllIncidents,
          canUpdateResolvedIncidentComment,
        },
        currentUserId,
      },
      cellRendererParams: {
        hideDateTime,
      },
      cellStyle: defaultCellStyles,
      suppressMenu: true,
    }),
    [
      defaultValueSetter,
      canUpdateResolvedIncident,
      canUpdateIncident,
      canUpdateAllIncidents,
      canUpdateResolvedIncidentComment,
      currentUserId,
      hideDateTime,
    ],
  );

  const { onSortHandler, columnDefs: updatedColDefs, onDragStopped } = useColumnState({ columnDefs });
  const getLocaleText = useGetLocaleText(t);

  const onChangePage = async (page: number, taskId: number) => {
    setPage(page);
    await showDrawer({ type: Drawer.INCIDENT_EDIT, props: { incidentId: taskId } });
  };

  return (
    <EntitiesGridBox>
      <AgGridReact
        {...gridDefaultSettings}
        containerStyle={CONTAINER_STYLE}
        ref={gridRef}
        rowData={data}
        columnDefs={updatedColDefs}
        rowClassRules={rowClassRules}
        onDragStopped={onDragStopped}
        onSortChanged={onSortHandler}
        defaultColDef={defaultColDef}
        overlayNoRowsTemplate={t('no-results-found')}
        getLocaleText={getLocaleText}
        onRowDataUpdated={({ api }) => {
          api.refreshCells({ force: true });
        }}
        onFirstDataRendered={scrollToRowByParams(onChangePage)}
        pagination
        suppressPaginationPanel
        paginationPageSize={pagesSize}
        onCellEditingStarted={scrollToColumnEdited}
      />
      <Pagination gridRef={gridRef} />
      {modals.close}
      {modals.reopen}
    </EntitiesGridBox>
  );
};

export default IncidentTable;
