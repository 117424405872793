import type { ProjectTask } from '../types';

export type ProjectTaskState = ProjectTask & { orgHierarchy: string[] };

export function addHierarchyField(projectTasks: ProjectTask[], parentHierarchy: string[] = []): ProjectTaskState[] {
  return projectTasks.reduce<ProjectTaskState[]>((acc, projectTask) => {
    const currentTask = {
      ...projectTask,
      orgHierarchy: [...parentHierarchy, String(projectTask.id)],
    };

    acc.push(currentTask);

    if (currentTask.children) {
      acc.push(...addHierarchyField(currentTask.children as unknown as ProjectTask[], currentTask.orgHierarchy));
    }

    return acc;
  }, []);
}
