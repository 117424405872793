import type { FC} from 'react';
import { useMemo } from 'react';

import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { useTranslation } from 'react-i18next';

import { useNewIncidentRow } from '../IncidentTable/state/hooks';
import { PhotosEditorBase } from './PhotosEditorBase';

export type PhotosAddFormProps = {
  onEndAction: () => void;
};

export const PhotosAddForm: FC<PhotosAddFormProps> = ({ onEndAction }) => {
  const { newIncident, setNewIncident } = useNewIncidentRow();
  const initialState = useMemo(
    () => (newIncident?.photoSources ?? []).map(
      ({ source }) => ({
        source: source as unknown as string,
        options: {
          type: 'local',
        },
      } as FilePondInitialFile),
    ),
    [newIncident],
  );
  const { t } = useTranslation();
  const title = t('add-new-incident-photos');

  const updatePhotos = (data: FilePondFile[]) => {
    if (newIncident) {
      const photoSources = data.map((file) => ({
        id: file.id,
        source: file.source as File,
      }));

      setNewIncident((oldState) => {
        if (oldState) {
          return { ...oldState, photoSources };
        }

        return undefined;
      });
    }

    onEndAction();
  };

  return (
    <PhotosEditorBase
      initialState={initialState}
      title={title}
      onUpdate={updatePhotos}
      buttonText={t('add-photos')}
    />
  );
};
