import { Divider, FormControl, FormGroup, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useCurrentUserEvent from '../../hooks/useCurrentUserEvent';
import { NotificationType, useShowNotification } from '../../state/notification';
import type { User } from '../../types';
import { Button, DrawerContainer } from '../base';
import { CheckboxField } from './components/CheckboxField';
import { LocationsField } from './components/LocationsField';
import { ParticipantsField } from './components/ParticipantsField';
import { RegisteredByFields } from './components/RegisteredByFields';
import { ResolutionLevelField } from './components/ResolutionLevelField';
import { ResponsibleField } from './components/ResponsibleField';
import { UserFunctionsField } from './components/UserFunctionsField';
import type { UserEventSettingsInitialValues } from './lib/formTypes';
import { useUpdateUserEventSettings } from './model/updateUserEventSettings';

export type UserFormSettingsProps = {
  user: User;
  defaultValues: UserEventSettingsInitialValues;
  onEndAction?: () => void;
};

export const UserFormSettings = ({ defaultValues, user, onEndAction }: UserFormSettingsProps) => {
  const { t } = useTranslation();
  const { execute: updatePermissions } = useUpdateUserEventSettings();
  const { execute: showNotification } = useShowNotification();
  const userEvent = useCurrentUserEvent(user);

  const form = useForm<UserEventSettingsInitialValues>({
    defaultValues,
  });
  const { watch, resetField, handleSubmit, control, formState } = form;

  const onlyOwnIncidents = watch('filters.onlyOwnIncidents');

  const onSubmit = async (formValues: UserEventSettingsInitialValues) => {
    if (userEvent) {
      await updatePermissions({
        userEvent,
        user,
        data: formValues,
      });
      onEndAction?.();
      await showNotification({ type: NotificationType.SUCCESS, text: t('user-event-save-success') });
    }
  };

  const onClearFilters = () => {
    resetField('filters.locations', { defaultValue: [] });
    resetField('filters.resolutionLevel', { defaultValue: [] });
    resetField('filters.userFunctions', { defaultValue: [] });
    resetField('filters.responsible', { defaultValue: [] });
    resetField('filters.participants', { defaultValue: [] });
    resetField('filters.registeredBy', { defaultValue: [] });
    resetField('filters.onlyOwnIncidents', { defaultValue: false });
  };

  const onChangeOnlyOwnIncidents = (value: boolean) => {
    // If use setValu, then build CI drop down this memory leak
    if (value) {
      resetField('filters.responsible', { defaultValue: [user] });
      resetField('filters.registeredBy', { defaultValue: [user] });
    } else {
      resetField('filters.registeredBy', { defaultValue: [] });
    }
  };

  return (
    <DrawerContainer component='form' onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Typography variant='h4'>
              <Trans i18nKey='user-settings-filters-title' />
            </Typography>
            <Button variant='text' onClick={onClearFilters}>
              {t('clear-filters')}
            </Button>
          </Stack>

          <ResolutionLevelField form={form} />
          <CheckboxField
            control={control}
            name='filters.onlyOwnIncidents'
            label={t('only-own-incidents')}
            onChange={onChangeOnlyOwnIncidents}
          />
          <ResponsibleField form={form} disabled={onlyOwnIncidents} />
          <RegisteredByFields form={form} disabled={onlyOwnIncidents} />
          <UserFunctionsField form={form} />
          <ParticipantsField form={form} />
          <LocationsField form={form} />
        </Stack>
        <Divider sx={{ margin: '24px 0 16px' }} />
        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Typography variant='h4'>
              <Trans i18nKey='user-settings-permissions-title' />
            </Typography>
          </Stack>
          <FormControl>
            <FormGroup>
              <CheckboxField
                control={control}
                name='permissions.canViewDashboard'
                label={t('permission-view-dashboard')}
              />
              <CheckboxField control={control} name='permissions.canViewMaps' label={t('permission-view-maps')} />
            </FormGroup>
          </FormControl>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Button
          fullWidth
          loading={formState.isSubmitting}
          type='submit'
          sx={{ marginTop: 'auto', color: 'white' }}
          variant='contained'
        >
          <Trans i18nKey='update-user' />
        </Button>
      </Stack>
    </DrawerContainer>
  );
};
