import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { usersState } from '../../../../state/users';
import type { User } from '../../../../types';
import getFullName from '../../../../utils/getFullName';
import { TableSelect, UserAvatar } from '../../../base';

const Item = (user: User) => (
  <Stack component='span' direction='row' spacing={2}>
    <UserAvatar user={user} size='s' />
    <Typography whiteSpace='normal'>{getFullName(user)}</Typography>
  </Stack>
);

const ResponsibleEditor = forwardRef((props: ICellEditorParams, ref) => {
  const cellData: User | undefined = props.value;
  const users = useRecoilValue(usersState);
  const [responsible, setResponsible] = useState<User | null>();

  const onSelect = (user: User | null) => {
    setResponsible(user);
  };

  useEffect(() => {
    if (responsible !== undefined) {
      props.stopEditing();
    }
  }, [responsible, props]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return responsible !== undefined ? responsible : cellData;
    },
  }));

  return (
    <TableSelect data={users} value={cellData} onSelect={onSelect} itemRenderer={Item} getOptionLabel={getFullName} />
  );
});

export default ResponsibleEditor;
