export type PointerMarkerView = 'default' | 'primary';
export const pointerMarkerColorMap: Record<PointerMarkerView, string> = {
  default: '#EA4335',
  primary: '#AA96C8',
};

export const pointerMarkerConfigSvg = {
  path: 'M18 0C8.055 0 0 7.975 0 17.81c0 3.997 1.33 7.686 3.576 10.657L15.12 48.256c.067.127.134.254.217.371l.025.044.007-.004A3.243 3.243 0 0 0 18.01 50c.993 0 1.858-.447 2.454-1.136l.03.017.114-.196c.168-.225.318-.465.426-.73l11.311-19.387A17.61 17.61 0 0 0 36 17.81C36 7.975 27.945 0 18 0Zm-.178 26.84c-4.9 0-8.869-3.929-8.869-8.775s3.969-8.776 8.869-8.776 8.869 3.93 8.869 8.776-3.969 8.775-8.87 8.775Z',
  fillOpacity: 1,
  strokeWeight: 0.7,
};

export type PointerMarkerProps = {
  label?: string;
  view?: PointerMarkerView;
};

export const PointerMarker = ({ label, view = 'default' }: PointerMarkerProps) => (
  <div style={{ width: '1px', height: '1px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      <svg
        width='28'
        height='37'
        viewBox='0 0 36 50'
        preserveAspectRatio='xMidYMid meet'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d={pointerMarkerConfigSvg.path}
          fill={pointerMarkerColorMap[view]}
          fillOpacity={pointerMarkerConfigSvg.fillOpacity}
          strokeWidth={pointerMarkerConfigSvg.strokeWeight}
          stroke='#000'
        />
      </svg>

      {label && (
        <div style={{ position: 'absolute', top: '7px', fontWeight: 'bold', fontFamily: 'Roboto, Arial, sans-serif' }}>
          {label}
        </div>
      )}
    </div>
  </div>
);
