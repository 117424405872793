import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, type MarkerProps } from 'react-leaflet';

import { CircleMarker, type CircleMarkerProps } from '../../Markers';

interface IncidentMarkerProps extends CircleMarkerProps, MarkerProps {}

export const IncidentMarker = (props: IncidentMarkerProps) => {
  const { color, ...rest } = props;
  return (
    <Marker
      {...rest}
      icon={divIcon({
        html: renderToStaticMarkup(<CircleMarker color={color} />),
        iconSize: [0, 0],
      })}
    />
  );
};
