import type { FC } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { DrawerNavigationProps } from '../../../state/drawer';
import { useEditIncident, useViewedBy } from '../../../state/incidents';
import { loadedIncidentsUpdatesState } from '../../../state/incidentsUpdates/atoms';
import { useIncidentsUpdatesList } from '../../../state/incidentsUpdates/hooks';
import { NotificationType, useShowNotification } from '../../../state/notification';
import { userState } from '../../../state/user';
import type { EditIncident, Incident, User } from '../../../types';
import { EditIncidentForm } from './EditIncidentForm';

type Props = {
  incidentId: number;
  onEndAction: () => void;
} & DrawerNavigationProps;

export const EditIncidentUpdatesForm: FC<Props> = ({ incidentId, onEndAction, goBack, goForward, isChild }) => {
  const { t } = useTranslation();
  const [incidents, setIncidents] = useRecoilState(loadedIncidentsUpdatesState);
  const incident = incidents[incidentId];
  const currentUser = useRecoilValue(userState);
  const isViewedByUser = incident.viewedBy.some((user) => user.id === currentUser.id);
  const { execute: editIncidentTask } = useEditIncident();
  const { loading } = useIncidentsUpdatesList();
  const { execute: updateViewedBy } = useViewedBy();
  const { execute: showNotification } = useShowNotification();

  useEffect(() => {
    if (!isViewedByUser) {
      updateViewedBy({
        userId: currentUser.id,
        incidentId,
        setViewedBy: (viewedBy: User[]) => {
          setIncidents((prevIncidents) => ({
            ...prevIncidents,
            [incidentId]: {
              ...prevIncidents[incidentId],
              viewedBy,
            },
          }));
        },
      });
    }
  }, [currentUser.id, incidentId, isViewedByUser, updateViewedBy, setIncidents]);

  const editIncident = (data: EditIncident) =>
    editIncidentTask({
      incident: data,
      setNewIncident: (newIncident: Incident) => {
        setIncidents((prevIncidents) => ({
          ...prevIncidents,
          [incidentId]: newIncident,
        }));
      },
      successCallback: () => {
        showNotification({
          type: NotificationType.SUCCESS,
          text: t('incident-was-edited-successfully'),
        });
        onEndAction();
      },
    });
  const navigationProps = { goBack, goForward, isChild };

  return <EditIncidentForm incident={incident} editIncident={editIncident} loading={loading} {...navigationProps} />;
};
