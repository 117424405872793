import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useController, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';

import type { ResolutionLevel } from '../../types';
import { ResolutionLevelBlock } from '../base';
import { useFullScreen } from '../FullScreen';

export type StatusFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  label: string;
  data: ResolutionLevel[];
  required?: boolean;
  disabled?: boolean;
} & UseControllerProps<TFieldValues, TName>;

export const ResolutionLevelField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: StatusFieldProps<TFieldValues, TName>,
) => {
  const { field } = useController(props);
  const { ref } = useFullScreen();
  const { label, required, disabled, data, name } = props;
  const labelId = `${name}-control-label`;

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        {...field}
        label={label}
        labelId={labelId}
        sx={{ height: '56px' }}
        disabled={disabled}
        MenuProps={{ container: ref.current }}
      >
        {!required && (
          <MenuItem key='empty' value=''>
            <em>None</em>
          </MenuItem>
        )}
        {data.map((item) => (
          <MenuItem key={item} value={item}>
            <ResolutionLevelBlock level={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
