import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { incidentService } from '../../../../../api';
import { NotificationType, useShowNotification } from '../../../../../state/notification';
import { useIncidentMap } from './IncidentMapProvider';

export function useUpdateIncidents() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { updatedIncidents, setUpdatedIncidents } = useIncidentMap();
  const { execute: showNotification } = useShowNotification();

  const onUpdateIncidents = async () => {
    setLoading(true);
    try {
      await Promise.all(
        updatedIncidents.map((item) => {
          const formData = new FormData();
          const data = item.geo
            ? {
                geo: {
                  latitude: item.geo.latitude,
                  longitude: item.geo.longitude,
                },
              }
            : undefined;

          formData.append('data', JSON.stringify(data));

          return incidentService.editIncident(item.id, formData);
        }),
      );

      showNotification({ type: NotificationType.SUCCESS, text: t('update-incidents-success') });
      setUpdatedIncidents([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    onUpdateIncidents,
    loading,
    isDisabled: loading || updatedIncidents.length === 0,
  };
}
