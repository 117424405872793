import { useRef } from 'react';

import type { YMap } from '@yandex/ymaps3-types';

import { MapLoader } from '../../../shared/components/MapLoader';
import {
  Hint,
  LocationMarker,
  YandexMap,
  YandexMapAutocomplete,
  YandexMapProvider,
  useYandexMap,
} from '../../../shared/components/YandexMap';
import { useNormalizeYandexMapData } from '../lib/normalizeYandexMapData';
import { useLocationMap } from '../model/LocationMapDataProvider';
import { AutocompleteBox } from './AutocompleteBox';

const YandexMapLocationBase = () => {
  const ymapRef = useRef<YMap>(null);
  const data = useLocationMap();
  const { ymapsInstance } = useYandexMap();
  const {
    zoom,
    center,
    locations,
    onMarkerClick,
    onMapClick,
    onDraggableMarker,
    searchLocation,
    onChangeSearchLocation,
  } = useNormalizeYandexMapData(data, ymapRef);

  if (!ymapsInstance) {
    return <MapLoader />;
  }

  const { YMapListener, YMapDefaultSchemeLayer } = ymapsInstance.ymaps;

  return (
    <YandexMap ref={ymapRef} location={{ zoom, center }}>
      <YMapDefaultSchemeLayer />
      <YMapListener onClick={onMapClick} />
      <Hint ymapsInstance={ymapsInstance} />
      {searchLocation && <LocationMarker ymapsInstance={ymapsInstance} coordinates={searchLocation} />}
      {locations.map((marker) => (
        <LocationMarker
          view='primary'
          draggable
          key={marker.key}
          ymapsInstance={ymapsInstance}
          coordinates={[marker.geo.longitude, marker.geo.latitude]}
          onClick={onMarkerClick(marker)}
          properties={marker.properties}
          onDragEnd={onDraggableMarker(marker)}
        />
      ))}
      <AutocompleteBox>
        <YandexMapAutocomplete ymapsInstance={ymapsInstance} onChange={onChangeSearchLocation} />
      </AutocompleteBox>
    </YandexMap>
  );
};

export const YandexMapLocation = () => (
  <YandexMapProvider>
    <YandexMapLocationBase />
  </YandexMapProvider>
);
