/* eslint-disable no-use-before-define */
import type { Layouts } from 'react-grid-layout';

import type { IncidentTableColumns } from '../components/IncidentTable/state';
import type { DailyTaskGridColumns } from '../modules/Tasks/state/dailyTasks';
import type { ProjectTaskGridColumns } from '../modules/Tasks/state/projectTasks';
import type { TaskType } from '../modules/Tasks/state/tasks';

export enum AppRoute {
  INCIDENTS = '/incidents',
  CREW = '/crew',
  CHECK = '/check',
  DASHBOARD = '/dashboards',
  LOGIN = '/login',
  MAP = '/incidentMap',
  EVENTS = '/events',
  USERS = '/users',
  PROJECT_PLAN_USERS = '/projectPlan/users',
  PROJECT_PLAN_DASHBOARD = '/projectPlan/dashboards',
  PROJECT_PLAN_GANTTA = '/projectPlan/ganta',
  PROJECT_PLAN = '/projectPlan',
  DAILY_PLAN = '/dailyPlan',
  DAILY_PLAN_PARAMS = '/dailyPlan/:day',
  DAILY_PLAN_USERS = '/dailyPlan/users',
  DAILY_PLAN_EVENTS = '/dailyPlan/events',
}

export enum ResolutionLevel {
  GREEN = 'Green',
  AMBER = 'Amber',
  ORANGE = 'Orange',
  RED = 'Red',
}

export enum Status {
  REGISTERED = 'registered',
  IN_PROGRESS = 'inprogress',
  RESOLVED = 'resolved',
  DRAFT = 'draft',
}

export type AvailableStatus = Exclude<Status, Status.DRAFT>;

export enum Language {
  EN = 'en',
  RU = 'ru',
}

export type Meta = {
  pagination: { page: number; pageSize: number; pageCount: number; total: number };
};

export type WithMeta<T> = {
  data: T;
  meta: Meta;
};

export type UserFunction = {
  id: number;
  name: string;
  description?: string;
  isDefault: boolean;
  canRemove?: boolean;
};

export type UserIncident = Pick<Incident, 'id'>;

export enum RolesEnum {
  USER = 'user',
  OPERATOR = 'operator',
  ADMIN = 'admin',
  OWNER = 'owner',
  AUTHENTICATED = 'authenticated',
  OBSERVER = 'observer',
}

export type UserPermissions = {
  canCreateIncident: boolean;
  canCreateIncidentResolutionLevelGreen: boolean;
  canCreateIncidentResolutionLevelAmber: boolean;
  canCreateIncidentResolutionLevelOrange: boolean;
  canCreateIncidentResolutionLevelRed: boolean;
  canUpdateIncident: boolean;
  canUpdateResolvedIncident: boolean;
  canUpdateAllIncidents: boolean;
  canViewLogIncident: boolean;
  canViewAllIncidents: boolean;

  canCreateProjectTask: boolean;
  canRemoveProjectTask: boolean;
  canUpdateProjectTask: boolean;
  canUpdateResolvedProjectTask: boolean;
  canUpdateAllProjectTask: boolean;

  canCreateOperationalTask: boolean;
  canRemoveOperationalTask: boolean;
  canUpdateOperationalTask: boolean;
  canUpdateResolvedOperationalTask: boolean;
  canUpdateAllOperationalTask: boolean;

  canCreateComment: boolean;
  canUpdateComment: boolean;
  canUpdateResolvedIncidentComment: boolean;
  canViewComment: boolean;

  canCreateEvent: boolean;
  canUpdateEvent: boolean;
  canCloseEvent: boolean;
  canUpdateLocationEvent: boolean;
  canViewEvent: boolean;

  canViewUsers: boolean;

  canLocationFilters: boolean;
  canLevelFilters: boolean;
  canUserFunctionFilters: boolean;
  canResponsibleFilters: boolean;
  canParticipantsFilters: boolean;
  canRegisteredByFilters: boolean;

  canViewDashboard: boolean;

  canViewMaps: boolean;

  canViewArchivedProject: boolean;

  canRemoveUserEvent: boolean;
  canUpdateUserEvent: boolean;

  canDownloadExcel: boolean;

  canUpdateOwnerRole: boolean;
  canUpdateAdminRole: boolean;
  canUpdateObserverRole: boolean;
  canUpdateOperatorRole: boolean;
  canUpdateUserRole: boolean;
  canUpdateAuthenticatedRole: boolean;
};

export type UserDesktopFilters = {
  text?: string;
  isAssignedToMe?: boolean;
  isCreatedByMe?: boolean;
  resolutionLevel?: ResolutionLevel[];
  status?: Status[];
  registeredBy?: number[];
  responsible?: number[];
  locations?: number[];
  responsibleIsInformed?: boolean;
  incidentIsUrgent?: boolean;
  typeDate: 'registeredDate' | 'resolvedDate';
  startDate?: Date | null;
  endDate?: Date | null;
  userFunctions?: number[];
  flagged?: boolean;
  viewedUser?: boolean;
  participants?: number[];
  hideDateTime?: boolean;
};

export type CustomUserFilters = {
  locations?: number[];
  userFunctions?: number[];
  resolutionLevel?: ResolutionLevel[];
  responsible?: number[];
  participants?: number[];
  registeredBy?: number[];
  onlyOwnIncidents?: boolean;
};

type ColumnsGridSettings<T> = {
  columns: T;
};

export type IncidentSettings = ColumnsGridSettings<IncidentTableColumns>;

type BaseRole = {
  type: RolesEnum;
  name: string;
  id: number;
};

export type UserEvent = {
  id: number;
  event: Event;
  role: BaseRole;
  function: UserFunction;
  permissions: UserPermissions;
  hasIncidents: boolean;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  provider?: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: Date;
  updatedAt: Date;
  avatar?: string;
  incidentSettings: IncidentSettings | null;
  projectTasksSettings?: ColumnsGridSettings<ProjectTaskGridColumns> | null;
  dailyTasksSettings?: ColumnsGridSettings<DailyTaskGridColumns> | null;
  dashboardSettings: Layouts | null;
  dashboardProjectTaskSettings: Layouts | null;
  language?: 'en' | 'ru';
  currentEvent: UserEventAttrs;
  userEvents: UserEvent[];
  flaggedIncidents: UserIncident[];
  eventSettings: EventSettings[];
  isOwner: boolean;
  notificationSettings: {
    email: boolean;
    push: boolean;
  } | null;
};

export type ProjectTaskDefaultFilters = {
  text?: string;
  isAssignedToMe?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  responsible?: number[];
  userFunctions?: number[];
  status?: Status[];
  participants?: number[];
  typeDate: 'registeredDate' | 'resolvedDate';
  resolutionLevel?: ResolutionLevel[];
};

export type DailyTaskDefaultFilters = {
  text?: string;
  isAssignedToMe?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  responsible?: number[];
  userFunctions?: number[];
  status?: Status[];
  participants?: number[];
  typeDate: 'registeredDate' | 'resolvedDate';
  resolutionLevel?: ResolutionLevel[];
  locations?: number[];
};

export type EventSettings = {
  id: number;
  event: Event;
  defaultDesktopFilters: UserDesktopFilters | null;
  dailyTaskDefaultFilter: DailyTaskDefaultFilters | null;
  projectTaskDefaultFilters: ProjectTaskDefaultFilters | null;
  customUserFilters: CustomUserFilters | null;
};

export type UserUpdate = Partial<Omit<User, 'function' | 'currentEvent' | 'events' | 'role' | 'flaggedIncidents'>> & {
  function?: number;
  currentEvent?: number;
  events?: number[];
  role?: number;
  flaggedIncidents?: number[];
};

export type NewUser = {
  email: string;
  firstName: string;
  lastName: string;
  language: Language;
};

export type NewUserEvent = {
  event: number;
  user: number;
  role: RolesEnum;
  function: number;
};

export type Permission = {
  id: number;
  action: string;
  subject: string;
  properties: string;
  conditions: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Role = {
  id: number;
  name: string;
  code: string;
  description: string;
  permissions: Permission[];
  type: RolesEnum;
};

export type GeoCoordinates = {
  latitude: number;
  longitude: number;
};

export type Geo = {
  id: number;
} & GeoCoordinates;

export type Location = {
  id: number;
  title: string;
  geo: Geo;
  description: string;
};

export type PhotoFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
};

export type Photo = {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats?: {
    large?: PhotoFormat;
    small?: PhotoFormat;
    medium?: PhotoFormat;
    thumbnail?: PhotoFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type Comment = {
  id: number;
  body: string;
  author: User;
  createdDate: Date;
  image?: Photo;
  edited: boolean;
  editedUser: User | null;
  editedDate: Date;
  hidden: boolean;
  hiddenDate: Date;
  hiddenUser: User | null;
};

export type Incident = {
  id: number;
  count: number | string;
  title: string;
  type: TaskType;
  description?: string;
  responsible?: Omit<User, 'userEvents'>;
  resolutionLevel: ResolutionLevel;
  participants?: User[];
  location?: Location;
  locationDescription?: string;
  userFunction?: UserFunction;
  status: Status;
  photos?: Photo[];
  comments: Comment[];
  isUrgent?: boolean;
  isResponsibleInformed?: boolean;
  isHaveSeenByOperator?: boolean;
  registeredDate: Date;
  resolvedDate?: Date;
  startDatetime?: Date;
  endDatetime?: Date;
  registeredBy: User;
  viewedBy: User[];
  geo?: Geo;
  children?: Incident[];
  parent?: Incident;
  relation?: Incident;
  relations?: Incident[];
};

export type Brand = {
  logo?: Photo;
  enable: boolean;
};

export type Map = 'yandex' | 'google';

export type Event = {
  id: number;
  locations: Location[];
  userFunctions: UserFunction[];
  name: string;
  description: string;
  isArchived: boolean;
  limitUsers?: number;
  limitLocations?: number;
  brand?: Brand | null;
  map: Map;
  projectPlan: boolean | null;
  dailyPlan: boolean | null;
  incidentPlan: boolean;
  sitePlan?: PhotoFormat;
};

export type NewEvent = {
  name: string;
  userFunctions: string[];
  description: string;
  limitUsers?: number;
  limitLocations?: number;
  sitePlan?: (File | string)[];
  incidentPlan: boolean;
  projectPlan: boolean;
  dailyPlan: boolean;
};

export type UserEventAttrs = Pick<
  Event,
  'id' | 'name' | 'description' | 'isArchived' | 'brand' | 'map' | 'dailyPlan' | 'projectPlan'
>;

export type NewPhotoSource = {
  id: string;
  source: File;
};

export type EditPhotoSource = {
  id: string | null;
  source: File | string;
};

export type CommentBody = {
  text: string;
  image?: File;
};

export type CellCommentEditValue = {
  text: string;
  image?: File;
  commentId: number;
};

export type CellCommentRemoveValue = {
  commentId: number;
};

export type CellCommentValue =
  | {
      type: 'add';
      data: CommentBody;
    }
  | {
      type: 'edit';
      data: CellCommentEditValue;
    }
  | {
      type: 'remove';
      data: CellCommentRemoveValue;
    };

export type PreparedNewIncident = Omit<
  Incident,
  'id' | 'registeredDate' | 'registeredBy' | 'registeredDate' | 'viewedBy' | 'count' | 'relations'
> & {
  photoSources: NewPhotoSource[];
  registeredBy?: User;
};

export interface EditIncident extends Partial<Incident> {
  photoSources?: EditPhotoSource[];
}

export type GoogleMapGeo = {
  lat: number;
  lng: number;
};

export type NewComment = Pick<Comment, 'body'> & { incident: number; image?: File };

type ChartDatasetItem = {
  value: string;
  count: number;
};

export enum DashboardKey {
  LOCATION = 'location',
  RESOLUTION_LEVEL = 'resolutionLevel',
  STATUS = 'status',
  TOP_RESPONSIBLES = 'topResponsibles',
  USER_FUNCTIONS = 'userFunctions',
  REGISTERD_VS_RESOLVED = 'registeredVsResolved',
  ACTIVE_VS_RESOLVED = 'activeVsResolved',
}

export type DoughnutChartDataType = {
  type: ChartTypes.DOUGHNUT_CHART;
  key: DashboardKey.STATUS | DashboardKey.RESOLUTION_LEVEL;
  dataset: ChartDatasetItem[];
  total: number;
};

type HorizontalBarChartDataType = {
  type: ChartTypes.HORIZONTAL_BAR_CHART;
  key: DashboardKey.USER_FUNCTIONS | DashboardKey.TOP_RESPONSIBLES | DashboardKey.LOCATION;
  dataset: ChartDatasetItem[];
  total: number;
};

type LineChartDataType = {
  type: ChartTypes.LINE_CHART;
  key: DashboardKey.REGISTERD_VS_RESOLVED;
  dataset: {
    [key: string]: number[];
  };
  total: number;
};

type LinearProgressChartDataType = {
  type: ChartTypes.LINEAR_PROGRESS_CHART;
  key: DashboardKey.ACTIVE_VS_RESOLVED;
  dataset: {
    [key: string]: number;
  }[];
  total: number;
};

export type DashboardDataSet =
  | DoughnutChartDataType
  | HorizontalBarChartDataType
  | LineChartDataType
  | LinearProgressChartDataType;

export enum ChartTypes {
  DOUGHNUT_CHART = 'doughnut',
  HORIZONTAL_BAR_CHART = 'horizontalBarChart',
  BAR_CHART = 'barChart',
  LINE_CHART = 'lineChart',
  LINEAR_PROGRESS_CHART = 'linearProgress',
}

export type DoughnutChartType = DoughnutChartDataType & {
  name: string;
  backgroundColor?: string[];
};

export type HorizontalBarChartType = HorizontalBarChartDataType & {
  name: string;
  legendTitle: string;
};

export type LineChartType = LineChartDataType & {
  name: string;
  datasetNames: string[];
  backgroundColor: string[];
  minDate?: Date;
  maxDate?: Date;
};

export type LinearProgressChartType = LinearProgressChartDataType & {
  name: string;
  legendTitle: string;
};

export type DashboardType = DoughnutChartType | HorizontalBarChartType | LineChartType | LinearProgressChartType;

type Diff<T, K = T> = { old: T; new: K };

export type CreateActionDescription = {
  type: 'create';
  attrs: {
    title?: string;
    description?: string;
    responsible?: User;
    resolutionLevel?: ResolutionLevel;
    location?: number;
    locationDescription?: string;
    userFunction?: number;
    status: Status;
    photos?: Photo[];
    isUrgent?: boolean;
    isResponsibleInformed?: boolean;
    registeredDate?: Date;
    resolvedDate?: Date;
    registeredBy: User;
    endDatetime?: Date;
    participants?: User[];
  };
};
export type UpdateActionDescription = {
  type: 'update';
  attrs: {
    title?: Diff<string>;
    description?: Diff<string>;
    responsible?: Diff<User>;
    resolutionLevel?: Diff<ResolutionLevel>;
    location?: Diff<number>;
    locationDescription?: Diff<string>;
    userFunction?: Diff<number>;
    status: Diff<Status>;
    photos?: Diff<Photo[]>;
    isUrgent?: Diff<boolean>;
    isResponsibleInformed?: Diff<boolean>;
    registeredDate?: Diff<Date>;
    resolvedDate?: Diff<Date>;
    registeredBy: Diff<User>;
    endDatetime?: Diff<Date>;
    participants?: Diff<User[]>;
  };
};

export type CommentActionDescription = {
  type: 'addComment';
  attrs: {
    comment: Comment;
  };
};

export type CommentActionRemoveDescription = {
  type: 'deleteComment';
  attrs: {
    date: Date;
    comment: Comment;
  };
};

export type CommentActionEditDescription = {
  type: 'editComment';
  attrs: {
    date: Date;
    new: Comment;
    old: Comment;
  };
};

type ActionDescription =
  | CommentActionDescription
  | UpdateActionDescription
  | CreateActionDescription
  | CommentActionRemoveDescription
  | CommentActionEditDescription;

export type IncidentLog = {
  id: number;
  user: User;
  incident: Incident;
  actionDescription: ActionDescription;
  createdAt: Date;
};
