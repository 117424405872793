import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { dashboardsIncidentState } from './atom';

const resetDashboardsTask =
  ({ reset }: RecoilTaskInterface) =>
  () => {
    reset(dashboardsIncidentState);
  };

export const useResetDashboards = () => useRecoilTask(resetDashboardsTask, []);
