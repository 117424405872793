import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { eventService } from '../../../../api';
import { projectTasksFiltersState } from '../../../../modules/Tasks/state/projectTasks';
import { DashboardKey, type DashboardDataSet } from '../../../../shared/lib/types/dashborad.types';
import { userState } from '../../../../state/user';
import { getQueryAll } from '../lib/getQueryAll';
import { dashboardsPrjectPlanState } from './atom';

const getDashboardsTask = (rti: RecoilTaskInterface) => async () => {
  const { set, snapshot } = rti;
  const currentUser = snapshot.getLoadable(userState).getValue();

  if (currentUser) {
    const { currentEvent, dashboardProjectTaskSettings } = currentUser;

    set(dashboardsPrjectPlanState, (prevState) => ({
      ...prevState,
      ...(dashboardProjectTaskSettings ? { layoutsSetting: dashboardProjectTaskSettings } : {}),
    }));

    if (currentUser.currentEvent) {
      const filter = snapshot.getLoadable(projectTasksFiltersState).getValue();
      const query = getQueryAll({ filter, eventId: currentEvent.id });

      const responseData: DashboardDataSet[] = await eventService.getDashboards({ eventId: currentEvent.id, query });

      set(dashboardsPrjectPlanState, (prevState) => ({
        ...prevState,
        ...(responseData ? { dashboards: responseData.filter((item) => item.key !== DashboardKey.LOCATION) } : {}),
      }));
    }
  }
};

export const useDashboards = () =>
  useRecoilTask(getDashboardsTask, [], {
    dataSelector: dashboardsPrjectPlanState,
    errorStack: true,
  });
