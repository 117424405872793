import type { SyntheticEvent } from 'react';

import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteValue,
  TextFieldProps,
} from '@mui/material';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useFullScreen } from '../FullScreen';

type AutocompleteControllerProps<
  T,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = false,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'isOptionEqualToValue'> & {
  onChange?: (
    event: SyntheticEvent,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>,
  ) => void;
} & {
  name: string;
  label: string;
  placeholder: string;
};

export const AutocompleteController = <T extends { id: number | string }>({
  name,
  label,
  placeholder,
  ...props
}: AutocompleteControllerProps<T>) => {
  const { control } = useFormContext();
  const { ref } = useFullScreen();

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...props}
            {...field}
            value={field.value ?? null}
            onChange={(_, value) => field.onChange(value)}
            renderInput={(params: TextFieldProps) => <TextField {...params} label={label} placeholder={placeholder} />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            componentsProps={{
              popper: {
                container: ref.current,
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};
