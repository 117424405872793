import { Button, Stack } from '@mui/material';
import { Trans } from 'react-i18next';

import { Drawer, useShowDrawer } from '../../state/drawer';
import { AdvancedMenuIncident } from '../AdvancedMenuIncident/AdvancedMenuIncident';
import { Icon } from '../base';
import { FullScreenButton } from '../FullScreen';
import { AddIncidentControl } from './components/AddIncidentControl';
import { FilterButton } from './components/FilterButton';
import { FlaggedControl } from './components/FlaggedControl';
import { SearchInput } from './components/SearchInput';
import { UnreadControl } from './components/UnreadControl';
import { WrapperBox } from './components/WrapperBox';

const ControlPanel = () => {
  const { execute: showDrawer } = useShowDrawer();

  return (
    <WrapperBox>
      <AddIncidentControl />

      <Stack direction='row' spacing='8px' sx={{ flexWrap: 'wrap' }}>
        <UnreadControl />
        <FlaggedControl />
        <SearchInput />

        <FilterButton onClick={() => showDrawer({ type: Drawer.INCIDENT_FILTER })} />

        <Button
          variant='outlined'
          color='inherit'
          sx={{ borderColor: '#C7D1D4', height: '40px', fontWeight: 400 }}
          onClick={() => showDrawer({ type: Drawer.INCIDENT_TABLE_COLUMNS })}
        >
          <Trans i18nKey='columns' />
          <Icon icon='showColumn' htmlColor='#C7D1D4' sx={{ width: '17px', height: '17px', marginLeft: '10px' }} />
        </Button>

        <FullScreenButton />
        <AdvancedMenuIncident />
      </Stack>
    </WrapperBox>
  );
};

export default ControlPanel;
