import { atom } from 'recoil';

import type { ProjectTaskState } from '../../../../state/projectTasks/lib/addHierarchyField';

interface ChildState extends Partial<Omit<ProjectTaskState, 'id'>> {
  id: number | string;
}

export const newChildTaskState = atom<Partial<ChildState> | null>({
  key: 'projectTaskChildState',
  default: null,
});
