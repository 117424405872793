import { UserAvatar as UserAvatarBase } from '../../../../components/base';
import type { User } from '../../../../types';

interface UserAvatarProps {
  user: Pick<User, 'avatar' | 'firstName' | 'lastName'>;
}

export const UserAvatar = (props: UserAvatarProps) => {
  const { user } = props;

  return (
    <UserAvatarBase
      sx={{
        top: '-4px',
        marginLeft: '-10px',
      }}
      user={user}
      size='s'
    />
  );
};
