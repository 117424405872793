import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, InputBase, Paper, Stack } from '@mui/material';
import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import {
  FILEPOND_FILE_TYPES_DOCUMENT,
  FILEPOND_FILE_TYPES_IMAGE,
  FILEPOND_FILE_TYPES_VIDEO,
  FILEPOND_MAX_SIZE,
} from '../../constants/base';
import type { CommentBody } from '../../types';
import { ImageEditor } from '../ImageEditor/ImageEditor';
import { useImageEditor } from '../ImageEditor/useImageEditor';
import { FilePond } from './FilePond';

const genLabel = () => renderToString(<AddPhotoAlternateIcon sx={{ width: '50px', height: '50px' }} />);

type PhotosState = (FilePondFile | FilePondInitialFile)[];

type Props = {
  data?: CommentBody;
  onSend: (body: CommentBody) => void;
  disabled?: boolean;
  stylePanelAspectRatio?: string;
};

const CommentInput: FC<Props> = ({ onSend, disabled = false, data, stylePanelAspectRatio }) => {
  const [commentText, setCommentText] = useState(data?.text || '');
  const { t } = useTranslation();
  const [currentFiles, setCurrentFiles] = useState<PhotosState>([]);
  const { imageEditor, imageToEdit } = useImageEditor({ photos: currentFiles, onConfirm: setCurrentFiles });
  const commentImage = currentFiles[0]?.source as File | undefined;

  const onChangeTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };

  const onSendHandler = () => {
    if (!commentText && !commentImage && !data?.image) {
      return;
    }

    onSend({ text: commentText, image: commentImage });
    setCommentText('');
    setCurrentFiles([]);
  };

  useEffect(() => {
    if (data) {
      setCommentText(data.text);
    }
  }, [data]);

  const onChangeHhandleUpdateFilesandler = (files: FilePondFile[]) => {
    setCurrentFiles(files);
  };

  return (
    <Paper
      sx={{
        padding: '8px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: '1px solid #C7D1D4',
      }}
    >
      <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>
        <Box
          sx={{
            '.filepond--drop-label.filepond--drop-label label': {
              width: '40px',
              height: '65px',
            },
            borderRadius: '8px',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <FilePond
            disabled={disabled}
            stylePanelLayout='compact'
            stylePanelAspectRatio={stylePanelAspectRatio}
            files={currentFiles as FilePondInitialFile[]}
            acceptedFileTypes={[
              ...FILEPOND_FILE_TYPES_IMAGE,
              ...FILEPOND_FILE_TYPES_DOCUMENT,
              ...FILEPOND_FILE_TYPES_VIDEO,
            ]}
            maxFileSize={FILEPOND_MAX_SIZE}
            imagePreviewHeight={80}
            name='file'
            labelIdle={genLabel()}
            onupdatefiles={onChangeHhandleUpdateFilesandler}
            imageEditEditor={imageEditor}
          />
          {imageToEdit && (
            <ImageEditor file={imageToEdit} onClose={imageEditor.onclose} onSave={imageEditor.onconfirm} />
          )}
        </Box>

        <Box display='flex' alignContent='center' width='100%'>
          <Box flexGrow={1} display='flex' alignContent='center'>
            <InputBase
              disabled={disabled}
              sx={{ ml: 1, flex: 1 }}
              value={commentText}
              maxRows={3}
              multiline
              onChange={onChangeTextHandler}
              placeholder={t('type-a-message')}
            />
          </Box>

          <IconButton disabled={disabled} color='primary' sx={{ p: '10px' }} onClick={onSendHandler}>
            <SendIcon />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  );
};
export default CommentInput;
