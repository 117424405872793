import type { RecoilTaskInterface } from 'recoil-toolkit';

import { incidentService } from '../../../api';
import { eventState } from '../../event/atoms';
import { userState } from '../../user';
import type { AddIncidentTaskParams } from '../types';
import { getIncidentsTaskWithFilters } from './get';

export const addIncidentTask = (recoilTaskEvent: RecoilTaskInterface) => async (params: AddIncidentTaskParams) => {
  const { data: incident, successCallback } = params;
  const { snapshot } = recoilTaskEvent;

  const user = snapshot.getLoadable(userState).getValue();
  const event = snapshot.getLoadable(eventState).getValue();

  const { photoSources, relation, ...incidentProps } = incident;

  const newIncident = {
    ...incidentProps,
    ...(incidentProps.responsible ? { responsible: incidentProps.responsible.id } : {}),
    registeredDate: new Date(),
    event: event.id,
    registeredBy: user.id,
    viewedBy: [user.id],
    relations: relation ? [relation?.id] : undefined,
  };

  const formData = new FormData();

  photoSources.forEach(({ source }) => {
    formData.append('files.photos', source, source.name);
  });

  formData.append('data', JSON.stringify(newIncident));

  await incidentService.addIncident(formData);

  await getIncidentsTaskWithFilters(recoilTaskEvent)();

  successCallback?.();
};
