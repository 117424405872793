import { endOfDay, startOfDay } from 'date-fns';

import { TaskType } from '../../../../modules/Tasks/state/tasks';
import { Status, type ProjectTaskDefaultFilters } from '../../../../types';

export const getFilters = (
  {
    responsible,
    resolutionLevel,
    userFunctions,
    status,
    text,
    participants,
    typeDate,
    endDate,
    startDate,
  }: ProjectTaskDefaultFilters,
  eventId: number,
): Record<string, unknown> => ({
  [typeDate]: {
    $gte: startDate ? startOfDay(startDate).toISOString() : undefined,
    $lte: endDate ? endOfDay(endDate).toISOString() : undefined,
  },
  userFunction: userFunctions,
  responsible: {
    id: responsible,
  },
  event: {
    id: [eventId],
  },
  resolutionLevel,
  status: {
    $notIn: [Status.DRAFT],
    $in: status,
  },
  participants: {
    id: participants,
  },
  containsText: text,
  type: TaskType.ProjectTask,
});
