import type { FC } from 'react';

import { Avatar } from '@mui/material';
import type { SystemCssProperties } from '@mui/system';

import type { User } from '../../types';
import getFullName from '../../utils/getFullName';
import stringAvatar from '../../utils/stringToAvatar';
import { Tooltip } from './Tooltip';

type AvatarSize = 's' | 'm' | 'l';

export type UserAvatarProps = {
  user: Pick<User, 'avatar' | 'firstName' | 'lastName'>;
  size?: AvatarSize;
  className?: string;
  sx?: SystemCssProperties;
};

const AVATAR_SIZES: Record<AvatarSize, SystemCssProperties> = {
  s: { width: 24, height: 24, fontSize: '12px' },
  m: { width: 32, height: 32, fontSize: '16px' },
  l: { width: 48, height: 48, fontSize: '24px' },
};

const UserAvatar: FC<UserAvatarProps> = ({ className, user, size = 'm', sx }) => {
  const fullName = getFullName(user);
  const stringAvatarProps = stringAvatar(fullName);

  return (
    <Tooltip placement='bottom' disableInteractive title={fullName}>
      <Avatar
        className={className}
        sx={{
          ...sx,
          ...stringAvatarProps.sx,
          ...AVATAR_SIZES[size],
        }}
        alt={fullName}
        src={user.avatar}
      >
        {stringAvatarProps.children}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
