import { compareAsc, parse } from 'date-fns';
import { groupBy, toPairs } from 'remeda';

import formatDay from '../../../../utils/formatDay';
import { hasUserFunction, hasTitle, hasStartDatetime } from '../tasks';
import type { DailyTask, PreparedNewDailyTask } from './types';

const DATE_FORMAT = 'dd-LL-yyyy';

export const parseDay = (date: string) => parse(date, DATE_FORMAT, new Date());

export function groupTasksByDay(tasks: DailyTask[]) {
  const tasksByDays = groupBy(tasks, (task) => formatDay(task.day));
  const pairedTasksGroup = toPairs(tasksByDays).map(([day, dailyTasks]) => ({ day, tasks: dailyTasks }));

  const sortedTasksByDays = pairedTasksGroup.sort((a, b) => compareAsc(parseDay(a.day), parseDay(b.day)));

  return sortedTasksByDays;
}

export const newEntityReady = (task: PreparedNewDailyTask) => {
  const baseCondition = hasTitle(task) && hasStartDatetime(task) && Boolean(task.day);

  if (task.responsible) {
    return baseCondition;
  }

  return baseCondition && hasUserFunction(task);
};
