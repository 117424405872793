import isNotNil from './isNotNil';

function updateIfExists<
  T extends Record<string, unknown>,
  K extends keyof T,
  R
>(prop: K, updater: (value: NonNullable<T[K]>) => R) {
  return (obj: T) => {
    if (prop in obj) {
      const value = obj[prop];
      if (isNotNil(value)) {
        return {
          ...obj,
          [prop]: updater(value),
        };
      }
    }
    return obj;
  };
}

export default updateIfExists;
