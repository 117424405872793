import { Box } from '@mui/system';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import type { DoughnutChartType } from '../../types';
import stringToColor from '../../utils/stringToColor';
import { getPercentage } from '../lib/getPercentage';
import type { KeyNameType } from '../lib/types/dashborad.types';
import { DoughnutLegend } from './DoughnutLegend';

export const DoughnutChart = ({ dataset, backgroundColor, name, total, keyName }: DoughnutChartType & KeyNameType) => {
  const { t } = useTranslation();
  const backgroundColorList = backgroundColor || dataset.map(({ value }) => stringToColor(`${value}`));
  const doughnutData = {
    datasets: [
      {
        label: t('my-first-dataset'),
        data: dataset.map(({ count }) => count),
        backgroundColor: backgroundColorList,
      },
    ],
  };
  const rows = dataset
    .map(({ count, value }, index) => ({
      name: value,
      value: count,
      color: backgroundColorList[index],
      percent: getPercentage(count, total),
    }))
    .filter(({ value }) => value > 0);

  return (
    <Box id={keyName}>
      <Doughnut data={doughnutData} />
      <DoughnutLegend name={name} rows={rows} total={total} totalPercent={100} />
    </Box>
  );
};
