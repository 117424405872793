import type { FC } from 'react';
import { useState } from 'react';

import { FormControl } from '@mui/material';
import type { FilePondFile, FilePondInitialFile } from 'filepond';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FILEPOND_FILE_TYPES_IMAGE,
  FILEPOND_IMAGE_PREVIEW_HEIGHT,
  FILEPOND_MAX_FILES,
  FILEPOND_MAX_SIZE,
} from '../../../constants/base';
import { API_URL } from '../../../constants/env';
import type { Photo } from '../../../types';
import { FilePond } from '../../base/FilePond';
import { ImageEditor } from '../../ImageEditor/ImageEditor';
import { useImageEditor } from '../../ImageEditor/useImageEditor';

type PhotosState = (FilePondInitialFile | FilePondFile)[];

type Props = {
  photos?: Photo[];
  disabled?: boolean;
};

const PhotosController: FC<Props> = ({ photos, disabled = false }) => {
  const [currentPhotos, setCurrentPhotos] = useState<PhotosState>(() =>
    (photos ?? []).map((photo) => ({
      source: `${photo.url}?id=${photo.id}`,
      options: {
        type: 'local',
      },
    })),
  );
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const { imageEditor, imageToEdit } = useImageEditor({ photos: currentPhotos, onConfirm: setCurrentPhotos });

  const onUpdatePhotosHandler = (files: FilePondFile[]) => {
    setCurrentPhotos(files);
    setValue(
      'photoSources',
      files.map((file) => {
        const id = typeof file.source === 'string' ? new URL(file.source, API_URL).searchParams.get('id') : file.id;

        return { id, source: file.source };
      }),
    );
  };

  return (
    <FormControl
      fullWidth
      sx={{
        '.filepond--item': {
          width: 'calc(50% - 0.5em)',
        },
      }}
    >
      <FilePond
        disabled={disabled}
        files={currentPhotos as FilePondInitialFile[]}
        allowReorder
        allowMultiple
        acceptedFileTypes={FILEPOND_FILE_TYPES_IMAGE}
        maxFileSize={FILEPOND_MAX_SIZE}
        imagePreviewHeight={FILEPOND_IMAGE_PREVIEW_HEIGHT}
        onreorderfiles={onUpdatePhotosHandler}
        onupdatefiles={onUpdatePhotosHandler}
        maxFiles={FILEPOND_MAX_FILES}
        server={{
          load: (src, load) => {
            fetch(src)
              .then((res) => res.blob())
              .then(load);
          },
        }}
        name='files'
        labelIdle={t('drap-n-drop-photos-or-browseUnescaped', {
          wrapperStart: '<span class="filepond--label-action">',
          wrapperEnd: '</span>',
        })}
        imageEditEditor={imageEditor}
      />
      {imageToEdit && <ImageEditor file={imageToEdit} onClose={imageEditor.onclose} onSave={imageEditor.onconfirm} />}
    </FormControl>
  );
};

export default PhotosController;
