import type { RecoilTaskInterface } from 'recoil-toolkit';

import { currentEventIdState, eventState } from '../../../../state/event';
import { userState } from '../../../../state/user';
import indexById from '../../../../utils/indexedById';
import { setColumnsToUserPropertyTask, updateColumnsFromUserPropertyTask } from '../../../EntitiesGrid';
import { taskService } from '../../api';
import { prepareEditTask, tasksState, type Task, TaskType, type PreparedNewTask, convertRelationsToId } from '../tasks';
import { projectTasksColumnsState, projectTasksFiltersState } from './atoms';
import { transformFiltersForBackend } from './lib/transformFiltersForBackend';

const USER_SETTINGS_ATTR = 'projectTasksSettings' as const;

export const setColumnsTask = setColumnsToUserPropertyTask(USER_SETTINGS_ATTR, projectTasksColumnsState);

export const updateColumnsFromUserTask = updateColumnsFromUserPropertyTask(
  USER_SETTINGS_ATTR,
  projectTasksColumnsState,
);

export const getProjectTasks =
  ({ snapshot, set }: RecoilTaskInterface) =>
  async () => {
    const eventId = snapshot.getLoadable(currentEventIdState).getValue();
    const filters = snapshot.getLoadable(projectTasksFiltersState).getValue();

    if (eventId) {
      const dataOfTasks = await taskService.getTasks({ event: eventId, filters: transformFiltersForBackend(filters) });
      set(tasksState, indexById(dataOfTasks.data));
    }
  };

export const removeRowTask =
  (rti: RecoilTaskInterface) =>
  async ({ id }: { id: number }) => {
    await taskService.removeTask(id);
    getProjectTasks(rti)();
  };

export const editPartProjectTask = (rti: RecoilTaskInterface) => async (id: number, data: Partial<Task>) => {
  const responseData = await taskService.editTask(id, prepareEditTask(data));
  const newTask = responseData.data;

  rti.set(tasksState, (tasks) => ({
    ...tasks,
    [newTask.id]: newTask,
  }));

  getProjectTasks(rti)();
};

export const importProjectTasksFromExcel = (recoilTask: RecoilTaskInterface) => async (file: File) => {
  const { snapshot } = recoilTask;
  const eventId = await snapshot.getLoadable(currentEventIdState).getValue();

  const data = {
    event: eventId,
    type: TaskType.ProjectTask,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', JSON.stringify(data));

  await taskService.importTasks(formData);
  await getProjectTasks(recoilTask)();
};

export const addProjectTask = (rti: RecoilTaskInterface) => async (params: { data: PreparedNewTask<Task> }) => {
  const { data: task } = params;
  const { snapshot, set } = rti;
  const user = snapshot.getLoadable(userState).getValue();
  const event = snapshot.getLoadable(eventState).getValue();
  const newTask = {
    ...convertRelationsToId(task),
    registeredDate: new Date(),
    event: event.id,
    registeredBy: user.id,
    viewedBy: [user.id],
    comments: [],
  };
  const result = await taskService.addTask(newTask);
  const addedTask = result.data;
  set(tasksState, (tasks) => ({
    ...tasks,
    [addedTask.id]: addedTask,
  }));
  getProjectTasks(rti)();
};
