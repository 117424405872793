/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from 'react';

import { Grid } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { useTooltip, Tooltip } from '../../../../modules/EntitiesGrid';
import { eventState } from '../../../../state/event';
import type { Incident } from '../../../../types';
import { ClampedTypography } from '../../../base';
import { CopyGMapLink } from '../../../CopyGMapLink';

const LocationRenderer = ({ data }: ICellRendererParams<Incident>) => {
  const geo = data?.geo || data?.location?.geo;
  const { ref, tooltipProps } = useTooltip();
  const event = useRecoilValue(eventState);
  return (
    <Tooltip title={data?.location?.title || ''} {...tooltipProps}>
      <Grid container columnSpacing={1} alignItems='center'>
        {geo && !event.sitePlan?.url && (
          <Grid item>
            <CopyGMapLink geo={geo} size='medium' />
          </Grid>
        )}
        {data?.location && (
          <Grid item>
            <ClampedTypography ref={ref} variant='body2' lineclamp={3}>
              {data.location.title}
            </ClampedTypography>
          </Grid>
        )}
      </Grid>
    </Tooltip>
  );
};

export default memo(LocationRenderer);
