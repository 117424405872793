import { useRecoilTask } from 'recoil-toolkit';

import { addCommentTask, removeCommentTask, updateCommentTask } from './tasks';

export const useAddComment = () => useRecoilTask(addCommentTask, [], {
  loaderStack: 'addCommentTask',
  errorStack: true,
});

export const useRemoveComment = () => useRecoilTask(removeCommentTask, [], {
  loaderStack: 'removeCommentTask',
  errorStack: true,
});

export const useUpdateComment = () => useRecoilTask(updateCommentTask, [], {
  loaderStack: 'updateCommentTask',
  errorStack: true,
});
