import type { WithMeta, UserFunction } from '../types';
import requestService from './requestService';

const userFunctionService = {
  getUserFunctions: ({ query }: { query: string }) =>
    requestService.get<WithMeta<UserFunction[]>>(`/user-functions?${query}`).then((response) => response.data),
  addUserFunction: ({ userFunction }: { userFunction: Partial<UserFunction> & { events: number[] } }) =>
    requestService.post('/user-functions', { data: userFunction }).then((response) => response.data),
  unsubscribe: ({ userFunctionsIds, event }: { userFunctionsIds: number[]; event: number }) =>
    requestService.post('/user-functions/unsubscribe', {
      data: {
        event,
        ids: userFunctionsIds,
      },
    }),
};

export default userFunctionService;
