import type { ReactElement } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useFullScreen } from '../../FullScreen';

type Props<T> = {
  name: 'status' | 'resolutionLevel';
  label: string;
  data: T[];
  itemRenderer: (value: T) => string | ReactElement;
  required?: boolean;
  disabled?: boolean;
};

const SelectController = <T extends string | number>({
  data,
  itemRenderer,
  name,
  label,
  required = false,
  disabled = false,
}: Props<T>) => {
  const { control } = useFormContext();
  const { ref: refFullScreen } = useFullScreen();

  const labelId = `${name}-control-label`;

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ref, onBlur, onChange } }) => (
          <Select
            label={label}
            labelId={labelId}
            ref={ref}
            name={name}
            onBlur={onBlur}
            value={value ?? ''}
            onChange={onChange}
            sx={{ height: '56px' }}
            disabled={disabled}
            MenuProps={{
              container: refFullScreen.current,
            }}
          >
            {!required && (
              <MenuItem key='empty' value=''>
                <em>None</em>
              </MenuItem>
            )}
            {data.map((item) => (
              <MenuItem key={item} value={item}>
                {itemRenderer(item)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default SelectController;
