import { TaskType } from '../../modules/Tasks/state/tasks';
import type { PreparedNewIncident } from '../../types';
import { ResolutionLevel, Status } from '../../types';

export const DEFAULT_PREPARED_INCIDENT: PreparedNewIncident = {
  title: '',
  description: '',
  comments: [],
  photos: [],
  photoSources: [],
  status: Status.REGISTERED,
  resolutionLevel: ResolutionLevel.GREEN,
  isHaveSeenByOperator: false,
  isResponsibleInformed: false,
  isUrgent: false,
  type: TaskType.Incident,
};
