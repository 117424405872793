import { Stack, Typography } from '@mui/material';

import { EditableIncidentsList } from '../../../../../components/EditableIncidentsList';
import { EditIncidentTableForm } from '../../../../../components/IncidentForm';
import type { DrawerNavigationProps } from '../../../../../state/drawer';
import type { MapForm } from '../lib/types';
import { useMapFormIncident } from '../model/useMapFormIncident';

export type MapFormProps = {
  mapForm: MapForm;
} & DrawerNavigationProps;

export const MapFormIncident = ({ onEndAction, mapForm, goBack, goForward, isChild }: MapFormProps) => {
  const form = useMapFormIncident(mapForm);
  const navigationProps = { goBack, goForward, isChild };

  if (!form) {
    return null;
  }

  if (form.type === 'cluster') {
    return (
      <EditableIncidentsList
        header={<Typography variant='h4'>{form.data.incidenstT}</Typography>}
        incidents={form.data.incidentsInCluster}
        {...navigationProps}
      />
    );
  }

  if (form.type === 'incident') {
    return <EditIncidentTableForm incidentId={form.data.id} onEndAction={onEndAction} {...navigationProps} />;
  }

  if (form.type === 'location') {
    return (
      <EditableIncidentsList
        header={
          <Stack spacing={2}>
            <Typography variant='h4'>{form.data.location.title}</Typography>
            <Typography variant='body1'>{form.data.location.description}</Typography>
            {form.data.incidentsInLocation.length ? (
              <Typography variant='h4'>{form.data.incidenstT}: </Typography>
            ) : null}
          </Stack>
        }
        incidents={form.data.incidentsInLocation}
        {...navigationProps}
      />
    );
  }

  return null;
};
