import { Grid } from '@mui/material';

import type { User } from '../../../../types';
import getFullName from '../../../../utils/getFullName';
import {
  UserAvatar, TruncatedTypography,
} from '../../../base';

const UserRenderer = (value: User) => (
  <Grid container wrap='nowrap' sx={{ alignItems: 'center' }}>
    <Grid item>
      <UserAvatar sx={{ marginRight: '5px' }} size='s' user={value} />
    </Grid>

    <TruncatedTypography variant='body2' sx={{ color: '#808080' }}>
      {getFullName(value)}
    </TruncatedTypography>
  </Grid>
);

export default UserRenderer;
