import { CRS } from 'leaflet';
import { ImageOverlay, MapContainer, Tooltip } from 'react-leaflet';

import { IncidentMarker, LocationMarker, MarkerClusterGroup } from '../../../shared/components/LeafletMap';
import { MapLoader } from '../../../shared/components/MapLoader';
import { useCreateSquareImage } from '../../../shared/hooks/useCreateSquareImage';
import { useNormalizeLeafletMapData } from '../lib/normalizeLeafletMapData';
import { useIncidentMap } from '../model/IncidentMapProvider';

interface LeafletPlanProps {
  url: string;
}

export const LeafletMapPlan = (props: LeafletPlanProps) => {
  const { url } = props;
  const { image, imageUrl } = useCreateSquareImage({ url });
  const data = useIncidentMap();
  const { Config, locations, incidents, onMarkerClick, onDraggableMarker, onClusterClick } =
    useNormalizeLeafletMapData(data);

  if (!image || image.width === 0 || image.height === 0 || !imageUrl) {
    return <MapLoader />;
  }

  return (
    <MapContainer
      style={{ height: '100%', width: '100%' }}
      center={[0, 0]}
      minZoom={-3}
      maxZoom={20}
      crs={CRS.Simple}
      zoom={0}
    >
      <Config />
      <ImageOverlay
        url={imageUrl}
        bounds={[
          [-image.width, -image.height],
          [image.width, image.height],
        ]}
      />
      <MarkerClusterGroup zoomToBoundsOnClick={false} onClick={onClusterClick}>
        {incidents.map((marker) => (
          <IncidentMarker
            draggable
            position={[marker.position[1], marker.position[0]]}
            color={marker.color}
            eventHandlers={{
              drag: onDraggableMarker(marker),
              click: () => onMarkerClick(marker.metadata),
            }}
            data-metadata={marker.metadata}
          >
            <Tooltip>{marker.hint}</Tooltip>
          </IncidentMarker>
        ))}
      </MarkerClusterGroup>
      {locations.map((marker) => (
        <LocationMarker
          view='primary'
          key={marker.key}
          label={marker.label}
          position={[marker.position[1], marker.position[0]]}
          eventHandlers={{
            click: onMarkerClick(marker.metadata),
          }}
        >
          <Tooltip>{marker.hint}</Tooltip>
        </LocationMarker>
      ))}
    </MapContainer>
  );
};
