import { useTranslation } from 'react-i18next';

import type { User } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { UserAvatar, UserChip } from '../../base';
import { AutocompleteMultipleField, type AutocompleteMultipleFieldProps } from '../../fields/AutocompleteMultipleField';
import type { ProjectTasksFormFilerValue } from '../lib/types';

export type ParticipantsFieldProps = Omit<
  AutocompleteMultipleFieldProps<ProjectTasksFormFilerValue, 'participants', User>,
  'name' | 'label' | 'getOptionLabel' | 'renderTags' | 'renderOption'
>;

export const ParticipantsField = (props: ParticipantsFieldProps) => {
  const { t } = useTranslation();

  return (
    <AutocompleteMultipleField
      {...props}
      name='participants'
      getOptionLabel={(user: User) => getFullName(user)}
      label={t('participants')}
      renderTags={(value, getTagProps) =>
        value.map((user, index) => <UserChip user={user} variant='outlined' size='small' {...getTagProps({ index })} />)
      }
      renderOption={(liProps, user) => (
        <li {...liProps}>
          <UserAvatar user={user} sx={{ marginRight: '8px' }} />
          {getFullName(user)}
        </li>
      )}
    />
  );
};
