/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

import type { GridApi, RefreshCellsParams, RowDragMoveEvent, RowNode } from 'ag-grid-community';

function arePathsEqual(path1: string[], path2: string[]) {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach((item, index) => {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}

export function useGridDragAndDrop({
  maxLevelCallback,
  updateCallback,
  rulesForBan = () => false,
}: {
  maxLevelCallback: (value: number) => boolean;
  updateCallback?: (rows: any[]) => void;
  rulesForBan?: (node: RowNode | undefined | null) => boolean;
}) {
  const potentialParent = useRef<any>(null);

  function refreshRows(api: GridApi, rowsToRefresh: RowNode[]) {
    const params: RefreshCellsParams = {
      // refresh these rows only.
      rowNodes: rowsToRefresh,
      // because the grid does change detection, the refresh
      // will not happen because the underlying value has not
      // changed. to get around this, we force the refresh,
      // which skips change detection.
      force: true,
    };
    api.refreshCells(params);
  }

  const setPotentialParentForNode = useCallback(
    (api: GridApi, overNode: RowNode | undefined | null, node: RowNode) => {
      let newPotentialParent;

      if (overNode) {
        newPotentialParent = node.data.orgHierarchy.includes(overNode.id) ? overNode.parent : overNode;
      }

      const alreadySelected = potentialParent.current === newPotentialParent;

      if (alreadySelected || maxLevelCallback(newPotentialParent?.uiLevel || 0) || rulesForBan(node)) {
        return;
      }

      const rowsToRefresh = [];
      if (potentialParent.current) {
        rowsToRefresh.push(potentialParent.current);
      }
      if (newPotentialParent) {
        rowsToRefresh.push(newPotentialParent);
      }
      potentialParent.current = newPotentialParent;
      refreshRows(api, rowsToRefresh);
    },
    [maxLevelCallback, rulesForBan],
  );

  const moveToPath = useCallback((newParentPath: string[], node: RowNode, allUpdatedNodes: any[]) => {
    const oldPath = node.data.orgHierarchy;
    const rowId = oldPath[oldPath.length - 1];
    const newOrgHierarchy = newParentPath.slice();
    newOrgHierarchy.push(rowId);
    const transformedNode = {
      ...node,
      data: {
        ...node.data,
        orgHierarchy: newOrgHierarchy,
        parent: potentialParent.current.data ?? null,
      },
    };
    allUpdatedNodes.push(transformedNode.data);
    if (transformedNode.childrenAfterGroup) {
      transformedNode.childrenAfterGroup.forEach((childNode) => {
        moveToPath(newOrgHierarchy, childNode, allUpdatedNodes);
      });
    }
  }, []);

  const onRowDragMove = useCallback(
    (event: RowDragMoveEvent) => {
      setPotentialParentForNode(event.api, event.overNode, event.node);
    },
    [setPotentialParentForNode],
  );

  const onRowDragLeave = useCallback(
    (event: RowDragMoveEvent) => {
      setPotentialParentForNode(event.api, null, event.node);
    },
    [setPotentialParentForNode],
  );

  const onRowDragEnd = useCallback(
    (event: RowDragMoveEvent) => {
      if (!potentialParent.current) {
        return;
      }

      const movingData = event.node.data;
      const newParentPath = potentialParent.current.data ? potentialParent.current.data.orgHierarchy : [];
      const needToChangeParent = !arePathsEqual(newParentPath, movingData.orgHierarchy);

      if (needToChangeParent) {
        const updatedRows: any[] = [];
        moveToPath(newParentPath, event.node, updatedRows);
        const getNestedLevels = updatedRows.map((row) => row.orgHierarchy.length - 1);
        const maxLevel = Math.max(...getNestedLevels);

        if (maxLevelCallback(maxLevel - 1)) {
          return;
        }

        updateCallback?.(updatedRows);
        event.api.applyTransaction({
          update: updatedRows,
        });
        event.api.clearFocusedCell();
      }

      setPotentialParentForNode(event.api, null, event.node);
    },
    [maxLevelCallback, moveToPath, setPotentialParentForNode, updateCallback],
  );

  return {
    onRowDragMove,
    onRowDragLeave,
    onRowDragEnd,
    potentialParent,
  };
}
