import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import type { UseNewEntityRowParams } from './types';

export const useNewEntityRow = <T>({
  newEntityAtom,
  useAddEntity,
  defaultPreparedEntity,
}: UseNewEntityRowParams<T>) => {
  const { execute: addEntity, loading } = useAddEntity();
  const [newEntity, setNewEntity] = useRecoilState(newEntityAtom);

  const prepareNewEntity = useCallback(
    (preparedEntity?: Partial<T>) => setNewEntity(
      preparedEntity ? { ...defaultPreparedEntity, ...preparedEntity } : defaultPreparedEntity,
    ),
    [setNewEntity, defaultPreparedEntity],
  );
  const destroyNewEntity = useCallback(() => setNewEntity(undefined), [setNewEntity]);
  const addNewEntity = useCallback(() => {
    if (newEntity) {
      addEntity({
        data: newEntity,
      }).then(() => {
        destroyNewEntity();
      });
    }
  }, [newEntity, addEntity, destroyNewEntity]);

  return {
    loading,
    newEntity,
    prepareNewEntity,
    destroyNewEntity,
    addNewEntity,
    setNewEntity,
  };
};
