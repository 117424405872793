import type { RecoilTaskInterface } from 'recoil-toolkit';
import { delay } from 'recoil-toolkit';

import { notificationState } from './atoms';
import { NOTIFICATION_LIVE_TIME } from './constants';
import type { Notification } from './types';

export const showNotificationTask = ({ set, reset }: RecoilTaskInterface) => async (notification: Notification) => {
  set(notificationState, notification);

  await delay(NOTIFICATION_LIVE_TIME);

  reset(notificationState);
};
