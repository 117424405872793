import type { FC } from 'react';

import { Grid, Stack, Typography, TextField } from '@mui/material';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, DrawerContainer } from '../../../../../components/base';
import { TypeLocation } from '../../../../../state/locationsForm';
import type { MapFormLocationProps } from '../model/useMapFormLocation';
import useMapFormLocation from '../model/useMapFormLocation';

export const MapFormLocation: FC<MapFormLocationProps> = ({ locationForm, onEndAction }) => {
  const { onSubmit, onDelete, form, control, isAddedLocation } = useMapFormLocation({ locationForm, onEndAction });
  const { t } = useTranslation();

  const editTitle = locationForm.type === TypeLocation.New ? t('edit-new-location') : t('edit-location');
  const title = isAddedLocation ? t('add-new-location') : editTitle;

  return (
    <FormProvider {...form}>
      <DrawerContainer component='form' onSubmit={onSubmit}>
        <Stack spacing={2}>
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
            <Typography variant='h4'>{title}</Typography>
            {locationForm.type === TypeLocation.New && !isAddedLocation ? (
              <Button onClick={onDelete} type='button' color='error' variant='contained'>
                {t('delete')}
              </Button>
            ) : null}
          </Stack>

          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid xs={12} item>
              <Controller
                name='title'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField placeholder='Title' label={t('title')} fullWidth required {...field} />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='description'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder='Description'
                    label={t('description')}
                    fullWidth
                    maxRows={5}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>

        <Button fullWidth type='submit' sx={{ marginTop: 'auto', color: 'white' }} variant='contained'>
          {title}
        </Button>
      </DrawerContainer>
    </FormProvider>
  );
};
