import { Language, Status } from '../types';
import { FILE_TYPE_MIME } from '../utils/mime';

export const TOKEN_KEY = 'jwtToken';

export const AVAILABLE_STATUSES = {
  [Status.DRAFT]: [Status.IN_PROGRESS, Status.REGISTERED, Status.RESOLVED],
  [Status.REGISTERED]: [Status.REGISTERED, Status.IN_PROGRESS, Status.RESOLVED],
  [Status.IN_PROGRESS]: [Status.IN_PROGRESS, Status.RESOLVED],
  [Status.RESOLVED]: [Status.RESOLVED],
};

export const FILEPOND_MAX_SIZE = '256MB';
export const FILEPOND_IMAGE_PREVIEW_HEIGHT = 200;
export const FILEPOND_MAX_FILES = 10;

export const FILEPOND_FILE_TYPES_IMAGE = [...FILE_TYPE_MIME.image];
export const FILEPOND_FILE_TYPES_DOCUMENT = [
  ...FILE_TYPE_MIME.doc,
  ...FILE_TYPE_MIME.excel,
  ...FILE_TYPE_MIME.pdf,
  ...FILE_TYPE_MIME.powerPoint,
  ...FILE_TYPE_MIME.key,
];

export const FILEPOND_FILE_TYPES_VIDEO = [
  ...FILE_TYPE_MIME.avi,
  ...FILE_TYPE_MIME.mp4,
  ...FILE_TYPE_MIME.mpeg,
  ...FILE_TYPE_MIME.mov,
];

export const LANGUAGES = [
  {
    name: 'English',
    value: Language.EN,
    id: 1,
  },
  {
    name: 'Russian',
    value: Language.RU,
    id: 0,
  },
];

export const USED_DEVICE = [
  {
    name: 'Mobile',
    value: 'mobile',
    id: 0,
  },
  {
    name: 'Desktop',
    value: 'desktop',
    id: 1,
  },
];

export const DATETIME_FORMAT = {
  full: 'dd/MM/yyyy HH:mm',
  short: 'dd/MM/yyyy',
};

export const DATE_START_OF_TIME = new Date(1970, 0, 1, 0, 0, 0, 0);
