import { amber, green, orange, red, grey } from '@mui/material/colors';

const INCIDENT_COLORS = {
  Green: green[700],
  Amber: amber[500],
  Orange: orange[800],
  Red: red[700],
  Grey: grey[700],
};

export { INCIDENT_COLORS };
