import { atom, selectorFamily } from 'recoil';

import { type Task } from './types';

export const tasksState = atom<Record<number, Task>>({
  key: 'tasks',
  default: {},
});

export const getTasksWithoutRelation = selectorFamily({
  key: 'getTasksWithoutRelation',
  get:
    () =>
    ({ get }) => {
      const tasks = get(tasksState);
      return Object.values(tasks).filter((item) => !item.relation);
    },
});

export const getTask = selectorFamily({
  key: 'getTask',
  get:
    (id: number) =>
    ({ get }) => {
      const tasks = get(tasksState);
      return Object.values(tasks).find((task) => task.id === id) as Task;
    },
});
