import type {
  FC,
} from 'react';

import {
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LANGUAGES, USED_DEVICE } from '../../constants/base';
import { ObjectSelectController } from '../form';

export const UserCreateFields: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <Grid xs={12} item>
        <Controller
          name='firstName'
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t('first-name')}
              label={t('first-name')}
              fullWidth
              required
              {...field}
            />
          )}
        />
      </Grid>

      <Grid xs={12} item>
        <Controller
          name='lastName'
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t('last-name')}
              label={t('last-name')}
              fullWidth
              required
              {...field}
            />
          )}
        />
      </Grid>

      <Grid xs={12} item>
        <ObjectSelectController
          name='language'
          label={t('language')}
          data={LANGUAGES}
          itemRenderer={(item) => item.name}
          required
        />
      </Grid>

      <Grid xs={12} item>
        <ObjectSelectController
          name='usedDevice'
          label={t('used-device')}
          data={USED_DEVICE}
          itemRenderer={(item) => item.name}
          required
        />
      </Grid>

    </>
  );
};
