import type { GridApi } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';

import { checkIsPrepareChildField } from '../lib/checkIsPrepareChildField';
import { newChildTaskState } from './store/newChildTask.state';

interface UpdateRowParams {
  id: string;
  colField: string;
  value: unknown;
  api: GridApi;
}
export const useUpdateRowTransaction = () => {
  const setChildState = useSetRecoilState(newChildTaskState);

  const handleUpdateRow = (params: UpdateRowParams) => {
    const { api, colField, value, id } = params;

    if (checkIsPrepareChildField(id)) {
      setChildState((prev) => {
        const newValue = {
          ...prev,
          [colField]: value,
        };

        api.applyTransaction({
          update: [{ ...prev, [colField]: value }],
        });

        return newValue;
      });
    }
  };

  return {
    callbackIdString: handleUpdateRow,
  };
};
