import { useTranslation } from 'react-i18next';

import { DateField, type DateFieldProps } from '../../fields/DateField';
import type { ProjectTasksFormFilerValue } from '../lib/types';

type StartDateFieldProps = Omit<DateFieldProps<ProjectTasksFormFilerValue, 'startDate'>, 'label' | 'name'>;

export const StartDateField = (props: StartDateFieldProps) => {
  const { t } = useTranslation();

  return <DateField {...props} name='startDate' label={t('start-date')} />;
};
