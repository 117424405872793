import type { RecoilTaskInterface } from 'recoil-toolkit';

import { authService } from '../../api';
import type { LoginParams } from '../../api/authService';
import { setAuthToken } from '../../utils/authUtils';
import history from '../../utils/history';
import { authState } from './atoms';

type LocationProps = {
  state: {
    from: Location;
  };
};

export const loginTask =
  ({ set }: RecoilTaskInterface) =>
  async (params: LoginParams) => {
    const response = await authService.login(params);
    const token = response.data.jwt;

    set(authState, token);
    setAuthToken(token);

    const redirectUrl = (history.location as LocationProps).state?.from || '/';
    history.push(redirectUrl);
  };

export const logoutTask =
  ({ reset }: RecoilTaskInterface) =>
  async () => {
    await authService.logout();
    reset(authState);
    window.location.href = '/login';
  };
