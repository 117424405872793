/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';

import type { LatLng, DragEndEventHandlerFn, LeafletMouseEvent } from 'leaflet';
import { useMapEvents } from 'react-leaflet';

import type { LocationForm } from '../../../../../state/locationsForm';
import type { LocationMapDataProviderApi } from '../model/LocationMapDataProvider';

export function useNormalizeLeafletMapData(data: LocationMapDataProviderApi) {
  const { onMapClick, onDraggableMarker, ...rest } = data;

  const handleClickMap = (event: LeafletMouseEvent) => {
    onMapClick({
      longitude: event.latlng.lng,
      latitude: event.latlng.lat,
    });
  };

  // Need create as component, because Leaflet use Context as MapContainer
  const Config = () => {
    const map = useMapEvents({
      click(event) {
        handleClickMap(event);
      },
    });

    // Remove Ukranian flag in copyright
    map.attributionControl.setPrefix('');

    return null;
  };

  const handleDraggableMarker =
    (locationForm: LocationForm): DragEndEventHandlerFn =>
    (event) => {
      const { lat, lng } = event.target.getLatLng() as LatLng;

      onDraggableMarker(locationForm, {
        longitude: lng,
        latitude: lat,
      });
    };

  return {
    ...rest,
    Config,
    onDraggableMarker: handleDraggableMarker,
  };
}
