import { FormControlLabel } from '@mui/material';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { Checkbox } from '../../base';

type CheckboxFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  onChange?: (value: boolean) => void;
};

export const CheckboxField = <TFieldValues extends FieldValues = FieldValues>(
  props: CheckboxFieldProps<TFieldValues>,
) => {
  const { name, label, onChange } = props;
  const { field } = useController(props);

  return (
    <FormControlLabel
      name={name}
      control={
        <Checkbox
          {...field}
          onChange={(event, checked) => {
            field.onChange(event);
            onChange?.(checked);
          }}
          checked={field.value}
        />
      }
      label={label}
    />
  );
};
