import { Box } from '@mui/material';

import { Tooltip } from '../../../../components/base/Tooltip';
import type { GanttStatus } from '../lib/types';

interface BarProps {
  status: GanttStatus;
  userFunction: string;
  width: number;
  height: number;
}

const colorMap: Record<BarProps['status'], string> = {
  active: '#6060ff',
  waiting: '#AA96C8',
  ending: '#ff5353',
};

export const Bar = (props: BarProps) => {
  const { status, width, height, userFunction } = props;
  return (
    <Box
      sx={{
        width,
        height,
        backgroundColor: colorMap[status],
        borderRadius: '999px',
      }}
    >
      <Tooltip placement='bottom' disableInteractive title={userFunction}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginBottom: '2px',
            fontSize: 12,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '12px',
            maxWidth: width,
          }}
        >
          {userFunction}
        </Box>
      </Tooltip>
    </Box>
  );
};
