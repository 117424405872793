import { useRecoilTask } from 'recoil-toolkit';

import { userState } from './atoms';
import {
  addUserEventTask,
  addUserTask,
  deleteUserEventTask,
  editUserEventTask,
  findUserByEmailTask,
  getCurrentUserTask,
  resetPasswordTask,
  updateCurrentEventTask,
  updateFlaggedIncidentsTask,
  updateLanguageTask,
  updateSendNotificationTask,
  updateUserTask,
} from './tasks';

export const useUser = () => useRecoilTask(getCurrentUserTask, [], {
  dataSelector: userState,
  errorStack: true,
});

export const useAddUser = () => useRecoilTask(addUserTask, [], {
  loaderStack: 'addUserTask',
  errorStack: true,
});

export const useAddUserEvent = () => useRecoilTask(addUserEventTask, [], {
  loaderStack: 'addUserEventTask',
  errorStack: true,
});

export const useDeleteUserEvent = () => useRecoilTask(deleteUserEventTask, [], {
  loaderStack: 'deleteUserEventTask',
  errorStack: true,
});

export const useEditUserEvent = () => useRecoilTask(editUserEventTask, [], {
  loaderStack: 'editUserEventTask',
  errorStack: true,
});

export const useUpdateUser = () => useRecoilTask(updateUserTask, [], {
  loaderStack: 'updateUserTask',
  errorStack: true,
});

export const useUpdateFlaggedIncidents = () => useRecoilTask(updateFlaggedIncidentsTask, [], {
  loaderStack: 'updateFlaggedIncidents',
  errorStack: true,
});

export const useUpdateCurrentEvent = () => useRecoilTask(updateCurrentEventTask, [], {
  loaderStack: 'updateCurrentEvent',
  errorStack: true,
});

export const useFindUserByEmail = () => useRecoilTask(findUserByEmailTask, [], {
  loaderStack: 'findUserByEmail',
  errorStack: true,
});

export const useResetPassword = () => useRecoilTask(resetPasswordTask, [], {
  loaderStack: 'resetPasswordTask',
  errorStack: true,
});

export const useSelectLanguage = () => useRecoilTask(updateLanguageTask, [], {
  loaderStack: 'selectLanguage',
  errorStack: true,
});

export const useSendNotification = () => useRecoilTask(updateSendNotificationTask, [], {
  loaderStack: 'changeSendNotification',
  errorStack: true,
});
