import { atom, selector, selectorFamily } from 'recoil';

import type { EventSettings, User } from '../../types';
import { getIncident } from '../incidents';

export const userState = atom<User>({
  key: 'user',
  default: undefined,
});

export const getIsFlaggedIncident = selectorFamily({
  key: 'isFlaggedIncident',
  get:
    (incidentId: number) =>
    ({ get }) => {
      const currentUser = get(userState);

      return currentUser.flaggedIncidents.some((flaggedIncident) => flaggedIncident.id === incidentId);
    },
});

export const getIsViewedIncident = selectorFamily({
  key: 'isViewedIncident',
  get:
    (incidentId: number) =>
    ({ get }) => {
      const currentUser = get(userState);
      const incident = get(getIncident(incidentId));

      return incident?.viewedBy.some((user) => user.id === currentUser.id);
    },
});

export const userByEmailState = atom<User>({
  key: 'userByEmail',
  default: undefined,
});

export const userEventBrand = selector({
  key: 'userEventBrand',
  get({ get }) {
    const user = get(userState);
    const brand = user?.currentEvent?.brand;

    if (!brand) {
      return {
        enable: false,
      };
    }

    return brand;
  },
});

export const userEventSettings = selector({
  key: 'userEventSettings',
  get({ get }) {
    const user = get(userState);
    const event = user.currentEvent;
    const eventSettings = user.eventSettings.find((item) => item.event.id === event.id) as EventSettings;
    return eventSettings;
  },
});
