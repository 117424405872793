import type { MouseEvent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Tab, type TabProps } from '@mui/material';

interface CustomTabProps extends TabProps {
  onRemove?: () => void;
  label: string;
}

export const CustomTab = (props: CustomTabProps) => {
  const { label, onRemove, sx, ...rest } = props;

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onRemove?.();
  };

  return (
    <Tab
      {...rest}
      sx={{
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: 'black',
        borderRadius: '8px',
        ...sx,
      }}
      label={
        <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {onRemove && (
            <IconButton size='small' onClick={handleRemove} component='span' sx={{ marginLeft: '10px' }}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      }
    />
  );
};
