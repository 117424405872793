import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';

import type { User } from '../../../../types';
import { UserAvatar } from '../../../base';

const UserRenderer = ({ value }: ICellRendererParams) => {
  if (!value) {
    return null;
  }

  const user: User = value;

  return (
    <UserAvatar size='s' user={user} />
  );
};

export default memo(UserRenderer);
