import type { MarkerProps } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

import { circleMarkerConfigSvg, type CircleMarkerProps } from '../../Markers';

export type IncidentMarkerProps = Omit<MarkerProps, 'icon'> & CircleMarkerProps;

export const IncidentMarker = ({ color, ...props }: IncidentMarkerProps) => (
  <Marker
    {...props}
    icon={{
      ...circleMarkerConfigSvg,
      fillColor: color,
      anchor: window?.google ? new google.maps.Point(10, 10) : null,
      strokeWeight: 0,
    }}
  />
);
