import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { dashboardsPrjectPlanState } from './atom';

const resetDashboardsTask =
  ({ reset }: RecoilTaskInterface) =>
  () => {
    reset(dashboardsPrjectPlanState);
  };

export const useResetDashboards = () => useRecoilTask(resetDashboardsTask, []);
