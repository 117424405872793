import type { ColDef } from 'ag-grid-community';

import { useColumnsSettings } from '../../../modules/EntitiesGrid';
import { columnsState, sortState } from '../state/atoms';
import { DEFAULT_SORT_STATE, SORT_MAP_FIELD } from '../state/constants';
import { useSetColumns, useSyncColumnStateFromUser } from '../state/hooks';

type UseColumnStateProps = {
  columnDefs: ColDef[],
}

export const useColumnState = ({ columnDefs }: UseColumnStateProps) => {
  useSyncColumnStateFromUser();
  return useColumnsSettings({
    columnDefs,
    columnRecoilState: columnsState,
    useSetRecoilColumnsSettings: useSetColumns,
    sortMapField: SORT_MAP_FIELD,
    columnSortRecoilState: sortState,
    defaultColumnSettingsSort: DEFAULT_SORT_STATE,
  });
};
