import type { TFunction } from 'i18next';

import { TaskType } from '../../../modules/Tasks/state/tasks';

export function getTitleForDocument(taskType: TaskType, t: TFunction<'translation'>): string {
  switch (taskType) {
    case TaskType.ProjectTask:
      return t('pmp');
    case TaskType.OperationalTask:
      return t('drs');
    case TaskType.Incident:
    default:
      return t('ims');
  }
}
