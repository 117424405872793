import { useCallback } from 'react';

import type { ValueSetterParams } from 'ag-grid-community';

import type { CellCommentValue } from '../../../../../types';
import type { TaskType } from '../../../../Tasks/state/tasks';

type AddCommentFn = (entity: { id: number; type: TaskType }, body: string, image?: File) => Promise<void>;
type UpdateFn = () => Promise<void>;

export const useGridCommentsSetter = <E extends { id: number }>(addComment: AddCommentFn, update: UpdateFn) => {
  const commentsSetter = useCallback(
    ({ colDef, data, newValue }: ValueSetterParams) => {
      if (!colDef.field) {
        return false;
      }
      const entity: E = data;
      const valueData: CellCommentValue = newValue;

      if (valueData && valueData.type === 'add' && (valueData.data.image || valueData.data.text)) {
        addComment(
          {
            id: entity.id,
            type: data.type,
          },
          valueData.data.text,
          valueData.data.image,
        ).then(() => {
          update();
        });
      }

      if (valueData && (valueData.type === 'remove' || valueData.type === 'edit')) {
        update();
      }

      return true;
    },
    [addComment, update],
  );

  return {
    commentsSetter,
  };
};
