import { Controller, useFormContext } from 'react-hook-form';

import { MultiUsersEditor } from '../../../modules/EntitiesGrid/components/editors';
import type { User } from '../../../types';

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  options: User[];
};

export const MultiUsersController = ({
  name,
  label,
  options,
  disabled = false,
}: Props) => {
  const {
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value, ref, onBlur, onChange,
        },
      }) => (
        <MultiUsersEditor
          disabled={disabled}
          options={options}
          label={label}
          ref={ref}
          values={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />

  );
};
