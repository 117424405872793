import { type RecoilState } from 'recoil';

import { FilterButton } from '../../../../components/ControlPanel/components/FilterButton';
import type { DailyTaskDefaultFilters, ProjectTaskDefaultFilters } from '../../../../types';
import { ContainsText } from '../../../EntitiesGrid';

type TaskFiltersProps = {
  filtersState: RecoilState<ProjectTaskDefaultFilters | DailyTaskDefaultFilters>;
  onClickFilterButton: () => void;
};

export const TaskFilters = ({ filtersState, onClickFilterButton }: TaskFiltersProps) => (
  <>
    <ContainsText filtersState={filtersState} />
    <FilterButton onClick={onClickFilterButton} />
  </>
);
