import type { TFunction } from 'i18next';

import { convertUpdateIncidentToFieldValue } from '../../../components/IncidentLog/components/utils';
import type {
  Location, UpdateActionDescription, UserFunction,
} from '../../../types';
import type { HeaderItem } from './exportCreateIncidentAction';
import { addWidthToCell, convertFieldValueCellToBodyRow } from './exportCreateIncidentAction';

export const createHeader = (t: TFunction<'translation'>, tableWidth: number) => ([
  {
    header: t('field'),
    dataKey: 'field',
    width: tableWidth * 0.15,
  },
  {
    header: t('before'),
    dataKey: 'before',
    width: tableWidth * 0.425,
  },
  {
    header: t('after'),
    dataKey: 'after',
    width: tableWidth * 0.425,
  },
]);

export const convertAttrsToRows = (
  incident: UpdateActionDescription['attrs'],
  t: TFunction<'translation'>,
  locations: Record<number, Location>,
  userFunctions: Record<number, UserFunction>,
  header: HeaderItem[],
) => {
  const fieldValuePairs = convertUpdateIncidentToFieldValue(incident);
  const bodyRows = fieldValuePairs.map((pair) => {
    const beforePair = convertFieldValueCellToBodyRow(pair.old, t, locations, userFunctions);
    const afterPair = convertFieldValueCellToBodyRow(pair.new, t, locations, userFunctions);

    return {
      field: beforePair.field,
      before: beforePair.value,
      after: afterPair.value,
    };
  });

  return addWidthToCell(bodyRows, header);
};
