import { Stack } from '@mui/material';

import type { User } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { ClampedTypography, UserAvatar } from '../../base';

const UserRenderer = (user: User) => (
  <Stack direction='row' spacing={2}>
    <UserAvatar user={user} size='s' />
    <ClampedTypography lineclamp={1} wordBreak='break-all'>{getFullName(user)}</ClampedTypography>
  </Stack>
);

export default UserRenderer;
