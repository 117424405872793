import { useEffect, useState } from 'react';

import type { AutocompleteGetTagProps } from '@mui/material';
import { Chip, FormControl, Grid, Stack, TextField, Typography, Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { defaultUserFunctions } from '../../constants/defaultUserFanctions';
import { LocationMap } from '../../modules/Map/feature/LocationMap';
import { useAddEvent } from '../../state/event';
import { locationsControlState, type LocationControlItem } from '../../state/locationsForm';
import { NotificationType, useShowNotification } from '../../state/notification';
import type { NewEvent } from '../../types';
import { chunks } from '../../utils/chunks';
import { Button } from '../base';
import { EventLimitFields } from '../EventLimitFields';
import { CheckboxField } from '../fields/CheckboxField';
import { ImageField } from '../fields/ImageField';

const renderTags = (tags: readonly string[], getTagProps: AutocompleteGetTagProps) =>
  tags.map((tag: string, index: number) => <Chip variant='outlined' label={tag} {...getTagProps({ index })} />);

type Props = {
  onSubmit: () => void;
};

export const EventForm = ({ onSubmit: onSubmitProps }: Props) => {
  const { t } = useTranslation();
  const [imageMap, setImageMap] = useState<{
    prev: string | undefined;
    curr: string | undefined;
  }>({
    prev: undefined,
    curr: undefined,
  });
  const { execute: addEvent, loading } = useAddEvent();
  const { execute: showNotification } = useShowNotification();
  const [locations, setLocations] = useRecoilState(locationsControlState);
  const form = useForm<NewEvent>({
    defaultValues: {
      name: '',
      description: '',
      userFunctions: [],
      sitePlan: [],
      incidentPlan: true,
      projectPlan: false,
      dailyPlan: false,
    },
  });
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = form;

  const sitePlanValue = watch('sitePlan');
  const dailyPlanValue = watch('dailyPlan');
  const incidentPlanValue = watch('incidentPlan');

  useEffect(() => {
    if (!sitePlanValue || sitePlanValue.length === 0) {
      setImageMap((prev) => ({
        prev: prev.curr,
        curr: undefined,
      }));
    } else {
      const url = typeof sitePlanValue[0] === 'string' ? sitePlanValue[0] : URL.createObjectURL(sitePlanValue[0]);
      setImageMap((prev) => ({
        prev: prev.curr,
        curr: url,
      }));
    }
  }, [sitePlanValue]);

  useEffect(() => {
    if (imageMap.curr !== imageMap.prev) {
      const locationChunks = chunks(locations, 5);
      const resetLocationGeo = locationChunks.map<LocationControlItem[]>((item, i) =>
        item.map((location, j) => ({
          ...location,
          geo: {
            id: 'id' in location.geo ? location.geo.id : 0,
            latitude: i,
            longitude: j,
          },
        })),
      );
      setLocations(resetLocationGeo.flat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageMap]);

  const onSubmit = (values: NewEvent) => {
    if (values.userFunctions.length < 1) {
      setError('userFunctions', { type: 'custom', message: t('at-least-one-user-function') });
    } else {
      addEvent({
        event: {
          ...values,
          locations,
        },
        successCallback: () => {
          showNotification({ type: NotificationType.SUCCESS, text: t('add-new-project-success') });
          onSubmitProps();
        },
      });
    }
  };

  return (
    <FormProvider {...form}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography variant='h4' sx={{ marginBottom: '16px' }}>
            <Trans i18nKey='add-new-project' />
          </Typography>

          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item container rowSpacing={2} columnSpacing={2} xs={12}>
              <Grid item container rowSpacing={2} xs={7} direction='column' wrap='nowrap'>
                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <Controller
                    name='name'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={t('name')}
                        label={t('name')}
                        fullWidth
                        required
                        inputProps={{
                          maxLength: 250,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <Controller
                    name='description'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={t('description')}
                        label={t('description')}
                        fullWidth
                        required
                        inputProps={{
                          maxLength: 250,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} item sx={{ '&': { flexBasis: 'auto' } }}>
                  <FormControl fullWidth>
                    <Controller
                      name='userFunctions'
                      control={control}
                      render={({ field: { value, ref, onBlur, onChange } }) => (
                        <Autocomplete
                          value={value}
                          onChange={(_, data) => onChange(data)}
                          multiple
                          options={defaultUserFunctions.map((option) => option.name)}
                          freeSolo
                          disableCloseOnSelect
                          renderTags={renderTags}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.userFunctions)}
                              helperText={errors.userFunctions?.message}
                              label={t('functions')}
                              placeholder={t('functions')}
                            />
                          )}
                          onBlur={onBlur}
                          ref={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid xs={5} item>
                <FormControl fullWidth>
                  <Typography variant='fieldLabel'>{t('site-plan-download')}</Typography>
                  <ImageField control={control} name='sitePlan' allowImagePreview={false} />
                </FormControl>
                <FormControl fullWidth>
                  <CheckboxField
                    control={control}
                    label={t('incidents')}
                    name='incidentPlan'
                    disabled={!dailyPlanValue}
                  />
                  <CheckboxField control={control} label={t('project-plan')} name='projectPlan' />
                  <CheckboxField
                    control={control}
                    label={t('daily-plan')}
                    name='dailyPlan'
                    disabled={!incidentPlanValue}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <Box
                sx={{
                  height: '500px',
                }}
                pt={2}
              >
                <LocationMap imageMap={imageMap.curr} />
              </Box>
            </Grid>

            <EventLimitFields />
          </Grid>
        </Stack>

        <Box pt={2}>
          <Button
            fullWidth
            type='submit'
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
            loading={loading}
          >
            {t('create-new-project')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
