import { useMemo, type FC, type RefObject } from 'react';

import type { ColDef, RowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import useDrawer from '../../../../hooks/useDrawer';
import { Drawer } from '../../../../state/drawer';
import { permissionState } from '../../../../state/event';
import { userState } from '../../../../state/user';
import { Status } from '../../../../types';
import { EntitiesGridBox, useGetLocaleText } from '../../../EntitiesGrid';
import { useGridDragAndDrop } from '../../../EntitiesGrid/hooks/useGridDragAndDrop';
import { projectTasksFiltersState, projectTasksState } from '../../state/projectTasks';
import { useProjectTaskInlineAdding, useProjectTasksData } from '../../state/projectTasks/hooks';
import { useGridSetters } from '../../state/tasks';
import type { ProjectTaskFormProps } from '../ProjectTaskForm/ProjectTaskForm';
import { FactoryNewRowRenderer } from './components/FactoryNewRowRenderer';
import { excludeSortPrepareField } from './lib/excludeSortPrepareField';
import { getIsMaxLevel } from './lib/maxLevel';
import type { ProjectTasksGridContext } from './lib/types';
import { updateDataAfterChangePreparedField } from './lib/updateDataAfterChangePreparedField';
import { useColumnDefs, useDefaultColDef } from './lib/useColumnDefs';
import { useColumnsState } from './lib/useColumnsState';
import { useScrollToRowByParams } from './lib/useScrollToRowByParams';
import { newChildTaskState } from './model/store/newChildTask.state';
import { useUpdateRows } from './model/updateRows';
import { useUpdateRowTransaction } from './model/useUpdateRowTransaction';

type Props<T extends object = object> = {
  gridRef: RefObject<AgGridReact<unknown>>;
} & T;

export const ProjectTasksGrid: FC<Props> = ({ gridRef, ...gridDefaultSettings }) => {
  const { editTaskPart, projectTasks } = useProjectTasksData();
  const { scrollToRowByParams } = useScrollToRowByParams();
  const { canCreateProjectTask, canUpdateResolvedProjectTask } = useRecoilValue(permissionState);
  const projectTasksWithoutHierarchy = useRecoilValue(projectTasksState);
  const { callbackIdString } = useUpdateRowTransaction();
  const [childRow] = useRecoilState(newChildTaskState);
  const { t } = useTranslation();
  const { editPreparedProjectTask } = useProjectTaskInlineAdding(gridRef);
  const { defaultValueSetter, statusSetter, commentsSetter, modals } = useGridSetters({
    editTaskPart,
    editPreparedTask: editPreparedProjectTask,
    callbackIdString,
  });

  const columnDefs = useColumnDefs(t, statusSetter, commentsSetter, defaultValueSetter);

  const defaultColDef = useDefaultColDef();

  const { columnDefs: updatedColDefs, onDragStopped } = useColumnsState(columnDefs);
  const getLocaleText = useGetLocaleText(t);
  const { execute: onUpdateRows } = useUpdateRows();

  const handleRulesForBan = (node: RowNode | undefined | null): boolean => {
    if (node && node.data.status === Status.RESOLVED && !canUpdateResolvedProjectTask) {
      return true;
    }

    return false;
  };

  const { onRowDragEnd, onRowDragLeave, onRowDragMove, potentialParent } = useGridDragAndDrop({
    maxLevelCallback: getIsMaxLevel,
    updateCallback: onUpdateRows,
    rulesForBan: handleRulesForBan,
  });
  const filters = useRecoilValue(projectTasksFiltersState);
  const permissions = useRecoilValue(permissionState);
  const currentUser = useRecoilValue(userState);
  const { showDrawer } = useDrawer();

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      field: '',
      headerName: '#',
      editable: false,
      resizable: false,
      sortable: true,
      sortingOrder: ['desc', 'asc'] as ('desc' | 'asc')[],
      pinned: 'left',
      minWidth: 300,
      rowDrag: canCreateProjectTask,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: FactoryNewRowRenderer,
      },
      cellClassRules: {
        'hover-over': (params) => params.node === potentialParent.current,
      },
    }),
    [canCreateProjectTask, potentialParent],
  );

  const context = useMemo<ProjectTasksGridContext>(
    () => ({
      projectTasks: projectTasksWithoutHierarchy,
      filters,
      permissions,
      currentUser,
    }),
    [filters, projectTasksWithoutHierarchy, permissions, currentUser],
  );

  const handleScrollToRow = async (params: ProjectTaskFormProps) => {
    await showDrawer({ type: Drawer.PROJECT_TASK_FORM, props: params });
  };

  const rowData = childRow ? [childRow, ...projectTasks] : projectTasks;

  return (
    <EntitiesGridBox>
      <AgGridReact
        {...gridDefaultSettings}
        treeData
        getDataPath={(data) => data.orgHierarchy}
        autoGroupColumnDef={autoGroupColumnDef}
        ref={gridRef}
        rowData={rowData}
        columnDefs={updatedColDefs}
        onDragStopped={onDragStopped}
        defaultColDef={defaultColDef}
        overlayNoRowsTemplate={t('no-tasks-found')}
        getLocaleText={getLocaleText}
        onRowDragMove={onRowDragMove}
        onRowDragLeave={onRowDragLeave}
        onRowDragEnd={onRowDragEnd}
        tooltipHideDelay={5000}
        groupDefaultExpanded={-1}
        context={context}
        onCellValueChanged={(event) => {
          updateDataAfterChangePreparedField(event);
        }}
        postSortRows={(params) => {
          excludeSortPrepareField(params);
        }}
        onFirstDataRendered={scrollToRowByParams(handleScrollToRow)}
      />
      {modals.close}
      {modals.reopen}
    </EntitiesGridBox>
  );
};
