import {
  forwardRef, useImperativeHandle, useState,
} from 'react';

import type { ICellEditorParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { usersState } from '../../../../state/users';
import type { User } from '../../../../types';
import { MultiUsersEditor } from '../../components/editors';

const CUSTOM_POPUP = {
  paper: {
    className: 'ag-custom-component-popup',
  },
  popper: {
    className: 'ag-custom-component-popup',
  },
};

export const MultiUsersGridEditor = forwardRef((props: ICellEditorParams, ref) => {
  const cellData: User[] | undefined = props.value;
  const usersOptions = useRecoilValue(usersState);
  const [users, setUsers] = useState(cellData);

  const onChange = (newUsers: User[] | undefined) => {
    setUsers(newUsers);
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return users !== undefined ? users : cellData;
    },
  }));

  return (
    <MultiUsersEditor
      componentsProps={CUSTOM_POPUP}
      values={users}
      onChange={onChange}
      options={usersOptions}
    />
  );
});
