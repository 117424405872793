import { Box, Stack } from '@mui/material';

import { FilterButton } from '../../../../components/ControlPanel/components/FilterButton';
import { WrapperBox } from '../../../../components/ControlPanel/components/WrapperBox';
import { FullScreenButton } from '../../../../components/FullScreen';
import { Drawer, useShowDrawer } from '../../../../state/drawer';

export const ControlPanel = () => {
  const { execute: showDrawer } = useShowDrawer();

  return (
    <WrapperBox>
      <Box width='100%'>
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <FilterButton color='violet' onClick={() => showDrawer({ type: Drawer.PROJECT_TASKS_FILTER })} />
          <FullScreenButton sx={{ ml: 1 }} />
        </Stack>
      </Box>
    </WrapperBox>
  );
};
