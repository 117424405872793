import type {
  FC} from 'react';
import { useMemo, useState,
} from 'react';

import {
  Grid,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../constants/base';
import useDrawer from '../../hooks/useDrawer';
import { NotificationType, useShowNotification } from '../../state/notification';
import { useResetPassword, useUpdateUser } from '../../state/user';
import type {
  Language, Role, UserFunction, User,
} from '../../types';
import { Button, ConfirmActionDialog, DrawerContainer } from '../base';
import { ObjectSelectController } from '../form';

type UserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  userFunction: UserFunction;
  language: {
    name: string;
    value: Language;
    id: number;
  };
}

type Props = {
  user: User;
}

export const UserEditForm: FC<Props> = ({ user }) => {
  const [showConfirmResetPassswordDialog, setShowConfirmResetPasswordDialog] = useState(false);
  const [showConfirmBlockDialog, setShowConfirmBlockDialog] = useState(false);
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();
  const { execute: showNotification } = useShowNotification();
  const { execute: updateUser, loading } = useUpdateUser();
  const { execute: resetPassword, loading: resetInProgress } = useResetPassword();

  const defaultValues = useMemo(() => ({
    email: user.email,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    language: LANGUAGES.find(({ value }) => value === user.language) || LANGUAGES[0],
  }), [
    user.email,
    user.firstName,
    user.language,
    user.lastName,
  ]);

  const form = useForm<UserFormValues>({
    defaultValues,
  });
  const {
    handleSubmit, control,
  } = form;

  const onSubmit = ({
    firstName,
    lastName,
    language,
  }: UserFormValues) => {
    updateUser({
      id: user.id,
      data: {
        firstName,
        lastName,
        language: language.value,
      },
      successCallback: () => {
        closeDrawer();
        showNotification({ type: NotificationType.SUCCESS, text: t('user-update-success-message') });
      },
    });
  };

  const handleBlockUser = () => {
    setShowConfirmBlockDialog(false);

    updateUser({
      id: user.id,
      data: {
        blocked: !user.blocked,
      },
      successCallback: () => {
        closeDrawer();
        showNotification({
          type: NotificationType.SUCCESS,
          text: t(user.blocked ? 'unblock-user-success-message' : 'block-user-success-message'),
        });
      },
    });
  };

  const handleResetPassword = () => {
    setShowConfirmResetPasswordDialog(false);

    resetPassword({
      id: user.id,
      successCallback: () => {
        closeDrawer();
        showNotification({ type: NotificationType.SUCCESS, text: t('reset-user-password-success-message') });
      },
    });
  };

  return (
    <FormProvider {...form}>
      <DrawerContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography variant='h4' sx={{ marginBottom: '16px' }}>
            <Trans i18nKey='update-user' />
          </Typography>

          <Grid container columnSpacing={1} rowSpacing={2}>

            <Grid xs={12} item>
              <Controller
                name='email'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled
                    placeholder={t('email')}
                    label={t('email')}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='firstName'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t('first-name')}
                    label={t('first-name')}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='lastName'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t('last-name')}
                    label={t('last-name')}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <ObjectSelectController
                name='language'
                label={t('language')}
                data={LANGUAGES}
                itemRenderer={(item) => item.name}
                required
              />
            </Grid>

          </Grid>
        </Stack>

        <Stack spacing={2}>
          <Button
            fullWidth
            loading={loading || resetInProgress}
            type='submit'
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
          >
            <Trans i18nKey='update-user' />
          </Button>
          <Button
            color='warning'
            fullWidth
            loading={loading || resetInProgress}
            onClick={() => setShowConfirmResetPasswordDialog(true)}
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
          >
            <Trans i18nKey='reset-user-password' />
          </Button>
          <Button
            fullWidth
            color='error'
            loading={loading || resetInProgress}
            onClick={() => setShowConfirmBlockDialog(true)}
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
          >
            <Trans i18nKey={user.blocked ? 'unblock-user' : 'block-user'} />
          </Button>
        </Stack>
      </DrawerContainer>
      <ConfirmActionDialog
        open={showConfirmResetPassswordDialog}
        onCancel={() => setShowConfirmResetPasswordDialog(false)}
        onConfirm={handleResetPassword}
        title={<Trans i18nKey='reset-user-password' />}
        content={<Trans i18nKey='reset-user-password-confirm-dialog-text' />}
      />
      <ConfirmActionDialog
        open={showConfirmBlockDialog}
        onCancel={() => setShowConfirmBlockDialog(false)}
        onConfirm={handleBlockUser}
        title={<Trans i18nKey={user.blocked ? 'unblock-user' : 'block-user'} />}
        content={(
          <Trans
            i18nKey={user.blocked ? 'unblock-user-confirm-dialog-text' : 'block-user-confirm-dialog-text'}
          />
        )}
      />
    </FormProvider>
  );
};
