import type { RefObject } from 'react';
import { useCallback } from 'react';

import type { ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { addDays, isValid, startOfDay } from 'date-fns';
import { useDebounce } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRecoilTask } from 'recoil-toolkit';
import { last } from 'remeda';

import { currentEventIdState } from '../../../../state/event';
import { userState } from '../../../../state/user';
import formatDay from '../../../../utils/formatDay';
import {
  useGridDragColumns,
  useInlineAdding,
  useNewEntityRow,
  useSyncColumnStateFromUserByUpdateHook,
} from '../../../EntitiesGrid';
import {
  currentTabWithDefaultDailyTasksState,
  dailyTasksColumnsState,
  dailyTasksFiltersState,
  newRowDailyTask,
  newTabsDailyTasksState,
  tabsDailyTasksState,
} from './atoms';
import { DEFAULT_PREPARED_DAILY_TASK } from './constants';
import {
  addDailyTask,
  editPartDailyTask,
  getDailyTasks,
  importDailyTasksFromExcel,
  removeDailyTask,
  setColumnsTask,
  updateColumnsFromUserTask,
} from './tasks';

const DEBOUNCE_TIME = 500;

export const useAddDailyTask = () =>
  useRecoilTask(addDailyTask, [], {
    loaderStack: 'addDailyTask',
    errorStack: true,
  });

const useIncidentInlineAddingParams = {
  newEntityAtom: newRowDailyTask,
  useAddEntity: useAddDailyTask,
  defaultPreparedEntity: DEFAULT_PREPARED_DAILY_TASK,
};

export function useDailyTaskInlineAdding(gridRef: RefObject<AgGridReact<unknown>>) {
  const { editNewEntity } = useInlineAdding(gridRef, useIncidentInlineAddingParams);

  return {
    editPreparedDailyTask: editNewEntity,
  };
}

export const useDailyTaskNewRow = () => useNewEntityRow(useIncidentInlineAddingParams);

export const useSetColumns = () =>
  useRecoilTask(setColumnsTask, [], {
    loaderStack: 'setColumnsTask',
    errorStack: true,
  });

const useUpdateColumnState = () =>
  useRecoilTask(updateColumnsFromUserTask, [], {
    loaderStack: 'updateColumnsFromUserTask',
    errorStack: true,
  });

export const useSyncColumnStateFromUser = () => useSyncColumnStateFromUserByUpdateHook(useUpdateColumnState);

export const useDailyTaskGridDragColumns = (columnDefs: ColDef[]) =>
  useGridDragColumns({
    columnDefs,
    columnRecoilState: dailyTasksColumnsState,
    useSetRecoilColumnsSettings: useSetColumns,
  });

export const useTabs = (newDayTitle = '') => {
  const tabs = useRecoilValue(tabsDailyTasksState);
  const currentTab = useRecoilValue(currentTabWithDefaultDailyTasksState);
  const [newTabs, setNewTabs] = useRecoilState(newTabsDailyTasksState);
  const addingNewRowInProcess = useRecoilValue(newRowDailyTask);

  const tabsWithLabel = tabs.map((tab) => {
    let label: string;
    if ('day' in tab) {
      label = 'tasks' in tab ? tab.key : `${formatDay(tab.day)} (${newDayTitle})`;
    } else {
      label = `${newDayTitle} ${tab.key}`;
    }

    return {
      ...tab,
      label,
    };
  });
  const handleAddTab = useCallback(() => {
    let resultTab: { id: number; day: Date } | null = null;

    const tabsWithDate = last(tabs.filter((tab) => 'day' in tab));
    const lastId = last(newTabs)?.id || 0;
    const newDay =
      tabsWithDate && 'day' in tabsWithDate && tabsWithDate.day ? addDays(tabsWithDate.day, 1) : startOfDay(Date.now());
    const newTab = {
      id: lastId + 1,
      day: newDay,
    };
    resultTab = newTab;

    setNewTabs([...newTabs, newTab]);
    return resultTab;
  }, [newTabs, setNewTabs, tabs]);

  const handleRemoveNewTab = useCallback(
    (value: number | Date) => {
      const updatedTabs = newTabs.filter((item) => {
        if (typeof value === 'number') {
          return item.id !== value;
        }

        return item.day === value;
      });

      setNewTabs(updatedTabs);
    },
    [newTabs, setNewTabs],
  );

  const handleChangeTab = (dateValue: Date | null) => {
    if (currentTab && isValid(dateValue)) {
      setNewTabs(
        newTabs.map((tab) => {
          if (tab.id.toString() === currentTab.key) {
            return {
              ...tab,
              day: dateValue || undefined,
            };
          }
          return tab;
        }),
      );
    }
  };

  return {
    tabs: tabsWithLabel,
    handleAddTab,
    handleChangeTab,
    handleRemoveNewTab,
    disabled: Boolean(addingNewRowInProcess),
    currentTab,
    hasTasks: currentTab && 'tasks' in currentTab,
    dayOptions: currentTab && 'day' in currentTab && { day: currentTab.day },
  };
};

export const useDailyTaskRemoveRow = () =>
  useRecoilTask(removeDailyTask, [], {
    loaderStack: 'dailyTaskRemoveRow',
    errorStack: true,
  });

const useTasks = () =>
  useRecoilTask(getDailyTasks, [], {
    dataSelector: currentTabWithDefaultDailyTasksState,
    loaderStack: 'getDailyTasks',
    errorStack: true,
  });

const useEditPartDailyTask = () =>
  useRecoilTask(editPartDailyTask, [], {
    loaderStack: 'editPartProjectTask',
    errorStack: true,
  });

export const useDailyTasksData = () => {
  const { execute: loadTasks, data: currentTab } = useTasks();

  const currentUser = useRecoilValue(userState);
  const currentEventId = useRecoilValue(currentEventIdState);
  const filters = useRecoilValue(dailyTasksFiltersState);

  const { execute: editTaskPart } = useEditPartDailyTask();

  useDebounce(
    () => {
      if (currentEventId) {
        loadTasks();
      }
    },
    DEBOUNCE_TIME,
    [loadTasks, currentEventId, filters],
  );

  return {
    currentUser,
    currentEventId,
    editTaskPart,
    currentTab,
  };
};

export const useImportDailyTasksFromExcel = () =>
  useRecoilTask(importDailyTasksFromExcel, [], {
    errorStack: true,
  });
