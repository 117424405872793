import { endOfDay, startOfDay } from 'date-fns';

import type { GanttStatus } from './types';

export function getStatusByColor(startDate: string | null, endDate: string | null): GanttStatus {
  const currentStartDay = startOfDay(new Date());
  const currentEndDay = endOfDay(new Date());

  const startDay = startOfDay(new Date(startDate || ''));
  const endDay = endOfDay(new Date(endDate || ''));

  switch (true) {
    case startDay <= currentStartDay && endDay >= currentEndDay:
      return 'active';

    case new Date(startDate || '') > currentStartDay:
      return 'waiting';

    case new Date(endDate || '') < currentEndDay:
    default:
      return 'ending';
  }
}
