import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '@mui/material';
import { styled, tooltipClasses } from '@mui/material';

import { Tooltip as TooltipBase } from '../../../../components/base/Tooltip';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipBase {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}));

export const useTooltip = (): {
  ref: RefObject<HTMLDivElement>;
  tooltipProps: Pick<TooltipProps, 'disableFocusListener' | 'disableHoverListener' | 'disableTouchListener'>;
} => {
  const ref = useRef<HTMLDivElement>(null);
  const [disableTooltip, setDisableTooltip] = useState(true);

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef && currentRef.clientHeight < currentRef.scrollHeight) {
      setDisableTooltip(false);
    } else {
      setDisableTooltip(true);
    }
  }, []);

  return {
    ref,
    tooltipProps: {
      disableFocusListener: disableTooltip,
      disableHoverListener: disableTooltip,
      disableTouchListener: disableTooltip,
    },
  };
};
