export function getCountByLevel(value: number | string, level: number) {
  if (value === '-') {
    return value;
  }

  if (level === 2) {
    return `M-${value}`;
  }

  if (level === 3) {
    return `T-${value}`;
  }

  return value;
}
