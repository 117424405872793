import { useCallback, useMemo, useState, type ChangeEvent, type FC } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, InputAdornment, Menu, MenuItem, MenuList, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppRoute } from '../../types';
import { Icon } from '../base';

const PAPER_PROPS = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '& > .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      maxHeight: '320px',
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  style: {
    maxHeight: 500,
  },
};

type Item = {
  name: string;
  id: number;
};

type EventMenuProps = {
  open: boolean;
  onClose: () => void;
  onClick: (id: number) => void;
  anchorEl?: HTMLElement;
  items: Item[];
  itemArchives?: Item[];
  displayAddProject: boolean;
};

export const EventMenu: FC<EventMenuProps> = ({
  open,
  onClose,
  onClick,
  anchorEl,
  items,
  displayAddProject,
  itemArchives = [],
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [openArchives, setOpenArchives] = useState(false);

  const handleClick = () => {
    setOpenArchives((prev) => !prev);
  };

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const { searchItems, searchItemArchives } = useMemo(
    () => ({
      searchItems: items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())),
      searchItemArchives: itemArchives.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())),
    }),
    [itemArchives, items, searchText],
  );

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={onClose}
      PaperProps={PAPER_PROPS}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {displayAddProject && (
        <MenuItem component={Link} to={`${AppRoute.EVENTS}?newProject=true`} onClick={onClose}>
          <Icon icon='plus' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
          <Trans i18nKey='add-new-project' />
        </MenuItem>
      )}
      {displayAddProject && <Divider key='menu-divider' />}
      <Box sx={{ padding: '0 16px 6px' }}>
        <TextField
          value={searchText}
          placeholder={t('search')}
          onChange={handleChangeSearch}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={{
            width: '100%',
          }}
          InputProps={{
            sx: { height: '40px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Icon icon='search' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <MenuList disablePadding sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {searchItems.length > 0 &&
          searchItems.map((item) => (
            <MenuItem key={item.id} onClick={() => onClick(item.id)}>
              {item.name}
            </MenuItem>
          ))}
        {searchItemArchives.length > 0 && (
          <>
            <MenuItem onClick={handleClick}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Trans i18nKey='archived' />
                </Box>{' '}
                {openArchives ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </MenuItem>
            <Collapse in={openArchives} timeout='auto'>
              <MenuList disablePadding sx={{ flexGrow: 1, overflowY: 'auto', paddingInlineStart: '10px' }}>
                {searchItemArchives.map((item) => (
                  <MenuItem key={item.id} onClick={() => onClick(item.id)}>
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Collapse>
          </>
        )}
      </MenuList>
    </Menu>
  );
};
