import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { userState } from '../../../../state/user';

type UseUpdateColumnStateFromUser = () => {
  loading: boolean;
  execute: () => Promise<void>;
  error: unknown;
  data: unknown;
  success: boolean;
  taskId: number;
};

export const useSyncColumnStateFromUserByUpdateHook = (useUpdateColumnState: UseUpdateColumnStateFromUser) => {
  const user = useRecoilValue(userState);
  const { execute: updateColumnsFromUser } = useUpdateColumnState();
  useEffect(() => {
    if (user) {
      updateColumnsFromUser();
    }
  }, [user, updateColumnsFromUser]);
};
