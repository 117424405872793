import { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { notificationState } from '../../state/notification';
import { NOTIFICATION_LIVE_TIME } from '../../state/notification/constants';

export const NotificationStack = () => {
  const notification = useRecoilValue(notificationState);
  const [open, setOpen] = useState(false);

  useEffect(() => { setOpen(true); }, [notification]);

  if (notification) {
    return (
      <Snackbar open={open} autoHideDuration={NOTIFICATION_LIVE_TIME} onClose={() => setOpen(false)}>
        <Alert severity={notification.type} sx={{ width: '100%' }}>
          {notification.text}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};
