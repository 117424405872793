import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { eventService } from '../../../../api';
import { incidentsFilterState } from '../../../../state/incidentsFilter';
import { clearUpdatedIncidents } from '../../../../state/incidentsUpdates/tasks';
import { userState } from '../../../../state/user';
import { getQueryAll } from '../lib/getQueryAll';
import { dashboardsIncidentState } from './atom';

const getDashboardsTask = (rti: RecoilTaskInterface) => async () => {
  const { set, snapshot } = rti;
  const currentUser = snapshot.getLoadable(userState).getValue();

  if (currentUser) {
    const { currentEvent, dashboardSettings } = currentUser;

    set(dashboardsIncidentState, (prevState) => ({
      ...prevState,
      ...(dashboardSettings ? { layoutsSetting: dashboardSettings } : {}),
    }));

    if (currentUser.currentEvent) {
      const filter = snapshot.getLoadable(incidentsFilterState).getValue();
      const query = getQueryAll({ filter, eventId: currentEvent.id });

      const responseData = await eventService.getDashboards({ eventId: currentEvent.id, query });

      set(dashboardsIncidentState, (prevState) => ({
        ...prevState,
        ...(responseData ? { dashboards: responseData } : {}),
      }));
      clearUpdatedIncidents(rti)();
    }
  }
};

export const useDashboards = () =>
  useRecoilTask(getDashboardsTask, [], {
    dataSelector: dashboardsIncidentState,
    errorStack: true,
  });
