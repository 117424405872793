import type { FC } from 'react';
import { useState } from 'react';

import { ListItem, ListItemButton, Stack, ListItemIcon, ListItemText, List, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useDrawer from '../../hooks/useDrawer';
import { currentEventSettings } from '../../state/event';
import kebabCase from '../../utils/kebabCase';
import { Checkbox, DrawerContainer, Button } from '../base';
import { ColumnKey, useSetColumns, columnsState } from '../IncidentTable/state';

export const TableColumnForm: FC = () => {
  const columns = useRecoilValue(columnsState);
  const { t } = useTranslation();
  const { execute: setColumns } = useSetColumns();
  const { availableDailyPlan, availableProjectPlan } = useRecoilValue(currentEventSettings);
  const [currentColumns, setCurrentColumns] = useState(columns);
  const { closeDrawer } = useDrawer();

  const setColumnHandler = (field: ColumnKey) => {
    setCurrentColumns((old) => ({
      ...old,
      [field]: { ...old[field], isShow: !old[field].isShow },
    }));
  };

  const updateColumnsStateHandler = () => {
    setColumns(currentColumns);
    closeDrawer();
  };

  return (
    <DrawerContainer>
      <Stack spacing={2}>
        <Typography variant='h4'>
          <Trans i18nKey='columns' />
        </Typography>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {Object.entries(currentColumns)
            .filter((item) => {
              if (item[0] === ColumnKey.Relations && !availableDailyPlan && !availableProjectPlan) {
                return false;
              }
              return true;
            })
            .map(([field, props]) => {
              const fieldKey = kebabCase(props?.key ?? field) as unknown as Parameters<typeof t>[0];

              return (
                <ListItem key={field} disablePadding>
                  <ListItemButton role={undefined} onClick={() => setColumnHandler(field as ColumnKey)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={props.isShow}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': field }}
                      />
                    </ListItemIcon>
                    <ListItemText id={field} primary={t(fieldKey)} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Stack>

      <Button sx={{ marginTop: 'auto', color: 'white' }} variant='contained' onClick={updateColumnsStateHandler}>
        <Trans i18nKey='apply-settings' />
      </Button>
    </DrawerContainer>
  );
};
