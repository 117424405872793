import type { FC } from 'react';

import { FormControlLabel, Grid, Stack, Typography, TextField } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AVAILABLE_STATUSES } from '../../../constants/base';
import useDrawer from '../../../hooks/useDrawer';
import { TaskType, getTasksWithoutRelation } from '../../../modules/Tasks/state/tasks';
import { currentEventSettings, permissionState } from '../../../state/event';
import { DEFAULT_PREPARED_INCIDENT, useAddIncident } from '../../../state/incidents';
import { locationsState } from '../../../state/locations';
import { NotificationType, useShowNotification } from '../../../state/notification';
import { userFunctionsState } from '../../../state/userFunctions';
import { usersState } from '../../../state/users';
import type { PreparedNewIncident } from '../../../types';
import { ResolutionLevel, Status } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { DrawerContainer, Button, Checkbox } from '../../base';
import { AutocompleteController } from '../../form/Autocomplete';
import {
  SelectController,
  StatusRenderer,
  ResolutionLevelRenderer,
  UserRenderer,
  PhotosController,
} from '../components';
import EndDatetimeController from '../components/EndDatetimeController';
import { MultiUsersController } from '../components/MultiUsersController';
import useUserFunction from '../hooks/useUserFunction';

export const AddIncidentForm: FC = () => {
  const locations = useRecoilValue(locationsState);
  const { t } = useTranslation();
  const users = useRecoilValue(usersState);
  const userFunctions = useRecoilValue(userFunctionsState);
  const permissions = useRecoilValue(permissionState);
  const { execute: addIncident, loading } = useAddIncident();
  const { closeDrawer } = useDrawer();
  const { execute: showNotification } = useShowNotification();
  const tasks = useRecoilValue(getTasksWithoutRelation(null));
  const form = useForm<Omit<PreparedNewIncident, 'children' | 'parent'>>({
    defaultValues: DEFAULT_PREPARED_INCIDENT,
  });
  const eventSettings = useRecoilValue(currentEventSettings);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isDisabled: isUserFunctionDisabled } = useUserFunction(watch('responsible'), setValue, users);

  const onSubmit = (incident: PreparedNewIncident) => {
    addIncident({
      data: incident,
      successCallback: () => {
        closeDrawer();
        showNotification({ type: NotificationType.SUCCESS, text: t('incident-was-added-successfully') });
      },
    });
  };

  const availableResolutionLevels = Object.values(ResolutionLevel).filter(
    (item) => permissions[`canCreateIncidentResolutionLevel${item}`],
  );

  return (
    <FormProvider {...form}>
      <DrawerContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography variant='h4' sx={{ marginBottom: '16px' }}>
            {t('add-new-incident')}
          </Typography>

          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid xs={12} item>
              <Controller
                name='title'
                rules={{ required: true, validate: (value) => Boolean(value.trim()) }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors.title)}
                    placeholder={t('title')}
                    label={t('title')}
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 250,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='description'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t('description')}
                    label={t('description')}
                    fullWidth
                    maxRows={5}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} sm={3} item>
              <SelectController
                name='status'
                label={t('status')}
                required
                data={AVAILABLE_STATUSES[Status.DRAFT]}
                itemRenderer={StatusRenderer}
              />
            </Grid>

            <Grid xs={12} sm={3} item>
              <SelectController
                name='resolutionLevel'
                label={t('level')}
                required
                data={availableResolutionLevels}
                itemRenderer={ResolutionLevelRenderer}
              />
            </Grid>

            <Grid xs={12} sm={6} item>
              <AutocompleteController
                name='responsible'
                label={t('responsible')}
                placeholder={t('responsible')}
                options={users}
                getOptionLabel={(user) => getFullName(user)}
                renderOption={(props, user) => <li {...props}>{UserRenderer(user)}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <MultiUsersController name='participants' label={t('participants')} options={users} />
            </Grid>

            <Grid xs={12} item>
              <AutocompleteController
                disabled={isUserFunctionDisabled}
                name='userFunction'
                label={t('user-function')}
                placeholder={t('user-function')}
                options={userFunctions}
                getOptionLabel={(userFunction) => userFunction.name}
                renderOption={(props, userFunction) => <li {...props}>{userFunction.name}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <AutocompleteController
                name='location'
                label={t('location')}
                placeholder={t('location')}
                options={locations}
                getOptionLabel={(location) => location.title}
                renderOption={(props, location) => <li {...props}>{location.title}</li>}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='locationDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t('location-details')}
                    label={t('location-details')}
                    fullWidth
                    maxRows={5}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <EndDatetimeController name='endDatetime' label={t('end-datetime')} />
            </Grid>

            {(eventSettings.availableDailyPlan || eventSettings.availableProjectPlan) && (
              <Grid xs={12} item>
                <AutocompleteController
                  name='relation'
                  label={t('relations')}
                  placeholder='Relation placeholder'
                  options={[...tasks].sort((a, b) => a.type.localeCompare(b.type))}
                  getOptionLabel={(task) => `${task.count}: ${task.title}`}
                  renderOption={(props, task) => <li {...props}>{`${task.count}: ${task.title}`}</li>}
                  groupBy={(task) => (task.type === TaskType.ProjectTask ? t('project-plan') : t('daily-plan'))}
                />
              </Grid>
            )}

            <Grid xs={12} item>
              <Controller
                name='isResponsibleInformed'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox name='isResponsibleInformed' />}
                    label={t('responsible-informed')}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                name='isUrgent'
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={<Checkbox name='isUrgent' />} label={t('urgent')} {...field} />
                )}
              />
            </Grid>

            <Grid xs={12} sm={12} item>
              <PhotosController />
            </Grid>
          </Grid>
        </Stack>

        <Button
          fullWidth
          disabled={Object.keys(errors).length > 0}
          loading={loading}
          type='submit'
          sx={{ marginTop: 'auto', color: 'white' }}
          variant='contained'
        >
          {t('add-new-incident')}
        </Button>
      </DrawerContainer>
    </FormProvider>
  );
};
