import type { Geo, GeoCoordinates } from '../../../../types';

type PartialGeo = { geo?: Geo | GeoCoordinates };

export type WithGeo<T> = T & { geo: Geo | GeoCoordinates };

export function getGeo(entity: { geo: Geo | GeoCoordinates }) {
  return [entity.geo.longitude, entity.geo.latitude];
}

export function isWithGeo<T extends PartialGeo>(item: T): item is WithGeo<T> {
  return Boolean(item.geo);
}

export function onlyWithGeo<T extends PartialGeo>(items: T[]): WithGeo<T>[] {
  return items.filter(isWithGeo);
}
