import type { Layouts } from 'react-grid-layout';
import { atom } from 'recoil';

import type { DashboardDataSet } from '../../../../shared/lib/types/dashborad.types';
import { initialLayoutsSettings } from '../config/layoutSettings';

interface DashboardsPrjectPlanState {
  dashboards: DashboardDataSet[];
  layoutsSetting: Layouts;
}

export const dashboardsPrjectPlanState = atom<DashboardsPrjectPlanState>({
  key: 'dashboardsPrjectPlan',
  default: {
    dashboards: [],
    layoutsSetting: initialLayoutsSettings,
  },
});
