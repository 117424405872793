import { useTranslation } from 'react-i18next';

import type { Location } from '../../../types';
import { AutocompleteMultipleField, type AutocompleteMultipleFieldProps } from '../../fields/AutocompleteMultipleField';
import type { FormIncidentsFilter } from '../lib/types';

export type LocationFieldProps = Omit<
  AutocompleteMultipleFieldProps<FormIncidentsFilter, 'locations', Location>,
  'name' | 'label' | 'getOptionLabel' | 'renderOption'
>;

export const LocationField = (props: LocationFieldProps) => {
  const { t } = useTranslation();

  return (
    <AutocompleteMultipleField
      {...props}
      name='locations'
      getOptionLabel={(location) => location.title}
      label={t('location')}
      renderOption={(liProps, location) => <li {...liProps}>{location.title}</li>}
    />
  );
};
