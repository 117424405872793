import { useEffect } from 'react';

import { useCallbackPrompt } from './useCallbackPrompt';
import { useConfirmDialog } from './useConfirmDialog';

export default function useConfirmLeavePage(hasNotSavedChanges: boolean, textMessage: string) {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    hasNotSavedChanges,
  ) as [boolean, () => void, () => void];
  const { showConfirm, confirmModal } = useConfirmDialog({ content: textMessage });

  useEffect(() => {
    if (showPrompt) {
      showConfirm().then((isOk) => {
        if (isOk) {
          confirmNavigation();
        } else {
          cancelNavigation();
        }
      });
    }
  }, [showPrompt, confirmNavigation, cancelNavigation, showConfirm]);

  return {
    confirmModal,
  };
}
