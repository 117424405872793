import { TextField } from '@mui/material';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { userFunctionsState } from '../../../state/userFunctions';
import { AutocompleteMultiple } from '../../AutocompleteMultiple';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

type UserFunctionsFieldProps = {
  form: UseFormReturn<UserEventSettingsInitialValues>;
};

export const UserFunctionsField = ({ form }: UserFunctionsFieldProps) => {
  const { t } = useTranslation();
  const userFunctions = useRecoilValue(userFunctionsState);

  return (
    <Controller
      name='filters.userFunctions'
      control={form.control}
      render={({ field }) => (
        <AutocompleteMultiple
          {...field}
          disableCloseOnSelect
          options={userFunctions}
          limitTags={4}
          filterSelectedOptions
          onChange={(_, values) => field.onChange(values === 'all' ? userFunctions : values)}
          getOptionLabel={(userFunction) => userFunction.name}
          renderInput={(params) => <TextField {...params} label={t('user-function')} />}
          renderOption={(props, userFunction) => <li {...props}>{userFunction.name}</li>}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      )}
    />
  );
};
