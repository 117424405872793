import type { TFunction } from 'i18next';
import type { CellDef } from 'jspdf-autotable';
import {
  indexBy, isObject, isString, mapValues,
} from 'remeda';

import type { FieldValueCellProps } from '../../../components/IncidentLog/components/utils';
import { convertCreateIncidentToFieldValue, fieldNameT } from '../../../components/IncidentLog/components/utils';
import { multiUsersType } from '../../../modules/EntitiesGrid/cellTypes';
import type { CreateActionDescription, Location, UserFunction } from '../../../types';
import formatDate from '../../../utils/formatDate';
import getFullName from '../../../utils/getFullName';
import getStatusTitle from '../../../utils/getStatusTitle';

const PHOTOS_PREFIX = '__PHOTOS__';

export type HeaderItem = {
  header: string,
  dataKey: string,
  width: number,
}

export type BodyRowItem = {
  [key: string]: string | CellDef;
}

export const createHeader = (t: TFunction<'translation'>, tableWidth: number) => ([
  {
    header: t('field'),
    dataKey: 'field',
    width: tableWidth * 0.2,
  },
  {
    header: t('value'),
    dataKey: 'value',
    width: tableWidth * 0.8,
  },
]);

export const isPhotosCell = (text = '') => text.startsWith(PHOTOS_PREFIX);

export const parsePhotosCell = (text: string) => text
  .replace(PHOTOS_PREFIX, '')
  .split(',')
  .map((id) => parseInt(id, 10));

export const convertFieldValueCellToBodyRow = (
  pair: FieldValueCellProps,
  t: TFunction<'translation'>,
  locations: Record<number, Location>,
  userFunctions: Record<number, UserFunction>,
) => {
  const { name, value } = pair;
  const field = t(fieldNameT(name));
  if (value === undefined || value === null || value === '') {
    return {
      field,
      value: '-',
    };
  }
  switch (name) {
    case 'status':
      return { field, value: getStatusTitle(value, t) };
    case 'responsible':
    case 'registeredBy':
      return { field, value: getFullName(value) };
    case 'resolutionLevel':
      return { field, value: t(value.toLowerCase()) };
    case 'location':
      return { field, value: locations[value]?.title };
    case 'userFunction':
      return { field, value: userFunctions[value]?.name };
    case 'photos':
      return {
        field,
        value: {
          content: `${PHOTOS_PREFIX}${value.map((photo) => photo.id).join(',')}`,
          styles: { minCellHeight: 80, textColor: '#f5f5f5' },
        },
      };
    case 'isUrgent':
    case 'isResponsibleInformed':
      return { field, value: value ? t('yes') : t('no') };
    case 'registeredDate':
    case 'resolvedDate':
    case 'endDatetime':
      return { field, value: formatDate(value) };
    case 'participants':
      return { field, value: multiUsersType.toString(value) };
    default:
      return { field, value };
  }
};

export const addWidthToCell = (rows: BodyRowItem[], header: HeaderItem[]) => {
  const headerByKey = indexBy(header, (headerCell) => headerCell.dataKey);
  return rows.map((row) => {
    const newRow = mapValues(row, (value, key) => {
      const headerCell = headerByKey[key] as HeaderItem;

      if (isString(value)) {
        return {
          content: value,
          styles: {
            cellWidth: headerCell.width,
          },
        };
      }
      if (isObject(value)) {
        return {
          ...value,
          styles: {
            ...(value.styles || {}),
            cellWidth: headerCell.width,
          },
        };
      }
      return value;
    });
    return newRow;
  });
};

export const convertAttrsToRows = (
  incident: CreateActionDescription['attrs'],
  t: TFunction<'translation'>,
  locations: Record<number, Location>,
  userFunctions: Record<number, UserFunction>,
  header: HeaderItem[],
) => {
  const fieldValuePairs = convertCreateIncidentToFieldValue(incident);
  const bodyRows = fieldValuePairs.map((pair) => convertFieldValueCellToBodyRow(pair, t, locations, userFunctions));

  return addWidthToCell(bodyRows, header);
};
