import type { ChangeEvent, KeyboardEvent } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';

import type { TextFieldProps } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';

import { TextField } from '../../../base';

type Props = ICellEditorParams & {
  inputProps: TextFieldProps['inputProps'];
};

const TextEditor = forwardRef((props: Props, ref) => {
  const cellData: string = props.value;
  const columnId = props.column?.getColId();
  const [text, setText] = useState<string>(cellData);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      props.stopEditing();
    }
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      if (columnId === 'title') {
        return (text || cellData).trim();
      }

      return text.trim();
    },
  }));

  const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // https://github.com/mui/material-ui/issues/12779#issuecomment-829085601
    const lengthOfInput = event.target.value.length;
    return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
  };

  return (
    <TextField
      autoFocus
      sx={{ width: '400px' }}
      maxRows={5}
      multiline
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={keyDownHandler}
      value={text}
      inputProps={props.inputProps}
    />
  );
});

export default TextEditor;
