import { fromPairs, omit, toPairs } from 'remeda';

import type { EditIncident, Incident } from '../../../types';
import { ColumnKey } from '../../IncidentTable/state';
import { mapColumnKeyToField } from '../../IncidentTable/state/constants';
import { cellСanBeEdited } from '../../IncidentTable/utils';

const changedFields = [
  ColumnKey.Urgent,
  ColumnKey.Title,
  ColumnKey.Description,
  ColumnKey.Status,
  ColumnKey.ResolutionLevel,
  ColumnKey.UserFunction,
  ColumnKey.Location,
  ColumnKey.LocationDescription,
  ColumnKey.Responsible,
  ColumnKey.ResponsibleInformed,
  ColumnKey.Photos,
  ColumnKey.Comments,
  ColumnKey.Participants,
  ColumnKey.EndDatetime,
  ColumnKey.Relations,
];

type UseCanBeEditedFieldsProps = {
  incident: Incident;
  canUpdateIncident: boolean | undefined;
  canUpdateResolvedIncident: boolean | undefined;
  canUpdateAllIncidents: boolean | undefined;
  canUpdateResolvedIncidentComment: boolean | undefined;
  canCreateComment: boolean | undefined;
  currentUserId: number | undefined;
};

const useCanBeEditedFields = ({
  incident,
  canUpdateIncident,
  canUpdateResolvedIncident,
  canUpdateAllIncidents,
  canUpdateResolvedIncidentComment,
  canCreateComment,
  currentUserId,
}: UseCanBeEditedFieldsProps) => {
  const pairs = changedFields.map(
    (field) =>
      [
        mapColumnKeyToField(field),
        cellСanBeEdited({
          data: incident,
          colDef: {
            field,
            cellEditorParams: {
              permissions: {
                canUpdateIncident,
                canUpdateResolvedIncident,
                canUpdateAllIncidents,
                canUpdateResolvedIncidentComment,
                canCreateComment,
              },
              currentUserId,
            },
          },
        }),
      ] as [string, boolean],
  );
  const fields = fromPairs(pairs);

  return {
    fields,
    formCanBeEdited: Object.values(omit(fields, ['comments'])).some(Boolean),
  };
};

export const omitDisabledFields = (fieldsCanBeEdited: Record<string, boolean>, data: EditIncident) => {
  let disabledFields = toPairs(fieldsCanBeEdited)
    .filter(([, canBeEdited]) => !canBeEdited)
    .map(([field]) => field) as unknown as (keyof Omit<EditIncident, 'id'>)[];

  if (!fieldsCanBeEdited.photos) {
    disabledFields = [...disabledFields, 'photoSources'];
  }
  return omit(data, disabledFields);
};

export default useCanBeEditedFields;
