import { useTranslation } from 'react-i18next';

import { CheckboxField, type CheckboxFieldProps } from '../../fields/CheckboxField';
import type { FormIncidentsFilter } from '../lib/types';

type AssignedToMeFieldProps = Omit<CheckboxFieldProps<FormIncidentsFilter, 'isAssignedToMe'>, 'name' | 'label'>;
export const AssignedToMeField = (props: AssignedToMeFieldProps) => {
  const { t } = useTranslation();

  return <CheckboxField {...props} name='isAssignedToMe' label={t('assigned-to-me')} />;
};
