import { useCallback } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { permissionState } from '../../state/event';
import { useExportToExcel, useExportToPdfTable, useExportToPdfCards } from '../../state/export';
import { incidentsFilterState, useChangeFilterByTimeDate } from '../../state/incidentsFilter';
import { MenuButton } from '../base';

export const AdvancedMenuIncident = () => {
  const { t } = useTranslation();
  const { canDownloadExcel } = useRecoilValue(permissionState);
  const { execute: exportToExcel, loading: isLoadingExportToExcel } = useExportToExcel();
  const { execute: exportToPdfTable, loading: isLoadingExportToPdfTable } = useExportToPdfTable();
  const { execute: exportToPdfCards, loading: isLoadingExportToPdfCards } = useExportToPdfCards();
  const { execute: changeFilterByTimeDate } = useChangeFilterByTimeDate();
  const { hideDateTime: showDateTime } = useRecoilValue(incidentsFilterState);

  const exportToExcelCallback = useCallback(() => exportToExcel(t), [exportToExcel, t]);
  const exportToPdfTableCallback = useCallback(() => exportToPdfTable(t), [exportToPdfTable, t]);
  const exportToPdfCardsCallback = useCallback(() => exportToPdfCards(t), [exportToPdfCards, t]);
  const onChangeTimeDate = useCallback(
    () => changeFilterByTimeDate(!showDateTime),
    [changeFilterByTimeDate, showDateTime],
  );

  const loading = isLoadingExportToExcel || isLoadingExportToPdfTable || isLoadingExportToPdfCards;

  return (
    <MenuButton
      buttonContent={<MenuIcon />}
      isLoading={loading}
      commands={[
        {
          content: <Trans i18nKey={showDateTime ? 'enable-time-date' : 'disable-time-date'} />,
          onClick: onChangeTimeDate,
        },
        {
          content: <Trans i18nKey='export-to-excel' />,
          onClick: exportToExcelCallback,
          disabled: !canDownloadExcel,
        },
        { content: <Trans i18nKey='export-table' />, onClick: exportToPdfTableCallback },
        { content: <Trans i18nKey='export-cards' />, onClick: exportToPdfCardsCallback },
      ]}
    />
  );
};
