/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ResolutionLevel } from '../../types';
import getResolutionLevelTitle from '../../utils/getResolutionLevelTitle';
import { getResolutionLevelColor } from '../../utils/resolutionLevelColor';

type Props = {
  level: ResolutionLevel;
  sx?: SxProps<Theme>;
};

const LEVEL_PROPS = {
  [ResolutionLevel.AMBER]: { color: getResolutionLevelColor(ResolutionLevel.AMBER), count: 2 },
  [ResolutionLevel.GREEN]: { color: getResolutionLevelColor(ResolutionLevel.GREEN), count: 1 },
  [ResolutionLevel.ORANGE]: { color: getResolutionLevelColor(ResolutionLevel.ORANGE), count: 3 },
  [ResolutionLevel.RED]: { color: getResolutionLevelColor(ResolutionLevel.RED), count: 4 },
};

const Eclipse = ({ color }: { color: string }) => (
  <Box
    sx={{
      bgcolor: color,
      width: '10px',
      height: '10px',
      borderRadius: '100%',
    }}
  />
);

const ResolutionLevelBlock: FC<Props> = ({ level, sx = {} }) => {
  const { count, color } = LEVEL_PROPS[level];
  const { t } = useTranslation();
  return (
    <Tooltip placement='bottom' disableInteractive title={getResolutionLevelTitle(level, t)}>
      <Stack direction='row' spacing='4px' sx={{ padding: '8px', ...sx }}>
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <Eclipse key={index} color={color} />
          ))}
      </Stack>
    </Tooltip>
  );
};

export default ResolutionLevelBlock;
