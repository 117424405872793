import { type PropsWithChildren } from 'react';

import { Box, Stack } from '@mui/material';

import { Clock } from '../base';
import { useFullScreen } from './FullScreenContext';

export const FullScreen = ({ children }: PropsWithChildren) => {
  const { ref } = useFullScreen();

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        height: '100%',

        ':fullscreen .timer': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <Stack>
        <Box
          p={1}
          className='timer'
          sx={{
            display: 'none',
          }}
        >
          <Clock inline />
        </Box>
      </Stack>
      {children}
    </Box>
  );
};
