import { useRecoilTask, type RecoilTaskInterface } from 'recoil-toolkit';

import { projectTasksFiltersState } from '../../../modules/Tasks/state/projectTasks';
import { userState } from '../../../state/user';
import { transformFilterForBackend } from '../lib/transformFilterForBackend';
import type { ProjectTasksFormFilerValue } from '../lib/types';

const getProjectTaskByFilters = (rti: RecoilTaskInterface) => async (filters: ProjectTasksFormFilerValue) => {
  const { set, snapshot } = rti;
  const currentUser = snapshot.getLoadable(userState).getValue();
  await set(projectTasksFiltersState, (prevValue) => ({
    ...prevValue,
    ...transformFilterForBackend({
      filters,
      currentUser,
    }),
  }));
};

export const useGetProjectTaskByFilters = () =>
  useRecoilTask(getProjectTaskByFilters, [], {
    loaderStack: 'getProjectTaskByFilters',
    errorStack: true,
  });
