type ChartDatasetItem = {
  value: string;
  count: number;
};

export type KeyNameType = {
  keyName: string;
};

export enum DashboardKey {
  LOCATION = 'location',
  RESOLUTION_LEVEL = 'resolutionLevel',
  STATUS = 'status',
  TOP_RESPONSIBLES = 'topResponsibles',
  USER_FUNCTIONS = 'userFunctions',
  REGISTERD_VS_RESOLVED = 'registeredVsResolved',
  ACTIVE_VS_RESOLVED = 'activeVsResolved',
}

export enum ChartTypes {
  DOUGHNUT_CHART = 'doughnut',
  HORIZONTAL_BAR_CHART = 'horizontalBarChart',
  BAR_CHART = 'barChart',
  LINE_CHART = 'lineChart',
  LINEAR_PROGRESS_CHART = 'linearProgress',
}

export type DoughnutChartDataType = {
  type: ChartTypes.DOUGHNUT_CHART;
  key: DashboardKey.STATUS | DashboardKey.RESOLUTION_LEVEL;
  dataset: ChartDatasetItem[];
  total: number;
};

interface HorizontalBarChartDataType {
  type: ChartTypes.HORIZONTAL_BAR_CHART;
  key: DashboardKey.USER_FUNCTIONS | DashboardKey.TOP_RESPONSIBLES | DashboardKey.LOCATION;
  dataset: ChartDatasetItem[];
  total: number;
}

interface LineChartDataType {
  type: ChartTypes.LINE_CHART;
  key: DashboardKey.REGISTERD_VS_RESOLVED;
  dataset: {
    [key: string]: number[];
  };
  total: number;
}

interface LinearProgressChartDataType {
  type: ChartTypes.LINEAR_PROGRESS_CHART;
  key: DashboardKey.ACTIVE_VS_RESOLVED;
  dataset: {
    [key: string]: number;
  }[];
  total: number;
}

export type DashboardDataSet =
  | DoughnutChartDataType
  | HorizontalBarChartDataType
  | LineChartDataType
  | LinearProgressChartDataType;
