import { useCallback } from 'react';

import { useCurrentUser } from '../../../hooks';
import type { IsNewRowEntityFn } from '../../newEntity';
import type { ColDefEditableFnParams } from '../types';
import { editableResolved, editableStaff, editableUserFunction } from './utils';

type Params = {
  isPreparedEntity: IsNewRowEntityFn;
  nonEditableFields: string[];
  nonEditableOnPreparedFields: string[];
  permissions: {
    canUpdateResolvedTasks: boolean;
    canUpdateAllTask: boolean;
    canUpdateTask: boolean;
    canCreateComment: boolean;
  };
};

export const useEditable = <E extends object>({
  isPreparedEntity,
  nonEditableFields,
  nonEditableOnPreparedFields,
  permissions,
}: Params) => {
  const { currentUserId } = useCurrentUser();
  const { canUpdateAllTask, canUpdateResolvedTasks, canUpdateTask, canCreateComment } = permissions;

  const editableFn = useCallback(
    ({ data, colDef }: ColDefEditableFnParams<E>) => {
      const field = colDef?.field;
      if (!field) {
        return false;
      }
      const nonEditableAlways = nonEditableFields.includes(field);
      const userFunctionCheck = editableUserFunction({ data, field });
      const resolvedCheck =
        field === 'comments' ? canCreateComment : editableResolved({ data, canUpdateResolvedTasks });
      const staffCheck = editableStaff({
        data,
        canUpdateAllTask,
        canUpdateTask,
        currentUserId,
        field,
      });

      if (nonEditableAlways || !userFunctionCheck) {
        return false;
      }

      if (isPreparedEntity(data)) {
        return !nonEditableOnPreparedFields.includes(field);
      }

      return resolvedCheck && staffCheck;
    },
    [
      nonEditableFields,
      canCreateComment,
      canUpdateResolvedTasks,
      canUpdateAllTask,
      canUpdateTask,
      currentUserId,
      isPreparedEntity,
      nonEditableOnPreparedFields,
    ],
  );

  return {
    editable: editableFn,
  };
};
