import { type CellClassParams } from 'ag-grid-community';

import { Status } from '../../../types';
import { checkIsPrepareChildField } from '../../Tasks/components/ProjectTasksGrid/lib/checkIsPrepareChildField';

export function defaultCellStyles({ colDef, data }: CellClassParams) {
  const isEditable = colDef.editable && data.status !== Status.RESOLVED;
  return {
    cursor: isEditable ? 'pointer' : 'default',
    ...(checkIsPrepareChildField(data.id) ? { backgroundColor: 'rgba(242, 168, 97, 0.3)' } : {}),
  };
}
