import { atom } from 'recoil';

import type {
  ColumnsSettings, GridSort, UseColumnSettingsParams, UseColumnSettingsSortParams,
} from './types';

export const columnsSettingsState = <T extends string>(
  params: UseColumnSettingsParams<T>,
) => atom<ColumnsSettings<T>>(params);

export const columnsSettingsSortState = (
  params: UseColumnSettingsSortParams,
) => atom<GridSort>(params);
