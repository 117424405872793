import { cookieService } from '../api';
import { TOKEN_KEY } from '../constants/base';

export const getAuthToken = () => cookieService.get(TOKEN_KEY);

export const setAuthToken = (token: string) => {
  cookieService.set(TOKEN_KEY, token);
};

export const removeAuthToken = async () => {
  cookieService.remove(TOKEN_KEY);
};
