import * as qs from 'qs';

import type {
  NewUser, Role, User, UserUpdate,
} from '../types';
import requestService from './requestService';

const getUsersFilters = (eventId: number) =>
  `filters[events][id]=${eventId}`;

const userService = {
  getMe: () => requestService
    .get<User>('/users/me')
    .then((response) => response.data),
  getUsers: ({ eventId }: { eventId: number; }) => requestService
    .get<User[]>(`/users?${getUsersFilters(eventId)}`)
    .then((response) => response.data),
  update: ({ id, data }: { id: number, data: UserUpdate }) => requestService
    .put<User>(`/users/${id}`, data)
    .then((response) => response.data),
  changeCurrentEvent: ({ id, currentEvent }: { id: number, currentEvent: number | null }) => requestService
    .put<User>(`/users/${id}`, { currentEvent })
    .then((response) => response.data),
  changeLanguage: ({ id, language }: { id: User['id'], language: User['language'] }) => requestService
    .put<User>(`/users/${id}`, { language })
    .then((response) => response.data),
  changeSendNotification: ({ id, notificationSettings }: { id: User['id'], notificationSettings: User['notificationSettings'] }) => requestService
    .put<User>(`/users/${id}`, { notificationSettings })
    .then((response) => response.data),
  getRoles: () => requestService
    .get<{roles: Role[]}>('/users-permissions/roles')
    .then((response) => response.data),
  register: ({ data }: { data: NewUser & { role: number, currentEvent: number, isMobile: boolean } }) => requestService
    .post<{ user: {id: number} }>('/auth/local/register', data)
    .then((response) => response.data),
  resendRegistrationEmail: ({ id, event }: { id: number, event: number }) => requestService
    .post('/auth/local/resend-registration-email', { id, event })
    .then((response) => response.data),
  findUserByEmail: ({ email }: { email: string }) => requestService
    .get<User[]>('/users', {
      params: {
        filters: {
          email,
        },
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((response) => response.data),
};

export default userService;
