import { atom } from 'recoil';

import type { DrawerState } from './types';

export const drawerState = atom<DrawerState>({
  key: 'drawer',
  default: {
    drawer: undefined,
    isOpen: false,
  },
});
