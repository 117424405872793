import { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import type { LocationEditableFields, LocationForm as LocationFormType } from '../../../../../state/locationsForm';
import {
  locationsControlState,
  isEqualLocations,
  TypeLocation,
  DEFAULT_LOCATION,
  updateLocationsControl,
} from '../../../../../state/locationsForm';

export type MapFormLocationProps = {
  locationForm: LocationFormType;
  onEndAction: () => void;
};

function useMapFormLocation({ locationForm, onEndAction }: MapFormLocationProps) {
  const [locationsControl, setLocationsControl] = useRecoilState(locationsControlState);
  const currentLocation = locationsControl.find((location) => isEqualLocations(location, locationForm));
  const isAddedLocation = locationForm.type === TypeLocation.New && !currentLocation;
  const form = useForm<LocationEditableFields>({
    defaultValues: currentLocation || DEFAULT_LOCATION,
  });
  const { handleSubmit, control } = form;
  const onFormSubmit = useCallback(
    (locationFields: LocationEditableFields) => {
      setLocationsControl((prevLocationsControl) => {
        if (isAddedLocation) {
          return [
            ...prevLocationsControl,
            {
              ...locationForm,
              ...locationFields,
            },
          ];
        }
        return updateLocationsControl(prevLocationsControl, locationForm, (prevLocation) => ({
          ...prevLocation,
          ...locationFields,
        }));
      });
      onEndAction();
    },
    [onEndAction, setLocationsControl, locationForm, isAddedLocation],
  );
  const onDelete = useCallback(() => {
    setLocationsControl((prevLocationsControl) =>
      prevLocationsControl.filter((location) => !isEqualLocations(location, locationForm)),
    );
    onEndAction();
  }, [setLocationsControl, locationForm, onEndAction]);

  return {
    onDelete,
    onSubmit: handleSubmit(onFormSubmit),
    isAddedLocation,
    control,
    form,
  };
}

export default useMapFormLocation;
