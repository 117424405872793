/* eslint-disable react/destructuring-assignment */
import { memo } from 'react';

import type { ICellRendererParams } from 'ag-grid-community';
import { isSameDay } from 'date-fns';

import formatDate from '../../../../utils/formatDate';
import formatTime from '../../../../utils/formatTime';
import { ClampedTypography } from '../../../base';

export type DateRendererProps = ICellRendererParams & {
  hideDateTime?: boolean;
};

const DateRenderer = ({ value, data, hideDateTime }: DateRendererProps) => {
  const date: Date | undefined = value;

  if (!date) {
    return null;
  }

  const isSameDayDate = 'day' in data && data.day && isSameDay(data.day, date);

  return (
    <ClampedTypography variant='date' lineclamp={4}>
      {isSameDayDate ? formatTime(date) : formatDate(date, hideDateTime)}
    </ClampedTypography>
  );
};

export default memo(DateRenderer);
