import { atom } from 'recoil';

import type { Location, GeoCoordinates, Geo } from '../types';
import updateArray from '../utils/updateArray';

export enum TypeLocation {
  New = 'new',
  Edit = 'edit',
  Delete = 'delete',
}

export type LocationEditableFields = Pick<Location, 'title' | 'description'>;

type NewLocationForm = {
  type: TypeLocation.New,
  key: string,
  geo: GeoCoordinates,
};

type EditLocationForm = {
  type: TypeLocation.Edit,
  id: number,
  geo: Geo,
};

type DeleteLocationForm = {
  type: TypeLocation.Delete,
  id: number,
  geo: GeoCoordinates,
};

export type LocationForm = NewLocationForm | EditLocationForm | DeleteLocationForm;
export type LocationControlItem = LocationForm & LocationEditableFields;

export const isEditLocationForm = (form: LocationForm): form is EditLocationForm => 'id' in form && 'id' in form.geo;

export function isEqualLocations<T extends LocationForm>(locationA: T, locationB: T): boolean {
  if (locationA.type === TypeLocation.New && locationB.type === TypeLocation.New) {
    return locationA.key === locationB.key;
  }
  if (locationA.type !== TypeLocation.New && locationB.type !== TypeLocation.New) {
    return locationA.id === locationB.id;
  }
  return false;
}

export function updateLocationsControl(
  locationsControl: LocationControlItem[],
  locationForm: LocationForm,
  updater: (item: LocationControlItem) => LocationControlItem,
) {
  return updateArray(
    locationsControl,
    (location) => isEqualLocations(location, locationForm),
    updater,
  );
}

export const DEFAULT_LOCATION = {
  title: '',
  description: '',
};

export const locationsControlState = atom<LocationControlItem[]>({
  key: 'locationsControl',
  default: [],
});
