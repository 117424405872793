import type { FC } from 'react';
import { useEffect } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useExportToPdfLogs } from '../../state/export';
import { useIncidentLogsList } from '../../state/incidentLogs/hooks';
import type { Incident } from '../../types';
import formatDate from '../../utils/formatDate';
import { ClampedTypography, Icon, UserChip } from '../base';
import { AddCommentAction } from './components/AddCommentAction';
import { CreateIncidentAction } from './components/CreateIncidentAction';
import { EditIncidentAction } from './components/EditCommentAction';
import { RemoveIncidentAction } from './components/RemoveCommentAction';
import { UpdateIncidentAction } from './components/UpdateIncidentAction';
import { actionTitle } from './components/utils';

type IncidentLogProps = {
  incident: Incident;
  onBack: () => void;
};

export const IncidentLog: FC<IncidentLogProps> = ({ incident, onBack }) => {
  const incidentId = incident.id;
  const { data, execute } = useIncidentLogsList(incident.id);
  const { t } = useTranslation();
  const { execute: exportToPdf } = useExportToPdfLogs();
  const onClickExport = () => {
    exportToPdf(t, Number(incident.count), data);
  };
  useEffect(() => {
    execute(incidentId);
  }, [incidentId, execute]);
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction='row' justifyContent='space-between'>
        <IconButton style={{ width: '50px' }} size='large' onClick={onBack} color='primary'>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ marginBottom: '16px' }}>
          {t('incident-history')}
          &nbsp;#
          {incident.count}
        </Typography>
        <IconButton style={{ width: '50px' }} size='large' onClick={onClickExport} color='primary'>
          <Icon icon='pdf' htmlColor='#AA96C8' sx={{ width: '17px', height: '17px' }} />
        </IconButton>
      </Stack>
      <div>
        {data.map((log) => {
          const panelId = `panel-${log.id}`;
          const actionTitleText = actionTitle[log.actionDescription.type](t);

          let actionView;

          switch (log.actionDescription.type) {
            case 'addComment':
              actionView = <AddCommentAction actionDescription={log.actionDescription} />;
              break;
            case 'update':
              actionView = <UpdateIncidentAction actionDescription={log.actionDescription} />;
              break;
            case 'editComment':
              actionView = <EditIncidentAction actionDescription={log.actionDescription} />;
              break;
            case 'deleteComment':
              actionView = <RemoveIncidentAction actionDescription={log.actionDescription} />;
              break;
            default:
              actionView = <CreateIncidentAction actionDescription={log.actionDescription} />;
              break;
          }

          return (
            <Accordion key={log.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={panelId}
                id={panelId}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    minWidth: 0,
                  },
                }}
              >
                <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='center' minWidth={0}>
                  <UserChip user={log.user} />
                  <Typography sx={{ flexShrink: 0 }}>{actionTitleText}</Typography>
                  <ClampedTypography variant='date' lineclamp={4} sx={{ flexShrink: 0 }}>
                    {formatDate(log.createdAt)}
                  </ClampedTypography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>{actionView}</AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Stack>
  );
};
