import { forwardRef, useImperativeHandle, useState } from 'react';

import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import type { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import type { ICellEditorParams, GridApi, RowNode } from 'ag-grid-community';

import { DATETIME_FORMAT } from '../../../../constants/base';
import { TextField } from '../../../base';
import { useFullScreen } from '../../../FullScreen';

type Props = {
  maxDateTime?: string;
  minDateTime?: string;
  inputFormat?: string;
  onChangeValue?: ({ value, node, api }: { value: unknown; node: RowNode; api: GridApi }) => void;
};

const DateEditor = forwardRef(
  (
    {
      value,
      data,
      maxDateTime,
      minDateTime,
      inputFormat = DATETIME_FORMAT.full,
      onChangeValue,
      node,
      api,
    }: ICellEditorParams & Props,
    ref,
  ) => {
    const { ref: refFullScreen } = useFullScreen();
    const cellData: unknown = value;
    const dayDate = 'day' in data && data.day;
    const now = Date.now();
    const [currentDate, setCurrentDate] = useState(cellData || dayDate || now);
    const [currentError, setCurrentError] = useState<DateTimeValidationError>(null);
    const maxDateTimeValue = maxDateTime && data[maxDateTime];
    const minDateTimeValue = minDateTime && data[minDateTime];

    useImperativeHandle(
      ref,
      () => ({
        getValue() {
          if (currentError) {
            return maxDateTimeValue || minDateTimeValue;
          }
          return currentDate;
        },
      }),
      [currentDate, currentError, maxDateTimeValue, minDateTimeValue],
    );

    const handleChange = (newValue: unknown) => {
      setCurrentDate(newValue);
      onChangeValue?.({
        value: newValue,
        node,
        api,
      });
    };

    return (
      <>
        {inputFormat === DATETIME_FORMAT.full && (
          <DateTimePicker
            PopperProps={{
              container: refFullScreen.current,
              className: 'ag-custom-component-popup',
            }}
            DialogProps={{
              className: 'ag-custom-component-popup',
            }}
            minDateTime={minDateTimeValue}
            maxDateTime={maxDateTimeValue}
            value={currentDate}
            ampm={false}
            inputFormat={inputFormat}
            onError={(reason) => setCurrentError(reason)}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
        {inputFormat === DATETIME_FORMAT.short && (
          <DatePicker
            PopperProps={{
              container: refFullScreen.current,
              className: 'ag-custom-component-popup',
            }}
            DialogProps={{
              className: 'ag-custom-component-popup',
            }}
            minDate={minDateTimeValue}
            maxDate={maxDateTimeValue}
            value={currentDate}
            inputFormat={inputFormat}
            onError={(reason) => setCurrentError(reason)}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </>
    );
  },
);

export default DateEditor;
