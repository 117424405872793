import type { RecoilTaskInterface } from 'recoil-toolkit';

import { userService } from '../../api';
import { currentEventIdState } from '../event';
import { usersState } from './atoms';

export const getUsersTask = ({ set, snapshot }: RecoilTaskInterface) => async () => {
  const eventId = snapshot.getLoadable(currentEventIdState).getValue();
  if (eventId) {
    const users = await userService.getUsers({ eventId });
    set(usersState, users);
  }
};
