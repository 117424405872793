import { useMemo } from 'react';

import { Drawer } from '@mui/material';

import { useChangeLocation } from '../../hooks/useChangeLocation';
import useDrawer from '../../hooks/useDrawer';
import { MapFormIncident } from '../../modules/Map/feature/IncidentMap';
import { MapFormLocation } from '../../modules/Map/feature/LocationMap';
import { ProjectTasksColumnsSettingsDrawer, DailyTasksColumnsSettingsDrawer } from '../../modules/Tasks';
import { ProjectTaskForm } from '../../modules/Tasks/components/ProjectTaskForm/ProjectTaskForm';
import type { DrawerType } from '../../state/drawer';
import { Drawer as DrawerEnum } from '../../state/drawer';
import { DrawerNavigation } from '../DrawerNavigation';
import { FilterForm } from '../FilterForm';
import { FilterFormDailyTasks } from '../FilterFormDailyTasks';
import { FilterFormProjectTasks } from '../FilterFormProjectTasks';
import { useFullScreen } from '../FullScreen';
import { AddIncidentForm } from '../IncidentForm';
import { EditIncidentTableForm } from '../IncidentForm/EditIncidentForms';
import { IncidentUpdatesForm } from '../IncidentUpdatesForm';
import { PhotosAddForm, PhotosEditForm } from '../PhotosEditorForm';
import { TableColumnForm } from '../TableColumnForm';
import { UserCreateOrAddForm } from '../UserCreateOrAddForm/UserCreateOrAddForm';
import { UserEditForm } from '../UserEditForm';
import { UserFormSettings } from '../UserFormSettings';

const getDrawerForm = (drawer: DrawerType, closeDrawer: () => void) => {
  switch (drawer.type) {
    case DrawerEnum.INCIDENT_PHOTO_EDIT: {
      return <PhotosEditForm {...drawer.props} onEndAction={closeDrawer} />;
    }
    case DrawerEnum.INCIDENT_PHOTO_ADD: {
      return <PhotosAddForm {...drawer.props} onEndAction={closeDrawer} />;
    }
    case DrawerEnum.INCIDENT_ADD: {
      return <AddIncidentForm />;
    }
    case DrawerEnum.INCIDENT_EDIT: {
      return (
        <DrawerNavigation>
          <EditIncidentTableForm {...drawer.props} onEndAction={closeDrawer} />
        </DrawerNavigation>
      );
    }
    case DrawerEnum.INCIDENT_TABLE_COLUMNS: {
      return <TableColumnForm />;
    }
    case DrawerEnum.MAP: {
      return (
        <DrawerNavigation>
          <MapFormIncident {...drawer.props} onEndAction={closeDrawer} />
        </DrawerNavigation>
      );
    }
    case DrawerEnum.INCIDENT_FILTER: {
      return <FilterForm />;
    }
    case DrawerEnum.EVENT_LOCATION: {
      return <MapFormLocation {...drawer.props} onEndAction={closeDrawer} />;
    }
    case DrawerEnum.USER_CREATE: {
      return <UserCreateOrAddForm />;
    }
    case DrawerEnum.USER_EDIT: {
      return <UserEditForm {...drawer.props} />;
    }
    case DrawerEnum.INCIDENTS_UPDATES: {
      return (
        <DrawerNavigation>
          <IncidentUpdatesForm onEndAction={closeDrawer} />
        </DrawerNavigation>
      );
    }
    case DrawerEnum.PROJECT_TASKS_COLUMNS: {
      return <ProjectTasksColumnsSettingsDrawer />;
    }
    case DrawerEnum.PROJECT_TASKS_FILTER: {
      return <FilterFormProjectTasks />;
    }
    case DrawerEnum.DAILY_TASKS_COLUMNS: {
      return <DailyTasksColumnsSettingsDrawer />;
    }
    case DrawerEnum.DAILY_TASKS_FILTER: {
      return <FilterFormDailyTasks />;
    }
    case DrawerEnum.USES_FORM_SETTINGS: {
      return <UserFormSettings {...drawer.props} onEndAction={closeDrawer} />;
    }
    case DrawerEnum.PROJECT_TASK_FORM: {
      return <ProjectTaskForm {...drawer.props} onEndAction={closeDrawer} />;
    }
    default: {
      return null;
    }
  }
};

export const DrawerStack = () => {
  const { drawer, isOpen, closeDrawer } = useDrawer();
  const { ref } = useFullScreen();

  const form = useMemo(() => (drawer ? getDrawerForm(drawer, closeDrawer) : null), [drawer, closeDrawer]);

  useChangeLocation(() => closeDrawer());

  return isOpen ? (
    <Drawer container={ref.current} open={isOpen} onClose={() => closeDrawer()} anchor='right'>
      {form}
    </Drawer>
  ) : null;
};
