import {
  ClampedTypography,
} from '../../../base';

const TextRenderer = (value: string) => (
  <ClampedTypography
    variant='body2'
    lineclamp={5}
  >
    {value}
  </ClampedTypography>
);

export default TextRenderer;
