import { type RefObject } from 'react';

import type { ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useDebounce } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRecoilTask } from 'recoil-toolkit';

import { currentEventIdState } from '../../../../state/event';
import { useIncidentsListWithoutFilters } from '../../../../state/incidents';
import { limitState } from '../../../../state/incidentTable/pagination';
import { userState } from '../../../../state/user';
import {
  useGridDragColumns,
  useInlineAdding,
  useNewEntityRow,
  useSyncColumnStateFromUserByUpdateHook,
} from '../../../EntitiesGrid';
import {
  projectTasksColumnsState,
  newRowProjectTask,
  projectTasksFiltersState,
  projectTasksStateWithHierarchy,
  projectTasksStateTree,
} from './atoms';
import { DEFAULT_PREPARED_PROJECT_TASK } from './constants';
import {
  editPartProjectTask,
  getProjectTasks,
  removeRowTask,
  setColumnsTask,
  importProjectTasksFromExcel,
  addProjectTask,
  updateColumnsFromUserTask,
} from './tasks';

const DEBOUNCE_TIME = 500;

export const useAddProjectTask = () =>
  useRecoilTask(addProjectTask, [], {
    loaderStack: 'addProjectTask',
    errorStack: true,
  });

const useIncidentInlineAddingParams = {
  newEntityAtom: newRowProjectTask,
  useAddEntity: useAddProjectTask,
  defaultPreparedEntity: DEFAULT_PREPARED_PROJECT_TASK,
};

export function useProjectTaskInlineAdding(gridRef: RefObject<AgGridReact<unknown>>) {
  const { editNewEntity } = useInlineAdding(gridRef, useIncidentInlineAddingParams);

  return {
    editPreparedProjectTask: editNewEntity,
  };
}

export const useProjectTaskNewRow = () => useNewEntityRow(useIncidentInlineAddingParams);

export const useSetColumns = () =>
  useRecoilTask(setColumnsTask, [], {
    loaderStack: 'setColumnsTask',
    errorStack: true,
  });

export const useProjectTaskGridDragColumns = (columnDefs: ColDef[]) =>
  useGridDragColumns({
    columnDefs,
    columnRecoilState: projectTasksColumnsState,
    useSetRecoilColumnsSettings: useSetColumns,
  });

const useUpdateColumnState = () =>
  useRecoilTask(updateColumnsFromUserTask, [], {
    loaderStack: 'updateColumnsFromUserTask',
    errorStack: true,
  });

export const useSyncColumnStateFromUser = () => useSyncColumnStateFromUserByUpdateHook(useUpdateColumnState);

export const useProjectTaskRemoveRow = () =>
  useRecoilTask(removeRowTask, [], {
    loaderStack: 'projectTaskRemoveRow',
    errorStack: true,
  });

const useTasks = () =>
  useRecoilTask(getProjectTasks, [], {
    dataSelector: projectTasksStateWithHierarchy,
    loaderStack: 'getProjectTasks',
    errorStack: true,
  });

const useTasksTree = () =>
  useRecoilTask(getProjectTasks, [], {
    dataSelector: projectTasksStateTree,
    loaderStack: 'getProjectTasks',
    errorStack: true,
  });

const useEditPartProjectTask = () =>
  useRecoilTask(editPartProjectTask, [], {
    loaderStack: 'editPartProjectTask',
    errorStack: true,
  });

export const useProjectTasksData = () => {
  const { execute: loadTasks, data: projectTasks } = useTasks();

  const currentUser = useRecoilValue(userState);
  const currentEventId = useRecoilValue(currentEventIdState);
  const filters = useRecoilValue(projectTasksFiltersState);
  const [, setLimit] = useRecoilState(limitState);
  const { execute: editTaskPart } = useEditPartProjectTask();
  const { execute: loadIncidents } = useIncidentsListWithoutFilters();

  useDebounce(
    () => {
      if (currentEventId) {
        setLimit(-1);
        loadTasks();
        loadIncidents();
      }
    },
    DEBOUNCE_TIME,
    [loadTasks, currentEventId, filters],
  );

  return {
    currentUser,
    currentEventId,
    editTaskPart,
    projectTasks,
  };
};

export const useProjectTasksDataTree = () => {
  const { execute: loadTasks, data: projectTasks } = useTasksTree();

  const [, setLimit] = useRecoilState(limitState);
  const currentEventId = useRecoilValue(currentEventIdState);
  const filters = useRecoilValue(projectTasksFiltersState);
  const { execute: loadIncidents } = useIncidentsListWithoutFilters();

  useDebounce(
    () => {
      if (currentEventId) {
        setLimit(-1);
        loadTasks();
        loadIncidents();
      }
    },
    DEBOUNCE_TIME,
    [loadTasks, currentEventId, filters],
  );

  return {
    currentEventId,
    projectTasks,
  };
};

export const useImportProjectTasksFromExcel = () =>
  useRecoilTask(importProjectTasksFromExcel, [], {
    errorStack: true,
  });
