import type { FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { eventState, permissionState } from '../../state/event';
import { userState, useUpdateCurrentEvent } from '../../state/user';
import type { Event } from '../../types';
import { EventMenu } from '../EventMenu';

type Props = {
  sx?: SxProps<Theme>;
};

export const EventControl: FC<Props> = ({ sx }) => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>();
  const open = Boolean(anchorEl);
  const currentUser = useRecoilValue(userState);
  const currentEvent = useRecoilValue(eventState);
  const { canCreateEvent } = useRecoilValue(permissionState);
  const { execute: updateCurrentEvent } = useUpdateCurrentEvent();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleChangeProject = (id: number) => {
    if (id === currentEvent.id) {
      handleClose();
    } else {
      updateCurrentEvent({
        eventId: id,
        successCallback: () => window.location.reload(),
      });
    }
  };

  const [activeEvents, archivedEvents] = useMemo(
    () =>
      (currentUser?.userEvents || [])
        .map(({ event }) => event)
        .sort((a, b) => Number(!!a.isArchived) - Number(!!b.isArchived))
        .reduce<[activeEvents: Event[], archivedEvents: Event[]]>(
          (acc, event) => {
            if (event.isArchived) {
              acc[1].push(event);
            } else {
              acc[0].push(event);
            }

            return acc;
          },
          [[], []],
        ),
    [currentUser?.userEvents],
  );

  return (
    <>
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          width: 'fit-content',
          border: '1px solid #C7D1D4',
          borderRadius: '8px',
          ...sx,
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          <Stack
            direction='row'
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
            }}
            spacing={2}
          >
            <Typography variant='body2'>
              <Trans i18nKey='projects' />
            </Typography>
          </Stack>
        </IconButton>
      </Box>
      {currentUser && (
        <EventMenu
          displayAddProject={canCreateEvent}
          open={open}
          onClose={handleClose}
          onClick={handleChangeProject}
          anchorEl={anchorEl}
          itemArchives={archivedEvents.map(({ name, id, isArchived }) => ({
            name: isArchived ? `${name} (A)` : name,
            id,
          }))}
          items={activeEvents}
        />
      )}
    </>
  );
};
