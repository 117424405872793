/* eslint-disable indent */
/* eslint-disable max-len */

type HeaderTemplateProps = {
  contentTemplate: string;
  isSort?: boolean;
  isFilter?: boolean;
};

const getHeaderTemplate = ({ contentTemplate, isSort = true, isFilter = false }: HeaderTemplateProps) => `
  <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span class="ag-header-cell-text">${contentTemplate}</span>
          ${
            isFilter
              ? '<span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>'
              : ''
          }
          ${
            isSort
              ? `<span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>`
              : ''
          }
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>
`;

export default getHeaderTemplate;
