import { useState } from 'react';

import { Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { add, eachDayOfInterval, format, min, startOfDay, parse } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import type { LineChartType } from '../../types';
import type { KeyNameType } from '../lib/types/dashborad.types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

const dateFormat = 'dd.LL.yyyy';

export const LineChart = ({
  dataset,
  backgroundColor,
  datasetNames,
  maxDate,
  minDate,
  keyName,
}: LineChartType & KeyNameType) => {
  const { t } = useTranslation();
  const dayStartTime = startOfDay(new Date());
  const defaultStartDate = add(dayStartTime, { days: -10 });
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(dayStartTime);

  let dates = Object.keys(dataset);

  if (startDate && endDate && startDate <= endDate) {
    const intervalDays = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    dates = intervalDays.map((day) => format(day, dateFormat));
  }

  const labels = dates.map((date) => format(parse(date, dateFormat, new Date()), 'dd.MM'));
  const datasets = datasetNames.map((datasetName, index) => ({
    label: datasetName,
    data: dates.map((date) => dataset[date]?.[index] || 0),
    borderColor: backgroundColor?.[index],
    backgroundColor: backgroundColor?.[index],
  }));

  const handleStartDateChange = (value: Date | null) => {
    const currentDate = new Date();

    setStartDate(value || defaultStartDate);

    if (value && (value > endDate || add(value, { days: 10 }) < endDate)) {
      setEndDate(min([currentDate, add(value, { days: 10 })]));
    }
  };

  const handleEndDateChange = (value: Date | null) => setEndDate(value || dayStartTime);
  const minDateOfEnd = startDate || minDate;
  const maxDateOfEnd = min([
    new Date(),
    ...(maxDate ? [maxDate] : []),
    ...(startDate ? [add(new Date(startDate), { days: 10 })] : []),
  ]);
  return (
    <div id={keyName}>
      <Stack direction='row' p={1} spacing={1}>
        <Box sx={{ minWidth: '160px' }}>
          <DatePicker
            label={t('start-date')}
            inputFormat='dd.MM.yyyy'
            value={startDate ?? null}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} size='small' />}
            disableFuture
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
        <Box sx={{ minWidth: '160px' }}>
          <DatePicker
            label={t('end-date')}
            inputFormat='dd.MM.yyyy'
            value={endDate ?? null}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} size='small' />}
            minDate={minDateOfEnd}
            maxDate={maxDateOfEnd}
          />
        </Box>
      </Stack>
      <Line
        options={options}
        data={{
          labels,
          datasets,
        }}
      />
    </div>
  );
};
