import { closestTo, isEqual } from 'date-fns';
import { atom, selector } from 'recoil';
import { concat, indexBy, values } from 'remeda';

import type { DailyTaskDefaultFilters } from '../../../../types';
import formatDay from '../../../../utils/formatDay';
import { columnsSettingsState, newRowEntityState } from '../../../EntitiesGrid';
import { tasksState } from '../tasks';
import { DEFAULT_DAILY_TASKS_COLUMNS } from './constants';
import type { DailyTask, PreparedNewDailyTask } from './types';
import { isDailyTask } from './types';
import { groupTasksByDay, parseDay } from './utils';

type DayType = {
  day: string;
  tasks: DailyTask[];
};

export const dailyTasksState = selector<DayType[]>({
  key: 'dailyTasks',
  get: ({ get }) => {
    const tasks = get(tasksState);
    const dailyTasks = values(tasks)
      .filter(isDailyTask)
      .map((task) => ({ ...task, orgHierarchy: [String(task.id)] }));
    const groupedByDay = groupTasksByDay(dailyTasks);

    return groupedByDay;
  },
});

export const newRowDailyTask = newRowEntityState<PreparedNewDailyTask>({ key: 'dailyTasksNewRow' });

export const newTabsDailyTasksState = atom<{ id: number; day?: Date }[]>({
  key: 'newTabsDailyTasks',
  default: [],
});

export const currentTabDailyTasksState = atom<string | false>({
  key: 'currentTabDailyTasks',
  default: false,
});

type TabTypeWithDate =
  | {
      key: string;
      selected?: boolean;
      day: Date;
      tasks: DailyTask[];
    }
  | {
      selected?: boolean;
      key: string;
      day: Date;
    };

type TabType =
  | TabTypeWithDate
  | {
      selected?: boolean;
      key: string;
    };

export const tabsDailyTasksState = selector<TabType[]>({
  key: 'tabsDailyTasks',
  get: ({ get }) => {
    const dailyTasks = get(dailyTasksState);
    const daysWithoutTasks = get(newTabsDailyTasksState);
    const daysWithoutTasksKeyed = daysWithoutTasks.map((tab) => ({
      ...tab,
      key: tab.id.toString(),
    }));
    const selectedTab = get(currentTabDailyTasksState);
    const tasksIndexed = indexBy(dailyTasks, ({ day }) => day);
    const filteredDaysWithoutTasks = daysWithoutTasksKeyed.filter(({ day }) =>
      day ? !tasksIndexed[formatDay(day)] : true,
    );
    const dailyTasksWithKey = dailyTasks.map(({ day, tasks }) => ({
      key: day,
      day: parseDay(day),
      tasks,
    }));

    let tabs = concat(dailyTasksWithKey, filteredDaysWithoutTasks);
    let selectedTabObj = tabs.find((tab) => selectedTab === tab.key);
    const selectedTabObjWithoutTasks = daysWithoutTasksKeyed.find((tab) => selectedTab === tab.key);

    if (!selectedTabObj && selectedTabObjWithoutTasks && selectedTabObjWithoutTasks.day) {
      const dayStr = formatDay(selectedTabObjWithoutTasks.day);
      const tabWithTasksOnThatDay = dailyTasksWithKey.find((tab) => dayStr === tab.key);
      if (tabWithTasksOnThatDay) {
        selectedTabObj = tabWithTasksOnThatDay;
      }
    }
    if (!selectedTabObj) {
      const days = tabs.map((tab) => 'day' in tab && tab.day).filter((day) => Boolean(day)) as Date[];
      if (days.length) {
        const closestDay = closestTo(Date.now(), days);
        selectedTabObj = tabs.find((tab) => closestDay && tab.day && isEqual(closestDay, tab.day));
      } else {
        [selectedTabObj] = tabs;
      }
    }

    if (selectedTabObj) {
      tabs = tabs.map((tab) =>
        selectedTabObj && selectedTabObj.key === tab.key
          ? {
              ...tab,
              selected: true,
            }
          : tab,
      );
    }
    return tabs;
  },
});

export const currentTabWithDefaultDailyTasksState = selector<TabType | false>({
  key: 'currentTabWithDefaultDailyTasks',
  get: ({ get }) => {
    const tabs = get(tabsDailyTasksState);
    const currentTabObj = tabs.find((tab) => tab.selected);

    return currentTabObj || false;
  },
});

export const dailyTasksColumnsState = columnsSettingsState({
  default: DEFAULT_DAILY_TASKS_COLUMNS,
  key: 'dailyTasksColumnSettings',
});
export const dailyTasksFiltersState = atom<DailyTaskDefaultFilters>({
  key: 'dailyTasksFilters',
  default: {
    typeDate: 'registeredDate',
  },
});
