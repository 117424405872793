import type { ReactNode } from 'react';

import SquareIcon from '@mui/icons-material/Square';
import {
  Table, TableBody, TableCell, TableFooter, TableHead, TableRow,
} from '@mui/material';
import { Trans } from 'react-i18next';

type RowType = {
  name: string;
  value: ReactNode;
  percent: ReactNode;
  color: string;
}

type DoughnutLegendProps = {
  name: ReactNode;
  rows: RowType[];
  total: ReactNode;
  totalPercent: ReactNode;
};

export const DoughnutLegend = ({
  name, rows, total, totalPercent,
}: DoughnutLegendProps) => (
  <Table size='small'>
    <TableHead>
      <TableRow>
        <TableCell component='th' />
        <TableCell component='th'>{name}</TableCell>
        <TableCell component='th'><Trans i18nKey='incidents' /></TableCell>
        <TableCell component='th'>%</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.name}>
          <TableCell><SquareIcon htmlColor={row.color} /></TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.value}</TableCell>
          <TableCell>
            {row.percent}
            %
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell />
        <TableCell><Trans i18nKey='total' /></TableCell>
        <TableCell>{total}</TableCell>
        <TableCell>
          {totalPercent}
          %
        </TableCell>
      </TableRow>
    </TableFooter>
  </Table>
);
