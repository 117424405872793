/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';

import requestService from '../../../api/requestService';
import { Status, type WithMeta } from '../../../types';
import type { PreparedNewTask, Task } from '../state/tasks';

const POPULATIONS = [
  'comments',
  'responsible',
  'comments.author',
  'comments.image',
  'comments.editedUser',
  'comments.hiddenUser',
  'userFunction',
  'resolutionLevel',
  'location',
  'registeredBy',
  'participants',
  'parent',
  'children',
];

export const getEditTaskPopulate = () =>
  qs.stringify(
    {
      populate: POPULATIONS,
    },
    { encodeValuesOnly: true },
  );
const POPULATE_STRING = getEditTaskPopulate();

export const taskService = {
  getTasks: ({ event, filters = {} }: { event: number; filters?: { [key: string]: any } }) =>
    requestService
      .get<WithMeta<Task[]>>(
        `/incidents/tasks?${qs.stringify({
          filters: {
            event,
            ...filters,
            status: {
              $notIn: [Status.DRAFT],
              ...filters?.status,
            },
          },
        })}`,
      )
      .then((response) => response.data),
  addTask: (data: PreparedNewTask<Task>) =>
    requestService.post<WithMeta<Task>>(`/incidents?${POPULATE_STRING}`, { data }).then((response) => response.data),
  editTask: (id: number, data: unknown) =>
    requestService
      .put<WithMeta<Task>>(`/incidents/${id}?${POPULATE_STRING}`, { data })
      .then((response) => response.data),
  removeTask: (id: number) => requestService.delete(`/incidents/${id}`).then((response) => response.data),
  importTasks: (data: FormData) =>
    requestService.post(`/incidents/uploadFile`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
};
