import type { FC } from 'react';

import {
  ListItemIcon, Menu, MenuItem, Stack, Typography,
} from '@mui/material';
import RU from 'country-flag-icons/react/3x2/RU';
import US from 'country-flag-icons/react/3x2/US';
import { Trans } from 'react-i18next';

import type { User } from '../../types';

const PAPER_PROPS = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

type LanguageMenuProps = {
  open: boolean,
  onClose?: () => void,
  onClick: (id: User['language']) => void,
  anchorEl?: HTMLElement,
};

const svgAttrs = { height: '24px' };
const languages = [
  {
    title: <Trans i18nKey='english' />,
    id: 'en' as const,
    icon: <US title='English' {...svgAttrs} />,
  },
  {
    title: <Trans i18nKey='russian' />,
    id: 'ru' as const,
    icon: <RU title='Russian' {...svgAttrs} />,
  },
];

export const LanguageMenu: FC<LanguageMenuProps> = ({
  open, onClose, onClick, anchorEl,
}) => (
  <Menu
    anchorEl={anchorEl}
    id='language-menu'
    open={open}
    onClose={onClose}
    PaperProps={PAPER_PROPS}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    {
      languages.map((language) => (
        <MenuItem key={language.id} onClick={() => onClick(language.id)}>
          <Stack direction='row' spacing={1}>
            <ListItemIcon>
              {language.icon}
            </ListItemIcon>
            <Typography variant='body1'>{language.title}</Typography>
          </Stack>
        </MenuItem>
      ))
    }
  </Menu>
);
