import type { MarkerProps } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

import type { PointerMarkerView } from '../../Markers';
import { pointerMarkerColorMap, pointerMarkerConfigSvg } from '../../Markers';

export type LocationMarkerProps = Omit<MarkerProps, 'icon'> & {
  view?: PointerMarkerView;
};

export const LocationMarker = ({ view = 'default', ...props }: LocationMarkerProps) => (
  <Marker
    {...props}
    icon={{
      ...pointerMarkerConfigSvg,
      scale: 0.73,
      fillColor: pointerMarkerColorMap[view],
      labelOrigin: window?.google ? new google.maps.Point(18, 18) : null,
      anchor: window?.google ? new google.maps.Point(14, 40) : null,
    }}
  />
);
