import { FormControl, FormLabel, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { type UseFormReturn, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ResolutionLevel } from '../../../types';
import { ResolutionLevelBlock } from '../../base';
import type { UserEventSettingsInitialValues } from '../lib/formTypes';

type ResolutionLevelFieldProps = {
  form: UseFormReturn<UserEventSettingsInitialValues>;
};

export const ResolutionLevelField = ({ form }: ResolutionLevelFieldProps) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel>{t('resolution-level')}</FormLabel>
      <Controller
        name='filters.resolutionLevel'
        control={form.control}
        render={({ field }) => (
          <ToggleButtonGroup {...field} onChange={(_, value) => field.onChange(value)}>
            <ToggleButton value={ResolutionLevel.GREEN}>
              <ResolutionLevelBlock level={ResolutionLevel.GREEN} />
            </ToggleButton>
            <ToggleButton value={ResolutionLevel.AMBER}>
              <ResolutionLevelBlock level={ResolutionLevel.AMBER} />
            </ToggleButton>
            <ToggleButton value={ResolutionLevel.ORANGE}>
              <ResolutionLevelBlock level={ResolutionLevel.ORANGE} />
            </ToggleButton>
            <ToggleButton value={ResolutionLevel.RED}>
              <ResolutionLevelBlock level={ResolutionLevel.RED} />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};
