import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { permissionState } from '../../state/event';
import { AppRoute } from '../../types';
import { CustomTab } from './components/CustomTab';
import { Menu, type MenuProps } from './components/Menu';

type MenuIncidentProps = MenuProps;

export const MenuIncident = (props: MenuIncidentProps) => {
  const { t } = useTranslation();
  const { canViewEvent, canViewUsers, canViewMaps, canViewDashboard } = useRecoilValue(permissionState);
  return (
    <Menu {...props}>
      <CustomTab label={t('table')} value={AppRoute.INCIDENTS} />
      {canViewDashboard && <CustomTab label={t('dashboard')} value={AppRoute.DASHBOARD} />}
      {canViewMaps && <CustomTab label={t('map')} value={AppRoute.MAP} />}
      {canViewEvent && <CustomTab label={t('project')} value={AppRoute.EVENTS} />}
      {canViewUsers && <CustomTab label={t('users')} value={AppRoute.USERS} />}
    </Menu>
  );
};
