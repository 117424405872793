import { useCallback } from 'react';

import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { groupBy, toPairs } from 'remeda';

import useDrawer from '../../hooks/useDrawer';
import { Drawer } from '../../state/drawer';
import { useIncidentsList } from '../../state/incidents';
import { incidentsUpdatesIdsState, IncidentUpdateType } from '../../state/incidentsUpdates/atoms';
import { AppRoute } from '../../types';

export const IncidentUpdates = () => {
  const location = useLocation();
  const tabsValue = location.pathname === '/' ? AppRoute.INCIDENTS : location.pathname;
  const isPageWithIncidents = tabsValue === AppRoute.INCIDENTS || tabsValue === AppRoute.MAP;
  const incidentUpdates = useRecoilValue(incidentsUpdatesIdsState);
  const groupedUpdates = groupBy(toPairs(incidentUpdates), ([, update]) => update.type);
  const { execute: loadIncidents } = useIncidentsList();

  const { showDrawer } = useDrawer();
  const { t } = useTranslation();
  const groupCreated = groupedUpdates[IncidentUpdateType.Created];
  const groupUpdated = groupedUpdates[IncidentUpdateType.Updated];
  const onShow = useCallback(() => showDrawer({ type: Drawer.INCIDENTS_UPDATES }), [showDrawer]);
  const onReload = useCallback(() => {
    loadIncidents();
  }, [loadIncidents]);

  return isPageWithIncidents && (groupUpdated || groupCreated) ? (
    <Alert
      sx={{
        position: 'fixed',
        bottom: 80,
        right: 20,
        zIndex: 1,
      }}
      severity='info'
      action={
        <Stack spacing={1}>
          <Button onClick={onShow} color='inherit'>
            {t('show')}
          </Button>
          <Button onClick={onReload} color='inherit'>
            {t('refresh-list')}
          </Button>
        </Stack>
      }
    >
      <AlertTitle>{t('incident-updates')}</AlertTitle>
      <Stack spacing={1}>
        {groupCreated ? <Typography>{t('new-incidents-with-count', { count: groupCreated.length })}</Typography> : null}
        {groupUpdated ? (
          <Typography>{t('updated-incidents-with-count', { count: groupUpdated.length })}</Typography>
        ) : null}
      </Stack>
    </Alert>
  ) : null;
};
