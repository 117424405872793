import type { ChangeEvent} from 'react';
import { useState } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useRecoilState } from 'recoil';

import { incidentsFilterState } from '../../../../state/incidentsFilter';
import { Icon } from '../../../base';

const SEARCH_DEBOUNCE = 500;

export const SearchInput = () => {
  const [filterState, setFilterState] = useRecoilState(incidentsFilterState);
  const [searchText, setSearchText] = useState(filterState.text);
  const { t } = useTranslation();

  useDebounce(
    () => {
      setFilterState((state) => ({ ...state, text: searchText }));
    },
    SEARCH_DEBOUNCE,
    [setFilterState, searchText],
  );

  const changeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <TextField
      value={searchText}
      placeholder={t('search')}
      onChange={changeHandler}
      InputProps={{
        sx: { height: '40px' },
        startAdornment: (
          <InputAdornment position='start'>
            <Icon icon='search' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};
