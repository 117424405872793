import type {
  Event,
  User,
  UserDesktopFilters,
  CustomUserFilters,
  ProjectTaskDefaultFilters,
  DailyTaskDefaultFilters,
} from '../types';
import requestService from './requestService';

type EventSettingsEntity = {
  id: number;
  user: User;
  event: Event;
  defaultDesktopFilters: UserDesktopFilters | null;
  customUserFilters: CustomUserFilters | null;
  dailyTaskDefaultFilter: DailyTaskDefaultFilters | null;
  projectTaskDefaultFilters: ProjectTaskDefaultFilters | null;
};

const eventSettingService = {
  create: (data: {
    user: EventSettingsEntity['user']['id'];
    event: EventSettingsEntity['event']['id'];
    defaultDesktopFilters?: EventSettingsEntity['defaultDesktopFilters'];
  }) => requestService.post<EventSettingsEntity>('/event-settings', { data }),
  update: ({
    id,
    ...data
  }: {
    id: EventSettingsEntity['id'];
    user?: EventSettingsEntity['user']['id'];
    event?: EventSettingsEntity['event']['id'];
    defaultDesktopFilters?: EventSettingsEntity['defaultDesktopFilters'];
    customUserFilters?: EventSettingsEntity['customUserFilters'];
    dailyTaskDefaultFilter?: EventSettingsEntity['dailyTaskDefaultFilter'];
    projectTaskDefaultFilters?: EventSettingsEntity['projectTaskDefaultFilters'];
  }) => requestService.put<{ data: EventSettingsEntity }>(`/event-settings/${id}`, { data }),
};

export default eventSettingService;
