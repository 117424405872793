import type { RecoilTaskInterface } from 'recoil-toolkit';

import { incidentService } from '../../api';
import indexById from '../../utils/indexedById';
import { currentEventIdState } from '../event';
import { getQueryByIds } from '../incidents/utils';
import { incidentsUpdatesIdsState, loadedIncidentsUpdatesState } from './atoms';

export const getUpdatedIncidents = ({ set, snapshot }: RecoilTaskInterface) => async () => {
  const eventId = snapshot.getLoadable(currentEventIdState).getValue();
  const incidentsIds = Object.values(snapshot.getLoadable(incidentsUpdatesIdsState).getValue());
  if (eventId) {
    const responseData = await incidentService.getIncidents(getQueryByIds({
      eventId,
      incidentsIds: incidentsIds.map(({ id }) => id),
    }));
    set(loadedIncidentsUpdatesState, indexById(responseData.data));
  }
};

export const clearUpdatedIncidents = ({ reset }: RecoilTaskInterface) => () => {
  reset(loadedIncidentsUpdatesState);
  reset(incidentsUpdatesIdsState);
};
