function updateArray<T, K>(array: Array<T>, finder: (item: T) => boolean, updater: (item: T) => K): Array<T | K> {
  return array.map((item) => {
    if (finder(item)) {
      return updater(item);
    }
    return item;
  });
}

export default updateArray;
