import useDrawer from '../../../../../hooks/useDrawer';
import { Drawer } from '../../../../../state/drawer';
import type { MetaData } from '../lib/types';

export function useIncidentMapDrawer() {
  const { showDrawer } = useDrawer();
  const onClusterClick = (metadata: MetaData[]) => () => {
    showDrawer({ type: Drawer.MAP, props: { mapForm: metadata } });
  };

  const onMarkerClick = (marker: MetaData) => () => {
    showDrawer({ type: Drawer.MAP, props: { mapForm: marker } });
  };

  return {
    onMarkerClick,
    onClusterClick,
  };
}
