import type { ColDef } from 'ag-grid-community';

import type { Incident, Location, ResolutionLevel } from '../../../types';
import { orderResolutionLevel } from './resolutionLevelForChildrenNodes';

export const comparatorResolutionLevel: ColDef['comparator'] = (valueA: ResolutionLevel, valueB: ResolutionLevel) =>
  orderResolutionLevel[valueA] - orderResolutionLevel[valueB];

export const comparatorRelation: ColDef['comparator'] = (valueA: boolean, valueB: boolean) =>
  (valueA ? 1 : 0) - (valueB ? 1 : 0);

export const comparatorUserFunction: ColDef<Incident>['comparator'] = (_valueA, _valueB, nodeA, nodeB) => {
  const valueA = nodeA.data?.userFunction?.name.toLocaleLowerCase() ?? '';
  const valueB = nodeB.data?.userFunction?.name.toLocaleLowerCase() ?? '';

  if (valueA < valueB) return -1;
  if (valueA > valueB) return 1;
  return 0;
};

export const comparatorLocation: ColDef<Incident>['comparator'] = (valueA?: Location, valueB?: Location) => {
  const valueALower = valueA?.title.toLocaleLowerCase() ?? '';
  const valueBLower = valueB?.title.toLocaleLowerCase() ?? '';

  if (valueALower < valueBLower) return -1;
  if (valueALower > valueBLower) return 1;
  return 0;
};
