import { useEffect, type SyntheticEvent } from 'react';

import { PostAdd } from '@mui/icons-material';
import { Tab, Tabs, type TabsProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import {
  currentTabDailyTasksState,
  currentTabWithDefaultDailyTasksState,
  useTabs,
} from '../../modules/Tasks/state/dailyTasks';
import { currentEventSettings, permissionState } from '../../state/event';
import { NotificationType, useShowNotification } from '../../state/notification';
import { AppRoute } from '../../types';
import { CustomTab } from './components/CustomTab';
import { Menu, type MenuProps } from './components/Menu';
import { useRemoveDailyTab } from './model/useRemoveDailyTab';

type URLParams = {
  day?: string;
};

type MenuDailyPlanProps = Omit<MenuProps, 'customTabs'>;

const ADD_TAB = 'add';

const CustomTabs = (props: TabsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { day: dayParams } = useParams<URLParams>();
  const { tabs } = useTabs(t('new-day-tab'));
  const currentTab = useRecoilValue(currentTabWithDefaultDailyTasksState);
  const setCurrentTab = useSetRecoilState(currentTabDailyTasksState);
  const { handleAddTab } = useTabs();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    if (newValue === ADD_TAB) {
      const tab = handleAddTab();
      if (tab) {
        setCurrentTab(tab.id.toString());
        navigate(`${AppRoute.DAILY_PLAN}/${tab.id}`);
      }

      return;
    }

    navigate(newValue);
  };

  const getValue = () => {
    if (location.pathname === AppRoute.DAILY_PLAN) {
      return false;
    }

    return dayParams && currentTab ? `${AppRoute.DAILY_PLAN}/${currentTab.key}` : location.pathname;
  };

  useEffect(() => {
    if (location.pathname === AppRoute.DAILY_PLAN && currentTab) {
      navigate(`${AppRoute.DAILY_PLAN}/${currentTab.key}${location.search}`);
    }
  }, [currentTab, location.pathname, location.search, navigate, tabs.length]);

  return (
    <Tabs
      {...props}
      value={getValue()}
      onChange={handleChange}
      orientation='horizontal'
      variant='scrollable'
      scrollButtons='auto'
    />
  );
};

export const MenuDailyPlan = (props: MenuDailyPlanProps) => {
  const { t } = useTranslation();
  const { tabs, disabled, handleRemoveNewTab } = useTabs(t('new-day-tab'));
  const { canViewUsers, canViewEvent } = useRecoilValue(permissionState);
  const { canCreateOperationalTask } = useRecoilValue(permissionState);
  const settings = useRecoilValueLoadable(currentEventSettings).valueMaybe();
  const setCurrentTab = useSetRecoilState(currentTabDailyTasksState);
  const { execute: onRemoveDailyTab } = useRemoveDailyTab();
  const { showConfirm, confirmModal } = useConfirmDialog({
    content: t('modal-remove-daily-tab-content'),
  });
  const { execute: showNotification } = useShowNotification();
  const { canRemoveOperationalTask } = useRecoilValue(permissionState);

  const handleRemoveDailyTask = (key: string, tasks: number, day?: Date) => async () => {
    // new date without tasks(before add of tasks)
    if (!tasks) {
      handleRemoveNewTab(Number(key));
      return;
    }

    const isOk = await showConfirm();
    if (isOk) {
      onRemoveDailyTab({
        key,
        successCallback: async () => {
          if (day) {
            handleRemoveNewTab(day);
          }
          await showNotification({ type: NotificationType.SUCCESS, text: t('daily-tab-remove-success') });
        },
      });
    }
  };

  const handleClickTab = (key: string) => () => {
    setCurrentTab(key);
  };

  return (
    <Menu {...props} CustomTabsComponent={CustomTabs}>
      {canCreateOperationalTask && <Tab key={ADD_TAB} value={ADD_TAB} icon={<PostAdd />} />}
      {tabs.length &&
        settings?.availableDailyPlan &&
        tabs.map((tab) => (
          <CustomTab
            key={tab.key}
            label={tab.label}
            value={`${AppRoute.DAILY_PLAN}/${tab.key}`}
            disabled={disabled}
            onClick={handleClickTab(tab.key)}
            onRemove={
              canRemoveOperationalTask
                ? handleRemoveDailyTask(
                    tab.key,
                    ('tasks' in tab && tab.tasks.length) || 0,
                    'day' in tab ? tab.day : undefined,
                  )
                : undefined
            }
          />
        ))}
      {canViewUsers && <CustomTab label={t('users')} value={AppRoute.DAILY_PLAN_USERS} />}
      {canViewEvent && <CustomTab label={t('project')} value={AppRoute.DAILY_PLAN_EVENTS} />}
      {confirmModal}
    </Menu>
  );
};
