import type { ColumnsSettings } from '../../../EntitiesGrid';
import type { PreparedNewTask, Task } from '../tasks';
import { TaskType } from '../tasks';

export enum DailyTaskColumnKey {
  Title = 'title',
  Description = 'description',
  Status = 'status',
  Responsible = 'responsible',
  UserFunction = 'userFunction',
  Comments = 'comments',
  ResolvedDate = 'resolvedDate',
  StartDatetime = 'startDatetime',
  EndDatetime = 'endDatetime',

  ResolutionLevel = 'resolutionLevel',
  Location = 'location',
  Relation = 'relation',
}

export type DailyTask = Task & {
  type: TaskType.OperationalTask;
  day: Date;
};

export function isDailyTask(task: Task): task is DailyTask {
  return task.type === TaskType.OperationalTask;
}

export type PreparedNewDailyTask = PreparedNewTask<DailyTask>;

export type DailyTaskGridColumns = ColumnsSettings<DailyTaskColumnKey>;
