import { useRecoilTask } from 'recoil-toolkit';

import {
  addFilterByFlaggedTask,
  clearFilterByFlaggedTask,
  addFilterByUnreadTask,
  clearFilterByUnreadTask,
  setDefaultFiltersTask,
  changeFilterByTimeDateTask,
} from './tasks';

export const useAddFilterByFlagged = () => useRecoilTask(addFilterByFlaggedTask, []);

export const useClearFilterByFlagged = () => useRecoilTask(clearFilterByFlaggedTask, []);

export const useAddFilterByUnread = () => useRecoilTask(addFilterByUnreadTask, []);

export const useClearFilterByUnread = () => useRecoilTask(clearFilterByUnreadTask, []);

export const useChangeFilterByTimeDate = () => useRecoilTask(changeFilterByTimeDateTask, [])

export const useSetDefaultFilters = () => useRecoilTask(setDefaultFiltersTask, [], {
  loaderStack: 'setDefaultFiltersTask',
  errorStack: true,
});
