import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { IconButton, type SxProps, type Theme } from '@mui/material';

import { useFullScreen } from './FullScreenContext';

interface FullScreenButtonProps {
  sx?: SxProps<Theme>;
}

export const FullScreenButton = ({ sx }: FullScreenButtonProps) => {
  const { onToggleFullScreen, fullScreen } = useFullScreen();

  return (
    <IconButton aria-label='Full screen' onClick={onToggleFullScreen} sx={sx}>
      {fullScreen ? <FullscreenExit /> : <Fullscreen />}
    </IconButton>
  );
};
