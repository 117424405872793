import { useMemo } from 'react';

import type { LngLat } from '@yandex/ymaps3-types';
import type { Feature } from '@yandex/ymaps3-types/packages/clusterer';

import { MapLoader } from '../../../shared/components/MapLoader';
import {
  Cluster,
  Hint,
  IncidentMarker,
  LocationMarker,
  YandexMap,
  YandexMapProvider,
  useYandexMap,
} from '../../../shared/components/YandexMap';
import { useCreateSquareImage } from '../../../shared/hooks/useCreateSquareImage';
import { CROP_SIZE } from '../lib/constants';
import type { CustomFeatureIncident, CustomFeatureLocation } from '../lib/normalizeYandexMapData';
import { normalizeYandexMapData } from '../lib/normalizeYandexMapData';
import { useIncidentMap } from '../model/IncidentMapProvider';

interface YandexMapIncidentPlanProps {
  url: string;
}

const SOURCE = 'source';

const YandexMapIncidentPlanBase = (props: YandexMapIncidentPlanProps) => {
  const { url } = props;
  const { image } = useCreateSquareImage({ url });
  const data = useIncidentMap();
  const { ymapsInstance } = useYandexMap();
  const { onMarkerClick, locationPoints, incidentPoints, onClusterClick, onDraggableMarker } = useMemo(
    () => normalizeYandexMapData(data),
    [data],
  );

  if (!ymapsInstance || !image || image.width === 0 || image.height === 0) {
    return <MapLoader />;
  }

  const { YMapFeatureDataSource, YMapLayer, YMapTileDataSource } = ymapsInstance.ymaps;
  const { YMapClusterer, clusterByGrid } = ymapsInstance.clusterer;
  const { Cartesian } = ymapsInstance.projection;

  const incidentMarker = (feature: Feature) => {
    const updateFeature = feature as CustomFeatureIncident;
    return (
      <IncidentMarker
        draggable
        onDragEnd={onDraggableMarker(updateFeature.properties)}
        ymapsInstance={ymapsInstance}
        coordinates={updateFeature.geometry.coordinates}
        source={SOURCE}
        onClick={onMarkerClick(updateFeature.properties.metadata)}
        color={updateFeature.properties.color}
        properties={{
          hint: updateFeature.properties.hint,
        }}
      />
    );
  };

  const cluster = (coordinates: LngLat, features: Feature[]) => {
    const updateFeature = features as (CustomFeatureIncident | CustomFeatureLocation)[];
    const metadata = updateFeature.map((feature) => feature.properties.metadata);
    return (
      <Cluster
        count={features.length}
        ymapsInstance={ymapsInstance}
        coordinates={coordinates}
        source={SOURCE}
        onClick={onClusterClick(metadata)}
      />
    );
  };

  const method = clusterByGrid({ gridSize: 64 });

  return (
    <YandexMap
      location={{ center: [0, 0], zoom: 1 }}
      mode='raster'
      worldOptions={{ cycledX: false }}
      projection={
        new Cartesian(
          [
            [-image.width, -image.height],
            [image.width, image.height],
          ],
          [false, false],
        )
      }
    >
      <YMapTileDataSource
        id='custom'
        raster={{
          type: 'ground',
          fetchTile: (x, y, z) =>
            new Promise((resolve) => {
              const canvas = document.createElement('canvas');
              canvas.width = CROP_SIZE;
              canvas.height = CROP_SIZE;

              const dimenssion = 2 ** z;
              const resize = CROP_SIZE * dimenssion;

              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(image, x * -CROP_SIZE, y * -CROP_SIZE, resize, resize);
              }

              resolve({ image: canvas });
            }),
        }}
      />
      <YMapLayer source='custom' type='ground' zIndex={5} />
      <YMapFeatureDataSource id={SOURCE} />
      <YMapLayer source={SOURCE} type='markers' zIndex={1800} />
      <Hint ymapsInstance={ymapsInstance} />

      <YMapClusterer method={method} features={incidentPoints} marker={incidentMarker} cluster={cluster} />

      {locationPoints.map((locationMarker) => (
        <LocationMarker
          view='primary'
          key={locationMarker.properties.key}
          ymapsInstance={ymapsInstance}
          label={locationMarker.properties.label}
          coordinates={locationMarker.geometry.coordinates}
          onClick={onMarkerClick(locationMarker.properties.metadata)}
          properties={{
            hint: locationMarker.properties.hint,
          }}
        />
      ))}
    </YandexMap>
  );
};

export const YandexMapIncidentPlan = (props: YandexMapIncidentPlanProps) => (
  <YandexMapProvider>
    <YandexMapIncidentPlanBase {...props} />
  </YandexMapProvider>
);
