import React, { useCallback, useState } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useConfirmLeavePage from '../../hooks/useConfirmLeavePage';
import { Drawer, useShowDrawer } from '../../state/drawer';
import { userEventState } from '../../state/event';
import { userState } from '../../state/user';
import { usersState } from '../../state/users';
import type { User } from '../../types';
import checkLimit from '../../utils/checkLimit';
import { Button, Icon, CenteredText } from '../base';
import { UserEventFormRow } from './components/UserEventFormRow';

const iconStyles = { width: '17px', height: '17px', marginRight: '10px' };

export const Users = () => {
  const { t } = useTranslation();
  const users = useRecoilValue(usersState);
  const currentUser = useRecoilValue(userState);
  const { execute: showDrawer } = useShowDrawer();
  const userEvent = useRecoilValue(userEventState);
  const [changedUserEvents, setChangedUserEvents] = useState<Record<string, boolean>>({});
  const canAddNewUser = currentUser.isOwner || checkLimit(userEvent?.event.limitUsers, users.length);
  const handleChange = useCallback(
    (id: number | undefined, hasChanges: boolean) => {
      if (id) {
        setChangedUserEvents((oldValue) => ({
          ...oldValue,
          [id.toString()]: hasChanges,
        }));
      }
    },
    [setChangedUserEvents],
  );
  const usersHasChanges = Object.values(changedUserEvents).some(Boolean);
  const { confirmModal } = useConfirmLeavePage(usersHasChanges, t('you-have-unsaved-changes-confirm-leave'));

  return (
    <>
      {canAddNewUser ? (
        <Box pl={2} pt={2} pr={2}>
          <Button
            type='submit'
            sx={{ marginTop: 'auto', color: 'white' }}
            variant='contained'
            onClick={() => showDrawer({ type: Drawer.USER_CREATE })}
          >
            <Icon icon='plus' sx={iconStyles} />
            <Trans i18nKey='add-new-user' />
          </Button>
        </Box>
      ) : (
        <CenteredText sx={{ marginTop: '50px' }}>
          <Trans i18nKey='limit-users-for-event-reached-out' />
        </CenteredText>
      )}
      {users.length > 0 ? (
        <Box p={2}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans i18nKey='id' />
                    </TableCell>
                    <TableCell>
                      <Trans i18nKey='name' />
                    </TableCell>
                    <TableCell>
                      <Trans i18nKey='email' />
                    </TableCell>
                    <TableCell>
                      <Trans i18nKey='role' />
                    </TableCell>
                    <TableCell>
                      <Trans i18nKey='user-function' />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: User) => (
                    <UserEventFormRow onChange={handleChange} user={user} key={user.id} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      ) : (
        <CenteredText sx={{ marginTop: '50px' }}>
          <Trans i18nKey='users-list-is-empty' />
        </CenteredText>
      )}
      {confirmModal}
    </>
  );
};
