/* eslint-disable @typescript-eslint/no-empty-function */
import { useRef, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '../components/base';

type UseConfirmDialogProps = {
  title?: string;
  content: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
};

export const useConfirmDialog = ({
  title,
  content,
  confirmBtnText,
  cancelBtnText,
}: UseConfirmDialogProps) => {
  const { t } = useTranslation();
  const confirmBtnTextMsg = confirmBtnText ?? t('ok');
  const cancelBtnTextMsg = cancelBtnText ?? t('cancel');
  const [isOpen, setIsOpened] = useState(false);
  const onConfirmRef = useRef<() => void>(() => {});
  const onCancelRef = useRef<() => void>(() => {});

  const showConfirm = useCallback(() => new Promise<boolean>((resolve) => {
    onConfirmRef.current = () => {
      resolve(true);
    };
    onCancelRef.current = () => {
      resolve(false);
    };
    setIsOpened(true);
  }), []);

  const handleCancel = useCallback(() => onCancelRef.current?.(), [onCancelRef]);
  const handleConfirm = useCallback(() => onConfirmRef?.current(), [onConfirmRef]);

  return {
    isOpen,
    showConfirm,
    confirmModal: (
      <ConfirmDialog
        isOpen={isOpen}
        onRequestClose={() => setIsOpened(false)}
        title={title}
        confirmBtnText={confirmBtnTextMsg}
        cancelBtnText={cancelBtnTextMsg}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      >
        {content}
      </ConfirmDialog>
    ),
  };
};
