import { useCallback } from 'react';

import { nanoid } from 'nanoid';

import useDrawer from '../../../../../hooks/useDrawer';
import { Drawer } from '../../../../../state/drawer';
import { TypeLocation, type LocationForm } from '../../../../../state/locationsForm';
import type { GeoCoordinates } from '../../../../../types';

export function useLocationFormDrawer() {
  const { showDrawer } = useDrawer();

  const onMapClick = useCallback(
    (latLng: GeoCoordinates) => {
      showDrawer({
        type: Drawer.EVENT_LOCATION,
        props: {
          locationForm: {
            type: TypeLocation.New,
            key: nanoid(),
            geo: latLng,
          },
        },
      });
    },
    [showDrawer],
  );

  const onMarkerClick = useCallback(
    (location: LocationForm) => () => {
      showDrawer({
        type: Drawer.EVENT_LOCATION,
        props: {
          locationForm: location,
        },
      });
    },
    [showDrawer],
  );

  return {
    onMarkerClick,
    onMapClick,
  };
}
