/* eslint-disable @typescript-eslint/no-explicit-any */
const normalizeData = (response: Record<string, any>): Record<string, any> => {
  if (Array.isArray(response)) {
    return response.map((e: any) => normalizeData(e));
  }

  if (response && response.data !== undefined) {
    return response.meta ? {
      meta: response.meta,
      data: normalizeData(response.data),
    } : normalizeData(response.data);
  }

  if (response && response.attributes) {
    const attrs: Record<string, any> = {};

    Object.keys(response.attributes).forEach((key) => {
      attrs[key] = normalizeData(response.attributes[key]);
    });

    return {
      id: response.id,
      ...attrs,
    };
  }

  return response;
};

export default normalizeData;
