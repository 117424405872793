import type { RefObject } from 'react';
import { useMemo } from 'react';

type Dimension = {
  height: number;
  width: number;
};
type Geos = number[][];
type Bounds = number[][];
export type Center = [number, number];
export type Zoom = number;

const WORLD_CENTER_AND_ZOOM: { center: Center; zoom: Zoom } = {
  center: [34, 40],
  zoom: 2,
};

/**
 * Find bounds on all markers on the map,
 * minimum latitude and minimum longitude (this will be the first point for bounds),
 * and maximum latitude and maximum longitude (this will be the second point for bounds).
 */
function getBounds(geo: Geos) {
  return geo.reduce(
    (prev, curr) => {
      const x1 = Math.min(curr[0], prev[0][0]);
      const x2 = Math.max(curr[0], prev[1][0]);
      const y1 = Math.min(curr[1], prev[0][1]);
      const y2 = Math.max(curr[1], prev[1][1]);

      return [
        [x1, y1],
        [x2, y2],
      ];
    },
    [
      [Infinity, Infinity],
      [0, 0],
    ],
  );
}

function getCenter(bounds: Bounds): Center {
  return [bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2, bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2];
}
function getBoundsZoomLevel(bounds: Bounds, mapDim: Dimension): Zoom {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 3;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = [bounds[1][0], bounds[1][1]];
  const sw = [bounds[0][0], bounds[0][1]];

  const latFraction = (latRad(ne[0]) - latRad(sw[0])) / Math.PI;

  const lngDiff = ne[0] - sw[1];
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

function getDimensions(element: HTMLElement | null): Dimension {
  if (!element) {
    return {
      height: 0,
      width: 0,
    };
  }

  return {
    height: element.clientHeight,
    width: element.clientWidth,
  };
}

export function useMapCenterAndZoom(geos: number[][], element: RefObject<HTMLElement>) {
  const bounds = useMemo(() => getBounds(geos), [geos]);
  const center = getCenter(bounds);
  const zoom = getBoundsZoomLevel(bounds, getDimensions(element.current));

  return {
    center: center.some((item) => Number.isNaN(item)) ? WORLD_CENTER_AND_ZOOM.center : center,
    zoom: Number.isNaN(zoom) ? WORLD_CENTER_AND_ZOOM.zoom : zoom,
  };
}
