import type { MouseEvent } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import type { ICellEditorParams } from 'ag-grid-community';

import type { Status } from '../../../../types';
import getAvailableStatuses from '../../../../utils/getAvailableStatuses';
import { StatusBadge } from '../../../base';
import type { CellPermissions } from '../../utils/cellCanBeEdited';

interface StatusEditorProps extends ICellEditorParams, CellPermissions {
  statuses?: Status[];
}

const StatusEditor = forwardRef((props: StatusEditorProps, ref) => {
  const { statuses, permissions, stopEditing } = props;
  const cellData: Status = props.value;
  const availableStatuses = statuses ?? getAvailableStatuses(permissions?.canUpdateAllIncidents, cellData);
  const [currentStatus, setCurrentStatus] = useState<Status>();

  const handleChange = (_: MouseEvent<HTMLElement>, value: Status | null) => {
    setCurrentStatus(value ?? cellData);
  };

  useEffect(() => {
    if (currentStatus !== undefined) {
      stopEditing();
    }
  }, [currentStatus, stopEditing]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return currentStatus || cellData;
    },
  }));

  return (
    <div>
      <ToggleButtonGroup value={cellData} orientation='vertical' exclusive onChange={handleChange}>
        {availableStatuses.map((status) => (
          <ToggleButton sx={{ textTransform: 'none' }} value={status} key={status}>
            <StatusBadge status={status} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
});

export default StatusEditor;
