import { atom, selector } from 'recoil';

import type { UserFunction } from '../../types';
import { userEventState } from '../event';

export const userFunctionsState = selector({
  key: 'userFunctions',
  get: ({ get }) => {
    const currentUserEvent = get(userEventState);

    return currentUserEvent ? currentUserEvent.event.userFunctions : [];
  },
});

export const userFunctionsDefaultState = atom<UserFunction[]>({
  key: 'userFunctionsDefault',
  default: [],
});
