import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useController, type FieldPath, type UseControllerProps, type FieldValues } from 'react-hook-form';

export type DateTimeFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  disabled?: boolean;
  label: string;
} & UseControllerProps<TFieldValues, TName>;

export const DateTimeField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: DateTimeFieldProps<TFieldValues, TName>,
) => {
  const { disabled, label } = props;
  const { field } = useController(props);

  return (
    <DateTimePicker
      {...field}
      disabled={disabled}
      label={label}
      inputFormat='dd/MM/yyyy HH:mm'
      value={field.value ?? null}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
