import type { EventSettings, Location, User, UserEvent, UserFunction } from '../../../../types';
import type { UserFormSettingsProps } from '../../../UserFormSettings';

export function prepareUserFormPermissionsData(
  userEvent: UserEvent,
  locations: Location[],
  userFunctions: UserFunction[],
  eventSettings: EventSettings,
  users: User[],
  user: User,
): UserFormSettingsProps {
  const { permissions } = userEvent;
  const { canViewMaps, canViewDashboard } = permissions;

  const userResolutionLevel = eventSettings.customUserFilters?.resolutionLevel ?? [];
  const userLocations = Array.isArray(eventSettings.customUserFilters?.locations)
    ? eventSettings?.customUserFilters?.locations.reduce<Location[]>((acc, id) => {
        const location = locations.find((l) => l.id === id);

        if (location) {
          acc.push(location);
        }

        return acc;
      }, [])
    : [];
  const userUserFunctions = Array.isArray(eventSettings.customUserFilters?.userFunctions)
    ? eventSettings.customUserFilters?.userFunctions.reduce<UserFunction[]>((acc, id) => {
        const userFunction = userFunctions.find((uf) => uf.id === id);

        if (userFunction) {
          acc.push(userFunction);
        }

        return acc;
      }, [])
    : [];

  const userResponsible = Array.isArray(eventSettings.customUserFilters?.responsible)
    ? eventSettings.customUserFilters?.responsible.reduce<User[]>((acc, id) => {
        const currentUser = users.find((u) => u.id === id);

        if (currentUser) {
          acc.push(currentUser);
        }

        return acc;
      }, [])
    : [];

  const userParticipants = Array.isArray(eventSettings.customUserFilters?.participants)
    ? eventSettings.customUserFilters?.participants.reduce<User[]>((acc, id) => {
        const currentUser = users.find((u) => u.id === id);

        if (currentUser) {
          acc.push(currentUser);
        }

        return acc;
      }, [])
    : [];

  const userRegisteredBy = Array.isArray(eventSettings.customUserFilters?.registeredBy)
    ? eventSettings.customUserFilters?.registeredBy.reduce<User[]>((acc, id) => {
        const currentUser = users.find((u) => u.id === id);

        if (currentUser) {
          acc.push(currentUser);
        }

        return acc;
      }, [])
    : [];

  const userOnlyOwnIncidents = eventSettings.customUserFilters?.onlyOwnIncidents ?? false;

  return {
    user,
    defaultValues: {
      filters: {
        locations: userLocations,
        resolutionLevel: userResolutionLevel,
        userFunctions: userUserFunctions,
        responsible: userResponsible,
        participants: userParticipants,
        registeredBy: userRegisteredBy,
        onlyOwnIncidents: userOnlyOwnIncidents,
      },
      permissions: {
        canViewMaps,
        canViewDashboard,
      },
    },
  };
}
