import { useTranslation } from 'react-i18next';

import type { User } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { UserAvatar, UserChip } from '../../base';
import { AutocompleteMultipleField, type AutocompleteMultipleFieldProps } from '../../fields/AutocompleteMultipleField';
import type { DailyTasksFormFilerValue } from '../lib/types';

export type ResponsibleFieldProps = Omit<
  AutocompleteMultipleFieldProps<DailyTasksFormFilerValue, 'responsible', User>,
  'name' | 'label' | 'getOptionLabel' | 'renderTags' | 'renderOption'
>;

export const ResponsibleField = (props: ResponsibleFieldProps) => {
  const { t } = useTranslation();

  return (
    <AutocompleteMultipleField
      {...props}
      name='responsible'
      getOptionLabel={(user) => getFullName(user)}
      renderTags={(value, getTagProps) =>
        value.map((user, index) => <UserChip user={user} variant='outlined' size='small' {...getTagProps({ index })} />)
      }
      label={t('responsible')}
      renderOption={(liProps, user) => (
        <li {...liProps}>
          <UserAvatar user={user} sx={{ marginRight: '8px' }} />
          {getFullName(user)}
        </li>
      )}
    />
  );
};
