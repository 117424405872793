import formatDate from '../../../../utils/formatDate';
import {
  ClampedTypography,
} from '../../../base';

const DateRenderer = (value: Date) => (
  <ClampedTypography
    variant='date'
    lineclamp={4}
  >
    {formatDate(value)}
  </ClampedTypography>
);

export default DateRenderer;
