import { Button } from '@mui/material';

import { Icon } from '../../../../components/base';

type Props = {
  openGridColumnsSettings: () => void,
  columnsText: string
}

export const ButtonGridColumns = ({ openGridColumnsSettings, columnsText }: Props) => (
  <Button
    variant='outlined'
    color='inherit'
    sx={{ borderColor: '#C7D1D4', height: '40px', fontWeight: 400 }}
    onClick={openGridColumnsSettings}
  >
    {columnsText}
    <Icon icon='showColumn' htmlColor='#C7D1D4' sx={{ width: '17px', height: '17px', marginLeft: '10px' }} />
  </Button>
);
