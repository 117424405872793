import { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { permissionState, useArchiveEvent, userEventState } from '../../state/event';
import { NotificationType, useShowNotification } from '../../state/notification';
import { AppRoute } from '../../types';
import history from '../../utils/history';
import { Button, Icon } from '../base';
import { EventEditForm } from '../EventEditForm';
import { EventForm } from '../EventForm';

export const Event = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const showNewProjectForm = Boolean(query.get('newProject'));
  const [showArchiveConfirmDialog, setShowArchiveConfirmDialog] = useState(false);
  const currentUserEvent = useRecoilValue(userEventState);
  const currentEvent = currentUserEvent?.event;
  const { execute: archiveEvent, loading: archiving } = useArchiveEvent();
  const { execute: showNotification } = useShowNotification();
  const { canCreateEvent, canCloseEvent } = useRecoilValue(permissionState);

  const closeArchiveConfirmDialog = () => {
    setShowArchiveConfirmDialog(false);
  };

  const handleArchiveEvent = () => {
    closeArchiveConfirmDialog();
    if (currentEvent) {
      archiveEvent({
        id: currentEvent.id,
        successCallback: () => {
          showNotification({ type: NotificationType.SUCCESS, text: t('archive-project-success') });
        },
      });
    }
  };

  return (
    <Stack p={2}>
      {showNewProjectForm ? (
        <Stack spacing={2}>
          <IconButton style={{ width: '50px' }} size='large' component={Link} to={`${AppRoute.EVENTS}`} color='primary'>
            <ArrowBack />
          </IconButton>
          <EventForm onSubmit={() => history.push(AppRoute.EVENTS)} />
        </Stack>
      ) : (
        <Stack>
          <Stack pb={3} direction='row' spacing={2} justifyContent='space-between'>
            {canCreateEvent && (
              <Button
                sx={{ marginTop: 'auto', color: 'white' }}
                variant='contained'
                onClick={() => history.push(`${AppRoute.EVENTS}?newProject=true`)}
              >
                <Icon icon='plus' sx={{ width: '17px', height: '17px', marginRight: '10px' }} />
                <Trans i18nKey='add-new-project' />
              </Button>
            )}

            {currentEvent && !currentEvent.isArchived && canCloseEvent && (
              <Button
                sx={{ marginTop: 'auto', color: 'white' }}
                variant='contained'
                color='warning'
                onClick={() => setShowArchiveConfirmDialog(true)}
                loading={archiving}
              >
                <Trans i18nKey='archive-project' />
              </Button>
            )}
          </Stack>

          {currentEvent && <EventEditForm />}
        </Stack>
      )}
      <Dialog
        open={showArchiveConfirmDialog}
        onClose={() => setShowArchiveConfirmDialog(false)}
        onBackdropClick={() => setShowArchiveConfirmDialog(false)}
      >
        <DialogTitle>
          <Trans i18nKey='archive-project' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans i18nKey='archive-project-dialog-confirm-text' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowArchiveConfirmDialog(false)}>
            <Trans i18nKey='cancel' />
          </Button>
          <Button color='warning' onClick={handleArchiveEvent} autoFocus>
            <Trans i18nKey='yes' />
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
