import { format } from 'date-fns';
import type { Gantt } from 'mrreds-rc-gantt';

import type { ProjectTask } from '../../../../modules/Tasks/state/projectTasks';
import type { User } from '../../../../types';

type Data = {
  name: string;
  startDate: string;
  endDate: string;
  responsible: Omit<User, 'userEvents'> | undefined;
  userFunction: string;
};

function getAllChildrens(data: ProjectTask): Gantt.Record<Data>[] {
  const result: Gantt.Record<Data>[] = [];

  if (!data.children || data.children.length === 0) {
    return [];
  }

  data.children.forEach((item) => {
    result.push({
      name: item.title,
      startDate: format(item.startDatetime || new Date(), 'yyyy-LL-dd'),
      endDate: format(item.endDatetime || new Date(), 'yyyy-LL-dd'),
      collapsed: false,
      responsible: item.responsible,
      userFunction: item.userFunction?.name ?? '',
      children: getAllChildrens(item as unknown as ProjectTask),
    });
  });

  return result;
}

interface TransformDataForGanttParams {
  projectTasks: ProjectTask[];
}

export const transformDataForGantt = (params: TransformDataForGanttParams): Gantt.Record<Data>[] => {
  const { projectTasks } = params;

  return projectTasks.map<Gantt.Record<Data>>((item) => ({
    name: item.title,
    startDate: format(item.startDatetime || new Date(), 'yyyy-LL-dd'),
    endDate: format(item.endDatetime || new Date(), 'yyyy-LL-dd'),
    collapsed: false,
    responsible: item.responsible,
    userFunction: item.userFunction?.name ?? '',
    children: getAllChildrens(item),
  }));
};
