import { useEffect } from 'react';

import useCurrentUserEvent from '../../../hooks/useCurrentUserEvent';
import type { Incident, User } from '../../../types';

function useUserFunction(
  responsible: Incident['responsible'],
  setValue: (name: 'userFunction', value: Incident['userFunction']) => void,
  users: User[],
) {
  const watchedUser = users.find((user) => responsible?.id === user.id);
  const userEvent = useCurrentUserEvent(watchedUser);
  useEffect(() => {
    if (userEvent) {
      setValue('userFunction', userEvent.function);
    }
  }, [userEvent, setValue]);

  return {
    isDisabled: Boolean(responsible),
  };
}

export default useUserFunction;
