export interface ClusterMarkerProps {
  count: number;
}

export const ClusterMarker = ({ count }: ClusterMarkerProps) => (
  <div
    style={{
      width: '1px',
      height: '1px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src='https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png'
      alt='cluster'
    />
    <div style={{ position: 'absolute', fontWeight: 'bold', fontFamily: 'Roboto, Arial, sans-serif', fontSize: 12 }}>
      {count}
    </div>
  </div>
);
