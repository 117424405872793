import { useCallback } from 'react';

import type { TFunction } from 'i18next';

const translatable = [
  'loadingOoo',
  'noRowsToShow',
  'to',
  'of',
  'firstPage',
  'previousPage',
  'nextPage',
  'lastPage',
  'ariaSortableColumn',
  'ariaRowSelect',
] as const;

type GetLocaleTextParams = { key: string, defaultValue: string }
export const useGetLocaleText = (t: TFunction<'translation'>) => useCallback((params: GetLocaleTextParams) => {
  const translateKey = translatable.find((key) => key === params.key);
  if (translateKey) {
    return t(translateKey);
  }

  return params.defaultValue;
}, [t]);
