import { Status } from '../../../types';
import { StatusBadge } from '../../base';
import { ButtonGroupField, type ButtonGroupFieldProps } from '../../fields/ButtonGroupField';
import type { DailyTasksFormFilerValue } from '../lib/types';

type StatusFieldProps = Omit<ButtonGroupFieldProps<DailyTasksFormFilerValue, 'status'>, 'name' | 'options'>;

export const StatusField = (props: StatusFieldProps) => (
  <ButtonGroupField
    {...props}
    name='status'
    options={[
      { value: Status.REGISTERED, children: <StatusBadge status={Status.REGISTERED} /> },
      { value: Status.RESOLVED, children: <StatusBadge status={Status.RESOLVED} /> },
    ]}
  />
);
