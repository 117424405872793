import { equals, pick } from 'remeda';

import type { LocationControlItem } from '../../state/locationsForm';
import type { Location } from '../../types';

const pickLocationData = pick<Location | LocationControlItem, 'title' | 'description' | 'geo'>([
  'title',
  'description',
  'geo',
]);

const equalsMappedList = <T, K>(listA: T[], listB: T[], mapper: (value: T) => K): boolean => {
  if (listA.length !== listB.length) {
    return false;
  }
  const listAData = listA.map(mapper);
  const listBData = listB.map(mapper);

  return equals(
    listAData,
    listBData,
  );
};

const equalsLocations = (existingLocations: Location[], locations: LocationControlItem[]): boolean =>
  equalsMappedList<Location | LocationControlItem, ReturnType<typeof pickLocationData>>(
    existingLocations,
    locations,
    pickLocationData,
  );

type Diff<T, K = T> = { old: T, new: K };
type FormDiff = {
  name: Diff<string>,
  description: Diff<string>,
  userFunctions: Diff<string[]>,
  locations: Diff<Location[], LocationControlItem[]>
}

const formHasChanges = ({
  name,
  description,
  userFunctions,
  locations,
}: FormDiff) => {
  const isBaseValuesChanged = [
    name, description, userFunctions,
  ].some((value) => !equals(value.old, value.new));
  const isLocationsChanged = !equalsLocations(locations.old, locations.new);

  return isBaseValuesChanged || isLocationsChanged;
};

export {
  equalsLocations,
  formHasChanges,
};
