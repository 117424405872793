import type { EditableCallback } from 'ag-grid-community';

import { Status } from '../../../../../../types';
import type { ProjectTasksGridContext } from '../types';

export const editableStatus: EditableCallback = (params) => {
  const { context, data } = params;
  const { permissions, currentUser } = context as ProjectTasksGridContext;
  const { canUpdateProjectTask, canUpdateResolvedProjectTask, canUpdateAllProjectTask } = permissions;
  const { id: currentUserId } = currentUser;

  if (!canUpdateProjectTask) {
    return false;
  }

  if (data.status === Status.RESOLVED && !canUpdateResolvedProjectTask) {
    return false;
  }

  if (!canUpdateAllProjectTask) {
    const responsibleChangeStatus = data.responsible?.id === currentUserId;

    if (!responsibleChangeStatus) {
      return false;
    }
  }

  return true;
};
