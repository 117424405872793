import type { FC } from 'react';

import { Grid, Stack } from '@mui/material';

import type { CommentActionEditDescription } from '../../../../types';
import getPhotoFormats from '../../../../utils/getPhotoFormats';
import {
  ClampedTypography, FallbackImage,
} from '../../../base';

type EditCommentDataProps = {
  actionDescription: CommentActionEditDescription
}

export const EditIncidentAction: FC<EditCommentDataProps> = ({ actionDescription }) => {
  const comments = actionDescription.attrs;
  const thumbnailImage = comments.new.image ? getPhotoFormats(comments.new.image).thumbnailSizeImage : undefined;

  return (
    <Stack gap='8px' spacing={2} direction='column' alignItems='left'>
      {thumbnailImage && (
        <FallbackImage
          sx={{
            marginBottom: '8px',
            height: '55px',
            maxHeight: '55px',
            borderRadius: '8px',
            objectFit: 'contain',
            width: 'fit-content',
          }}
          src={thumbnailImage.url}
          alt={thumbnailImage.name}
          mime={thumbnailImage.mime}
          url={thumbnailImage.url}
          name={thumbnailImage.name}
          aria-hidden='true'
        />
      )}

      {comments.old.body && comments.new.body && (
        <Grid container display='flex' flexDirection='column'>
          <Grid item padding={2} marginBottom={2} sx={{ backgroundColor: '#f2aeae' }}>
            <ClampedTypography lineclamp={3} variant='body2' width='100%'>
              {comments.old.body}
            </ClampedTypography>
          </Grid>
          <Grid item padding={2} sx={{ backgroundColor: '#c5f6c6' }}>
            <ClampedTypography lineclamp={3} variant='body2' width='100%'>
              {comments.new.body}
            </ClampedTypography>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
