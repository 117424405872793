import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  lineclamp?: number;
  wordBreak?: 'normal' | 'break-all' | 'break-word' | 'keep-all';
} & TypographyProps;

const ClampedTypography = styled(Typography)<Props>(({ lineclamp, wordBreak = 'break-word' }) => ({
  textAlign: 'left',
  WebkitLineClamp: lineclamp,
  WebkitBoxOrient: 'vertical',
  wordBreak,
  display: '-webkit-box',
  overflow: 'hidden',
  whiteSpace: 'pre-line',
}));

export default ClampedTypography;
