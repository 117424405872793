import getHashOfString from './getHashOfString';

const H_RANGE = [0, 360];
const S_RANGE = [50, 75];
const L_RANGE = [25, 60];

const normalizeHash = (hash: number, min: number, max: number) => Math.floor((hash % (max - min)) + min);

const stringToColor = (name: string) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, H_RANGE[0], H_RANGE[1]);
  const s = normalizeHash(hash, S_RANGE[0], S_RANGE[1]);
  const l = normalizeHash(hash, L_RANGE[0], L_RANGE[1]);

  return `hsl(${h}, ${s}%, ${l}%)`;
};

export default stringToColor;
