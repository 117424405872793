import { Stack } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';

import { ControlPanel } from '../../components/ControlPanel';
import { FullScreen } from '../../components/FullScreen';
import { IncidentTable } from '../../components/IncidentTable';
import { IncidentUpdates } from '../../components/IncidentUpdates';
import { userState } from '../../state/user';

const Incidents = () => {
  const currentUser = useRecoilValueLoadable(userState).valueMaybe();

  if (!currentUser) {
    return null;
  }

  return (
    <FullScreen>
      <Stack
        sx={{
          height: '100%',
          background: '#ffffff',
        }}
      >
        <ControlPanel />
        <IncidentTable />
        <IncidentUpdates />
      </Stack>
    </FullScreen>
  );
};

export default Incidents;
