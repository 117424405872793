import type { FC } from 'react';

import type { CheckboxProps } from '@mui/material';
import { Checkbox as MUICheckbox } from '@mui/material';

import Icon from './Icon';

type Props = Omit<CheckboxProps, 'icon'>

const Checkbox: FC<Props> = (props) => (
  <MUICheckbox
    icon={<Icon icon='checkboxEmpty' htmlColor='#C7D1D4' />}
    checkedIcon={<Icon icon='checkbox' />}
    {...props}
  />
);

export default Checkbox;
