import type { ReactNode } from 'react';

import type {
  ButtonProps} from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trans } from 'react-i18next';

import { Button } from '.';

type Props = {
  confirmButtonProps?: ButtonProps;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: ReactNode;
  content: ReactNode;
}

const ConfirmActionDialog = ({
  confirmButtonProps,
  onCancel,
  onConfirm,
  open,
  title,
  content,
}: Props) => (
  <Dialog open={open} onClose={onCancel} onBackdropClick={onCancel}>
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        <Trans i18nKey='cancel' />
      </Button>
      <Button color='warning' onClick={onConfirm} autoFocus {...confirmButtonProps}>
        <Trans i18nKey='yes' />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmActionDialog;
