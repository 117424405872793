import { useEffect } from 'react';

import {
  Box, Button, Checkbox, Container, FormControlLabel, Typography, SvgIcon,
} from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';

import { TrimTextField } from '../components/base';
import PasswordField from '../components/base/PasswordField';
import { authState, useLogin } from '../state/auth';

type LoginForm = {
  identifier: string,
  password: string,
}

const Login = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const { execute: login } = useLogin();
  const form = useForm<LoginForm>({
    defaultValues: {
      identifier: '',
      password: '',
    },
  });

  const {
    handleSubmit, control, formState: { errors },
  } = form;

  const onSubmit = ({ identifier, password }: LoginForm) => {
    login({ identifier, password });
  };

  useEffect(() => {
    if (auth) {
      navigate('/');
    }
  }, [auth, navigate]);

  return (
    <FormProvider {...form}>
      <Container
        maxWidth='xs'
        sx={{ display: 'flex', alignItems: 'center' }}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SvgIcon sx={{ fontSize: 200, marginBottom: 24 }} inheritViewBox />
          <Typography variant='h4'>
            <Trans i18nKey='login' />
          </Typography>

          <Box sx={{ mt: 1 }}>
            <Controller
              name='identifier'
              rules={{ required: true, validate: (value) => Boolean(value.trim()) }}
              control={control}
              render={({ field }) => (
                <TrimTextField
                  autoFocus
                  autoComplete='email'
                  placeholder='Email Address'
                  label='Email Address'
                  margin='normal'
                  error={Boolean(errors.identifier)}
                  fullWidth
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name='password'
              rules={{ required: true, validate: (value) => Boolean(value.trim()) }}
              control={control}
              render={({ field }) => (
                <PasswordField
                  autoFocus
                  autoComplete='current-password'
                  type='password'
                  placeholder='Password'
                  label='Password'
                  margin='normal'
                  error={Boolean(errors.password)}
                  fullWidth
                  required
                  {...field}
                />
              )}
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2, color: '#FFFFFF' }}

            >
              <Trans i18nKey='login' />
            </Button>
          </Box>
        </Box>
      </Container>
    </FormProvider>
  );
};

export default Login;
